import React from 'react'

export default function Calendar(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0.63 24 24" stroke="#747894" {...props}>
    <g clipPath="url(#clip0_27_1134)">
      <path d="M9 11.875C13.1421 11.875 16.5 10.364 16.5 8.5C16.5 6.63604 13.1421 5.125 9 5.125C4.85786 5.125 1.5 6.63604 1.5 8.5C1.5 10.364 4.85786 11.875 9 11.875Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.5 8.5V12.25C1.5 14.1137 4.85812 15.625 9 15.625C13.1419 15.625 16.5 14.1137 16.5 12.25V8.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6 11.5938V15.3438" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.5 9.69247C19.9237 10.0056 22.5 11.3668 22.5 13C22.5 14.8637 19.1419 16.375 15 16.375C13.1625 16.375 11.4787 16.0778 10.1747 15.5837" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.5 15.5575V16.75C7.5 18.6137 10.8581 20.125 15 20.125C19.1419 20.125 22.5 18.6137 22.5 16.75V13" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18 16.0938V19.8438" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 11.5938V19.8438" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_27_1134">
        <rect width="24" height="24" fill="white" transform="translate(0 0.625)"/>
      </clipPath>
    </defs>
  </svg>
}
