import { observer } from 'mobx-react-lite'
import React, { ReactElement, useEffect, useState } from 'react'
import { RADOM_COLORS } from '../util/Constants'
import { PrimaryButton, SecondaryButton } from './Button'
import Modal from './Modal'
import Treasury from '../state/Treasury'
import { errorToast, shortAddress, successToast } from '../util/Util'
import Radom, { WithdrawalAccount } from '../state/Radom'
import NumberInput from './NumberInput'
import { getMethod } from '../util/Managed'
import styled from 'styled-components'
import Dropdown, { DropdownItem } from './Dropdown'
import { useNavigate } from 'react-router-dom'
import LoadingBar from './LoadingBar'

const WithdrawalRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  flex-grow: 1;
  border-bottom: 1px solid;
  border-color: ${RADOM_COLORS.GRAY_DARK};
  padding-bottom: 15px;
  border-radius: 5px;
  cursor: pointer;

  &:last-child {
    border: 0;
  }
`

export interface WithdrawableAsset {
  network: string
  token?: string
  maxAmount: string
}

interface IProps {
  modalOpen: boolean
  onModalOpen: (open: boolean) => void
  assetToWithdraw: WithdrawableAsset
}

const AssetWithdrawalModal = observer((props: IProps): ReactElement => {
  const navigate = useNavigate()

  const [amountToWithdraw, setWithdrawalAmount] = useState('')
  const [withdrawalAccount, setWithdrawalAccount] = useState<WithdrawalAccount>()
  const [isWithdrawing, setIsWithdrawing] = useState(false)

  const [logoUrl, setLogoUrl] = useState('')
  const [assetName, setAssetName] = useState('')

  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false)
  const [withdrawalAccounts, setWithdrawalAccounts] = useState<WithdrawalAccount[]>([])

  const filteredAccounts = withdrawalAccounts
    .filter(a =>
      a.accountDetails.Crypto &&
      a.accountDetails.Crypto.network === props.assetToWithdraw.network
    )

  useEffect(() => {
    setIsLoadingAccounts(true)
    Radom
      .listWithdrawalAccounts()
      .then(a => setWithdrawalAccounts(a))
      .finally(() => setIsLoadingAccounts(false))
  }, [])

  useEffect(() => {
    setWithdrawalAmount(props.assetToWithdraw.maxAmount)

    const { network, token } = props.assetToWithdraw
    setLogoUrl(getMethod(network, token).logo)
    setAssetName(getMethod(network, token).name)

    setWithdrawalAmount(props.assetToWithdraw.maxAmount)
  }, [props.assetToWithdraw])

  const onTokenWithdrawal = async (): Promise<void> => {
    if (!withdrawalAccount) {
      return
    }

    let isError = false

    setIsWithdrawing(true)
    const { network, token } = props.assetToWithdraw
    try {
      await Radom.submitWithdrawalRequest(network, amountToWithdraw, withdrawalAccount.id, token)
      successToast('Successfully submitted withdrawal request')
      Treasury.loadBalances()
    } catch (err) {
      isError = true
      console.error(err)
      errorToast(err.reason || err.message || err.toString())
    }
    setIsWithdrawing(false)

    if (!isError) {
      props.onModalOpen(false)
    }
  }

  return (
    <Modal visible={props.modalOpen}
      innerStyles={{ overflow: 'hidden' }}
      onClose={() => props.onModalOpen(false)}
      title='Withdraw'>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}>
        <div style={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          justifyContent: 'start',
          gap: 10,
          padding: filteredAccounts.length > 0 ? '0 30px' : '0 30px 30px 30px'
        }}>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: 15 }}>
            <WithdrawalRow>
              <div style={{ display: 'flex', flexShrink: 0, columnGap: 5 }}>
                <div style={{
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  backgroundImage: `url(${logoUrl})`,
                  minWidth: 35,
                  height: 35
                }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontSize: 12, opacity: 0.5 }}>{assetName}</span>
                  <span style={{ fontSize: 16, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    { props.assetToWithdraw.maxAmount }
                  </span>
                </div>
              </div>
            </WithdrawalRow>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <div style={{ fontSize: 14 }}>Withdrawal</div>
            <div style={{ display: 'flex', flexGrow: 1, gap: 10 }}>
              <NumberInput
                disabled={isWithdrawing}
                autoFocus
                value={amountToWithdraw}
                onUserInput={e => setWithdrawalAmount(e)}
                fontSize='14'
                align='right'
                required={true}
              />
              <SecondaryButton
                style={{ padding: '10px 15px' }}
                disabled={isWithdrawing}
                onClick={() => setWithdrawalAmount(props.assetToWithdraw.maxAmount)}>
                Max
              </SecondaryButton>
            </div>

            <div style={{ fontSize: 14, marginTop: 10 }}>Withdrawal address</div>

            {
              isLoadingAccounts
                ? <LoadingBar style={{ borderRadius: 25, width: '100%', height: 40 }} />
                : filteredAccounts.length > 0
                  ? <Dropdown
                    disabled={isLoadingAccounts}
                    selectedContent={
                      withdrawalAccount
                        ? <div
                          style={{
                            fontSize: 14,
                            alignItems: 'center',
                            display: 'flex',
                            gap: 10
                          }}>
                          {
                            withdrawalAccount.accountDetails.Crypto &&
                            <img
                              src={getMethod(withdrawalAccount.accountDetails.Crypto.network).logo}
                              style={{
                                height: 20,
                                width: 'auto',
                                maxWidth: 20
                              }} />
                          }
                          <span style={{
                            maxWidth: 180,
                            textOverflow: 'ellipsis',
                            whiteSpace: 'pre',
                            overflow: 'auto'
                          }}>{withdrawalAccount.name}</span>
                          {
                            withdrawalAccount.accountDetails.Crypto &&
                            <span style={{ opacity: 0.5 }}>
                              ({shortAddress(withdrawalAccount.accountDetails.Crypto.address)})
                            </span>
                          }
                        </div>
                        : <span style={{ fontSize: 14 }}>Select account</span>
                    }
                    dropdownContent={
                      filteredAccounts
                        .filter(t => t.id !== withdrawalAccount?.id)
                        .map(t =>
                          <DropdownItem
                            key={t.name}
                            onClick={() => {
                              setWithdrawalAccount(t)
                            }}
                            style={{
                              fontSize: 14,
                              alignItems: 'center',
                              display: 'flex',
                              gap: 10
                            }}>
                            {
                              t.accountDetails.Crypto &&
                              <img
                                src={getMethod(t.accountDetails.Crypto.network).logo}
                                style={{
                                  height: 20,
                                  width: 'auto',
                                  maxWidth: 20
                                }} />
                            }
                            <span style={{
                              maxWidth: 180,
                              textOverflow: 'ellipsis',
                              whiteSpace: 'pre',
                              overflow: 'auto'
                            }}>{t.name}</span>
                            {
                              t.accountDetails.Crypto &&
                              <span style={{ opacity: 0.5 }}>
                                ({shortAddress(t.accountDetails.Crypto.address)})
                              </span>
                            }
                          </DropdownItem>
                        )
                    } />
                  : <SecondaryButton onClick={() => {
                    navigate('/accounts')
                    props.onModalOpen(false)
                  }}>Create withdrawal account</SecondaryButton>

            }

            {/* <TextInput
              spellCheck='false'
              disabled={isWithdrawing}
              autoFocus
              value={withdrawalAddress}
              placeholder='E.g. 0x0000000000000000000000000000000000000000'
              onChange={e => setWithdrawalAddress(e.target.value)} /> */}
          </div>
        </div>
        {
          filteredAccounts.length > 0 &&
          <div style={{
            padding: 30,
            borderRadius: 15,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
          }}>

            <PrimaryButton
              disabled={!amountToWithdraw || !withdrawalAccount}
              isLoading={isWithdrawing}
              style={{ width: '100%' }}
              onClick={onTokenWithdrawal}>
                Withdraw
            </PrimaryButton>
          </div>
        }
      </div>
    </Modal>
  )
})

export default AssetWithdrawalModal
