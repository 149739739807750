import React, { ReactElement } from 'react'
import { ICheckoutSession } from '../state/Radom'
import LinkExternal from '../components/LinkExternal'
import TimeText from '../components/TimeText'
import { PAY_BASE_URL, RADOM_COLORS } from '../util/Constants'
import Table from '../components/Table'
import { StatusBadge } from '../views/CheckoutSessions'
import { ExternalLinkText } from '../views/Events'
import {
  Section, SubTitle, SectionContent, SectionItem, ItemDataThumbnail,
  ProductThumbnail, PaymentDetailsSection
} from './PaymentDetails'
import RefundDetails from './RefundDetails'

interface CheckoutSessionDetailsProps {
  checkoutSession: ICheckoutSession
  netPayment: number
}

const CheckoutSessionDetails = ({ checkoutSession, netPayment }: CheckoutSessionDetailsProps): ReactElement => {
  return <>
    <Section>
      <SubTitle style={{ fontSize: 18 }}>
        Checkout session details
      </SubTitle>

      <SectionContent>
        <SectionItem>
          <SubTitle>ID</SubTitle>
          <ExternalLinkText href={`${PAY_BASE_URL}/checkout/${checkoutSession.id}`} target='_blank'>
            {checkoutSession.id}
            <LinkExternal stroke={RADOM_COLORS.BLACK} width={13} />
          </ExternalLinkText>
        </SectionItem>

        <SectionItem>
          <SubTitle>Status</SubTitle>
          <StatusBadge status={checkoutSession.sessionStatus}>{checkoutSession.sessionStatus}</StatusBadge>
        </SectionItem>

        <SectionItem>
          <SubTitle>Created at</SubTitle>
          <div>
            <TimeText textColor={RADOM_COLORS.BLACK} date={new Date(checkoutSession.createdAt)} />
          </div>
        </SectionItem>

        {
          checkoutSession.items && checkoutSession.items.length > 0 &&
          <SectionItem>
            <SubTitle>Line items</SubTitle>
            <div style={{ fontSize: 14 }}>
              {checkoutSession.items.map((item, i) => <ItemDataThumbnail key={i} p={item} />)}
            </div>
          </SectionItem>
        }

        {
          checkoutSession.products && checkoutSession.products.length > 0 &&
          <SectionItem>
            <SubTitle>Products</SubTitle>
            <div style={{ fontSize: 14 }}>
              {checkoutSession.products.map((item, i) =>
                <ProductThumbnail key={i} p={item} />
              )}
            </div>
          </SectionItem>
        }

      </SectionContent>

      {
        checkoutSession && checkoutSession.metadata.length > 0 &&
        <div style={{ fontSize: 14 }}>
          <SubTitle style={{ fontSize: 14, paddingBottom: 10 }}>Metadata</SubTitle>
          <Table
            disableHoverStyle
            headers={[
              <span key="productType">Key</span>,
              <span key="quantity">Value</span>
            ]}
            items={
              checkoutSession.metadata.map(p => [
                <span key="type">{p.key}</span>,
                <span key="quantity">{p.value}</span>
              ])
            }
            isLoading={false}
          />
        </div>
      }
    </Section>

    {
      checkoutSession.payment?.managed &&
      <PaymentDetailsSection payment={checkoutSession.payment?.managed} netPayment={netPayment} />
    }

    {
      checkoutSession?.payment?.managedTokenDelegatePayment &&
      <PaymentDetailsSection payment={checkoutSession.payment.managedTokenDelegatePayment} netPayment={netPayment} />
    }

    {
      checkoutSession.refunds.length > 0 &&
      <RefundDetails refunds={checkoutSession.refunds} />
    }
  </>
}

export default CheckoutSessionDetails
