import { addDays, addMonths, format, getDate, getDay, getDaysInMonth, startOfMonth, startOfWeek, subMonths } from 'date-fns'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Chevron } from '../icons/Chevron'
import { RADOM_COLORS } from '../util/Constants'
import Dropdown from './Dropdown'
import { Calendar as CalendarIcon } from '../icons/Calendar'

const Calendar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  cursor: default;
`

const CalendarHeader = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
`

const CalendarGrid = styled.div`
  display: grid;
  align-items: center;
  row-gap: 6px;
  justify-content: center;
  grid-template-columns: repeat(7, 1fr);
  width: 300px;
`

const MonthArrow = styled(Chevron)`
  width: 10px;
  height: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 100%;

  :hover {
    background-color: ${RADOM_COLORS.GRAY_MED};
  }
`

const GridItem = styled.div`
  width: 30px;
  height: 30px;
  line-height: 30px;
`

const Day = styled(GridItem)<{ disabled?: boolean, selected?: boolean }>`
  text-align: center;
  border-radius: 100%;

  :hover {
    background-color: ${({ selected, disabled }) => selected ? RADOM_COLORS.NEW_BLUE : disabled ? 'transparent' : RADOM_COLORS.GRAY_MED}
  }

  ${({ selected }) => selected && `background-color: ${RADOM_COLORS.NEW_BLUE};`}
  ${({ selected }) => selected && 'color: white;'}
  ${({ disabled }) => !disabled && 'cursor: pointer;'}
  ${({ disabled }) => disabled && 'opacity: 40%;'}
`

interface IProps {
  value: Date | undefined
  onChange: (date: Date | undefined) => void
  placeholder?: string
  min?: Date
}

const DateSelector = (props: IProps): React.ReactElement => {
  const [onCloseFn, setOnCloseFn] = useState(() => () => {})
  const curDate = new Date()
  const [selectedDate, setSelectedDate] = useState<Date>(props.value ?? curDate)
  const [dateProbe, setDateProbe] = useState<Date>(new Date())

  const firstDOW = startOfWeek(new Date())
  const shortWeekDaysArray = Array.from(Array(7)).map((e, i) => format(addDays(firstDOW, i), 'EEEEEE'))

  // First day of month of curDateProbe
  const probeDay = useMemo(() => {
    return getDay(startOfMonth(dateProbe))
  }, [dateProbe])

  // Number of days in month of curDateProbe
  const probeDaysInMonth = useMemo(() => {
    return getDaysInMonth(dateProbe)
  }, [dateProbe])

  const onDaySelect = (date: number): void => {
    const newDate = new Date(
      dateProbe.getFullYear(),
      dateProbe.getMonth(),
      date
    )
    setSelectedDate(newDate)
    props.onChange(newDate)
    onCloseFn?.()
  }

  const onPrevMonth = (): void => {
    const newDate = subMonths(dateProbe, 1)
    if (
      props.min &&
      newDate.getMonth() < props.min.getMonth() &&
      newDate.getFullYear() <= props.min.getFullYear()
    ) return

    setDateProbe(newDate)
    setSelectedDate(newDate)
    props.onChange(newDate)
  }

  const onNextMonth = (): void => {
    const newDate = addMonths(dateProbe, 1)
    setDateProbe(newDate)
    setSelectedDate(newDate)
    props.onChange(newDate)
  }

  const dayIsDisabled = (date: number): boolean => {
    const day = new Date(dateProbe.getFullYear(), dateProbe.getMonth(), date + 2)
    if (!props.min) return false
    return day < props.min
  }

  return <div style={{ width: 'fit-content' }}>
    <Dropdown
      onCloseFn={fn => setOnCloseFn(() => fn)}
      selectedContent={
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <CalendarIcon style={{ height: 18 }} stroke={RADOM_COLORS.GRAY_DARKER} />
          {props.value && <div>{format(selectedDate, 'MM/dd/yyyy')}</div>}
          {!props.value && props.placeholder}
          {!props.value && !props.placeholder && <span style={{ whiteSpace: 'nowrap' }}>Select date</span>}
        </div>
      }
      dropdownContent={
        <Calendar>
          <CalendarHeader>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <MonthArrow
                onClick={onPrevMonth}
                style={{ transform: 'rotate(90deg)' }}
              />
              <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                <span style={{ fontSize: '16px' }}>{format(dateProbe, 'MMMM')}</span>
                <span style={{ opacity: '30%' }}>{format(dateProbe, 'yyyy')}</span>
              </div>
              <MonthArrow
                onClick={onNextMonth}
                style={{ transform: 'rotate(270deg)' }}
              />
            </div>
          </CalendarHeader>
          <CalendarGrid>
            {
              shortWeekDaysArray.map(d => {
                return <div key={d} style={{ textAlign: 'center', width: '30px', height: '30px' }}>{d}</div>
              })
            }
            {
              Array.from({ length: probeDay }, (_, i) => <GridItem key={i} />)
            }
            {
              Array.from({ length: probeDaysInMonth }, (_, i) => {
                return <Day
                  disabled={dayIsDisabled(i)}
                  selected={getDate(selectedDate) === (i + 1)}
                  onClick={() => onDaySelect(i + 1)}
                  key={i}>
                  <span>{i + 1}</span>
                </Day>
              })
            }
          </CalendarGrid>
        </Calendar>
      }
    />
  </div>
}

export default DateSelector
