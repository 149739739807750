import React, { useEffect, useState } from 'react'
import LineGraph, { GraphData } from '../components/LineGraph'
import { observer } from 'mobx-react'
import Spinner from '../components/Spinner'
import Treasury from '../state/Treasury'
import { PrimaryButton } from '../components/Button'
import { RADOM_COLORS, WINDOW_SIZE } from '../util/Constants'
import { TrendNeutral, Trend } from '../components/TrendCookie'
import { differenceInDays, format, formatDistance, startOfMonth, subDays, subMonths } from 'date-fns'
import styled from 'styled-components'
import Analytics from '../state/Analytics'
import Tooltip from '../components/Tooltip'
import Events from '../state/Events'
import { useNavigate } from 'react-router-dom'
import { errorToast, formatCurrency, useTestMode } from '../util/Util'
import { Chevron } from '../icons/Chevron'
import { Container } from '../components/Animations'
import LoadingBar from '../components/LoadingBar'
import { Tokens } from '../util/Tokens'
import { uniq } from 'lodash'
import { getMethod, getMethodLogoByTicker } from '../util/Managed'
import User from '../state/User'
import Dropdown, { DropdownItem } from '../components/Dropdown'
import VerificationBanner from '../components/VerificationBanner'

const EmptyData = {
  curPeriod: Array(7).fill(0).map((_, i) => ({ x: subDays(new Date(), i).getTime(), y: 0 })).reverse(),
  prevPeriod: Array(7).fill(0).map((_, i) => ({ x: subDays(new Date(), i).getTime(), y: 0 })).reverse()
}

const GraphGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
  
  @media (max-width: ${WINDOW_SIZE.TABLET}) {
    grid-template-columns: auto auto;
  }
  
  @media (max-width: ${WINDOW_SIZE.MOBILE_LARGE}) {
    grid-template-columns: auto;
  }
`

const WithdrawButton = styled(PrimaryButton)`
  padding: 10px 40px;
  font-size: 16px;
  margin: 30px;
  margin-top: 0px;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    svg {
      transform: rotate(-90deg) translateY(5px) !important;
    }
  }
`

interface Point {
  x: number
  y: number
}

interface MetricData {
  name: string
  unitLabel: string
  formatLabel: (value: number) => string
  formatYLabelFn?: (value: number) => string
  aggregateLabelValue?: boolean
  data: {
    curPeriod: Point[]
    prevPeriod: Point[]
  }
  dataFn: (numDays: number, testMode: boolean) => Promise<GraphData>
  tooltipMessage?: string
  isLoading: boolean
  flipTrend?: boolean
}

const calculateMetricTrend = (metric: MetricData, flip: boolean = false): React.ReactElement => {
  const pointOne = metric.data.prevPeriod.reduce((p, n) => p + n.y, 0)
  const pointTwo = metric.data.curPeriod.reduce((p, n) => p + n.y, 0)

  // Calculate percent change
  const _percentage = ((pointTwo - pointOne) / (pointOne || 1)) * 100
  const percentage = isNaN(_percentage) ? 0 : _percentage

  if (percentage > 0) {
    return <Trend percentage={percentage} isGreen={!flip} trendUp />
  }
  if (percentage < 0) {
    return <Trend percentage={percentage} isGreen={!!flip} trendUp={false} />
  }
  return <TrendNeutral />
}

const Home = observer(() => {
  const navigate = useNavigate()

  const [testMode] = useTestMode()
  const [selectedTimeRangeValue, setSelectedTimeRangeValue] = useState(14)
  const [selectedTimeRange, setSelectedTimeRange] = useState('Last 7 days')
  const [dropdownCloseFn, setDropdownCloseFn] = useState(() => () => {})
  const formatCurrencyNew = (v: number): string => formatCurrency(v, User.organization?.defaultCurrency ?? 'USD')

  const [mainMetric, setMainMetric] = useState({
    name: 'Daily gross volume',
    unitLabel: '$',
    formatLabel: (v: number) => formatCurrencyNew(v),
    data: EmptyData,
    dataFn: Analytics.getGrossDailyVolume,
    tooltipMessage: 'Estimated sum of all your revenue today.',
    formatYLabelFn: (v: number) => formatCurrencyNew(v),
    aggregateLabelValue: true,
    isLoading: true
  })
  const [metrics, setMetrics] = useState<MetricData[]>([
    {
      name: 'Gross volume',
      unitLabel: '$',
      formatLabel: (v: number) => formatCurrencyNew(v),
      data: EmptyData,
      dataFn: Analytics.getGrossVolume,
      tooltipMessage: 'Estimated sum of all your revenue.',
      formatYLabelFn: (v: number) => formatCurrencyNew(v),
      aggregateLabelValue: true,
      isLoading: true
    },
    {
      name: 'Net volume',
      unitLabel: '$',
      formatLabel: (v: number) => formatCurrencyNew(v),
      data: EmptyData,
      dataFn: Analytics.getNetVolume,
      tooltipMessage: "Estimated sum of all your revenue minus Radom's protocol fees.",
      formatYLabelFn: (v: number) => formatCurrencyNew(v),
      aggregateLabelValue: true,
      isLoading: true
    },
    {
      name: 'Recurring payments',
      unitLabel: '#',
      formatLabel: (v: number) => v.toString(),
      data: EmptyData,
      dataFn: Analytics.getRecurringPayments,
      aggregateLabelValue: true,
      isLoading: true
    },
    {
      name: 'One-time payments',
      unitLabel: '#',
      formatLabel: (v: number) => v.toString(),
      data: EmptyData,
      dataFn: Analytics.getOneTimePayments,
      aggregateLabelValue: true,
      isLoading: true
    },
    {
      name: 'Total subscribers',
      unitLabel: '#',
      formatLabel: (v: number) => v.toString(),
      data: EmptyData,
      dataFn: Analytics.getTotalSubscribers,
      isLoading: true
    },
    {
      name: 'New subscriptions',
      unitLabel: '#',
      formatLabel: (v: number) => v.toString(),
      data: EmptyData,
      dataFn: Analytics.getNewSubscriptions,
      aggregateLabelValue: true,
      isLoading: true
    },
    {
      name: 'Subscription cancellations',
      unitLabel: '#',
      formatLabel: (v: number) => v.toString(),
      data: EmptyData,
      dataFn: Analytics.getCancelledSubscriptions,
      aggregateLabelValue: true,
      isLoading: true,
      flipTrend: true
    },
    {
      name: 'Subscription expirations',
      unitLabel: '#',
      formatLabel: (v: number) => v.toString(),
      data: EmptyData,
      dataFn: Analytics.getExpiredSubscriptions,
      aggregateLabelValue: true,
      isLoading: true,
      flipTrend: true
    }
  ])

  const loadMetrics = async (numDays: number): Promise<void> => {
    const newMetrics: MetricData[] = []

    try {
      await User.getOrganization()
      await Treasury.loadPriceQuotes()
    } catch {}

    // const org = await User.getOrganization()
    setMetrics(metrics.map(m => ({ ...m, data: EmptyData, isLoading: true })))

    Promise.all(metrics.map(async m => await m.dataFn(numDays, testMode)))
      .then((data) => {
        data.forEach((data, idx) => {
          // Slice data returned from function into curPeriod and prevPeriod
          const curPeriod = data.slice((data.length / 2), data.length)
          const prevPeriod = data.slice(0, (data.length / 2))
          newMetrics.push({
            ...metrics[idx],
            data: {
              curPeriod,
              prevPeriod
            }
          })
        })
      })
      .catch(err => {
        console.error('Failed to load metrics', err)
        errorToast(err.reason || err.message)
      })
      .finally(() => {
        setMetrics(
          newMetrics.map(m => ({
            ...m,
            // formatLabel: (v: number) => formatCurrency(v, org?.defaultCurrency),
            // formatYLabelFn: (v: number) => formatCurrency(v, org?.defaultCurrency),
            isLoading: false
          })))
      })

    Promise.all([mainMetric.dataFn(0, testMode), mainMetric.dataFn(1, testMode)])
      .then(([curPeriod, prevPeriod]) => {
        setMainMetric({
          ...mainMetric,
          isLoading: false,
          data: {
            curPeriod,
            prevPeriod
          }
        })
      })
      .catch(err => {
        console.error('Failed to load metrics', err)
        errorToast(err.reason || err.message)
      })
  }

  useEffect(() => {
    if (!User.organization) {
      return
    }

    Treasury.loadBalances()
    Events.loadEvents(testMode, selectedTimeRangeValue).finally(async () => await loadMetrics(selectedTimeRangeValue))
  }, [testMode, selectedTimeRangeValue, User.organization])

  if (User.isLoading) {
    return <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 'calc(100vh - 180px)'
    }}>
      <Spinner />
    </div>
  }

  return <Container style={{
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
    padding: 40
  }}>

    <VerificationBanner />

    <div>
      <div style={{ marginBottom: 30, display: 'flex', columnGap: 10, alignItems: 'center' }}>
        <div style={{ fontSize: 24 }}>Overview</div>
      </div>
      <div style={{ display: 'flex', gap: 30, flexWrap: 'wrap' }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          width: 'fit-content',
          height: 'fit-content',
          borderRadius: 15,
          border: '1px solid',
          borderColor: RADOM_COLORS.GRAY_DARK,
          overflow: 'hidden',
          boxShadow: `0 1px 1px ${RADOM_COLORS.GRAY_MED}`,
          minWidth: 400
        }}>
          <div style={{
            display: 'flex',
            gap: 40,
            // backgroundColor: RADOM_COLORS.GRAY_LIGHTEST,
            // borderBottom: '1px solid',
            // borderColor: RADOM_COLORS.GRAY_DARK,
            // boxShadow: '0 1px 1px rgb(226 228 239 / 25%)',
            padding: 30,
            paddingBottom: 0,
            justifyContent: 'space-between'
          }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: 'fit-content' }}>
              <div style={{ fontSize: 16, display: 'flex', alignItems: 'center' }}>
                <span style={{ fontWeight: 400 }}>Balance</span>
                <span style={{ marginLeft: 5, marginRight: 5, fontWeight: 300 }}>
                  ({User.organization?.defaultCurrency ?? 'USD'})
                </span>
                <Tooltip message='Estimated balance based on the sum of your assets.' />
              </div>
              <div style={{ fontSize: 38, fontWeight: 300 }}>
                {
                  formatCurrency(Treasury.totalEstimatedDollarBalance(testMode), User.organization?.defaultCurrency ?? 'USD')
                }
              </div>
            </div>

          </div>
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, auto)',
            gridTemplateRows: '1fr',
            gap: 30,
            padding: 30
          }}>
            {
              uniq(
                Treasury.managedBalances.map(b => getMethod(b.network, b.token).ticker)
                  .filter(t => Treasury.assetSum(t, testMode) > 0)
                  .sort((tickerA, tickerB) => Treasury.dollarAssetSum(tickerB, testMode) -
                  Treasury.dollarAssetSum(tickerA, testMode))
                  .concat(['USDC', 'USDT', 'BUSD', 'BAT', 'BTC', 'ETH'])
              )
                .slice(0, 6)
                .map(token =>
                  <div key={token}
                    style={{ display: 'flex', columnGap: 5, flexGrow: 1 }}>
                    <div style={{
                      background: `url(${Tokens[token]?.logo || getMethodLogoByTicker(token)})`,
                      width: 30,
                      height: 30,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: 'contain'
                    }} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span style={{ fontSize: 12, opacity: 0.5 }}>{token}</span>
                      <span style={{ fontSize: 14 }}>
                        {
                          Treasury.loading
                            ? <LoadingBar style={{ height: 15, width: 40 }} />
                            : new Intl.NumberFormat('en-US', { maximumFractionDigits: 4, maximumSignificantDigits: 4 })
                              .format(Number(Treasury.assetSum(token, testMode).toFixed(8)))
                        }
                      </span>
                    </div>
                  </div>
                )
            }
          </div>
          <WithdrawButton
            onClick={() => navigate('/funds')}
            type='button'>
            <span>Withdraw</span>
            <Chevron
              fill={'white'}
              style={{
                opacity: 0.5,
                marginBottom: -1,
                transition: '0.2s ease all',
                transform: 'rotate(-90deg)',
                minWidth: 10,
                height: 'auto'
              }} />
          </WithdrawButton>
        </div>
        <div style={{ display: 'flex', flexGrow: 1 }}>
          {
            <div
              key={mainMetric.name}
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                flexGrow: 1
              }}>
              <div style={{ fontSize: 16, fontWeight: 400, display: 'flex', flexDirection: 'row' }}>
                <span>{mainMetric.name}</span>
                <span style={{
                  marginLeft: 5,
                  marginRight: 5,
                  fontWeight: 200
                }}>({mainMetric.unitLabel})</span>
                {mainMetric.tooltipMessage && <Tooltip message={mainMetric.tooltipMessage} />}
              </div>
              <div style={{ fontSize: 24, fontWeight: 400, display: 'flex', alignItems: 'center', marginBottom: 10, gap: 5 }}>
                <span>
                  {
                    mainMetric.formatLabel(
                      mainMetric.aggregateLabelValue
                        ? mainMetric.data.curPeriod.reduce((t, c) => t + c.y, 0)
                        : mainMetric.data[mainMetric.data.curPeriod.length - 1].y
                    )
                  }
                </span>
                { mainMetric.isLoading
                  ? <LoadingBar style={{ height: 15 }} />
                  : calculateMetricTrend(mainMetric)}
              </div>
              <LineGraph
                isLoading={mainMetric.isLoading || Events.isLoading }
                height={270}
                data={mainMetric.data.curPeriod}
                formatXAxisLabelFn={val => {
                  return format(new Date(val), 'h:mm aaa')
                }}
                formatXLabelFn={val => {
                  return format(val, 'MM/dd/yyyy h:mm aaa')
                }}
                formatYLabelFn={mainMetric.formatYLabelFn}
              />
            </div>

          }
        </div>
      </div>
    </div>

    {/* Analytics */}
    <div>
      <div style={{ marginBottom: 30, display: 'flex', columnGap: 10, alignItems: 'center' }}>
        <div style={{ fontSize: 24 }}>Analytics</div>
        <div>
          <Dropdown
            onCloseFn={f => setDropdownCloseFn(() => f)}
            selectedContent={<span style={{ fontSize: 14 }}>{selectedTimeRange}</span>}
            dropdownContent={
              [
                { label: 'Last 7 days', numDays: 14 },
                { label: 'Last 30 days', numDays: 60 },
                { label: 'Last 3 months', numDays: differenceInDays(new Date(), startOfMonth(subMonths(new Date(), 3))) * 2 },
                { label: 'Last 6 months', numDays: differenceInDays(new Date(), startOfMonth(subMonths(new Date(), 3))) * 4 },
                { label: 'Last year', numDays: differenceInDays(new Date(), startOfMonth(subMonths(new Date(), 3))) * 8 }
              ]
                .map(t =>
                  <DropdownItem
                    key={t.label}
                    style={{ fontSize: 14 }}
                    onClick={async () => {
                      await loadMetrics(t.numDays)
                      setSelectedTimeRange(t.label)
                      setSelectedTimeRangeValue(t.numDays)
                      dropdownCloseFn()
                    }}>
                    {t.label}
                  </DropdownItem>
                )
            }
          />
        </div>
      </div>
      <GraphGrid>
        {
          metrics.map(m =>
            <div
              key={m.name}
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                flexGrow: 1
              }}>
              <div style={{ fontSize: 16, fontWeight: 400, display: 'flex', flexDirection: 'row' }}>
                <span>{m.name}</span>
                <span style={{ marginLeft: 5, marginRight: 5, fontWeight: 200 }}>({m.unitLabel})</span>
                {m.tooltipMessage && <Tooltip message={m.tooltipMessage} />}
              </div>
              <div style={{ fontSize: 24, fontWeight: 400, display: 'flex', alignItems: 'center', marginBottom: 10, gap: 5 }}>
                <span>
                  {
                    m.formatLabel(
                      m.aggregateLabelValue
                        ? m.data.curPeriod.reduce((t, c) => t + c.y, 0)
                        : m.data.curPeriod[m.data.curPeriod.length - 1].y
                    )
                  }
                </span>
                { m.isLoading ? <LoadingBar style={{ height: 15 }} /> : calculateMetricTrend(m, m.flipTrend)}
              </div>
              <LineGraph
                isLoading={m.isLoading || Events.isLoading}
                data={m.data.curPeriod}
                formatXAxisLabelFn={val => {
                  if (val === m.data.curPeriod[m.data.curPeriod.length - 1].x) {
                    return 'now'
                  }
                  return formatDistance(
                    new Date(val),
                    new Date(m.data.curPeriod[m.data.curPeriod.length - 1].x), { addSuffix: false }
                  )
                }}
                formatXLabelFn={val => {
                  if (val === m.data.curPeriod[m.data.curPeriod.length - 1].x) {
                    return 'Today'
                  }
                  return format(val, 'MM/dd/yyyy')
                }}
                formatYLabelFn={m.formatYLabelFn}
              />
            </div>
          )
        }
      </GraphGrid>
    </div>
  </Container>
})

export default Home
