import React, { ReactElement, useEffect, useState } from 'react'
import Table from '../../components/Table'
import { PrimaryButton } from '../../components/Button'
import PageHeader from '../../components/PageHeader'
import Radom, { IPermissionRole } from '../../state/Radom'
import ConfirmButtonModal from '../../components/ConfirmButtonModal'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import Dots from '../../icons/Dots'
import { errorToast, successToast } from '../../util/Util'
import { RADOM_COLORS } from '../../util/Constants'
import { useNavigate } from 'react-router-dom'
import Checkmark from '../../icons/AnimatedCheckmark'

const ADMIN_ROLE_ID = '00000000-0000-0000-0000-000000000000'
const READONLY_ROLE_ID = '00000000-0000-0000-0000-000000000001'

const APIRoles = (): ReactElement => {
  const navigate = useNavigate()
  const [isLoadingRoles, setIsLoadingRoles] = useState(true)
  const [roles, setRoles] = useState([] as IPermissionRole[])

  const aggregatePermissions = (
    permissions: string[]
  ): { [key: string]: string[] } => {
    const perms: { [key: string]: string[] } = { read: [], write: [] }
    permissions.forEach(p => {
      if (p.startsWith('Read')) {
        const r = p.replace('Read', '')
        perms.read.push(r)
      } else if (p.startsWith('Write')) {
        const r = p.replace('Write', '')
        perms.write.push(r)
      }
    })
    return perms
  }

  const listPermissionRoles = (): void => {
    setIsLoadingRoles(true)
    Radom.listPermissionRoles()
      .then(roles => setRoles(roles))
      .finally(() => {
        setIsLoadingRoles(false)
      })
  }

  useEffect(() => {
    listPermissionRoles()
  }, [])

  return <>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <PageHeader title='Roles' subTitle='Manage roles in your team' />
      <PrimaryButton onClick={() => navigate('/role/create')} style={{ gap: 8 }}>
        <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
        <span>Create role</span>
      </PrimaryButton>
    </div>
    <Table
      borderless
      disableHoverStyle
      disableOnClickState
      headers={['Role name', 'Users', 'Permissions', ''].map(h => <span key={h}>{h}</span>)}
      items={
        roles.map(r => [
          <span key="roleName">{r.name}</span>,
          <span key="users">{r.users.length ? r.users.map(u => <div key={u}>{u}</div>) : '-'}</span>,
          <div key="permissions" style={{ display: 'flex', gap: 10 }}>

            {
              r.id === ADMIN_ROLE_ID &&
              <Dropdown
                outerStyle={{
                  backgroundColor: RADOM_COLORS.GRAY_MED,
                  border: 0,
                  boxShadow: 'none'
                }}
                selectedContent={<>All</>}
                noChevron
                dropdownContent={<></>} />
            }
            {
              r.id === READONLY_ROLE_ID &&
              <Dropdown
                outerStyle={{
                  backgroundColor: RADOM_COLORS.GRAY_MED,
                  border: 0,
                  boxShadow: 'none'
                }}
                selectedContent={<>Read only</>}
                noChevron
                dropdownContent={<></>} />
            }

            {
              r.id !== ADMIN_ROLE_ID && r.id !== READONLY_ROLE_ID &&
              aggregatePermissions(r.permissions).read.length > 0 &&

              <Dropdown
                outerStyle={{
                  backgroundColor: RADOM_COLORS.GRAY_MED,
                  border: 0,
                  boxShadow: 'none'
                }}
                selectedContent={<>Read</>}
                dropdownContent={
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{
                      padding: '15px 20px',
                      borderBottom: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
                      color: RADOM_COLORS.GRAY_DARKEST,
                      fontWeight: 500
                    }}>
                      Read permissions
                    </div>
                    <div style={{
                      padding: 20,
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      gap: '10px 40px'
                    }}>
                      {
                        aggregatePermissions(r.permissions).read.map(p =>
                          <div key={p} style={{
                            display: 'flex',
                            width: 'fit-content',
                            borderRadius: 5,
                            color: RADOM_COLORS.GRAY_DARKEST,
                            gap: 5
                          }}>
                            <Checkmark
                              animationDelay='0s'
                              animationSpeed='0s'
                              style={{ width: 20, height: 'auto' }}
                              color={RADOM_COLORS.NICE_GREEN} />
                            <span>{p}</span>
                          </div>
                        )
                      }
                    </div>
                  </div>
                } />

            }

            {
              r.id !== ADMIN_ROLE_ID && r.id !== READONLY_ROLE_ID &&
              aggregatePermissions(r.permissions).write.length > 0 &&
              <Dropdown
                outerStyle={{
                  backgroundColor: RADOM_COLORS.GRAY_MED,
                  border: 0,
                  boxShadow: 'none'
                }}
                selectedContent={<>Write</>}
                dropdownContent={
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{
                      padding: '15px 20px',
                      borderBottom: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
                      color: RADOM_COLORS.GRAY_DARKEST,
                      fontWeight: 500
                    }}>
                    Write permissions
                    </div>
                    <div style={{
                      padding: 20,
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      gap: '10px 40px'
                    }}>
                      {
                        aggregatePermissions(r.permissions).write.map(p =>
                          <div key={p} style={{
                            display: 'flex',
                            width: 'fit-content',
                            borderRadius: 5,
                            color: RADOM_COLORS.GRAY_DARKEST,
                            gap: 5
                          }}>
                            <Checkmark
                              animationDelay='0s'
                              animationSpeed='0s'
                              style={{ width: 20, height: 'auto' }}
                              color={RADOM_COLORS.NICE_GREEN} />
                            <span>{p}</span>
                          </div>
                        )
                      }
                    </div>
                  </div>
                } />

            }
          </div>,
          <div key="actions">
            {
              r.id !== ADMIN_ROLE_ID && r.id !== READONLY_ROLE_ID &&
              <Dropdown
                key={r.id}
                noChevron
                selectedContent={<Dots width={15}/>}
                onCloseFn={() => {}}
                dropdownContent={
                  <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                    <ConfirmButtonModal
                      title='Delete role'
                      description='Are you sure you want to delete this role? All team members with this role will be unassigned.'
                      action='Delete role'
                      onConfirm={async () => {
                        Radom.deletePermissionRole(r.id)
                          .then(() => {
                            successToast('Successfully deleted role')
                            listPermissionRoles()
                          })
                          .catch(err => {
                            errorToast(err.response?.data?.error || err.message)
                          })
                      }}
                      ActionComponent={props =>
                        <DropdownItem {...props}>
                          Delete role
                        </DropdownItem>
                      }
                    />
                  </div>
                }
              />
            }
          </div>
        ])
      }
      isLoading={isLoadingRoles}
      emptyPlaceholderText='No roles created yet'
      onClickRow={() => {}}
    />

  </>
}

export default APIRoles
