import React, { ReactElement } from 'react'
import { RADOM_COLORS } from '../util/Constants'
import styled from 'styled-components'
import Checkmark from '../icons/AnimatedCheckmark'

interface IProps {
  disabled?: boolean
  checked: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  size?: number
  isRadio?: boolean
}

const CheckboxWrapper = styled.div<{ isChecked: boolean, isRadio: boolean, isDisabled?: boolean, size: number }>`
  background-color: ${({ isChecked }) => isChecked ? RADOM_COLORS.NICE_GREEN : '#FFF'};
  border: 1px solid;
  border-color: ${({ isChecked }) => isChecked ? RADOM_COLORS.NICE_GREEN : RADOM_COLORS.GRAY_DARK};
  border-radius: ${({ isRadio }) => isRadio ? '100%' : '5px'};
  opacity: ${({ isDisabled }) => isDisabled ? 0.4 : 1};
  ${({ size }) => `
    min-width: ${size}px;
    max-width: ${size}px;
  `}
  ${({ size }) => `
    min-height: ${size}px;
    max-height: ${size}px;
  `}
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease all;
  position: relative;
  
  :focus-within {
    box-shadow: 0 0 5px ${RADOM_COLORS.NICE_GREEN};
  }
  
  &:hover {
    border-color: ${RADOM_COLORS.GRAY_DARKER};
  }

  &:active {
    opacity: 0.5;
  }
`
const CheckboxInput = styled.input<{ isChecked: boolean }>`
  cursor: pointer;
  appearance: none;
  width: 100%;
  height: 100%;
  outline: none;
`

export default class Checkbox extends React.Component<IProps> {
  render(): ReactElement {
    return <CheckboxWrapper
      size={this.props.size ?? 20}
      isRadio={!!this.props.isRadio}
      isChecked={this.props.checked}
      isDisabled={this.props.disabled}
      onClick={e => {
        if (!this.props.disabled) {
          this.props.onClick?.(e)
        }
      }}>
      <CheckboxInput
        disabled={this.props.disabled}
        onChange={() => {}}
        type="checkbox"
        name="toggle"
        checked={this.props.checked}
        isChecked={this.props.checked} />
      {
        this.props.checked &&
        <Checkmark color='white' animationDelay='0'
          style={{
            width: this.props.size ?? 20,
            height: this.props.size ?? 20,
            position: 'absolute',
            userSelect: 'none'
          }} />
      }
    </CheckboxWrapper>
  }
}
