import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AssociatedEntityType, ManagedPaymentEvent, parseManagedEvent } from '../../state/Events'
import { RADOM_COLORS } from '../../util/Constants'
import { ExternalLinkText } from '../Events'
import LinkExternal from '../../components/LinkExternal'
import Pagination from '../../components/Pagination'
import Table from '../../components/Table'
import TimeText from '../../components/TimeText'
import Order from '../../icons/Order'
import SubscriptionRevoked from '../../icons/SubscriptionRevoked'
import PaymentMethodDisplay from '../../components/PaymentMethodDisplay'
import Radom from '../../state/Radom'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import Checkbox from '../../components/Checkbox'
import { prodNetworks, testNetworks } from '../../util/Managed'
import { useTestMode } from '../../util/Util'

enum PaymentStatusFilter {
  Complete = 'Complete',
  Incomplete = 'Incomplete'
}

enum PaymentTypeFilter {
  OneTime = 'One-time',
  Recurring = 'Recurring'
}

const FundsEvents = observer((): ReactElement => {
  const navigate = useNavigate()

  const pageSize = 20
  const [curPage, setCurPage] = useState(0)

  const [isLoading, setIsLoading] = useState(false)
  const [events, setEvents] = useState<ManagedPaymentEvent[]>([])
  const [totalEventsSize, setTotalEventsSize] = useState(0)

  const [paymentStatusFilter, setPaymentStatusFilter] = useState<PaymentStatusFilter>()
  const [paymentTypeFilter, setPaymentTypeFilter] = useState<PaymentTypeFilter>()

  const [testMode] = useTestMode()

  const loadPaginatedEvents = (): void => {
    let eventTypes = ['payment', 'recurringPayment', 'incompletePayment', 'subscriptionPayment']
    switch (paymentStatusFilter) {
    case PaymentStatusFilter.Complete:
      eventTypes = ['payment', 'recurringPayment', 'subscriptionPayment']
      break
    case PaymentStatusFilter.Incomplete:
      eventTypes = ['incompletePayment']
      break
    }

    switch (paymentTypeFilter) {
    case PaymentTypeFilter.OneTime:
      eventTypes = eventTypes.filter(e => e !== 'recurringPayment' && e !== 'subscriptionPayment')
      break
    case PaymentTypeFilter.Recurring:
      eventTypes = eventTypes.filter(e => e !== 'payment')
      break
    }

    setIsLoading(true)
    Radom.listPaginatedEvents(
      curPage * pageSize,
      pageSize,
      eventTypes,
      testMode ? testNetworks : prodNetworks
    )
      .then(d => {
        const events = d.data.map(d => parseManagedEvent(d)).filter(e => !!e) as any[]
        setEvents(events)
        setTotalEventsSize(d.total)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    loadPaginatedEvents()
  }, [curPage, paymentStatusFilter, paymentTypeFilter, testMode])

  useEffect(() => {
    setCurPage(0)
  }, [paymentStatusFilter, paymentTypeFilter, testMode])

  const onClickOrder = (i: number): void => {
    const order = events[i]
    if (!order) return
    navigate(`/payment/${order.id}`)
  }

  return (
    <>
      <div style={{ display: 'flex', gap: 10 }}>

        <Dropdown
          selectedContent={<div style={{ fontSize: 14 }}>{paymentStatusFilter || 'Payment status'}</div>}
          dropdownContent={<div>
            {[PaymentStatusFilter.Complete, PaymentStatusFilter.Incomplete].map(s =>
              <DropdownItem
                key={s}
                style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: 14 }}
                onClick={() => {
                  if (s === paymentStatusFilter) {
                    setPaymentStatusFilter(undefined)
                  } else {
                    setPaymentStatusFilter(s)
                  }
                }}>
                <Checkbox size={15} checked={paymentStatusFilter === s}/>
                <span>{s}</span>
              </DropdownItem>
            )}
          </div>} />

        <Dropdown
          selectedContent={<div style={{ fontSize: 14 }}>{paymentTypeFilter || 'Payment type'}</div>}
          dropdownContent={<div>
            {[PaymentTypeFilter.OneTime, PaymentTypeFilter.Recurring].map(s =>
              <DropdownItem
                key={s}
                style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: 14 }}
                onClick={() => {
                  if (s === paymentTypeFilter) {
                    setPaymentTypeFilter(undefined)
                  } else {
                    setPaymentTypeFilter(s)
                  }
                }}>
                <Checkbox size={15} checked={paymentTypeFilter === s}/>
                <span>{s}</span>
              </DropdownItem>
            )}
          </div>} />
      </div>
      <Table
        borderless
        headers={[
          <span key="type">Type</span>,
          <span key="amount">Amount</span>,
          <span key="relatedEntity">Related entity</span>,
          <span key="txTime">Transaction time</span>
        ]}
        isLoading={isLoading}
        items={events.map(o => [
          <span key="type">
            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              { o.name !== 'IncompletePaymentEvent' && <Order style={{ width: 20, height: 20 }} />}
              { o.name === 'IncompletePaymentEvent' && <SubscriptionRevoked style={{ width: 20, height: 20 }} />}
              <span>{o.label}</span>
            </div>
          </span>,
          <div key="amount" style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            <PaymentMethodDisplay
              style={{ marginRight: 5 }}
              method={{ network: '' }}
              paymentMethod={o.paymentMethod}
              excludeName
              disableTooltip={false} />
            <span>{JSON.stringify(o.amount)}</span>
            <span>{o.paymentMethod.ticker}</span>
          </div>,
          <span key="relatedEntity">
            {
              o.associatedEntity &&
              o.associatedEntity.entityType === AssociatedEntityType.PaymentLink &&
              <ExternalLinkText
                onClick={e => e.stopPropagation()}
                href={o.associatedEntity.link}
                target="_blank" rel="noreferrer">
                {AssociatedEntityType[o.associatedEntity.entityType]}
                <LinkExternal stroke={RADOM_COLORS.BLACK} width={13} />
              </ExternalLinkText>
            }
            {
              o.associatedEntity &&
              o.associatedEntity.entityType !== AssociatedEntityType.PaymentLink &&
              <span>{AssociatedEntityType[o.associatedEntity.entityType]}</span>
            }
          </span>,
          <TimeText key="txTime" date={new Date(o.date)} />
        ])}
        emptyPlaceholderText='No payments yet'
        onClickRow={onClickOrder}
      />
      {
        !isLoading && <Pagination
          curPage={curPage}
          numPages={Math.ceil(totalEventsSize / pageSize)}
          onPageClick={page => setCurPage(page)}
        />
      }
    </>
  )
})

export default FundsEvents
