import React, { ReactElement } from 'react'

function Dots(props: React.SVGProps<SVGSVGElement>): ReactElement {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#292929" strokeWidth="2" {...props}>
    <g clipPath="url(#clip0_3_444)">
      <path d="M5 16L4 20L8 19L19.5858 7.41421C20.3668 6.63316 20.3668 5.36683 19.5858 4.58579L19.4142 4.41421C18.6332 3.63316 17.3668 3.63317 16.5858 4.41421L5 16Z" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15 6L18 9" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13 20H21" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_3_444">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
}

export default Dots
