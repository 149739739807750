import React from 'react'

export default function Calendar(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0.63 24 24" {...props}>
    <g clipPath="url(#clip0_1_4727)">
      <path d="M3.96938 13.5944C3.82899 13.4538 3.75009 13.2633 3.75 13.0647V4.375H12.4397C12.6383 4.37509 12.8288 4.45399 12.9694 4.59438L22.2806 13.9056C22.4212 14.0463 22.5001 14.237 22.5001 14.4358C22.5001 14.6346 22.4212 14.8253 22.2806 14.9659L14.3438 22.9056C14.2031 23.0462 14.0124 23.1251 13.8136 23.1251C13.6148 23.1251 13.4241 23.0462 13.2834 22.9056L3.96938 13.5944Z" stroke="#747894" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.875 9.625C8.49632 9.625 9 9.12132 9 8.5C9 7.87868 8.49632 7.375 7.875 7.375C7.25368 7.375 6.75 7.87868 6.75 8.5C6.75 9.12132 7.25368 9.625 7.875 9.625Z" fill="#747894"/>
    </g>
    <defs>
      <clipPath id="clip0_1_4727">
        <rect width="24" height="24" fill="white" transform="translate(0 0.625)"/>
      </clipPath>
    </defs>
  </svg>
}
