import { makeAutoObservable } from 'mobx'

class NavbarController {
  isFullscreen: boolean = false
  isModalOpen: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setFullscreen = (_isFullscreen: boolean): void => {
    this.isFullscreen = _isFullscreen
  }

  setModalOpen = (_isModalOpen: boolean): void => {
    this.isModalOpen = _isModalOpen
  }
}

export default new NavbarController()
