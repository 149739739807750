import React from 'react'

export default function Calendar(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#747894" {...props}>
    <g clipPath="url(#clip0_1_4535)">
      <path d="M13.5 6L17.25 2.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.75 6.75L18 10.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21 13.5L10.5 3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.875 12.375L14.3785 17.8716C13.8159 18.434 13.0529 18.75 12.2574 18.75C11.4618 18.75 10.6989 18.434 10.1363 17.8716L6.12845 13.8638C5.56598 13.3012 5.25 12.5382 5.25 11.7427C5.25 10.9471 5.56598 10.1842 6.12845 9.62156L11.625 4.125" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.13281 15.8672L3 21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1_4535">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
}
