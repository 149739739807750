import React, { ReactElement, useState } from 'react'
import { PrimaryButton, SecondaryButton } from './Button'
import Radom from '../state/Radom'
import { InputLabel } from './Input'
import { RADOM_COLORS } from '../util/Constants'
import Checkbox from './Checkbox'
import styled from 'styled-components'
import { endOfDay, endOfMonth, intlFormat, startOfDay, startOfMonth, subDays, subMonths } from 'date-fns'
import { errorToast } from '../util/Util'
import DateSelector from './DateSelector'

const DateRange = styled(SecondaryButton)`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 10px;
  color: ${RADOM_COLORS.BLACK};
  border: 0;
`

const EXPORT_TIME_RANGES = [
  {
    label: 'Today',
    dateFn: () => {
      return {
        from: startOfDay(new Date()),
        to: new Date()
      }
    }
  },
  {
    label: 'Current month',
    dateFn: () => {
      return {
        from: startOfMonth(new Date()),
        to: endOfDay(new Date())
      }
    }
  },
  {
    label: 'Last 7 days',
    dateFn: () => {
      return {
        from: startOfDay(subDays(new Date(), 7)),
        to: endOfDay(new Date())
      }
    }
  },
  {
    label: 'Last month',
    dateFn: () => {
      return {
        from: startOfMonth(subMonths(new Date(), 1)),
        to: endOfMonth(subMonths(new Date(), 1))
      }
    }
  },
  {
    label: 'Last 3 months',
    dateFn: () => {
      return {
        from: startOfMonth(subMonths(new Date(), 2)),
        to: endOfMonth(new Date())
      }
    }
  },
  {
    label: 'All',
    dateFn: () => {
      return {
        from: new Date(0),
        to: new Date()
      }
    }
  },
  { label: 'Custom' }
]

function downloadBlob(blob: Blob, filename: string): HTMLAnchorElement {
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')

  a.href = url
  a.download = filename || 'download'

  const clickHandler = (): void => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      removeEventListener('click', clickHandler)
    }, 150)
  }

  a.addEventListener('click', clickHandler, false)
  a.click()

  return a
}

const ExportBalanceForm = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedTimeRange, setSelectedTimeRange] = useState(EXPORT_TIME_RANGES[0])

  const [customFromDate, setCustomFromDate] = useState<Date | undefined>(undefined)
  const [customToDate, setCustomToDate] = useState<Date | undefined>(undefined)

  const [period, setPeriod] = useState('daily')

  const exportBalance = async (): Promise<void> => {
    let fromDate, toDate
    if (selectedTimeRange.label === 'Custom') {
      if (customFromDate && customToDate) {
        fromDate = startOfDay(customFromDate).toISOString()
        toDate = endOfDay(customToDate).toISOString()
      } else {
        errorToast('Must select from and to custom date ranges')
        return
      }
    } else if (selectedTimeRange.dateFn) {
      fromDate = selectedTimeRange.dateFn().from.toISOString()
      toDate = selectedTimeRange.dateFn().to.toISOString()
    }
    setIsLoading(true)

    Radom.exportBalance(
      fromDate,
      toDate,
      period
    )
      .then(data => {
        const blob = new Blob(
          [data],
          { type: 'text/csv' }
        )

        downloadBlob(blob, 'balance.csv')
      })
      .catch(err => {
        errorToast(err.response?.data?.error || err.message)
      }).finally(() => {
        setIsLoading(false)
      })
  }

  return <>
    <div style={{
      width: 600,
      padding: '0 30px',
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      fontSize: 14
    }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <InputLabel>Date range</InputLabel>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
          {EXPORT_TIME_RANGES.map(t =>
            <>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>

                <DateRange onClick={() => setSelectedTimeRange(t)} disabled={isLoading}>
                  <Checkbox isRadio checked={selectedTimeRange === t} size={18} disabled={isLoading} />
                  <span>{t.label}</span>

                </DateRange>
                {
                  selectedTimeRange.label === 'Custom' && t.label === 'Custom' &&
                <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                  <DateSelector onChange={d => setCustomFromDate(d)} value={customFromDate} />
                  <span>-</span>
                  <DateSelector onChange={d => setCustomToDate(d)} value={customToDate} />
                </div>
                }
              </div>
              {
                t.dateFn && t.label !== 'All' && t.label !== 'Today' &&
                 <div style={{ display: 'flex', opacity: 0.5 }}>
                   <span>{intlFormat(t.dateFn?.().from)}</span>
                   <span style={{ margin: '0 5px' }}>-</span>
                   <span>{intlFormat(t.dateFn?.().to)}</span>
                 </div>
              }
              {
                t.label === 'Today' && <div style={{ opacity: 0.5 }}>Today</div>
              }
              {
                t.label === 'All' && <div />
              }
            </>
          )}
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <InputLabel>Period</InputLabel>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {
            ['Daily', 'Weekly', 'Monthly'].map(p =>
              <DateRange key={p} onClick={() => setPeriod(p.toLowerCase())} disabled={isLoading}>
                <Checkbox isRadio checked={p.toLowerCase() === period} size={18} disabled={isLoading} />
                <span>{p}</span>

              </DateRange>)
          }
        </div>
      </div>

    </div>
    <div style={{ padding: 30 }}>
      <PrimaryButton
        style={{ width: '100%' }}
        onClick={exportBalance}
        isLoading={isLoading}>
      Export balance report
      </PrimaryButton>
    </div>
  </>
}

export default ExportBalanceForm
