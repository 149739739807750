import styled, { keyframes } from 'styled-components'
import RadomPattern from '../images/Pattern.png'

const PatternAnimation = keyframes`
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: 120px 69px;
  }
`

export const PatternBackground2 = styled.div`
  //background-image: url('${RadomPattern}');
  background-size: 20px 20px;
  background-image:
    linear-gradient(to right, blue 1px, transparent 1px),
    linear-gradient(to bottom, blue 1px, transparent 1px);
  opacity: 0.025;
  //background-size: 120px 69px;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;

`

export const PatternBackground = styled.div`
  background-image: url('${RadomPattern}');
  opacity: 0.7;
  background-size: 120px 69px;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;

  ::after {
    position: absolute;
    content:"";
    height:100%;
    width:100%;
    top:0;
    left:0;
    z-index: 1;
    background: radial-gradient(#e8e9ed,rgb(173 184 223 / 95%));
  }
`

export const TransparentPatternBackground = styled.div`
  background-image: url('${RadomPattern}');
  background-size: 120px 69px;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  animation: ${PatternAnimation} 18s linear infinite;
`
