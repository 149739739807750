import React from 'react'

const Order = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
  return <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="0.5" width="48" height="48" rx="6.85714" fill="url(#paint0_linear_23_183)"/>
    <g clipPath="url(#clip0_23_183)">
      <path d="M22.2499 33.5C25.1494 33.5 27.4999 29.4705 27.4999 24.5C27.4999 19.5294 25.1494 15.5 22.2499 15.5C19.3504 15.5 16.9999 19.5294 16.9999 24.5C16.9999 29.4705 19.3504 33.5 22.2499 33.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.2499 15.5H26.7499C29.6496 15.5 31.9999 19.5312 31.9999 24.5C31.9999 29.4687 29.6496 33.5 26.7499 33.5H22.2499" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26.1631 18.5H30.6631" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M27.4999 24.5H31.9999" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26.1631 30.5H30.6631" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_23_183" x1="0.5" y1="0.5" x2="43.8973" y2="46.3064" gradientUnits="userSpaceOnUse">
        <stop stopColor="#618AF9"/>
        <stop offset="1" stopColor="#2B47D8"/>
      </linearGradient>
      <clipPath id="clip0_23_183">
        <rect width="24" height="24" fill="white" transform="translate(12.5 12.5)"/>
      </clipPath>
    </defs>
  </svg>
}

export default Order
