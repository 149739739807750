import React, { ReactElement, useEffect, useState } from 'react'
import Modal from './Modal'
import { PrimaryButton } from './Button'
import { InputLabel } from './Input'
import { observer } from 'mobx-react'
import { RoleSelector } from './RoleSelector'
import Radom, { IPermissionRole, Member } from '../state/Radom'
import { errorToast, successToast } from '../util/Util'

interface IProps {
  visible: boolean
  user: Member
  currentRoles: string[]
  onClose: () => void
}

const UserEditModal = observer((props: IProps): ReactElement => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [apiRoles, setAPIRoles] = useState([] as IPermissionRole[])

  const [newRoles, setNewRoles] = useState([] as IPermissionRole[])

  const listPermissionRoles = (): void => {
    if (!Radom.isLoggedIn) return
    Radom.listPermissionRoles()
      .then(roles => setAPIRoles(roles))
  }

  useEffect(() => {
    listPermissionRoles()
  }, [Radom.isLoggedIn])

  useEffect(() => {
    const roles = props.currentRoles.map(r => apiRoles.find(ar => ar.id === r)).filter(r => !!r)
    setNewRoles(roles as IPermissionRole[])
  }, [props.user, props.currentRoles, apiRoles])

  const onSubmitUser = async (): Promise<void> => {
    setIsSubmitting(true)
    try {
      await Radom.editMember(props.user.userId, newRoles.map(r => r.id))

      successToast('Successfully edited member')
      props.onClose()
    } catch (err) {
      errorToast(err.reason || err.response?.data?.error || err.message)
      console.error('Failed to edit member roles', err)
    }
    setIsSubmitting(false)
  }

  return <Modal visible={props.visible} onClose={() => props.onClose()} title='Edit member roles'>
    <form
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
      onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()
      }}>
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', rowGap: 20, padding: '0 30px' }}>
        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
          <InputLabel>Member</InputLabel>
          <div style={{ fontSize: 14, display: 'flex', gap: 20 }}>
            {props.user.emailAddress || props.user.evmAddress}
          </div>
        </div>
        <div style={{ fontSize: 14 }}>
          <InputLabel style={{ marginBottom: 10 }}>Roles</InputLabel>
          <RoleSelector
            onCloseFn={props.onClose}
            disabled={isSubmitting}
            roleCreationPage='/team/roles'
            roles={apiRoles}
            formatFunction={r => r.name}
            selectedRoles={newRoles}
            onSelectRole={r => setNewRoles([...newRoles, r])}
            onRemoveRole={roleId => setNewRoles(newRoles.filter(r => r !== roleId))}
          />
        </div>
      </div>
      <div style={{
        padding: 30,
        borderRadius: 15,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
      }}>
        <PrimaryButton
          style={{ width: '100%' }}
          disabled={isSubmitting}
          isLoading={isSubmitting}
          onClick={async () => await onSubmitUser()}>
          Update
        </PrimaryButton>
      </div>
    </form>
  </Modal>
})

export default UserEditModal
