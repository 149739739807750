import React from 'react'

export default function Calendar(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" stroke="#747894" fill="none" viewBox="0 0.25 24 24" {...props}>
    <g clipPath="url(#clip0_75_702)">
      <path d="M12 15.25C13.6569 15.25 15 13.9069 15 12.25C15 10.5931 13.6569 9.25 12 9.25C10.3431 9.25 9 10.5931 9 12.25C9 13.9069 10.3431 15.25 12 15.25Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.5 6.25H1.5V18.25H22.5V6.25Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.5 10C21.5631 9.84121 20.6989 9.39494 20.027 8.72304C19.3551 8.05113 18.9088 7.18686 18.75 6.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.75 18.25C18.9088 17.3131 19.3551 16.4489 20.027 15.777C20.6989 15.1051 21.5631 14.6588 22.5 14.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.5 14.5C2.43686 14.6588 3.30113 15.1051 3.97304 15.777C4.64494 16.4489 5.09121 17.3131 5.25 18.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.25 6.25C5.09121 7.18686 4.64494 8.05113 3.97304 8.72304C3.30113 9.39494 2.43686 9.84121 1.5 10" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_75_702">
        <rect width="24" height="24" fill="white" transform="translate(0 0.25)"/>
      </clipPath>
    </defs>
  </svg>
}
