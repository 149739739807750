import React, { ReactElement, useRef } from 'react'
import CSSTransition from 'react-transition-group/CSSTransition'
import styled from 'styled-components'

const Appear = styled(CSSTransition)<{ classNames: string, timeout: number }>`
  font-size: 1.5em;
  padding: 0;
  margin: 0;

  &.${({ classNames }) => `${classNames}`}-enter {
      opacity: 0.01;
  }

  &.${({ classNames }) => `${classNames}`}-enter-active {
      opacity: 1;
      transition: opacity ${({ timeout }) => timeout}ms ease-in-out;
  }

  &.${({ classNames }) => `${classNames}`}-exit {
      opacity: 1;
  }

  &.${({ classNames }) => `${classNames}`}-exit-active {
      opacity: 0.01;
      transition: opacity ${({ timeout }) => timeout}ms ease-in-out;
  }
`

interface IAppearProps {
  in: boolean
  transitionId: string
  duration: number
  children: ReactElement
}

const AppearTransition = (props: IAppearProps): ReactElement => {
  const nodeRef = useRef(null)
  return (
    <Appear
      in={props.in}
      nodeRef={nodeRef}
      classNames={props.transitionId}
      timeout={props.duration}
      unmountOnExit>
      <div ref={nodeRef}>{props.children}</div>
    </Appear>
  )
}

export default AppearTransition
