import React, { useState } from 'react'
import { PrimaryButton, SecondaryButton } from './Button'
import { RADOM_COLORS, RADOM_TOKEN_EXPIRY_LOCAL_STORAGE_KEY, RADOM_TOKEN_LOCAL_STORAGE_KEY, WINDOW_SIZE } from '../util/Constants'
import { observer } from 'mobx-react'
import User from '../state/User'
import RadomLogo from '../icons/Radom'
import RadomOldLogo from '../icons/RadomOld'
import GoogleLogo from '../images/Google.svg'
import styled, { keyframes } from 'styled-components'
import Radom from '../state/Radom'
import { useGoogleLogin } from '@react-oauth/google'
import EmailLogin from './EmailLogin'
import Metamask from '../images/Metamask.svg'
import Brave from '../images/Brave.png'
import LoginImage2 from '../images/login23.png'
import { TextInput } from './Input'
import { errorToast, successToast } from '../util/Util'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  background: url(${LoginImage2});
  background-repeat: no-repeat;
  background-position-y: 10%;
  background-size: cover;
  padding: 40px;

  @media (max-width: ${WINDOW_SIZE.TABLET}) {
    padding: 40px 20px;
  }
`

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  z-index: 1;
  overflow: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  
  @media (max-width: ${WINDOW_SIZE.TABLET}) {
    width: 100%;
  }
`

const LoadingAnimation = keyframes`
  0% {
    stroke-dashoffset: 150;
    opacity: 0;
  }
  50% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
  100% {
    stroke-dashoffset: -150;
    opacity: 0;
  }
`

const LoadingLogo = styled(RadomOldLogo)`
  width: 60px;
  height: auto;

  > path {
    stroke-width: 1px;
    fill: none;
    stroke: url(#gradient);
    stroke-dasharray: 150;
    animation: ${LoadingAnimation} 2s infinite linear;
    transform-origin: center center;
  }
`

const WalletButton = styled(PrimaryButton)`
  background-color: transparent;
  border: 1px solid ${RADOM_COLORS.GRAY_DARK};
  border-radius: 20px;
  width: 100%;
  color: ${RADOM_COLORS.BLACK};
  gap: 10px;
  padding: 15px;
  font-size: 14px;

  &:hover {
    box-shadow: none;
  }
  
  &:focus {
    box-shadow: none;
  }
`

if (localStorage.getItem('isWalletLogin')) {
  User.setupWalletConnection()
  User.connect()
}

const WithConnectedWallet = observer(props => {
  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: tokenResponse => {
      Radom.loginWithSSO('google', tokenResponse.code)
    },
    onError: err => console.error('errored', err),
    onNonOAuthError: err => console.error('errored', err)

  })

  const verifyOnetimePassword = async(): Promise<void> => {
    if (!token) {
      return errorToast('Enter a valid token')
    }

    try {
      const res = await Radom.verifyOneTimePassword(token)
      Radom.token = res.jwtToken
      localStorage.setItem(RADOM_TOKEN_LOCAL_STORAGE_KEY, res.jwtToken)
      localStorage.setItem(RADOM_TOKEN_EXPIRY_LOCAL_STORAGE_KEY, new Date(res.expiresAt).toString())
      successToast('Successfully logged in')
      Radom.isLoggedIn = true
    } catch (err) {
      errorToast(err.response?.data?.error || err.message)
    }
  }

  const [token, setToken] = useState<string>()

  if (Radom.isLoggedIn) {
    return props.children
  }

  if (User.isLoading || Radom.isLoggingIn) {
    return <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100%',
      background: `linear-gradient(to top, ${RADOM_COLORS.BLUE}, #030219)`
    }}>
      <div style={{ position: 'relative' }}>
        <LoadingLogo
          logoOnly
          logoColor={RADOM_COLORS.ORANGE}
          strokeDashoffset={50}
          textColor='white' />
      </div>
    </div>
  }

  if (Radom.requires2fa) {
    return <Container>

      <RadomLogo style={{
        width: 90,
        height: 'auto'
      }} />

      <LoginContainer>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 30, width: 400, maxWidth: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20, alignItems: 'center' }}>

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
              color: RADOM_COLORS.NEW_GRAY_1,
              marginBottom: 10,
              alignItems: 'center'
            }}>

              <span style={{ fontSize: 20, fontWeight: 500, textAlign: 'center' }}>
                Two-factor authentication
              </span>
              <span style={{ fontSize: 14, textAlign: 'center' }}>
                Open the two-step verification app on your mobile device to get your verification code.
              </span>
            </div>
          </div>

          <form
            onSubmit={e => {
              e.preventDefault(); verifyOnetimePassword()
            }}
            style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <TextInput
              onSubmit={verifyOnetimePassword}
              autoFocus
              value={token}
              onChange={e => setToken(e.target.value)}
              placeholder='Authentication code' />

            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>

              <PrimaryButton
                type='button'
                isLoading={User.isLoading}
                onClick={verifyOnetimePassword}>
                <span>Authenticate</span>
              </PrimaryButton>

              <SecondaryButton
                type='button'
                onClick={() => {
                  localStorage.clear()
                  window.location = '/' as any
                }}>
                Back to login
              </SecondaryButton>
            </div>

          </form>

        </div>
      </LoginContainer>
    </Container>
  }

  return <Container>

    <RadomLogo style={{
      width: 90,
      height: 'auto'
    }} />

    <LoginContainer>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 30, width: 400, maxWidth: '100%' }}>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 20, alignItems: 'center' }}>

          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            color: RADOM_COLORS.NEW_GRAY_1,
            marginBottom: 10,
            alignItems: 'center'
          }}>

            <span style={{ fontSize: 20, fontWeight: 500, textAlign: 'center' }}>
              Ready to start accepting<br/> crypto payments?
            </span>
            <span style={{ fontSize: 14, textAlign: 'center' }}>
              Create your account to get started.
            </span>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, width: '100%' }}>
          <WalletButton
            isLoading={User.isLoading}
            onClick={() => login()}>
            <img src={GoogleLogo as string} style={{ height: 15, width: 'auto' }} />
            <span>Sign in with Google</span>
          </WalletButton>

          <WalletButton
            disabled={User.isLoading}
            onClick={async () => {
              User.setupWalletConnection()
              await User.connect()
                .then(async () => await Radom.login())
                .then(() => {
                  localStorage.setItem('isWalletLogin', 'true')
                })
                .catch(() => {})
            }}>
            <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>

              <img src={Metamask as any} style={{ width: 'auto', height: 15 }} />
              <img src={Brave} style={{ width: 'auto', height: 15 }} />
            </div>
            {/* <Plug style={{ width: 15, height: 15, position: 'absolute', left: 10 }} stroke={RADOM_COLORS.BLUE} strokeWidth={3} /> */}
            <span>Connect wallet</span>
          </WalletButton>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <div style={{ height: 1, flex: 1, flexGrow: 1, background: 'black', opacity: 0.1 }} />
          <span style={{ fontSize: 12, textTransform: 'uppercase', opacity: 0.33 }}>Or</span>
          <div style={{ height: 1, flex: 1, flexGrow: 1, background: 'black', opacity: 0.1 }} />
        </div>

        <EmailLogin />

      </div>
    </LoginContainer>

    {/* <BankImage /> */}
  </Container>
})

export default WithConnectedWallet
