import React, { ReactElement } from 'react'
import { observer } from 'mobx-react'
import { PrimaryLinkButton } from '../components/Button'

@observer
export default class NotFound extends React.Component {
  render(): ReactElement {
    return (
      <div
        style={{
          paddingTop: '180px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
          <span style={{ fontSize: 32, fontWeight: 300, marginBottom: 5 }}>Oops</span>
          <span style={{ fontSize: 16, opacity: 0.8 }}>We couldn&apos;t find the page you were looking for!</span>
        </div>
        <PrimaryLinkButton style={{ width: 90, textAlign: 'center' }} href='/'>Home</PrimaryLinkButton>
      </div>
    )
  }
}
