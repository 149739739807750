import { observer } from 'mobx-react-lite'
import React, { useReducer, useState } from 'react'

import styled from 'styled-components'
import { PrimaryButton, SecondaryButton } from './Button'
import { TextInputWithLabel } from './Input'
import Radom, { Customer } from '../state/Radom'
import { useNavigate } from 'react-router-dom'
import { errorToast, successToast } from '../util/Util'
import { Chevron } from '../icons/Chevron'
import { RADOM_COLORS } from '../util/Constants'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  font-size: 14px;
`

const CustomerForm = observer((props: { onCreateCustomer?: (customer: Customer) => void }) => {
  const navigate = useNavigate()

  const [showMore, setShowMore] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [customerForm, updateCustomerForm] = useReducer((prev, next) => { return { ...prev, ...next } }, {
    name: '',
    email: '',
    billingAddress: '',
    walletAddress: '',
    phone: '',
    telegram: '',
    discord: ''
  })

  const onCreateCustomer = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()
    e.stopPropagation()

    setIsSubmitting(true)
    try {
      const customer = await Radom.createCustomer(customerForm)
      if (props.onCreateCustomer) {
        props.onCreateCustomer(customer)
      } else {
        navigate('/customers')
      }
      successToast('Successfully created user')
    } catch (err) {
      const msg = err.response.data.error ?? err.code
      errorToast(`Error: ${msg}`)
    } finally {
      setIsSubmitting(false)
    }
  }

  return <Form onSubmit={onCreateCustomer}>
    <TextInputWithLabel
      label="Name"
      value={customerForm.name}
      onChange={(e) => updateCustomerForm({ name: (e.target as HTMLInputElement).value })}
    />
    <TextInputWithLabel
      label="Email"
      autoFocus
      required
      value={customerForm.email}
      onChange={(e) => updateCustomerForm({ email: (e.target as HTMLInputElement).value })}
    />

    {
      showMore && <>
        <TextInputWithLabel
          label="Billing address"
          value={customerForm.billingAddress}
          onChange={(e) => updateCustomerForm({ billingAddress: (e.target as HTMLInputElement).value })}
        />
        <TextInputWithLabel
          label="Phone number"
          value={customerForm.phone}
          onChange={(e) => updateCustomerForm({ phone: (e.target as HTMLInputElement).value })}
        />
        <TextInputWithLabel
          label="Telegram"
          value={customerForm.telegram}
          onChange={(e) => updateCustomerForm({ telegram: (e.target as HTMLInputElement).value })}
        />
        <TextInputWithLabel
          label="Discord"
          value={customerForm.discord}
          onChange={(e) => updateCustomerForm({ discord: (e.target as HTMLInputElement).value })}
        />
      </>
    }

    {
      <SecondaryButton
        onClick={() => setShowMore(!showMore)}
        type='button'
        style={{
          display: 'flex',
          border: 0,
          alignItems: 'center',
          gap: 5,
          padding: 0,
          color: RADOM_COLORS.BLACK,
          opacity: 0.5
        }}>
        <span>{showMore ? 'Hide' : 'Add'} more fields</span>
        <Chevron style={{ transform: `rotate(${showMore ? 180 : 0}deg)` }} />
      </SecondaryButton>
    }

    <PrimaryButton isLoading={isSubmitting} style={{ marginTop: '10px' }}>
          Create customer
    </PrimaryButton>
  </Form>
})

export default CustomerForm
