import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'
import { Link } from 'react-router-dom'
import { Chevron } from '../icons/Chevron'

export interface BreadCrumbItem {
  item: ReactElement | string
  to: string
}

interface IProps {
  items: BreadCrumbItem[]
}

const BreadCrumbDiv = styled(Link)<{ $isLast?: boolean }>`
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  color: ${({ $isLast }) => $isLast ? RADOM_COLORS.GRAY_DARKEST : RADOM_COLORS.GRAY_DARKER};
  text-decoration: none;
`

const BreadCrumbs = ({ items }: IProps): ReactElement => {
  return <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
    {items.map((item, i) => <div key={i} style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
      <BreadCrumbDiv to={item.to} $isLast={i === items.length - 1}>{item.item}</BreadCrumbDiv>
      { i < items.length - 1 && <Chevron fill={RADOM_COLORS.GRAY_DARKER} style={{ width: 12, transform: 'rotate(-90deg)' }} /> }
    </div>)}
  </div>
}

export default BreadCrumbs
