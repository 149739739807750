import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { IconButton, PrimaryButton } from '../../components/Button'
import Table from '../../components/Table'
import PageHeader from '../../components/PageHeader'
import WebhookModal from '../../components/WebhookModal'
import ConfirmButtonModal from '../../components/ConfirmButtonModal'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import { RADOM_COLORS } from '../../util/Constants'
import Dots from '../../icons/Dots'
import Radom, { IWebhook } from '../../state/Radom'
import Web3 from '../../state/User'
import { TextInput } from '../../components/Input'
import Copy from '../../icons/Copy'
import { errorToast, infoToast, successToast } from '../../util/Util'
import { Container } from '../../components/Animations'

const Developers = observer((): React.ReactElement => {
  const [isLoadingWebhooks, setIsLoadingWebhooks] = useState(true)
  const [webhooks, setWebhooks] = useState([] as IWebhook[])

  const navigate = useNavigate()

  const loadWebhooks = (): void => {
    setIsLoadingWebhooks(true)
    setWebhooks([])
    Radom.getWebhooks(Web3.accounts[0])
      .then(webhooks => {
        setWebhooks(webhooks.filter(webhook => !webhook.isDeleted)
          .sort((a, b) =>
            new Date(b.createdTimestamp).getTime() - new Date(a.createdTimestamp).getTime()
          )
        )
      })
      .catch(err => errorToast(err.message))
      .finally(() => setIsLoadingWebhooks(false))
  }

  const onClickWebhook = (i: number): void => {
    const webhook = webhooks.at(i)
    if (webhook) {
      navigate(`/developers/webhook/${webhook.id}`)
    }
  }

  useEffect(() => {
    loadWebhooks()
  }, [])

  return <Container>
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, rowGap: 20 }}>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PageHeader title='Webhooks' subTitle='Manage your webhooks' />
        <WebhookModal
          webhookId=''
          webhookForm={{
            url: ''
          }}
          isUpdating={false}
          onClose={() => {
            loadWebhooks()
          }}
          ActionComponent={props =>
            <PrimaryButton {...props}
            >+ Create webhook</PrimaryButton>
          }
        />
      </div>
      <Table
        headers={[
          <span key="id">ID</span>,
          <span key="url">Endpoint</span>,
          <span key="paused">Paused</span>,
          <span key="action"></span>
        ]}
        isLoading={isLoadingWebhooks}
        items={webhooks.map(w => [
          <div onClick={e => e.stopPropagation()} key="id" >
            {w.id}
          </div>,
          <div onClick={e => e.stopPropagation()} key="url" style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '5px', color: RADOM_COLORS.GRAY_DARKER }}>
            <TextInput value={w.url} style={{ maxWidth: '280px', height: 'fit-content' }} disabled />
            <IconButton style={{ padding: 0 }}>
              <Copy style={{ width: 20 }} onClick={() => {
                navigator.clipboard.writeText(w.url)
                infoToast('Webhook url copied to clipboard')
              }} />
            </IconButton>
          </div>,
          <div key="paused" style={{ fontSize: 14, display: 'flex' }}>
            {!w.isPaused
              ? <div style={{
                borderRadius: 5,
                padding: '5px 10px',
                backgroundColor: RADOM_COLORS.SUCCESS_LIGHT,
                color: RADOM_COLORS.SUCCESS
              }}>Active</div>
              : <div style={{
                borderRadius: 5,
                padding: '5px 10px',
                backgroundColor: RADOM_COLORS.ERROR_LIGHT,
                color: RADOM_COLORS.ORANGE
              }}>Paused</div>}
          </div>,
          <div onClick={e => e.stopPropagation()} key="5" style={{ display: 'flex' }}>
            {
              <Dropdown
                key={w.id}
                noChevron
                selectedContent={<Dots width={15}/>}
                dropdownContent={
                  <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                    <ConfirmButtonModal
                      title='Resend webhook failures'
                      description='Resend failed webhook POST messages.'
                      action='Resend webhook failures'
                      onConfirm={async () => {
                        await Radom.resendWebhookMessageFailures(w.id)
                          .then(() => {
                            successToast('Resending failed webhook messages')
                            loadWebhooks()
                          })
                          .catch(err => {
                            errorToast(err.response?.data?.error || err.message)
                          })
                      }}
                      ActionComponent={props => <DropdownItem {...props}>Resend webhook failures</DropdownItem>}
                    />
                    <WebhookModal
                      webhookId={w.id}
                      webhookForm={{
                        url: w.url
                      }}
                      isUpdating={true}
                      onClose={() => {
                        loadWebhooks()
                      }}
                      ActionComponent={props =>
                        <DropdownItem {...props}>
                              Edit webhook
                        </DropdownItem>
                      }
                    />
                    <ConfirmButtonModal
                      title={w.isPaused ? 'Resume webhook' : 'Pause webhook'}
                      description={w.isPaused
                        ? 'Resume receiving POST requests to your webhook.'
                        : 'Pause webhook to stop receiving POST requests.'
                      }
                      action={w.isPaused ? 'Resume webhook' : 'Pause webhook'}
                      onConfirm={async () => {
                        await Radom.updateWebhook(
                          w.id,
                          {
                            url: w.url,
                            verificationKey: w.verificationKey,
                            isPaused: !w.isPaused,
                            isDeleted: w.isDeleted
                          })
                          .then(() => {
                            successToast('Successfully updated webhook')
                            loadWebhooks()
                          })
                          .catch(err => {
                            errorToast(err.response?.data?.error || err.message)
                          })
                      }}
                      ActionComponent={props =>
                        <DropdownItem {...props}>
                          {w.isPaused ? 'Resume webhook' : 'Pause webhook'}
                        </DropdownItem>}
                    />
                    <ConfirmButtonModal
                      title='Delete webhook'
                      description='Are you sure you want to delete this webhook? This action cannot be undone.'
                      action='Delete webhook'
                      onConfirm={async () => {
                        await Radom.updateWebhook(
                          w.id,
                          {
                            url: w.url,
                            verificationKey: w.verificationKey,
                            isPaused: w.isPaused,
                            isDeleted: true
                          })
                          .then(() => {
                            successToast('Successfully deleted webhook')
                            loadWebhooks()
                          })
                          .catch(err => {
                            errorToast(err.response?.data?.error || err.message)
                          })
                      }}
                      ActionComponent={props => <DropdownItem {...props}>Delete webhook</DropdownItem>}
                    />
                  </div>
                }
              />
            }
          </div>
        ])}
        emptyPlaceholderText='No webhooks created yet'
        onClickRow={onClickWebhook}
        disableOnClickState
      />

    </div>
  </Container>
})

export default Developers
