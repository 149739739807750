import React, { PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ScaleIn } from './Animations'
import { RADOM_COLORS } from '../util/Constants'
import { HoverInfoIcon } from '../icons/Info'

interface IProps extends PropsWithChildren {
  message: string
  disabled?: boolean
  style?: any
}

const TooltipMessage = styled.div`
  position: fixed;
  top: 0px;
  padding: 15px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  width: max-content;
  max-width: 200px;
  z-index: 100;
  animation: ${ScaleIn} 0.2s;
  color: ${RADOM_COLORS.GRAY_DARKEST};
  font-weight: 400;
`

const Tooltip = (props: IProps): ReactElement => {
  const [open, setOpen] = useState(false)
  const container = useRef<HTMLDivElement | null>(null)
  const [top, setTop] = useState(0)
  const [left, setLeft] = useState(0)

  useEffect(() => {
    if (!container.current) return
    const rect = container.current.getBoundingClientRect()
    setTop(rect.top)
    setLeft(rect.left + rect.width + 10)
  }, [container, open])

  return <div
    ref={container}
    style={{ position: 'relative', fontSize: 0 }}
    onMouseEnter={() => !props.disabled && setOpen(true)}
    onMouseLeave={() => setOpen(false)}>
    {
      props.children
        ? props.children
        : <HoverInfoIcon
          width={20} />
    }
    {
      open &&
        <TooltipMessage style={{ top, left, ...props.style }}>
          {props.message}
        </TooltipMessage>
    }
  </div>
}

export default Tooltip
