import React, { ReactElement, useEffect, useState } from 'react'
import QRCode from 'qrcode'
import { PrimaryButton } from './Button'
import Radom from '../state/Radom'
import User from '../state/User'
import { TextInputWithLabel } from './Input'
import { errorToast } from '../util/Util'
import { RADOM_COLORS, RADOM_TOKEN_EXPIRY_LOCAL_STORAGE_KEY, RADOM_TOKEN_LOCAL_STORAGE_KEY } from '../util/Constants'
import { chunk } from 'lodash'
import LoadingBar from './LoadingBar'

interface Props {
  onTwoFactorSetSuccess: () => void
}

const OneTimePassForm = ({ onTwoFactorSetSuccess }: Props): ReactElement => {
  const [isGeneratingImage, setIsGeneratingImage] = useState(true)
  const [imageData, setImageData] = useState<string>()
  const [token, setToken] = useState<string>()
  const [secret, setSecret] = useState<string>()

  const verifyOnetimePassword = async(): Promise<void> => {
    if (!token) {
      return
    }

    try {
      await Radom.verifyGeneratedOneTimePassword(token)
      const res = await Radom.verifyOneTimePassword(token)
      Radom.token = res.jwtToken
      localStorage.setItem(RADOM_TOKEN_LOCAL_STORAGE_KEY, res.jwtToken)
      localStorage.setItem(RADOM_TOKEN_EXPIRY_LOCAL_STORAGE_KEY, new Date(res.expiresAt).toString())
      onTwoFactorSetSuccess()
    } catch (err) {
      errorToast(err.response?.data?.error || err.message)
    }
  }

  const generateOneTimePassword = async(): Promise<void> => {
    setIsGeneratingImage(true)
    await User.getUser()
    const res = await Radom.generateOneTimePassword()

    const data = await QRCode.toDataURL(res.otpAuthUrl)

    setSecret(res.otpBase32)
    setImageData(data)
    setIsGeneratingImage(false)
  }

  useEffect(() => {
    generateOneTimePassword()
  }, [])

  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    alignItems: 'center'
  }}>

    <div style={{ fontSize: 14 }}>
      Scan this QR code with a security or authentication app like Google Authenticator, Authy or Duo.
      Download one from the App Store or Play Store.
    </div>

    {/* <div style={{ fontSize: 14 }}>
      Once you connect your Radom account to the security or authentication app,
      you&apos;ll get a one-time verification code whenever you need it.
    </div> */}

    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20 }}>

      {
        isGeneratingImage
          ? <LoadingBar style={{ width: 200, height: 200, borderRadius: 20 }} />
          : <img
            style={{
              borderRadius: 20,
              boxShadow: `0 0 3px 3px ${RADOM_COLORS.GRAY8}`,
              width: 200,
              height: 200
            }}
            src={imageData} />
      }

      <span style={{ fontSize: 10, textTransform: 'uppercase', color: RADOM_COLORS.GRAY4 }}>Scan QR code or copy setup key</span>

      <div style={{ display: 'flex', gap: 5, fontSize: 14, backgroundColor: RADOM_COLORS.GRAY8, padding: 10 }}>
        {chunk(secret, 4).map((t, i) => <span key={i}>{t}</span>)}
      </div>
    </div>

    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      width: '100%',
      borderTop: `1px solid ${RADOM_COLORS.GRAY8}`,
      paddingTop: 20
    }}>

      <TextInputWithLabel
        label='Enter authentication code'
        required
        autoFocus
        value={token}
        onChange={e => setToken((e.target as HTMLInputElement).value)}
        placeholder='Authentication code' />

      <PrimaryButton onClick={verifyOnetimePassword}>Enable two-factor authentication</PrimaryButton>
    </div>
  </div>
}

export default OneTimePassForm
