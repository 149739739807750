import React, { ReactElement, useEffect, useState } from 'react'
import { PrimaryButton } from '../../components/Button'
import Radom from '../../state/Radom'
import { InputLabel, TextInput } from '../../components/Input'
import styled from 'styled-components'
import { RADOM_COLORS } from '../../util/Constants'
import { errorToast, successToast } from '../../util/Util'
import PageHeader from '../../components/PageHeader'
import Table from '../../components/Table'
import { useNavigate } from 'react-router-dom'

const Permissions = [
  { resource: 'Products' },
  { resource: 'PaymentLinks' },
  { resource: 'Invoices' },
  { resource: 'CheckoutSessions' },
  { resource: 'PaymentSessions' },
  { resource: 'Customers' },
  { resource: 'Webhooks' },
  { resource: 'Events', readOnly: true },
  { resource: 'Balance', readOnly: true },
  { resource: 'IncompletePayments', writeOnly: true },
  { resource: 'Refunds' },
  { resource: 'Withdrawal' },
  { resource: 'WithdrawalAccount' },
  { resource: 'Automation' },
  { resource: 'Subscriptions' },
  { resource: 'DiscountCodes' },
  { resource: 'APITokens' },
  { resource: 'Organization', writeOnly: true },
  { resource: 'Roles' },
  { resource: 'Team' }
]

const PermissionTogglet = styled.div<{ $selected?: boolean }>`
  border-right: 1px solid ${RADOM_COLORS.GRAY_LIGHTEST};
  padding: 10px;
  cursor: pointer;
  transition: 0.2s ease all;
  color: ${RADOM_COLORS.GRAY_DARKER};
  
  ${({ $selected }) => $selected && `
    font-weight: 600;
    color: ${RADOM_COLORS.NEW_GRAY_1};
    background-color: ${RADOM_COLORS.GRAY_LIGHTEST};
  `}

  &:active {
    opacity: 0.5;
  }

  &:last-child {
    border-right: 0;
  }
  
`

enum PermissionType {
  None,
  Read,
  Write
}

interface PermissionToggleProps {
  readOnly?: boolean
  writeOnly?: boolean
  onChange: (permissions: PermissionType) => void
}

const PermissionToggle = ({ readOnly, writeOnly, onChange }: PermissionToggleProps): ReactElement => {
  const [selectedPermission, setSelectedPermission] = useState(PermissionType.None)

  useEffect(() => {
    onChange(selectedPermission)
  }, [selectedPermission])

  return <div style={{
    display: 'flex',
    fontSize: 14,
    border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
    boxShadow: `0 1px 1px ${RADOM_COLORS.GRAY_LIGHTEST}`,
    width: 'fit-content',
    height: 'fit-content',
    borderRadius: 5,
    overflow: 'hidden'
  }}>
    <PermissionTogglet
      onClick={() => setSelectedPermission(PermissionType.None)}
      $selected={selectedPermission === PermissionType.None}>
        None
    </PermissionTogglet>

    {
      !writeOnly &&
      <PermissionTogglet
        onClick={() => setSelectedPermission(PermissionType.Read)}
        $selected={selectedPermission === PermissionType.Read}>
          Read
      </PermissionTogglet>
    }

    {
      !readOnly &&
      <PermissionTogglet
        onClick={() => setSelectedPermission(PermissionType.Write)}
        $selected={selectedPermission === PermissionType.Write}>
          Write
      </PermissionTogglet>
    }
  </div>
}

const CreateAPIRole = (): ReactElement => {
  const navigate = useNavigate()
  const [isCreatingRole, setIsCreatingRole] = useState(false)
  const [roleName, setRoleName] = useState('')
  const [permissions, setPermissions] = useState(
    Permissions.reduce((t, c) => {
      if (!c.writeOnly) {
        t[`Read${c.resource}`] = false
      }

      if (!c.readOnly) {
        t[`Write${c.resource}`] = false
      }

      return t
    }, {})
  )

  const createAPIRole = async (): Promise<void> => {
    setIsCreatingRole(true)
    Radom.createPermissionRole({
      name: roleName,
      permissions: Object.entries(permissions)
        .filter(([_, b]) => !!b)
        .reduce<string[]>((t, [permissionName]) => {
        t.push(permissionName)
        return t
      }, [])
    })
      .then(() => {
        successToast('Successfully created role')
        navigate('/team/roles')
      })
      .catch(err => {
        const msg = err.response
          ? typeof err.response.data === 'object'
            ? err.response.data.error
            : err.response.data
          : err.message
        errorToast(msg)
      })
      .finally(() => {
        setIsCreatingRole(false)
      })
  }

  return <div style={{
    maxWidth: 960,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '40px auto'
  }}>
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: 30
    }}>
      <PageHeader title='Create role' subTitle='Create a custom permission role to assign to users' />
    </div>

    <form
      style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
      onSubmit={e => {
        e.preventDefault()
        createAPIRole()
      }}>

      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}>
        <div style={{ display: 'flex', maxWidth: 500, flexDirection: 'column', gap: 10 }}>
          <InputLabel>Role name</InputLabel>
          <TextInput placeholder='E.g. Admin' value={roleName} onChange={e => setRoleName(e.target.value)} />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <InputLabel>Permission set</InputLabel>
          <Table
            headers={[
              <div key='resource' style={{ opacity: 0.3, textTransform: 'uppercase', fontSize: 12 }}>Resource</div>,
              <div key='permissions' style={{ opacity: 0.3, textTransform: 'uppercase', fontSize: 12 }}>Permissions</div>
            ]}
            isLoading={false}
            disableHoverStyle
            items={
              Permissions.map(p =>
                [
                  <div key={p.resource} style={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'flex-start'
                  }}>
                    <div>{p.resource}</div>
                  </div>,

                  <PermissionToggle
                    key={p.resource}
                    readOnly={p.readOnly}
                    writeOnly={p.writeOnly}
                    onChange={permission => {
                      switch (permission) {
                      case PermissionType.None:
                        setPermissions({
                          ...permissions,
                          ...(p.writeOnly ? {} : { [`Read${p.resource}`]: false }),
                          ...(p.readOnly ? {} : { [`Write${p.resource}`]: false })
                        })
                        break
                      case PermissionType.Read:
                        setPermissions({
                          ...permissions,
                          ...(p.writeOnly ? {} : { [`Read${p.resource}`]: true }),
                          ...(p.readOnly ? {} : { [`Write${p.resource}`]: false })
                        })
                        break
                      case PermissionType.Write:
                        setPermissions({
                          ...permissions,
                          ...(p.writeOnly ? {} : { [`Read${p.resource}`]: true }),
                          ...(p.readOnly ? {} : { [`Write${p.resource}`]: true })
                        })
                      }
                    }} />
                ]
              )
            } />
        </div>

      </div>
      <div style={{
        padding: '20px 0',
        borderTop: `1px solid ${RADOM_COLORS.GRAY_LIGHTEST}`,
        display: 'flex',
        justifyContent: 'flex-end'
      }}>
        <PrimaryButton isLoading={isCreatingRole}>
          Create role
        </PrimaryButton>
      </div>
    </form>
  </div>
}

export default CreateAPIRole
