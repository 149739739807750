import React, { useEffect, useReducer, useState } from 'react'
import { observer } from 'mobx-react'
import { PrimaryButton } from '../components/Button'
import { RADOM_COLORS } from '../util/Constants'
import { useNavigate } from 'react-router-dom'
import Table from '../components/Table'
import { errorToast, successToast, SecondsToPaymentPeriod, infoToast } from '../util/Util'
import Radom, { IProduct } from '../state/Radom'
import Dots from '../icons/Dots'
import Dropdown, { DropdownItem } from '../components/Dropdown'
import PageHeader from '../components/PageHeader'
import TimeText from '../components/TimeText'
import { Currencies } from '../util/Currencies'
import { Container } from '../components/Animations'
import Checkbox from '../components/Checkbox'
import ProductForm from '../components/ProductForm'
import Modal from '../components/Modal'
import { IProductCreateV2 } from './Products/CreateProduct'

enum ProductsFilter {
  Archived = 'Archived'
}

const defaultProduct = {
  sellerAddress: '',
  name: '',
  description: '',
  chargingIntervalSeconds: 0,
  currency: Currencies[0].ticker,
  price: '',
  image: undefined,
  meteredChargingInterval: 0,
  meteredBudget: 0,
  addOns: [],
  sendSubscriptionEmails: true
}

const ProductsView = observer(() => {
  const navigate = useNavigate()

  const [products, setProducts] = useState([] as IProduct[])
  const [isLoadingProducts, setIsLoadingProducts] = useState(false)

  const [closeDropdownFns, updateCloseDropdownFns] = useReducer((prev, next) => { return { ...prev, ...next } }, {})
  const [closeArchivedDropdownFns, updateArchivedCloseDropdownFns] = useReducer((p, n) => ({ ...p, ...n }), {})

  const [productsFilter, setProductsFilter] = useState<ProductsFilter>()

  const [productModalOpen, setProductModalOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [productForm, updateProductForm] = useReducer(
    (p: IProductCreateV2, n: Partial<IProductCreateV2>) => {
      return { ...p, ...n }
    }, defaultProduct as any)

  const handleUpdateForm = (form: Partial<IProductCreateV2>): void => {
    updateProductForm(form)
  }

  const handleCreateProduct = async (): Promise<void> => {
    setIsSubmitting(true)

    try {
      const product = await Radom.createProduct({
        name: productForm.name,
        description: productForm.description,
        chargingIntervalSeconds: productForm.chargingIntervalSeconds !== undefined &&
         productForm.chargingIntervalSeconds <= 0
          ? undefined
          : productForm.chargingIntervalSeconds,
        addOns: [],
        currency: productForm.currency,
        price: productForm.price,
        sendSubscriptionEmails: productForm.sendSubscriptionEmails,
        productType: productForm.productType
      })

      if (productForm.image) {
        const formData = new FormData()
        formData.append('entityId', product.id)
        formData.append('imageType', 'productImage')
        formData.append('image', productForm.image)
        await Radom.uploadImage(formData)
      }

      setIsSubmitting(false)
      successToast('Created product successfully')
      setProductModalOpen(false)
      loadProducts()
    } catch (err) {
      setIsSubmitting(false)
      console.error(err)
      const msg = err.response
        ? typeof err.response.data === 'object'
          ? err.response.data.error
          : err.response.data
        : err.message
      errorToast(msg)
    }
  }

  const loadProducts = (): void => {
    setIsLoadingProducts(true)
    Radom.getProducts(true)
      .then(products => setProducts(
        products.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()))
      )
      .catch(err => errorToast(err.message))
      .finally(() => setIsLoadingProducts(false))
  }

  useEffect(() => {
    loadProducts()
  }, [])

  return <Container>
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, rowGap: 20 }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <PageHeader title='Products' subTitle='Create and manage your products' />

        <PrimaryButton style={{ gap: 8 }} onClick={() => { setProductModalOpen(true) }}>
          <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
          <span>Create product</span>
        </PrimaryButton>
      </div>

      <div style={{ display: 'flex', gap: 10 }}>

        <Dropdown
          selectedContent={<div style={{ fontSize: 14 }}>{productsFilter || 'Product status'}</div>}
          dropdownContent={<div>
            {[ProductsFilter.Archived].map(s =>
              <DropdownItem
                key={s}
                style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: 14 }}
                onClick={() => {
                  if (s === productsFilter) {
                    setProductsFilter(undefined)
                  } else {
                    setProductsFilter(s)
                  }
                }}>
                <Checkbox size={15} checked={productsFilter === s}/>
                <span>{s}</span>
              </DropdownItem>
            )}
          </div>} />

      </div>

      {
        productModalOpen &&
        <Modal
          visible={productModalOpen}
          onClose={() => {
            setProductModalOpen(false)
            updateProductForm(defaultProduct as any)
          }}
          title='Create product'>
          <ProductForm
            isLoading={false}
            isSubmitting={isSubmitting}
            form={productForm}
            onFormSubmit={handleCreateProduct}
            onFormUpdate={handleUpdateForm}
          />
        </Modal>
      }

      {
        !productsFilter &&
        <>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            rowGap: 10
          }}>
            <Table
              borderless
              headers={['Product', 'Price', 'Created', 'Updated', ''].map(h => <span key={h}>{h}</span>)}
              items={products.filter(p => !p.isArchived)
                .map(p => [
                  <div key="title" style={{
                    fontSize: 14,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8
                  }}>
                    {p.imageUrl && <img src={p.imageUrl} style={{ width: 25, borderRadius: 5, height: 'auto' }} />}
                    {p.name}
                  </div>,
                  <div key="price" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 14,
                    columnGap: 5
                  }}>
                    <div style={{ display: 'flex', gap: 3 }}>
                      {Currencies.find(c => c.ticker.toLowerCase() === p.currency.toLowerCase())?.icon}
                      <span>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 12 }).format(p.price)}</span>
                    </div>
                    {
                      p.chargingIntervalSeconds !== undefined && p.chargingIntervalSeconds > 0 &&
                      <span style={{ fontSize: 12, color: RADOM_COLORS.GRAY_DARKER }}>
                        per {SecondsToPaymentPeriod(p.chargingIntervalSeconds).toLowerCase()}
                      </span>
                    }
                  </div>,
                  <div key="created">
                    <TimeText date={new Date(p.createdAt)} />
                  </div>,
                  <div key="updated">
                    <TimeText date={new Date(p.updatedAt)} />
                  </div>,
                  <div key="dots" style={{ display: 'flex' }}>
                    <Dropdown
                      key={p.id}
                      noChevron
                      selectedContent={<Dots width={15} />}
                      onCloseFn={f => updateCloseDropdownFns({ [p.id]: f })}
                      dropdownContent={
                        <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                          <DropdownItem onClick={() => navigate({
                            pathname: p.productType?.Presale?.Token ? '/presales/create' : '/payment_links/create',
                            search: `?product_id=${p.id}`
                          })}>Create {p.productType?.Presale?.Token ? 'presale' : 'payment'} link</DropdownItem>
                          <div style={{ width: '100%', border: '0.5px dashed', borderColor: RADOM_COLORS.GRAY_DARK }} />
                          <DropdownItem onClick={() => navigate(`/products/${p.id}`)}>Edit product</DropdownItem>
                          <DropdownItem onClick={() => {
                            navigator.clipboard.writeText(p.id)
                            infoToast('Product ID copied to clipboard')
                            closeDropdownFns[p.id]()
                          }}>Copy product ID</DropdownItem>
                          <DropdownItem onClick={async () => {
                            await Radom.archiveProduct(p.id, true)
                              .then(() => {
                                successToast('Successfully archived product')
                                loadProducts()
                              })
                              .catch(err => {
                                errorToast(err.response?.data?.error || err.message)
                              })
                          }}>Archive product</DropdownItem>
                        </div>
                      }
                    />
                  </div>
                ])}
              isLoading={isLoadingProducts}
              emptyPlaceholderText="No products yet"
            />
          </div>
        </>
      }
      {
        productsFilter === ProductsFilter.Archived &&
        <>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            rowGap: 10
          }}>
            <Table
              headers={['Product', 'Price', 'Created', 'Updated', ''].map(h => <span key={h}>{h}</span>)}
              items={products.filter(p => p.isArchived)
                .map(p => [
                  <div key="title" style={{
                    fontSize: 14,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8
                  }}>
                    {p.imageUrl && <img src={p.imageUrl} style={{ width: 25, borderRadius: 5, height: 'auto' }} />}
                    {p.name}
                  </div>,
                  <div key="price" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 14,
                    columnGap: 5
                  }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                      {Currencies.find(c => c.ticker.toLowerCase() === p.currency.toLowerCase())?.icon}
                      <span>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 12 }).format(p.price)}</span>
                    </div>
                    {
                      p.chargingIntervalSeconds !== undefined && p.chargingIntervalSeconds > 0 &&
                      <span style={{ fontSize: 12, color: RADOM_COLORS.GRAY_DARKER }}>
                        per {SecondsToPaymentPeriod[p.chargingIntervalSeconds].toLowerCase()}
                      </span>
                    }
                  </div>,
                  <div key="created">
                    <TimeText date={new Date(p.createdAt)} />
                  </div>,
                  <div key="updated">
                    <TimeText date={new Date(p.updatedAt)} />
                  </div>,
                  <div key="dots" style={{ display: 'flex' }}>
                    <Dropdown
                      noChevron
                      selectedContent={<Dots width={15} />}
                      onCloseFn={f => updateArchivedCloseDropdownFns({ [p.id]: f })}
                      dropdownContent={
                        <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                          <DropdownItem onClick={() => navigate(`/products/${p.id}`)}>Edit product</DropdownItem>
                          <DropdownItem onClick={() => {
                            navigator.clipboard.writeText(p.id)
                            infoToast('Product ID copied to clipboard')
                            closeArchivedDropdownFns[p.id]()
                          }}>Copy product ID</DropdownItem>
                          <DropdownItem onClick={async () => {
                            await Radom.archiveProduct(p.id, false)
                              .then(() => {
                                successToast('Successfully removed product from archive')
                                loadProducts()
                              })
                              .catch(err => {
                                errorToast(err.response?.data?.error || err.message)
                              })
                          }}>Unarchive product</DropdownItem>
                        </div>
                      }
                    />
                  </div>
                ])}
              isLoading={isLoadingProducts}
              emptyPlaceholderText="No products yet"
            />
          </div>
        </>
      }

    </div>
  </Container>
})

export default ProductsView
