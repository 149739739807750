import React, { ReactElement } from 'react'

interface RadomLogoProps extends React.SVGProps<SVGSVGElement> {
  logoProps?: React.SVGAttributes<SVGPathElement>
}

function RadomLogo({ ...props }: RadomLogoProps): ReactElement {
  return <svg width="120" height="32" viewBox="0 0 120 32" xmlns="http://www.w3.org/2000/svg" fill="black" {...props}>
    <g clipPath="url(#clip0_526_40)">
      <path d="M0.499695 2.35787C0.688149 2.35787 0.863681 2.35787 1.03921 2.35787C4.26879 2.35787 7.49836 2.35571 10.7279 2.35894C12.7277 2.3611 14.7102 2.49248 16.5808 3.29583C19.6715 4.62255 21.2555 7.28461 21.2189 10.9019C21.1888 13.9117 19.1782 16.184 16.5808 17.2146C15.8367 17.5096 15.0322 17.6496 14.2666 17.8962C14.0641 17.9619 13.9047 18.1601 13.726 18.2979C13.9295 18.4056 14.1233 18.5671 14.3398 18.6113C15.1432 18.775 15.9767 18.8277 16.7596 19.056C18.2317 19.4857 19.1169 20.5637 19.6219 21.9679C20.5373 24.5126 21.4354 27.0637 22.3378 29.6116C22.3787 29.7279 22.3981 29.8518 22.439 30.0187C22.2754 30.037 22.1429 30.0628 22.0104 30.0639C20.6826 30.0672 19.3548 30.0542 18.0271 30.0736C17.662 30.079 17.4983 29.9821 17.4046 29.5998C16.9179 27.6172 16.4031 25.6422 15.883 23.6672C15.2627 21.311 13.8024 20.0349 11.2804 19.8486C9.19122 19.6946 7.08915 19.7162 4.99353 19.6462C4.56385 19.6322 4.55201 19.8723 4.55201 20.1943C4.55847 23.244 4.55631 26.2948 4.55631 29.3446C4.55631 29.8256 4.31007 30.0661 3.81757 30.0661C2.72561 30.0661 1.63365 30.0661 0.499695 30.0661V2.35787H0.499695ZM4.55631 16.1958C4.79108 16.1958 4.98276 16.1958 5.17552 16.1958C6.82639 16.1958 8.47725 16.1743 10.1281 16.2023C11.387 16.2238 12.5823 15.9675 13.7443 15.5142C16.5754 14.4093 17.8687 11.478 16.724 8.68994C15.9993 6.92385 14.5401 6.06127 12.7342 5.95466C10.2121 5.80605 7.67928 5.85558 5.15183 5.79528C4.67693 5.78451 4.54232 5.92881 4.54555 6.40372C4.56493 9.54391 4.55524 12.6841 4.55524 15.8243C4.55524 15.9266 4.55524 16.03 4.55524 16.1958H4.55631Z" />
      <path d="M119.786 30.0476H116.058V29.4618C116.058 25.5678 116.063 21.6738 116.052 17.7798C116.051 17.2079 116.009 16.6307 115.929 16.0643C115.659 14.183 114.493 13.1104 112.585 12.951C110.393 12.7679 108.503 14.1151 107.891 16.3927C107.67 17.2122 107.557 18.0845 107.548 18.9342C107.511 22.433 107.532 25.9318 107.532 29.4316C107.532 29.6233 107.532 29.8161 107.532 30.0369H103.777C103.777 29.7999 103.777 29.591 103.777 29.3821C103.777 25.6496 103.797 21.9171 103.762 18.1847C103.753 17.2596 103.651 16.3174 103.442 15.4171C102.89 13.0286 100.663 12.6118 99.0872 13.0985C97.1456 13.6994 96.1193 15.1737 95.6239 17.027C95.3827 17.9316 95.2912 18.8976 95.2793 19.8377C95.2384 23.0317 95.2643 26.2258 95.2643 29.4198C95.2643 29.6126 95.2643 29.8053 95.2643 30.0638C94.0538 30.0638 92.893 30.0702 91.7332 30.0519C91.6449 30.0509 91.5199 29.8947 91.4822 29.7859C91.437 29.6578 91.4672 29.5027 91.4672 29.3584C91.4672 23.1136 91.4672 16.8687 91.4672 10.6249C91.4672 10.1526 91.6994 9.91672 92.1639 9.91743C93.1676 9.91743 94.1712 9.91743 95.2223 9.91743C95.331 11.004 95.2449 12.0454 95.0112 13.077C94.9563 13.3215 94.8583 13.5605 94.8324 13.8071C94.8109 14.0117 94.868 14.225 94.8906 14.4339C95.0618 14.2853 95.2836 14.1668 95.3924 13.9816C95.6832 13.4895 95.891 12.9489 96.1915 12.4632C97.5602 10.2523 100.258 9.15608 102.766 9.80651C104.532 10.2642 105.669 11.4326 106.307 13.1147C106.39 13.3322 106.416 13.5767 106.53 13.7748C106.616 13.9256 106.793 14.1087 106.94 14.1162C107.086 14.1248 107.3 13.9676 107.387 13.8244C107.674 13.3505 107.882 12.8272 108.184 12.3641C109.533 10.2943 111.419 9.34453 113.905 9.67298C115.165 9.83882 116.329 10.177 117.358 11.0019C118.694 12.0734 119.32 13.4959 119.582 15.1059C119.735 16.0384 119.792 16.9958 119.798 17.9424C119.822 21.8181 119.809 25.6938 119.808 29.5706C119.808 29.7106 119.792 29.8506 119.782 30.0476H119.786Z" />
      <path d="M65.5957 30.0652C64.4273 30.0652 63.3159 30.076 62.2057 30.049C62.0904 30.0458 61.888 29.8304 61.8815 29.7066C61.8223 28.5199 61.8632 27.3428 62.2713 26.2024C62.3413 26.0064 62.2563 25.7544 62.2433 25.5283C62.0646 25.6844 61.7943 25.805 61.72 26.001C60.9748 27.9545 59.6233 29.3114 57.6515 29.937C54.5533 30.9192 51.7179 30.3452 49.3089 28.1322C47.2596 26.2498 46.3905 23.7837 46.1687 21.0797C45.949 18.4036 46.4562 15.8697 47.881 13.5544C49.5824 10.7911 52.5719 9.35995 55.782 9.6464C57.6881 9.81655 59.3271 10.5478 60.5731 12.0069C61.0297 12.5411 61.3162 13.2206 61.6758 13.8366C61.748 13.9593 61.7695 14.1252 61.8664 14.2178C61.9774 14.3233 62.1464 14.3685 62.2897 14.4396C62.3263 14.2964 62.4243 14.137 62.3909 14.0121C61.3194 9.99747 62.0786 5.91069 61.9009 1.86269C61.9009 1.84761 61.916 1.83254 61.9472 1.77438H65.5968V30.0652H65.5957ZM49.8592 19.9673C49.9626 20.7286 50.0078 21.5029 50.1833 22.247C50.5193 23.675 51.1687 24.9801 52.3748 25.846C54.9625 27.7025 58.4473 27.0919 60.2651 24.7034C62.1863 22.1781 62.3963 19.3664 61.0695 16.5417C59.8193 13.8796 57.2401 12.428 54.073 13.2809C53.2804 13.4941 52.5848 13.8409 51.9946 14.4364C50.4709 15.9731 49.9141 17.8576 49.8581 19.9683L49.8592 19.9673Z" />
      <path d="M28.6953 16.1755H25.0554C25.1749 15.0663 25.526 14.0713 26.0838 13.1473C26.9486 11.7161 28.2344 10.7911 29.7862 10.2645C32.4654 9.3556 35.1738 9.33191 37.8671 10.2203C40.4904 11.0861 41.9743 12.9427 42.39 15.6435C42.5397 16.6149 42.6258 17.6045 42.6334 18.5866C42.6614 22.2653 42.6452 25.9439 42.6441 29.6236C42.6441 29.7475 42.6301 29.8713 42.6215 30.0296H38.8847C38.8471 28.691 38.7954 27.3719 39.2067 26.0828C39.2832 25.8448 39.3521 25.6004 39.378 25.3538C39.3898 25.2407 39.2864 25.1147 39.2358 24.9952C39.113 25.0695 38.9364 25.1147 38.8761 25.2235C38.5908 25.7393 38.3851 26.3057 38.0642 26.7957C36.2453 29.5709 33.5929 30.609 30.3838 30.3085C28.2311 30.1071 26.3972 29.1993 25.2223 27.3126C23.1536 23.9883 24.825 19.8315 28.6468 18.6599C30.456 18.1053 32.3276 18.1193 34.1863 18.0008C35.1695 17.9384 36.1548 17.8641 37.1327 17.7434C39.253 17.4818 39.1378 15.9634 38.4152 14.6269C37.9392 13.7461 37.0616 13.3648 36.1301 13.1613C34.5148 12.8081 32.8897 12.779 31.283 13.2087C29.9315 13.5705 29.1077 14.4643 28.8062 15.8341C28.7836 15.9386 28.7405 16.0387 28.6953 16.1744V16.1755ZM38.8104 19.2694C38.1665 19.9715 37.4094 20.286 36.5888 20.4357C35.3913 20.6532 34.1809 20.7986 32.9867 21.029C31.8861 21.2422 30.7414 21.3898 29.7183 21.813C27.9285 22.5517 27.6238 24.9909 29.0786 26.2164C29.7732 26.8022 30.61 26.9573 31.4876 26.9756C36.2033 27.0789 38.9967 23.5252 38.8115 19.2694H38.8104Z" />
      <path d="M88.2782 19.9631C88.3051 22.5035 87.688 24.8565 86.0953 26.8864C84.5597 28.8442 82.5201 29.9512 80.0723 30.2722C78.0456 30.5371 76.0674 30.3271 74.2011 29.4225C71.8848 28.2993 70.3427 26.4708 69.5102 24.0704C68.512 21.1908 68.527 18.2875 69.6922 15.4542C71.2516 11.6625 74.563 9.53676 78.6831 9.6046C81.8287 9.65629 84.438 10.8172 86.3268 13.392C87.5114 15.0073 88.0962 16.8542 88.2728 18.8357C88.3062 19.2083 88.2771 19.5873 88.2771 19.9631H88.2782ZM84.5446 19.7025C84.5554 18.6493 84.3174 17.3905 83.7057 16.221C82.6741 14.2481 81.0706 13.1379 78.8167 13.0646C76.5401 12.9903 74.7288 13.8766 73.6401 15.8979C72.1206 18.7172 72.1475 21.5925 73.8005 24.3482C75.8186 27.7135 80.4589 27.4798 82.4694 25.4413C83.9706 23.9196 84.5554 22.0534 84.5446 19.7025Z" />
    </g>
    <defs>
      <clipPath id="clip0_526_40">
        <rect width="119.315" height="28.6268" transform="translate(0.498489 1.77344)"/>
      </clipPath>
    </defs>
  </svg>
}

export default RadomLogo
