import React, { ReactElement } from 'react'
import { observer } from 'mobx-react'
import { Container } from '../components/Animations'
import styled from 'styled-components'
import Overview from './Funds/Overview'

export const TokenImage = styled.div<{ imageUrl: string }>`
  height: 30px;
  width: 30px;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`

export const TokenTitle = styled.span`
  font-weight: 500;
`

const Wallet = observer((): ReactElement => {
  return <Container>
    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', rowGap: 20 }}>
      <Overview />
    </div>
  </Container>
})

export default Wallet
