import { formatDistance, formatRelative } from 'date-fns'
import React, { ReactElement, useState } from 'react'
import { RADOM_COLORS } from '../util/Constants'

interface IProps {
  date: Date
  textColor?: string
  formatModes?: TimeFormatType[]
}

export enum TimeFormatType {
  Relative,
  UTC,
  Distance
}

function TimeText({
  date,
  textColor,
  formatModes = [TimeFormatType.Relative, TimeFormatType.UTC]
}: IProps): ReactElement {
  const [timeFormatIndex, setTimeFormatIndex] = useState(0)

  return <span style={{
    fontSize: 14,
    color: `${textColor ?? RADOM_COLORS.GRAY_DARKER}`,
    textDecoration: `underline dotted ${textColor ?? RADOM_COLORS.GRAY_DARK}`,
    textUnderlineOffset: '2px',
    cursor: 'pointer'
  }}
  onClick={e => {
    e.preventDefault()
    e.stopPropagation()
    setTimeFormatIndex((timeFormatIndex + 1) % formatModes.length)
  }}>
    {formatModes[timeFormatIndex] === TimeFormatType.Relative && formatRelative(date, new Date())}
    {formatModes[timeFormatIndex] === TimeFormatType.Distance && formatDistance(date, new Date())}
    {formatModes[timeFormatIndex] === TimeFormatType.UTC && date.toUTCString()}
  </span>
}

export default TimeText
