import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../../util/Constants'
import Radom from '../../state/Radom'
import { Container } from '../../components/Animations'
import SearchMultiselect from '../../components/SearchMultiselect'
import { Currencies, Currency } from '../../util/Currencies'
import { SettingContainer } from './Notifications'
import User from '../../state/User'
import { errorToast, successToast } from '../../util/Util'
import Treasury from '../../state/Treasury'

const SubHeader = styled.div`
  font-size: 16px;
  color: ${RADOM_COLORS.BLACK};
  font-weight: 500;
`

const CurrenciesSettings = observer(() => {
  const [isLoading, setIsLoading] = useState(true)
  const [currency, setCurrency] = useState<Currency>()

  const getOrganization = async (): Promise<void> => {
    if (!User._user?.defaultOrganizationId) {
      return
    }

    setIsLoading(true)
    Radom.getOrganizationById(User._user.defaultOrganizationId)
      .then(r => {
        setCurrency(Currencies.filter(c => c.ticker === r.defaultCurrency)[0])
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    getOrganization()
  }, [User._user?.defaultOrganizationId])

  return <Container>
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        <SubHeader>Currencies</SubHeader>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <SettingContainer style={{ alignItems: 'center', gap: 30 }}>
            <div style={{ opacity: 0.5 }}>Default currency</div>
            <SearchMultiselect
              isLoading={isLoading}
              disabled={false}
              placeholder="Select currency"
              itemArray={Currencies}
              selectedItems={currency ? [currency] : []}
              setSelectedItems={async c => {
                try {
                  await Radom.updateSellerProfile({
                    defaultCurrency: c[0].ticker
                  })

                  await User.getOrganization(true)
                  Treasury.loadPriceQuotes(true)
                  successToast('Updated default currency')

                  setCurrency(c[0])
                } catch (err) {
                  errorToast(err.response?.data?.error || err.message)
                }
              }}
              keyExtractor={c => c.ticker}
              labelExtractor={c => <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>{c.icon}{c.ticker}</div>}
              isSingleSelect
            />
          </SettingContainer>
        </div>
      </div>

    </div>
  </Container>
})

export default CurrenciesSettings
