import React from 'react'
import { IPaymentMethod } from '../state/Radom'
import { ManagedPaymentMethod, getMethod } from '../util/Managed'
import styled from 'styled-components'
import Tooltip from './Tooltip'

const PaymentMethod = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
`

export const SelectedMethodIcon = (props: {
  method: ManagedPaymentMethod
  disableToolTip?: boolean
  size?: number
}): React.ReactElement => {
  return <Tooltip
    style={{ maxWidth: 300 }}
    message={props.method.hostChain ? `${props.method.name} on ${props.method.hostChain.name}` : props.method.name}
    disabled={props.disableToolTip}>
    <div style={{
      width: props.size ?? 25,
      height: props.size ?? 25,
      backgroundImage: `url(${props.method.logo})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      position: 'relative',
      cursor: 'pointer'
    }}>
      {
        props.method.hostChain && <div style={{
          background: 'rgba(255, 255, 255, 0.95)',
          borderRadius: '100%',
          width: props.size ? props.size * 0.6 : 15,
          height: props.size ? props.size * 0.6 : 15,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          bottom: -1,
          right: -5,
          boxShadow: '0 0 1px'
        }}>
          <div style={{
            width: props.size ? props.size * 0.4 : 10,
            height: props.size ? props.size * 0.4 : 10,
            backgroundImage: `url(${props.method.hostChain.logo})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }} />
        </div>
      }
    </div>
  </Tooltip>
}

const PaymentMethodDisplay = (props: {
  method: IPaymentMethod
  paymentMethod?: ManagedPaymentMethod
  disableTooltip?: boolean
  size?: number
  excludeName?: boolean
  includeNetworkName?: boolean
  style?: React.CSSProperties
}): React.ReactElement => {
  const m = props.paymentMethod ?? getMethod(props.method.network, props.method.token)
  return <PaymentMethod style={props.style}>
    <SelectedMethodIcon method={m} disableToolTip={props.disableTooltip ?? true} size={props.size} />
    {!props.excludeName && <div style={{ marginLeft: 4 }}>{m.name}</div>}
    {props.includeNetworkName && <div>on {props.method.network}</div>}
  </PaymentMethod>
}

export default PaymentMethodDisplay
