import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { IconButton, PrimaryButton } from '../../components/Button'
import Table from '../../components/Table'
import PageHeader from '../../components/PageHeader'
import Modal from '../../components/Modal'
import ConfirmButtonModal from '../../components/ConfirmButtonModal'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import { RADOM_COLORS } from '../../util/Constants'
import Dots from '../../icons/Dots'
import Radom, { IToken } from '../../state/Radom'
import { InputLabel, TextInput } from '../../components/Input'
import Copy from '../../icons/Copy'
import TimeText from '../../components/TimeText'
import { errorToast, infoToast, successToast } from '../../util/Util'
import { Container } from '../../components/Animations'

const Developers = observer((): React.ReactElement => {
  const [newTokenName, setNewTokenName] = useState('')
  const [isCreatingToken, setIsCreatingToken] = useState(false)
  const [newToken, setNewToken] = useState<IToken | undefined>(undefined)
  const [isAPITokenModalOpen, setIsAPITokenModalOpen] = useState(false)

  const [isLoadingTokens, setIsLoadingTokens] = useState(true)
  const [tokens, setTokens] = useState<IToken[]>([])

  const loadAPITokens = async (): Promise<void> => {
    setIsLoadingTokens(true)
    Radom.listAPITokens()
      .then(tokens => {
        tokens.sort((t1, t2) => new Date(t2.createdAt).getTime() - new Date(t1.createdAt).getTime())
        setTokens(tokens)
      }).finally(() => {
        setIsLoadingTokens(false)
      })
  }

  useEffect(() => {
    loadAPITokens()
  }, [])

  return <Container>
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, rowGap: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PageHeader title='API tokens' subTitle='Create and manage your Radom API tokens' />
        <PrimaryButton
          onClick={() => {
            setNewToken(undefined)
            setIsAPITokenModalOpen(true)
          }}>+ Create API token</PrimaryButton>
      </div>
      <Table
        borderless
        headers={[
          <span key="ID">ID</span>,
          <span key="token">Token name</span>,
          <span key="created">Created</span>,
          <span key="delete"></span>
        ]}
        isLoading={isLoadingTokens}
        items={tokens.map(token => [
          <div key="id">{token.sessionId}</div>,
          <div key="token">{token.name}</div>,
          <div key="created"><TimeText date={new Date(token.createdAt)} /></div>,
          <div key="delete">
            <Dropdown
              key={token.sessionId}
              noChevron
              selectedContent={<Dots width={15}/>}
              dropdownContent={
                <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>

                  <ConfirmButtonModal
                    title='Delete API token'
                    description='Are you sure you want to delete this API token? This action cannot be undone and all API requests using this token will no longer work.'
                    action='Delete API token'
                    onConfirm={async () => {
                      await Radom.deleteAPIToken(token.sessionId)
                        .then(() => {
                          successToast('Successfully deleted API token')
                          loadAPITokens()
                        })
                        .catch(err => errorToast(err.reason || err.message))
                    }}
                    ActionComponent={props =>
                      <DropdownItem {...props}>
                              Delete API token
                      </DropdownItem>
                    }
                  />
                </div>
              }
            />
          </div>
        ])}
        emptyPlaceholderText='No API tokens created yet'
      />
      <Modal
        visible={isAPITokenModalOpen}
        title={newToken ? 'API token created' : 'Create API token'}
        onClose={() => {
          setIsAPITokenModalOpen(false)
          setNewTokenName('')
        }}>
        <form
          onSubmit={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            maxWidth: 600
          }}>
          <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', rowGap: 20, padding: '0 30px' }}>
            {
              newToken && <>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <span style={{ fontSize: 14, fontWeight: 600 }}>
                        Secure your token
                  </span>
                  <span style={{ fontSize: 14, color: RADOM_COLORS.GRAY_DARKEST }}>
                        Your API token has been successfully created. This token will only be shown once for
                        security purposes. Copy the token below and store it in a safe place.
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                  <InputLabel style={{ fontSize: 14 }}>API token</InputLabel>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                    <TextInput value={newToken.token} disabled />
                    <IconButton style={{ padding: 0 }}>
                      <Copy style={{ width: 20 }} onClick={() => {
                        navigator.clipboard.writeText(newToken.token ?? '')
                        infoToast('API token copied to clipboard')
                      }} />
                    </IconButton>
                  </div>
                </div>
              </>
            }
            {
              !newToken &&
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    <InputLabel style={{ fontSize: 14 }}>Token name</InputLabel>
                    <TextInput
                      placeholder='Enter a name, e.g. My API App'
                      value={newTokenName}
                      onChange={e => setNewTokenName(e.target.value)} />
                  </div>
            }
          </div>
          <div style={{ padding: 30 }}>
            <PrimaryButton
              isLoading={isCreatingToken}
              disabled={!newToken && newTokenName.length <= 0}
              onClick={() => {
                if (newToken) {
                  setIsAPITokenModalOpen(false)
                  return
                }

                setIsCreatingToken(true)
                Radom.generateAPIToken(newTokenName)
                  .then(newToken => {
                    setNewToken(newToken)
                    successToast('Created new API token')
                    loadAPITokens()
                    setIsAPITokenModalOpen(true)
                  }).catch(err => {
                    errorToast(err.reason || err.response?.data?.error || err.message)
                  }).finally(() => {
                    setIsCreatingToken(false)
                  })
              }}
              style={{ width: '100%' }}>
              {newToken ? 'Close' : 'Create token'}
            </PrimaryButton>
          </div>
        </form>
      </Modal>
    </div>
  </Container>
})

export default Developers
