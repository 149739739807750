import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RADOM_COLORS } from '../../util/Constants'
import Table from '../../components/Table'
import TimeText, { TimeFormatType } from '../../components/TimeText'
import Radom, { IProduct, ISubscription, SubscriptionStatus } from '../../state/Radom'
import ConfirmButtonModal from '../../components/ConfirmButtonModal'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import PageHeader from '../../components/PageHeader'
import Dots from '../../icons/Dots'
import { testNetworks, prodNetworks } from '../../util/Managed'
import { useTestMode, formatCurrency, SecondsToPaymentPeriod, successToast } from '../../util/Util'
import { StatusBadge } from '../CheckoutSessions'
import Pagination from '../../components/Pagination'
import Checkbox from '../../components/Checkbox'

enum SubscriptionStatusFilter {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Expired = 'Expired'
}

const Subscriptions = observer((): ReactElement => {
  const pageSize = 20
  const [curPage, setCurPage] = useState(0)

  const [subscriptionStatusFilter, setSubscriptionStatusFilter] = useState<SubscriptionStatusFilter | undefined>(
    SubscriptionStatusFilter.Active
  )

  const [isLoading, setIsLoading] = useState(false)
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([])
  const [products, setProducts] = useState<{ [productId: string]: IProduct }>({})

  const [lastDateTabLabel, setLastDateTabLabel] = useState<string>()
  const [lastDateTab, setLastDateTab] = useState<string>()

  const navigate = useNavigate()
  const [testMode] = useTestMode()

  const [totalEventsSize, setTotalEventsSize] = useState(0)

  const loadSubscriptions = async (): Promise<void> => {
    let statuses: SubscriptionStatus[] = []
    switch (subscriptionStatusFilter) {
    case SubscriptionStatusFilter.Active:
      statuses = ['active']
      setLastDateTabLabel('Next payment date')
      setLastDateTab('nextBillingDateAt')
      break
    case SubscriptionStatusFilter.Cancelled:
      statuses = ['cancelled']
      setLastDateTabLabel('Cancelled at')
      setLastDateTab('cancelledAt')
      break
    case SubscriptionStatusFilter.Expired:
      statuses = ['expired']
      setLastDateTabLabel('Expired at')
      setLastDateTab('expiredAt')
      break
    }

    setIsLoading(true)
    setSubscriptions([])
    Radom.listSubscriptions(
      new Date().toISOString(),
      new Date(0).toISOString(),
      new Date(0).toISOString(),
      testMode ? testNetworks : prodNetworks,
      statuses,
      curPage * pageSize,
      pageSize
    )
      .then(s => {
        setSubscriptions(s.data)
        setTotalEventsSize(s.total)
        return s
      }).then(async s => {
        const productIds = [...new Set(s.data.map(s => s.tags?.productId).filter(p => !!p))] as string[]
        const newProducts = {}
        for (const productId of productIds) {
          const product = await Radom.getProduct(productId)
          newProducts[productId] = product
        }
        setProducts(newProducts)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    loadSubscriptions()
  }, [testMode, curPage, subscriptionStatusFilter])

  return <>
    <PageHeader
      title='Subscriptions'
      subTitle='Manage your subscriptions'

    />

    <div style={{ display: 'flex', gap: 10 }}>
      <Dropdown
        selectedContent={<div style={{ fontSize: 14 }}>{subscriptionStatusFilter || 'Status'}</div>}
        dropdownContent={<div>
          {[SubscriptionStatusFilter.Active, SubscriptionStatusFilter.Cancelled, SubscriptionStatusFilter.Expired]
            .map(s =>
              <DropdownItem
                key={s}
                style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: 14 }}
                onClick={() => {
                  if (s === subscriptionStatusFilter) {
                    setSubscriptionStatusFilter(undefined)
                  } else {
                    setSubscriptionStatusFilter(s)
                  }
                }}>
                <Checkbox size={15} checked={subscriptionStatusFilter === s}/>
                <span>{s}</span>
              </DropdownItem>
            )}
        </div>} />
    </div>

    <Table
      borderless
      disableOnClickState
      headers={[
        <span key="customerAddress">Customer</span>,
        <span key="status">Status</span>,
        <span key="subscription">Subscription</span>,
        <span key="start">Start date</span>,
        <span key="payment">{lastDateTabLabel}</span>,
        <span key="cancel"></span>
      ]}
      onClickRow={i => {
        const s = subscriptions[i]
        navigate(`/subscription/${s.id}`)
      }}
      isLoading={isLoading}
      items={subscriptions.map(s => {
        const product = s.tags?.productId ? products[s.tags.productId] : undefined

        return [
          <div key="1">
            {s.subscriptionType?.automatedEVMSubscription?.emailAddress ||
          s.subscriptionType?.automatedEVMSubscription?.buyerAddress}
            {s.subscriptionType?.emailInvoiceSubscription?.emailAddress}
          </div>,
          <div key="2"><StatusBadge status={s.status}>{s.status}</StatusBadge></div>,
          <div key="3" style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            {
              product
                ? <>
                  <div key="title" style={{
                    fontSize: 14,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8
                  }}>
                    {
                      product.imageUrl &&
                    <img src={product.imageUrl} style={{ width: 25, borderRadius: 5, height: 'auto' }} />
                    }
                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 5 }}>
                      {product.name}

                      <div style={{ display: 'flex', gap: 3 }}>
                        {formatCurrency(product.price, product.currency)}
                      </div>

                      {
                        product.chargingIntervalSeconds && product.chargingIntervalSeconds > 0 &&
                      <span style={{ fontSize: 12, color: RADOM_COLORS.GRAY_DARKER }}>
                        per {SecondsToPaymentPeriod(product.chargingIntervalSeconds).toLowerCase()}
                      </span>
                      }
                    </div>
                  </div>
                </>
                : <>
                  <span>{formatCurrency(s.amount as any, s.currency)}</span>
                  <span>{s.period === 'custom' ? `every ${s.periodCustomDuration} seconds` : s.period}</span>
                </>
            }
          </div>,
          <div key="4">
            <TimeText date={new Date(s.createdAt)} />
          </div>,
          <div key="5">
            {
              lastDateTab && <TimeText
                date={new Date(s[lastDateTab])}
                formatModes={[TimeFormatType.Relative, TimeFormatType.UTC]} />
            }
          </div>,
          <div key={s.status} onClick={e => e.stopPropagation()}>
            {
              s.status === 'active' &&
          <Dropdown
            noChevron
            selectedContent={<Dots width={15} />}
            dropdownContent={
              <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                <ConfirmButtonModal
                  title='Cancel subscription'
                  description='Are you sure you want to cancel this subscription?'
                  action='Cancel subscription'
                  onConfirm={async () => {
                    Radom.cancelSubscription(s.id)
                      .then(async () => await loadSubscriptions())
                      .then(() => successToast('Cancelled subscription'))
                  }}
                  ActionComponent={props => <DropdownItem {...props}>Cancel subscription</DropdownItem>}
                />
              </div>
            }
          />
            }
          </div>
        ]
      })}
      emptyPlaceholderText='No subscriptions yet'
    />
    {
      !isLoading && <Pagination
        curPage={curPage}
        numPages={Math.ceil(totalEventsSize / pageSize)}
        onPageClick={page => setCurPage(page)}
      />
    }
  </>
})

export default Subscriptions
