
import React from 'react'

export function Mobile(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="7.95 2.21 18 28" stroke="black" {...props}>
    <path d="M22.946 3.20941H10.946C9.84148 3.20941 8.94604 4.10484 8.94604 5.20941V27.2094C8.94604 28.314 9.84148 29.2094 10.946 29.2094H22.946C24.0506 29.2094 24.946 28.314 24.946 27.2094V5.20941C24.946 4.10484 24.0506 3.20941 22.946 3.20941Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.94604 7.20941H24.946" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.94604 25.2094H24.946" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
}
