import { endOfDay, subDays, subHours } from 'date-fns'
import { makeAutoObservable } from 'mobx'
import { GraphData } from '../components/LineGraph'
import Events, { ManagedNewSubscriptionEvent, ManagedPaymentEvent, ManagedSubscriptionCancelledEvent, ManagedSubscriptionExpiredEvent } from './Events'
import Treasury from './Treasury'
import Radom from './Radom'
import { testNetworks, prodNetworks } from '../util/Managed'

class Analytics {
  isLoading: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  getGrossDailyVolume = async (_: number = 0, testMode: boolean): Promise<GraphData> => {
    const end = subDays(new Date(), 24)
    end.setHours(24, 0, 0, 0)

    const data = Array(25).fill(0).map((_, idx) => ({
      x: subHours(end, idx).getTime(),
      y: 0
    })).reverse()

    Events.managedEvents.filter(e =>
      e.name === 'PaymentEvent' ||
      e.name === 'SubscriptionPaymentEvent' ||
      e.name === 'RecurringPaymentEvent' ||
      e.name === 'IncompletePaymentEvent'
    ).forEach((e: ManagedPaymentEvent) => {
      if ((e.paymentMethod.isTestnet && !testMode) || (!e.paymentMethod.isTestnet && testMode)) {
        return
      }

      const eventTime = new Date(e.date).getTime()
      if (eventTime < data[0].x) return

      const method = e.paymentMethod
      const conversion = Treasury.priceQuotes.find(p => p.from.toLowerCase() === method.ticker.toLowerCase())

      for (let i = 0; i < data.length; i++) {
        if (eventTime > data[i].x) {
          continue
        }

        data[i].y += Number(e.amount) * (conversion?.value ?? 1)
        break
      }
    })

    return data
  }

  getManagedGrossVolume = (days: number, testMode: boolean): GraphData => {
    const end = endOfDay(new Date())

    const data = Array(days).fill(0).map((_, idx) => ({
      x: subDays(end, idx).getTime(),
      y: 0
    })).reverse()

    Events.managedEvents.filter(e =>
      e.name === 'PaymentEvent' ||
      e.name === 'SubscriptionPaymentEvent' ||
      e.name === 'RecurringPaymentEvent' ||
      e.name === 'IncompletePaymentEvent'
    ).forEach((e: ManagedPaymentEvent) => {
      if ((e.paymentMethod.isTestnet && !testMode) || (!e.paymentMethod.isTestnet && testMode)) {
        return
      }

      const eventTime = new Date(e.date).getTime()
      if (eventTime < data[0].x) return

      const method = e.paymentMethod
      const conversion = Treasury.priceQuotes.find(p => p.from.toLowerCase() === method.ticker.toLowerCase())

      for (let i = 0; i < data.length; i++) {
        if (eventTime > data[i].x) {
          continue
        }
        data[i].y += Number(e.amount) * (conversion?.value ?? 1)
        break
      }
    })

    return data
  }

  getGrossVolume = async(days: number, testMode: boolean): Promise<GraphData> => {
    const managedVolume = this.getManagedGrossVolume(days, testMode)
    return managedVolume
  }

  getNetVolume = async (days: number, testMode: boolean): Promise<GraphData> => {
    const managedVolume = this.getManagedGrossVolume(days, testMode)
    return managedVolume
  }

  getRecurringPayments = async (days: number, testMode: boolean): Promise<GraphData> => {
    const end = endOfDay(new Date())

    const data = Array(days).fill(0).map((_, idx) => ({
      x: subDays(end, idx).getTime(),
      y: 0
    })).reverse()

    Events.managedEvents.filter(e => e.name === 'RecurringPaymentEvent' || e.name === 'SubscriptionPaymentEvent').forEach((e: ManagedPaymentEvent) => {
      if ((e.paymentMethod.isTestnet && !testMode) || (!e.paymentMethod.isTestnet && testMode)) {
        return
      }

      const eventTime = new Date(e.date).getTime()
      if (eventTime < data[0].x) return

      for (let i = 0; i < data.length; i++) {
        if (eventTime > data[i].x) {
          continue
        }

        data[i].y += 1
        break
      }
    })

    return data
  }

  getOneTimePayments = async (days: number, testMode: boolean): Promise<GraphData> => {
    const end = endOfDay(new Date())

    const data = Array(days).fill(0).map((_, idx) => ({
      x: subDays(end, idx).getTime(),
      y: 0
    })).reverse()

    Events.managedEvents.filter(e => e.name === 'PaymentEvent').forEach((e: ManagedPaymentEvent) => {
      if ((e.paymentMethod.isTestnet && !testMode) || (!e.paymentMethod.isTestnet && testMode)) {
        return
      }

      const eventTime = e.date.getTime()
      if (eventTime < data[0].x) return

      for (let i = 0; i < data.length; i++) {
        if (eventTime > data[i].x) {
          continue
        }
        data[i].y += 1
        break
      }
    })

    return data
  }

  getNewSubscriptions = async (days: number, testMode: boolean): Promise<GraphData> => {
    const end = endOfDay(new Date())

    const data = Array(days).fill(0).map((_, idx) => ({
      x: subDays(end, idx).getTime(),
      y: 0
    })).reverse()

    Events.managedEvents.filter(e => e.name === 'NewSubscription').forEach((e: ManagedNewSubscriptionEvent) => {
      if (e.managedMethod.isTestnet && !testMode) {
        return
      }

      const eventTime = e.date.getTime()
      if (eventTime < data[0].x) return

      for (let i = 0; i < data.length; i++) {
        if (eventTime > data[i].x) {
          continue
        }
        data[i].y += 1
        break
      }
    })

    return data
  }

  getCancelledSubscriptions = async (days: number, testMode: boolean): Promise<GraphData> => {
    const end = endOfDay(new Date())

    const data = Array(days).fill(0).map((_, idx) => ({
      x: subDays(end, idx).getTime(),
      y: 0
    })).reverse()

    Events.allEvents.filter(e => e.name === 'SubscriptionCancelled').forEach((s: ManagedSubscriptionCancelledEvent) => {
      if (s.managedMethod.isTestnet && !testMode) {
        return
      }

      const subscriptionCancelled = new Date(s.data.cancelledAt).getTime()
      if (subscriptionCancelled < data[0].x) return

      for (let i = 0; i < data.length; i++) {
        if (subscriptionCancelled > data[i].x) {
          continue
        }
        data[i].y += 1
        return
      }
    })

    return data
  }

  getExpiredSubscriptions = async (days: number, testMode: boolean): Promise<GraphData> => {
    const end = endOfDay(new Date())

    const data = Array(days).fill(0).map((_, idx) => ({
      x: subDays(end, idx).getTime(),
      y: 0
    })).reverse()

    Events.allEvents.filter(e => e.name === 'SubscriptionExpired').forEach((s: ManagedSubscriptionExpiredEvent) => {
      if (s.managedMethod.isTestnet && !testMode) {
        return
      }

      const subscriptionExpired = new Date(s.data.expiredAt).getTime()
      if (subscriptionExpired < data[0].x) return

      for (let i = 0; i < data.length; i++) {
        if (subscriptionExpired > data[i].x) {
          continue
        }
        data[i].y += 1
        return
      }
    })

    return data
  }

  getTotalSubscribers = async (days: number, testMode: boolean): Promise<GraphData> => {
    const end = endOfDay(new Date())

    const data = Array(days).fill(0).map((_, idx) => ({
      x: subDays(end, idx).getTime(),
      y: 0
    })).reverse()

    const subscriptions = await Radom.listSubscriptions(
      new Date(data[0].x).toISOString(),
      new Date(data[0].x).toISOString(),
      new Date(data[0].x).toISOString(),
      testMode ? testNetworks : prodNetworks,
      ['active', 'cancelled', 'expired'],
      0,
      100
    )

    const newSubscriptions = await this.getNewSubscriptions(days, testMode)
    const expiredSubscriptions = await this.getExpiredSubscriptions(days, testMode)
    const cancelledSubscriptions = await this.getCancelledSubscriptions(days, testMode)

    for (let i = 0; i < data.length; i++) {
      const baseAmount = i === 0 ? subscriptions.total : data[i - 1].y
      data[i].y = baseAmount + newSubscriptions[i].y - expiredSubscriptions[i].y - cancelledSubscriptions[i].y
    }

    return data
  }
}

export default new Analytics()
