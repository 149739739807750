import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'
import { Token } from '../util/Tokens'

const TokenLogo = styled.img`
  width: 16px;
  height: 16px;
`

export const TokenDisplay = ({ token }: { token: Token }): ReactElement => {
  return (
    <div style={{ display: 'flex', gap: '6px' }}>
      <TokenLogo src={token.logo} />
      <span style={{ fontWeight: '500' }}>{token.name}</span>
      <span style={{ color: RADOM_COLORS.GRAY_DARKEST }}>{token.ticker}</span>
    </div>
  )
}
