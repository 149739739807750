import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Container } from '../components/Animations'
import { IconButton, PrimaryButton } from '../components/Button'
import Table from '../components/Table'
import Radom, { IDonationLink } from '../state/Radom'
import PageHeader from '../components/PageHeader'
import Dropdown, { DropdownItem } from '../components/Dropdown'
import Dots from '../icons/Dots'
import { TextInput } from '../components/Input'
import Copy from '../icons/Copy'
import ConfirmButtonModal from '../components/ConfirmButtonModal'
import LinkExternal from '../components/LinkExternal'
import TimeText from '../components/TimeText'
import { errorToast, infoToast, successToast } from '../util/Util'
import { RADOM_COLORS } from '../util/Constants'

const CloseFns = {}

const DonationLinks = observer((): React.ReactElement => {
  const navigate = useNavigate()
  const [donationLinks, setDonationLinks] = useState<IDonationLink[]>([])
  const [isLoadingDonationLinks, setIsLoadingDonationLinks] = useState(false)
  const inputRefs = {}

  useEffect(() => {
    loadDonationLinks()
  }, [])

  const loadDonationLinks = (): void => {
    setIsLoadingDonationLinks(true)
    Radom.getDonationLinks()
      .then(donationLinks => {
        const convertedDonationLinks = donationLinks.map(donationLink => ({
          ...donationLink,
          createdAt: new Date(donationLink.createdAt),
          updatedAt: new Date(donationLink.updatedAt)
        }))
        setDonationLinks(convertedDonationLinks.sort((a, b) =>
          b.updatedAt.getTime() - a.updatedAt.getTime()))
      })
      .catch(err => errorToast(err.message))
      .finally(() => setIsLoadingDonationLinks(false))
  }

  return (
    <Container>
      <div style={{ flexGrow: 1 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PageHeader title='Donation links' subTitle='Create donation links for receiving donations' />
          <Link to="/donation_links/create" style={{ textDecoration: 'none' }}>
            <PrimaryButton style={{ gap: 8 }}>
              <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
              <span>Create donation link</span>
            </PrimaryButton>
          </Link>
        </div>
        <div style={{ paddingTop: 20 }}>
          <Table
            borderless
            headers={[
              <span key="URL">URL</span>,
              <span key="name">Name</span>,
              <span key="created">Created</span>,
              <span key="updated">Updated</span>,
              <span key="action"></span>
            ]}
            isLoading={isLoadingDonationLinks}
            items={donationLinks.map(d => {
              const createdAt = new Date(d.createdAt)
              const updatedAt = new Date(d.updatedAt)

              return [
                <div key="url" style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '5px' }}>
                  <TextInput ref={r => r && (inputRefs[d.id] = r)} value={d.url || ''} style={{ maxWidth: '280px', height: 'fit-content' }} disabled />
                  <IconButton style={{ padding: 0 }}>
                    <Copy style={{ width: 20 }} onClick={() => {
                      inputRefs[d.id].select()
                      inputRefs[d.id].setSelectionRange(0, 99999)
                      navigator.clipboard.writeText(inputRefs[d.id].value)
                      infoToast('Copied donation link!')
                    }} />
                  </IconButton>
                  <IconButton style={{ padding: 0 }} onClick={() => window.open(d.url, '_blank')} >
                    <LinkExternal stroke={RADOM_COLORS.GRAY_DARK} width={20} />
                  </IconButton>
                </div>,
                <div key="name">{d.name}</div>,
                <div key="created" style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                  {isNaN(createdAt.getTime()) ? 'Invalid date' : <TimeText date={createdAt} />}
                </div>,
                <div key="updated" style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                  {isNaN(updatedAt.getTime()) ? 'Invalid date' : <TimeText date={updatedAt} />}
                </div>,
                <div key="dots" style={{ display: 'flex' }}>
                  <Dropdown
                    key={d.id}
                    noChevron
                    selectedContent={<Dots width={15} />}
                    onCloseFn={f => {
                      CloseFns[d.id] = f
                    }}
                    dropdownContent={
                      <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                        <DropdownItem onClick={() => navigate(`/donation_link/${d.id}`)}>Edit donation link</DropdownItem>
                        <ConfirmButtonModal
                          title='Delete donation link'
                          description='Are you sure you want to delete this donation link? This action cannot be undone.'
                          action='Delete donation link'
                          onConfirm={async () => {
                            await Radom.deleteDonationLink(d.id)
                              .then(() => {
                                successToast('Successfully deleted donation link')
                                loadDonationLinks()
                              })
                              .catch(err => {
                                errorToast(err.response?.data?.error || err.message)
                              })
                          }}
                          ActionComponent={props => <DropdownItem {...props}>Delete donation link</DropdownItem>}
                        />
                      </div>
                    }
                  />
                </div>
              ]
            })}
            emptyPlaceholderText='No donation links created yet'
          />
        </div>
      </div>
    </Container>
  )
})

export default DonationLinks
