import React, { ReactElement, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { PrimaryButton, SecondaryButton } from './Button'
import { RADOM_COLORS } from '../util/Constants'
import { InputLabel, TextInputWithLabel, OptionalLabel, TextAreaWithLabel } from './Input'
import NumberInput from './NumberInput'
import PaymentPeriodSelector, { ProductPeriod } from './PaymentPeriodSelector'
import { IProductCreate, IProductUpdate } from '../state/Radom'
import SearchMultiselect from './SearchMultiselect'
import { Currencies } from '../util/Currencies'
import { errorToast } from '../util/Util'
import Checkbox from './Checkbox'
import Upload from '../icons/Upload'
import Subscription from '../icons/nav/Billing'
import Checkmark from '../icons/Checkmark'

const PageSectionTitle = styled.h2`
  margin: 0px 0 16px 0;
  color: ${RADOM_COLORS.GRAY_DARKEST};
  font-weight: 400;
  font-size: 16px;
`

export const ImageUploadButton = styled.div`
  cursor: pointer;
  border: 1px solid ${RADOM_COLORS.GRAY4};
  border-radius: 10px;
  width: 70px;
  height: 70px;
  border-style: dashed;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${RADOM_COLORS.GRAY_DARKER};
  transition: 0.2s ease all;
  position: relative;
  overflow: hidden;
  background-size: cover;

  :hover {
    border-color: ${RADOM_COLORS.GRAY_DARK};
  }

  :active {
    opacity: 0.5;
  }
`

export const ProductPeriodSecs = {
  Second: 1,
  Minute: 60,
  Hour: 60 * 60,
  Day: 60 * 60 * 24,
  Week: 60 * 60 * 24 * 7,
  Month: 60 * 60 * 24 * 30,
  Quarter: 60 * 60 * 24 * 30 * 3,
  SixMonths: 60 * 60 * 24 * 30 * 6,
  Year: 60 * 60 * 24 * 365
}

const ProductPeriodDisplay: { [key in ProductPeriod]: string } = {
  [ProductPeriod.Day]: 'Day',
  [ProductPeriod.Week]: 'Week',
  [ProductPeriod.Month]: 'Month',
  [ProductPeriod.Quarter]: 'Quarter',
  [ProductPeriod.SixMonths]: 'Six Months',
  [ProductPeriod.Year]: 'Year'
}

export interface IProductUpdateV2 extends IProductUpdate {
  image: File
}

interface IProps {
  form: IProductCreate
  isSubmitting: boolean
  isLoading: boolean
  modal?: boolean
  imageUrl?: string
  disableRecurring?: boolean
  disabled?: boolean
  update?: boolean
  sendEmailNotifications?: boolean
  allowedProductTypes?: ProductType[]
  onCancel?: () => void
  onFormUpdate: (form: Partial<IProductUpdateV2>) => void
  onFormSubmit: () => void
}

export enum ProductType {
  OneTime = 'One time',
  Subscription = 'Subscription',
  Presale = 'Presale'
}

interface ProductCategoryProps {
  name: string
  description: string
  isSelected: boolean
  onClick: () => void
}

const ProductCategory = (props: ProductCategoryProps): ReactElement => {
  return <SecondaryButton
    type='button'
    onClick={props.onClick}
    style={{
      border: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      minWidth: 220,
      width: '50%',
      gap: 3,
      userSelect: 'none',
      fontSize: 14,
      color: RADOM_COLORS.BLACK
    }}>
    <span style={{ color: props.isSelected ? RADOM_COLORS.BLUE4 : 'initial' }}>{props.name}</span>
    <span style={{ fontSize: 12, opacity: 0.5 }}>{props.description}</span>
    <div style={{
      width: '100%',
      height: 3,
      borderRadius: 2,
      marginTop: 5,
      transition: '0.2s ease all',
      backgroundColor: props.isSelected ? RADOM_COLORS.BLUE5 : RADOM_COLORS.GRAY8
    }} />
  </SecondaryButton>
}

interface PricingTypeProps {
  isOnetime?: boolean
  isSelected: boolean
  disabled?: boolean
  onClick: () => void
}

const PricingType = (props: PricingTypeProps): ReactElement => {
  return <SecondaryButton
    onClick={props.onClick}
    type='button'
    disabled={props.disabled}
    style={{
      display: 'flex',
      flexGrow: 1,
      width: '50%',
      border: '1px solid',
      borderColor: props.isSelected ? RADOM_COLORS.BLUE4 : RADOM_COLORS.GRAY8,
      padding: '10px 20px',
      borderRadius: 10,
      gap: 15,
      alignItems: 'center',
      minWidth: 220
    }}>

    {
      props.isOnetime
        ? <Checkmark
          style={{ width: 15, height: 15 }}
          stroke={props.isSelected ? RADOM_COLORS.BLUE4 : RADOM_COLORS.GRAY4} />
        : <Subscription
          style={{ width: 18, height: 18 }}
          stroke={props.isSelected ? RADOM_COLORS.BLUE4 : RADOM_COLORS.GRAY4}
          fill={props.isSelected ? RADOM_COLORS.BLUE4 : RADOM_COLORS.GRAY4} />
    }

    <div style={{
      display: 'flex',
      flexDirection: 'column',
      color: RADOM_COLORS.GRAY4,
      alignItems: 'flex-start'
    }}>
      <span style={{
        fontSize: 14,
        color: props.isSelected ? RADOM_COLORS.BLUE4 : RADOM_COLORS.GRAY4
      }}>
        {props.isOnetime ? 'One-time' : 'Subscription'}
      </span>
      <span style={{ fontSize: 12, opacity: 0.75 }}>{props.isOnetime ? 'Charge a one time fee' : 'Charge an ongoing fee'}</span>
    </div>

  </SecondaryButton>
}

const ProductForm = (props: IProps): ReactElement => {
  const imageFileInput = useRef<HTMLInputElement>(null)
  const [imgUrl, setImgUrl] = useState<string>()
  const [productType, setProductType] = useState<ProductType>(
    props.allowedProductTypes ? props.allowedProductTypes[0] : ProductType.OneTime
  )
  const [period, setPeriod] = useState(ProductPeriod.Month)
  const productTypes = props.allowedProductTypes ?? [ProductType.OneTime, ProductType.Subscription, ProductType.Presale]

  const disabled = (): boolean => {
    return props.isLoading || props.isSubmitting
  }

  const onImageFileChange = async (): Promise<void> => {
    const fileList = imageFileInput.current?.files
    if (fileList && fileList.length > 0) {
      const imageFile = fileList[0]
      if (imageFile.size > 4000000) {
        errorToast('File size must be less than 4MB')
      }
      setImgUrl(URL.createObjectURL(imageFile))

      props.onFormUpdate({ image: fileList[0] })
    }
  }

  const onFormSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    e.stopPropagation()

    if (!props.form.name || props.form.name.trim() === '') {
      errorToast('Product name is required.')
      return
    }

    if (!props.form.price || Number(props.form.price) <= 0) {
      errorToast('Price is required and must be greater than zero.')
      return
    }

    props.onFormSubmit()
  }

  useEffect(() => {
    if (productType === ProductType.Subscription) {
      props.onFormUpdate({
        chargingIntervalSeconds: ProductPeriodSecs[period]
      })
    } else {
      props.onFormUpdate({
        chargingIntervalSeconds: undefined
      })
    }
  }, [productType])

  useEffect(() => {
    if (props.form.chargingIntervalSeconds && props.form.chargingIntervalSeconds > 0) {
      setProductType(ProductType.Subscription)
      setPeriod(Object.entries(ProductPeriodSecs)
        .find(p => p[1] === props.form.chargingIntervalSeconds)?.[0] as ProductPeriod ??
        ProductPeriod.Month
      )
    }

    if (props.form.productType?.Presale) {
      setProductType(ProductType.Presale)
    }
  }, [props.form])

  useEffect(() => {
    if (props.imageUrl) {
      setImgUrl(props.imageUrl)
    }
  }, [props.imageUrl])

  return <div style={{ fontSize: 14, width: '100%', minWidth: 450 }}>
    <form onSubmit={onFormSubmit} style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
      <div style={{ padding: '0 30px', display: 'flex', flexDirection: 'column', gap: 30 }}>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>

          {
            !props.update &&
            productTypes.includes(ProductType.Presale) &&
            productTypes.includes(ProductType.OneTime) &&

            <div style={{ display: 'flex', gap: 20 }}>

              <ProductCategory
                onClick={() => setProductType(ProductType.OneTime)}
                name='Good / Service'
                description='One-time products or subscriptions'
                isSelected={productType !== ProductType.Presale} />

              <ProductCategory
                onClick={() => setProductType(ProductType.Presale)}
                name='Token'
                description='Sell a digital token'
                isSelected={productType === ProductType.Presale} />

            </div>
          }

          {
            productType !== 'Presale' &&
            <>
              <TextInputWithLabel
                disabled={disabled()}
                label='Name'
                value={props.form.name}
                placeholder='Pro subscription plan, sneakers, etc.'
                onChange={e => props.onFormUpdate({ name: (e.target as HTMLInputElement).value })}
                required
              />

              <div style={{ display: 'flex', gap: 20 }}>
                <div style={{ flexGrow: 1 }}>
                  <TextAreaWithLabel
                    style={{ maxHeight: 150 }}
                    disabled={disabled()}
                    label='Description'
                    value={props.form.description}
                    placeholder=''
                    onChange={e => props.onFormUpdate({ description: (e.target as HTMLInputElement).value })}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <div style={{ display: 'flex', gap: 5 }}>
                    <InputLabel>Image</InputLabel>
                    <OptionalLabel>Optional</OptionalLabel>
                  </div>
                  <ImageUploadButton>
                    {
                      !imgUrl &&
                       <div style={{
                         display: 'flex',
                         flexDirection: 'column',
                         alignItems: 'center',
                         pointerEvents: 'none',
                         gap: 2,
                         color: RADOM_COLORS.BLACK
                       }}>
                         <Upload style={{ height: 25 }} />
                         <span style={{ fontSize: 10 }}>Upload</span>
                       </div>
                    }
                    <input
                      disabled={disabled()}
                      type="file"
                      name="profile_picture"
                      ref={imageFileInput}
                      onChange={async () => await onImageFileChange()}
                      style={{
                        opacity: 0,
                        cursor: 'pointer',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0
                      }}
                    />
                    {
                      imgUrl &&
                      <img src={imgUrl} style={{
                        pointerEvents: 'none',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        position: 'absolute'
                      }} />
                    }
                  </ImageUploadButton>
                </div>
              </div>
            </>
          }

          {
            productType === ProductType.Presale && <>
              <div style={{ display: 'flex', gap: 20 }}>

                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 20 }}>
                  <TextInputWithLabel
                    disabled={disabled()}
                    label='Token name'
                    value={props.form.name}
                    placeholder='Uniswap'
                    onChange={e => props.onFormUpdate({ name: (e.target as HTMLInputElement).value })}
                    required
                  />

                  <TextInputWithLabel
                    disabled={disabled()}
                    label='Token ticker'
                    value={props.form.productType?.Presale?.Token?.ticker}
                    placeholder='UNI'
                    onChange={e => props.onFormUpdate({
                      productType: {
                        Presale: {
                          Token: {
                            ...props.form.productType?.Presale?.Token,
                            ticker: (e.target as HTMLInputElement).value
                          }
                        }
                      }
                    })}
                    required
                  />

                  <TextInputWithLabel
                    disabled={disabled()}
                    label='Token decimals'
                    value={props.form.productType?.Presale?.Token?.decimals}
                    placeholder='6'
                    onChange={(e: any) => props.onFormUpdate({
                      productType: {
                        Presale: {
                          Token: {
                            ...props.form.productType?.Presale?.Token,
                            decimals: isNaN(e.target.value) || !e.target.value ? '' : Number(e.target.value)
                          }
                        }
                      }
                    })}
                    required
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: 10, minWidth: 80 }}>
                  <div style={{ display: 'flex', gap: 5 }}>
                    <InputLabel>Token icon</InputLabel>
                  </div>
                  <ImageUploadButton>
                    {
                      !imgUrl &&
                       <div style={{
                         display: 'flex',
                         flexDirection: 'column',
                         alignItems: 'center',
                         pointerEvents: 'none',
                         gap: 2,
                         color: RADOM_COLORS.BLACK
                       }}>
                         <Upload style={{ height: 25 }} />
                         <span style={{ fontSize: 10 }}>Upload</span>
                       </div>
                    }
                    <input
                      disabled={disabled()}
                      type="file"
                      name="profile_picture"
                      ref={imageFileInput}
                      onChange={async () => await onImageFileChange()}
                      style={{
                        opacity: 0,
                        cursor: 'pointer',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0
                      }}
                    />
                    {
                      imgUrl &&
                      <img src={imgUrl} style={{
                        pointerEvents: 'none',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        position: 'absolute'
                      }} />
                    }
                  </ImageUploadButton>
                </div>
              </div>

            </>
          }
        </div>

        {
          productType === ProductType.Presale &&
          <div>
            <PageSectionTitle>Token pricing</PageSectionTitle>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <InputLabel>Currency</InputLabel>
                <SearchMultiselect
                  disabled={props.update ?? disabled()}
                  placeholder="Select currency"
                  itemArray={Currencies}
                  selectedItems={Currencies.filter(c => c.ticker === props.form.currency) ?? []}
                  setSelectedItems={c => props.onFormUpdate({ currency: c[0]?.ticker })}
                  keyExtractor={c => c.ticker}
                  labelExtractor={c => <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>{c.icon}{c.ticker}</div>}
                  isSingleSelect
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flexGrow: 1 }}>
                <InputLabel>Token price</InputLabel>
                <NumberInput
                  disabled={disabled() || props.update}
                  value={props.form.price}
                  onUserInput={(e) => props.onFormUpdate({ price: e as any })}
                  fontSize='14'
                  align='right'
                  required
                />
              </div>

            </div>
          </div>
        }

        {
          productType !== ProductType.Presale &&
          <div>
            <PageSectionTitle>Product pricing</PageSectionTitle>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>

              <div style={{ display: 'flex', gap: 10 }}>
                <PricingType
                  disabled={props.update ?? disabled()}
                  isOnetime
                  isSelected={productType === ProductType.OneTime}
                  onClick={() => setProductType(ProductType.OneTime)} />
                <PricingType
                  disabled={props.update ?? disabled()}
                  isSelected={productType === ProductType.Subscription}
                  onClick={() => setProductType(ProductType.Subscription)} />

              </div>

              {
                productType === ProductType.Subscription &&
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <InputLabel>Subscription management</InputLabel>
                  <SecondaryButton
                    type='button'
                    onClick={() =>
                      props.onFormUpdate({ sendSubscriptionEmails: !props.form.sendSubscriptionEmails })
                    }
                    style={{
                      border: 0,
                      display: 'flex',
                      gap: 10,
                      alignItems: 'center',
                      padding: 0,
                      color: 'black'
                    }}>
                    <Checkbox
                      size={16}
                      checked={!!props.form.sendSubscriptionEmails} />
                    <InputLabel style={{ opacity: 1 }}>Send customer email payment reminders</InputLabel>
                  </SecondaryButton>
                </div>
              }
              {
                productType === ProductType.Subscription &&
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <PaymentPeriodSelector
                    label
                    period={period}
                    disabled={props.update}
                    setPeriod={p => props.onFormUpdate({
                      chargingIntervalSeconds: ProductPeriodSecs[p as string]
                    })}
                  />
                </div>
              }
              <div style={{ display: 'flex', gap: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <InputLabel>Currency</InputLabel>
                  <SearchMultiselect
                    disabled={props.update ?? disabled()}
                    placeholder="Select currency"
                    itemArray={Currencies}
                    selectedItems={Currencies.filter(c => c.ticker === props.form.currency) ?? []}
                    setSelectedItems={c => props.onFormUpdate({ currency: c[0]?.ticker })}
                    keyExtractor={c => c.ticker}
                    labelExtractor={c => <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>{c.icon}{c.ticker}</div>}
                    isSingleSelect
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flexGrow: 1 }}>
                  {
                    productType === ProductType.Subscription &&
                      <InputLabel>Price per {ProductPeriodDisplay[period].toLowerCase()}</InputLabel>
                  }
                  {productType !== ProductType.Subscription && <InputLabel>Price</InputLabel>}
                  <NumberInput
                    disabled={disabled() || props.update}
                    value={props.form.price}
                    onUserInput={(e) => props.onFormUpdate({ price: e as any })}
                    fontSize='14'
                    align='right'
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        }

      </div>

      {
        <div style={{
          display: 'flex',
          position: 'sticky',
          bottom: 0,
          justifyContent: 'center',
          gap: 20,
          background: 'white',
          padding: 20
        }}>
          {
            props.update && <SecondaryButton
              disabled={disabled()}
              onClick={props.onCancel}
              style={{ width: '100%' }}>
                  Cancel
            </SecondaryButton>
          }
          <PrimaryButton
            isLoading={ props.isLoading || props.isSubmitting }
            disabled={ disabled() || (props.disabled ?? false) }
            onClick={onFormSubmit}
            style={{ width: '100%' }}>
            { props.update ? 'Update' : 'Create' }
          </PrimaryButton>
        </div>
      }
    </form>
  </div>
}

export default ProductForm
