import React, { ReactElement, useEffect, useState } from 'react'
import PageHeader from '../../components/PageHeader'
import Table from '../../components/Table'
import Radom, { WithdrawalRequest } from '../../state/Radom'
import { getMethod, prodNetworks, testNetworks } from '../../util/Managed'
import TimeText from '../../components/TimeText'
import { StatusBadge } from '../Invoices'
import { TokenImage, TokenTitle } from '../Funds'
import { RADOM_COLORS } from '../../util/Constants'
import LoadingBar from '../../components/LoadingBar'
import Treasury from '../../state/Treasury'
import { observer } from 'mobx-react'
import { IconButton, SecondaryButton } from '../../components/Button'
import Modal from '../../components/Modal'
import Close from '../../icons/Close'
import { ExternalLinkText } from '../Events'
import { formatExplorerUrl } from '../../util/Chains'
import { formatCurrency, shortAddress, useTestMode } from '../../util/Util'
import LinkExternal from '../../components/LinkExternal'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import Dots from '../../icons/Dots'
import ExportWithdrawalsForm from '../../components/ExportWithdrawalsForm'
import { Container } from '../../components/Animations'
import Pagination from '../../components/Pagination'
import User from '../../state/User'

const ManagedWithdrawals = observer((): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)

  const pageSize = 20
  const [curPage, setCurPage] = useState(0)
  const [withdrawals, setWithdrawals] = useState([] as WithdrawalRequest[])
  const [totalWithdrawalsSize, setTotalWithdrawalsSize] = useState(0)

  const [receiptOpen, setReceiptOpen] = useState(false)
  const [receiptWithdrawal, setReceiptWithdrawal] = useState<WithdrawalRequest>()

  const [exportWithdrawalsModalOpen, setExportWithdrawalsModalOpen] = useState(false)

  const [testMode] = useTestMode()

  const transactionHash = receiptWithdrawal?.receipt?.EVMReceipt?.transactionHash ||
    receiptWithdrawal?.receipt?.DOTReceipt?.transactionHash ||
    receiptWithdrawal?.receipt?.BTCReceipt?.transactionId ||
    receiptWithdrawal?.receipt?.SOLReceipt?.signature

  const networkFeeAmount = receiptWithdrawal?.receipt?.EVMReceipt?.networkFeeAmount ||
    receiptWithdrawal?.receipt?.DOTReceipt?.networkFeeAmount ||
    receiptWithdrawal?.receipt?.BTCReceipt?.networkFeeAmount ||
    receiptWithdrawal?.receipt?.SOLReceipt?.networkFeeAmount

  const loadWithdrawals = async (): Promise<void> => {
    setIsLoading(true)
    Treasury.loadPriceQuotes()
    Radom.listWithdrawals(curPage * pageSize, pageSize, testMode ? testNetworks : prodNetworks)
      .then(res => {
        setIsLoading(false)
        setWithdrawals(res.data)
        setTotalWithdrawalsSize(res.total)
      })
      .catch(() => {})
  }

  useEffect(() => {
    loadWithdrawals()
  }, [testMode, curPage])

  return <Container>
    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', rowGap: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <PageHeader title='Withdrawals' subTitle='View your withdrawal requests' />
        <Dropdown
          noChevron
          selectedContent={<Dots width={15} />}
          dropdownContent={
            <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
              <DropdownItem onClick={() => setExportWithdrawalsModalOpen(true)}>Export withdrawals</DropdownItem>
            </div>
          }
        />
      </div>
      <Table
        borderless
        headers={['Asset', 'Status', 'Withdrawal amount', 'Requested', 'Receipt', 'Completed'].map(h => <span key={h}>{h}</span>)}
        items={
          withdrawals
            .map(w => {
              const method = getMethod(w.network, w.token)
              const conversion = Treasury.priceQuotes
                .find(p => p.from.toLowerCase() === method.ticker.toLowerCase())

              return [
                <div key="1">
                  <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                    <TokenImage imageUrl={method.logo} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <TokenTitle>{method.currencyName ?? method.name}</TokenTitle>
                      <span style={{ color: RADOM_COLORS.GRAY_DARKEST }}>{method.hostChain?.name || method.name}</span>
                    </div>
                  </div>
                </div>,
                <div key="4"><StatusBadge status={w.withdrawalStatus as any}>{w.withdrawalStatus}</StatusBadge></div>,
                <div key="2">
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <span>{w.withdrawalAmount}</span>
                    <span>
                      {
                        conversion
                          ? <span style={{ opacity: 0.5 }}>
                            ≈ {formatCurrency(Number(w.withdrawalAmount) * (conversion.value), User.organization?.defaultCurrency ?? 'USD')}
                          </span>
                          : <LoadingBar style={{ height: 10 }} />
                      }
                    </span>
                  </div>
                </div>,
                <div key="5"><TimeText date={new Date(w.requestedAt)} /></div>,
                <div key="3">{
                  w.receipt
                    ? <SecondaryButton onClick={() => {
                      if (w.receipt) {
                        setReceiptWithdrawal(w)
                        setReceiptOpen(true)
                      }
                    }}
                    style={{
                      border: 0,
                      padding: 0,
                      color: RADOM_COLORS.BLACK
                    }}>
                    View receipt
                    </SecondaryButton>
                    : '-'
                }</div>,
                <div key="6">
                  {w.completedAt ? <TimeText date={new Date(w.completedAt)} /> : '-' }
                </div>
              ]
            }
            )
        }
        isLoading={isLoading}
        emptyPlaceholderText='No withdrawal requests yet'
      />
      {
        !isLoading && <Pagination
          curPage={curPage}
          numPages={Math.ceil(totalWithdrawalsSize / pageSize)}
          onPageClick={page => setCurPage(page)}
        />
      }

      <Modal visible={receiptOpen} onClose={() => setReceiptOpen(false)}>
        <div style={{
          display: 'flex',

          justifyContent: 'space-between',
          borderBottom: '1px solid',
          borderColor: RADOM_COLORS.GRAY_DARK,
          padding: 30,
          marginBottom: 10
        }}>
          <div style={{
            display: 'flex',

            alignItems: 'center',
            columnGap: 10
          }}>
            <span style={{ fontSize: 22, fontWeight: 300 }}>Withdrawal receipt</span>
          </div>
          <IconButton style={{ padding: 5 }} onClick={() => setReceiptOpen(false)}>
            <Close style={{ width: 15, pointerEvents: 'none' }} />
          </IconButton>
        </div>
        <div style={{ fontSize: 14, padding: 30, display: 'flex', flexDirection: 'column', gap: 20 }}>
          {
            receiptWithdrawal?.receipt && <>
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: 30 }}>
                <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>Withdrawal request ID</span>
                <span>{receiptWithdrawal.id}</span>
              </div>
              {
                transactionHash &&
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: 30 }}>
                <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>Transaction hash</span>
                <ExternalLinkText
                  onClick={e => e.stopPropagation()}
                  target="_blank" rel="noreferrer"
                  href={formatExplorerUrl(
                    receiptWithdrawal.network,
                    'tx',
                    transactionHash ?? ''
                  )}>
                  {shortAddress(transactionHash ?? '', 18)}
                  <LinkExternal stroke={RADOM_COLORS.BLACK} width={13} />
                </ExternalLinkText>
              </div>
              }
              {
                networkFeeAmount &&
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: 30 }}>
                <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>Network fee amount</span>
                <span>{networkFeeAmount}</span>
              </div>
              }
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: 30 }}>
                <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>Requested at timestamp</span>
                <TimeText date={new Date(receiptWithdrawal.requestedAt)} />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: 30 }}>
                <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>Completed at timestamp</span>
                <TimeText date={new Date(receiptWithdrawal.completedAt || 0)} />
              </div>
            </>
          }
        </div>
      </Modal>

      {
        exportWithdrawalsModalOpen &&
      <Modal visible={exportWithdrawalsModalOpen} onClose={() => setExportWithdrawalsModalOpen(false)} title='Export withdrawals'>
        <ExportWithdrawalsForm />
      </Modal>
      }
    </div>
  </Container>
})

export default ManagedWithdrawals
