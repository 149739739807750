import React, { ReactElement } from 'react'

function S(props: React.SVGProps<SVGSVGElement>): ReactElement {
  return <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="0.5" width="48" height="48" rx="6.85714" fill="url(#paint0_linear_24_41)"/>
    <g clipPath="url(#clip0_24_41)">
      <path d="M14.75 29.75V27.6059C14.75 22.2172 19.0766 17.7688 24.4653 17.75C25.7486 17.7454 27.0202 17.9943 28.2071 18.4822C29.3941 18.9701 30.473 19.6876 31.382 20.5934C32.2911 21.4992 33.0124 22.5756 33.5045 23.7608C33.9967 24.946 34.25 26.2167 34.25 27.5V29.75C34.25 29.9489 34.171 30.1397 34.0303 30.2803C33.8897 30.421 33.6989 30.5 33.5 30.5H15.5C15.3011 30.5 15.1103 30.421 14.9697 30.2803C14.829 30.1397 14.75 29.9489 14.75 29.75Z" stroke="#3464E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24.5 17.75V20.75" stroke="#3464E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.25 30.5L28.25 22.25" stroke="#3464E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.25 26H34.1356" stroke="#3464E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.8803 26H17.75" stroke="#3464E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_24_41" x1="48.5" y1="48.5" x2="0.5" y2="0.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#C6D5FF"/>
        <stop offset="1" stopColor="#E7EDFF"/>
      </linearGradient>
      <clipPath id="clip0_24_41">
        <rect width="24" height="24" fill="white" transform="translate(12.5 12.5)"/>
      </clipPath>
    </defs>
  </svg>
}

export default S
