import React, { ReactElement } from 'react'
import { PaymentSession } from '../state/Radom'
import TimeText from '../components/TimeText'
import { RADOM_COLORS } from '../util/Constants'
import { StatusBadge } from '../views/CheckoutSessions'
import { Section, SubTitle, SectionContent, SectionItem, PaymentDetailsSection } from './PaymentDetails'
import { Currencies } from '../util/Currencies'
import RefundDetails from './RefundDetails'

interface PaymentSessionDetailsProps {
  paymentSession: PaymentSession
  netPayment: number
}

const PaymentSessionDetails = ({ paymentSession, netPayment }: PaymentSessionDetailsProps): ReactElement => {
  return <>
    <Section>
      <SubTitle style={{ fontSize: 18 }}>
        Payment session details
      </SubTitle>

      <SectionContent>
        <SectionItem>
          <SubTitle>ID</SubTitle>
          <div>
            {paymentSession.id}
          </div>
        </SectionItem>

        <SectionItem>
          <SubTitle>Status</SubTitle>
          <StatusBadge status={paymentSession.sessionStatus}>{paymentSession.sessionStatus}</StatusBadge>
        </SectionItem>

        <SectionItem>
          <SubTitle>Created at</SubTitle>
          <div>
            <TimeText textColor={RADOM_COLORS.BLACK} date={new Date(paymentSession.createdAt)} />
          </div>
        </SectionItem>

        <SectionItem>
          <SubTitle>Total</SubTitle>

          <div style={{ display: 'flex', gap: 3 }}>
            {Currencies.find(c => c.ticker.toLowerCase() === paymentSession.currency.toLowerCase())?.icon}
            <span key="total">
              {new Intl.NumberFormat('en-US', { maximumFractionDigits: 12 }).format(paymentSession.total as any)}
            </span>
          </div>

        </SectionItem>

      </SectionContent>

    </Section>

    {
      paymentSession.payment?.managed &&
      <PaymentDetailsSection payment={paymentSession.payment.managed} netPayment={netPayment} />
    }

    {
      paymentSession.refunds && paymentSession.refunds.length > 0 &&
      <RefundDetails refunds={paymentSession.refunds} />
    }
  </>
}

export default PaymentSessionDetails
