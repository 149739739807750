import React, { ReactElement } from 'react'

function Withdrawal(props: React.SVGProps<SVGSVGElement>): ReactElement {
  return <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="0.5" width="48" height="48" rx="6.85714" fill="url(#paint0_linear_23_151)"/>
    <path d="M24.5002 15.5001V29.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.7502 23.0001L24.5002 29.75L31.2502 23.0001" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.2502 32.75H32.7502" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <defs>
      <linearGradient id="paint0_linear_23_151" x1="48.5" y1="48.5" x2="0.5" y2="0.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2B47D8"/>
        <stop offset="1" stopColor="#618AF9"/>
      </linearGradient>
    </defs>
  </svg>
}

export default Withdrawal
