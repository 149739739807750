import React from 'react'

export default function Calendar(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0.88 24 24" stroke="#747894" {...props}>
    <g clipPath="url(#clip0_1_4398)">
      <path d="M19.5 6.125H4.5C3.67157 6.125 3 6.79657 3 7.625V18.125C3 18.9534 3.67157 19.625 4.5 19.625H19.5C20.3284 19.625 21 18.9534 21 18.125V7.625C21 6.79657 20.3284 6.125 19.5 6.125Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 12.125H9C9 12.9206 9.31607 13.6837 9.87868 14.2463C10.4413 14.8089 11.2044 15.125 12 15.125C12.7956 15.125 13.5587 14.8089 14.1213 14.2463C14.6839 13.6837 15 12.9206 15 12.125H21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 9.125H21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1_4398">
        <rect width="24" height="24" fill="white" transform="translate(0 0.875)"/>
      </clipPath>
    </defs>
  </svg>
}
