import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../../util/Constants'
import Switch from '../../components/Switch'
import { promiseToast } from '../../util/Util'
import Radom from '../../state/Radom'
import LoadingBar from '../../components/LoadingBar'
import { Container } from '../../components/Animations'

const NOTIFICATION_TYPES = [
  {
    name: 'Successful payments',
    id: 'PaymentSuccessful',
    description: 'Receive a notification when a payment completes successfully.'
  },
  {
    name: 'Incomplete payments',
    id: 'PaymentIncomplete',
    description: 'Receive a notification when an incomplete (i.e. partial amount of the total due) payment is made.'
  },
  {
    name: 'New subscriptions',
    id: 'Subscription',
    description: 'Receive a notification when a new subscription is created.'
  },
  {
    name: 'Subscription payment attempt failure',
    id: 'SubscriptionPaymentAttemptFailure',
    description: 'Receive a notification when an auto-pay enabled subscription payment fails.'
  },
  {
    name: 'Withdrawals',
    id: 'Withdrawal',
    description: 'Receive a notification when a withdrawal request completes.'
  }
]

const SubHeader = styled.div`
  font-size: 16px;
  color: ${RADOM_COLORS.BLACK};
  font-weight: 500;
`

export const SettingContainer = styled.div`
  display: flex;
  font-size: 14px;
  text-align: initial;
  background: none;
  outline: none;
  border-top: 1px solid ${RADOM_COLORS.GRAY_MED};
  padding: 20px 0;
  gap: 10px;
`

const Notifications = observer(() => {
  const [isLoading, setIsLoading] = useState(true)
  const [notificationSettings, setNotificationSettings] = useState({})

  useEffect(() => {
    setIsLoading(true)
    Radom.getNotificationSettings()
      .then(r => setNotificationSettings(r))
      .finally(() => setIsLoading(false))
  }, [])

  const changeSetting = (setting: string): void => {
    const newSettings = { ...notificationSettings, [setting]: !notificationSettings[setting] }
    setNotificationSettings(newSettings)
    promiseToast(Radom.updateNotificationSettings({ settings: newSettings }).catch(err => {
      setNotificationSettings({ ...notificationSettings, [setting]: !!notificationSettings[setting] })
      throw err
    }), 'Saving changes', 'Settings saved', 'Failed to update settings')
  }

  return <Container>
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        <SubHeader>Email notifications</SubHeader>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {
            NOTIFICATION_TYPES.map(n =>

              <SettingContainer key={n.name}>
                <div style={{ marginTop: 3 }}>
                  {
                    isLoading
                      ? <LoadingBar style={{ width: 35, borderRadius: 20 }} />
                      : <Switch sizeScale={0.85} checked={notificationSettings[n.id]} onClick={() => {
                        changeSetting(n.id)
                      }} />
                  }
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>

                  <span style={{ fontSize: 15 }}>{n.name}</span>
                  <span style={{ color: RADOM_COLORS.GRAY_DARKEST }}>
                    {n.description}
                  </span>
                </div>
              </SettingContainer>

            )
          }
        </div>
      </div>

    </div>
  </Container>
})

export default Notifications
