import React from 'react'

export default function Calendar(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0.25 24 24" stroke="#747894" {...props}>
    <g clipPath="url(#clip0_1_4549)">
      <path d="M20.25 4.75H3.75C3.33579 4.75 3 5.08579 3 5.5V19C3 19.4142 3.33579 19.75 3.75 19.75H20.25C20.6642 19.75 21 19.4142 21 19V5.5C21 5.08579 20.6642 4.75 20.25 4.75Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 9.25H21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1_4549">
        <rect width="24" height="24" fill="white" transform="translate(0 0.25)"/>
      </clipPath>
    </defs>
  </svg>
}
