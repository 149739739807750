import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import Spinner from '../components/Spinner'
import { AssociatedEntityType, ManagedPaymentEvent, parseManagedEvent } from '../state/Events'
import { Link, useParams } from 'react-router-dom'
import { RADOM_COLORS } from '../util/Constants'
import styled from 'styled-components'
import TimeText from '../components/TimeText'
import BreadCrumbs from '../components/Breadcrumbs'
import { Container } from '../components/Animations'
import Radom, { Customer, DonationLinkOrder, ICheckoutSession, Invoice, IPaymentLink, PaymentLinkOrder, PaymentSession } from '../state/Radom'
import Table from '../components/Table'
import LinkExternal from '../components/LinkExternal'
import { ExternalLinkText } from './Events'
import CheckoutSessionDetails from '../components/CheckoutSessionDetails'
import PaymentSessionDetails from '../components/PaymentSessionDetails'
import { PaymentDetailsSection } from '../components/PaymentDetails'
import Dropdown, { DropdownItem } from '../components/Dropdown'
import Dots from '../icons/Dots'
import { PrimaryButton } from '../components/Button'
import Modal from '../components/Modal'
import { errorToast } from '../util/Util'
import LoadingBar from '../components/LoadingBar'
import RefundForm from '../components/RefundForm'
import RefundDetails from '../components/RefundDetails'

const SubTitle = styled.div`
  font-size: 14px;
  color: ${RADOM_COLORS.GRAY_DARKEST};
`

const Payment = observer((): ReactElement => {
  const params = useParams()

  const [paymentEvent, setPaymentEvent] = useState<ManagedPaymentEvent>()
  const [isLoading, setIsLoading] = useState(true)

  const [paymentLinkOrder, setPaymentLinkOrder] = useState<PaymentLinkOrder>()
  const [paymentLink, setPaymentLink] = useState<IPaymentLink>()
  const [donationLinkOrder, setDonationLinkOrder] = useState<DonationLinkOrder>()
  const [checkoutSession, setCheckoutSession] = useState<ICheckoutSession>()
  const [paymentSession, setPaymentSession] = useState<PaymentSession>()
  const [isLoadingDetails, setIsLoadingDetails] = useState(false)
  const [invoice, setInvoice] = useState<Invoice>()

  const [isIncomplete, setIsIncomplete] = useState(false)
  const [isIncompleteModalOpen, setIsIncompleteModalOpen] = useState(false)
  const [isCompletingPayment, setIsCompletingPayment] = useState(false)

  const [refundModalOpen, setRefundModalOpen] = useState(false)

  const [customer, setCustomer] = useState<Customer>()

  useEffect(() => {
    if (!params.id) return
    Radom.getEvent(params.id).then(d => {
      const paymentEvent = parseManagedEvent(d) as ManagedPaymentEvent
      setPaymentEvent(paymentEvent)
      setIsIncomplete(paymentEvent.name === 'IncompletePaymentEvent')
    }).finally(() => setIsLoading(false))
  }, [params.id])

  useEffect(() => {
    if (!paymentEvent || !Radom.isLoggedIn) {
      return
    }

    setPaymentLinkOrder(undefined)
    setCheckoutSession(undefined)
    setPaymentSession(undefined)
    setDonationLinkOrder(undefined)

    if (paymentEvent.associatedEntity.entityType === AssociatedEntityType.PaymentLink) {
      setIsLoadingDetails(true)
      Radom.getPaymentLinkOrder(paymentEvent.associatedEntity.id)
        .then(paymentLinkOrder => {
          setPaymentLinkOrder(paymentLinkOrder)

          Radom.getPaymentLink(paymentLinkOrder.paymentLinkId)
            .then(paymentLink => {
              setPaymentLink(paymentLink)
            })
            .catch(e => console.error('Failed to look up payment link', e))
        })
        .catch(e => console.error('Failed to look up paymentEvent link order', e))
        .finally(() => setIsLoadingDetails(false))
    }

    if (paymentEvent.associatedEntity.entityType === AssociatedEntityType.PaymentSession) {
      setIsLoadingDetails(true)
      Radom.getPaymentSession(paymentEvent.associatedEntity.id)
        .then(s => {
          setPaymentSession(s)
        })
        .catch(e => console.error('Failed to look up paymentEvent session', e))
        .finally(() => setIsLoadingDetails(false))
    }

    if (paymentEvent.associatedEntity.entityType === AssociatedEntityType.CheckoutSession) {
      setIsLoadingDetails(true)
      Radom.getCheckoutSession(paymentEvent.associatedEntity.id)
        .then(s => {
          setCheckoutSession(s)
        })
        .catch(e => console.error('Failed to look up checkout session', e))
        .finally(() => setIsLoadingDetails(false))
    }

    if (paymentEvent.associatedEntity.entityType === AssociatedEntityType.Invoice) {
      setIsLoadingDetails(true)
      Radom.getInvoice(paymentEvent.associatedEntity.id)
        .then(s => {
          setInvoice(s)
        })
        .catch(e => console.error('Failed to look up invoice', e))
        .finally(() => setIsLoadingDetails(false))
    }

    if (paymentEvent.associatedEntity.entityType === AssociatedEntityType.DonationLink) {
      setIsLoadingDetails(true)
      Radom.getDonationLinkOrder(paymentEvent.associatedEntity.id)
        .then(async donationLinkOrder => {
          setDonationLinkOrder(donationLinkOrder)
          const paymentId = donationLinkOrder.payment.managed?.id
          if (paymentId) {
            try {
              const payment = await Radom.getPayment(paymentId)
              if (payment.customerId) {
                const customer = await Radom.getCustomer(payment.customerId)
                setCustomer(customer)
              }
            } catch (err) {
              console.error('Failed to look up donation link payment', err)
            }
          }
        })
        .catch(e => console.error('Failed to look up donation link order', e))
        .finally(() => setIsLoadingDetails(false))
    }
  }, [paymentEvent])

  if (isLoading) {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 140, gap: 20 }}>
      <Spinner />
    </div>
  }

  if (paymentEvent) {
    return <Container style={{ flexDirection: 'column', fontSize: 14, gap: 30 }}>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <BreadCrumbs items={[
          { item: 'Payments', to: '/payments' },
          { item: paymentEvent.id, to: '.' }
        ]} />

        {
          isIncomplete &&
          <Dropdown
            noChevron
            selectedContent={<Dots width={15} />}
            dropdownContent={
              <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                <DropdownItem onClick={() => setIsIncompleteModalOpen(true)}>Complete payment</DropdownItem>
              </div>
            }
          />
        }

        {
          !isIncomplete &&
          (paymentSession || donationLinkOrder) &&
          <Dropdown
            noChevron
            selectedContent={<Dots width={15} />}
            dropdownContent={
              <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                <DropdownItem onClick={() => setRefundModalOpen(true)}>Refund payment</DropdownItem>
              </div>
            }
          />
        }
      </div>

      {
        refundModalOpen &&
        <Modal visible={refundModalOpen} onClose={() => setRefundModalOpen(false)} title='Refund payment'>
          <div style={{ width: 500, padding: '0 30px 30px 30px' }}>
            <RefundForm
              paymentSession={paymentSession}
              donationLinkOrder={donationLinkOrder}
              onRefund={() => setRefundModalOpen(false)} />
          </div>
        </Modal>
      }

      <div style={{
        padding: 30,
        borderRadius: 10,
        border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
        display: 'flex',
        flexDirection: 'column',
        gap: 20
      }}>
        <div style={{
          fontSize: '18px',
          paddingBottom: 5
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            fontSize: '32px'
          }}>

            <img src={paymentEvent.paymentMethod.logo} style={{ width: 32, height: 'auto', maxHeight: 32 }} />
            <span>{paymentEvent.amount}</span>
            <span style={{
              background: RADOM_COLORS.GRAY_MED,
              color: RADOM_COLORS.BLACK,
              borderRadius: 10,
              fontSize: 14,
              padding: '5px 10px',
              fontWeight: 400

            }}>{paymentEvent.paymentMethod.ticker}</span>

          </div>
        </div>

        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          flexWrap: 'wrap',
          gap: 30
        }}>
          <div>
            <SubTitle>Type</SubTitle>
            <div>{paymentEvent.label}</div>
          </div>
          <div>
            <SubTitle>Associated entity</SubTitle>
            <div>
              {
                paymentEvent.associatedEntity &&
                paymentEvent.associatedEntity.entityType !== AssociatedEntityType.PaymentSession &&
                paymentEvent.associatedEntity.entityType !== AssociatedEntityType.PaymentLink &&
                <ExternalLinkText
                  onClick={e => e.stopPropagation()}
                  href={paymentEvent.associatedEntity.link}
                  target="_blank" rel="noreferrer">
                  <span>{AssociatedEntityType[paymentEvent.associatedEntity.entityType]}</span>
                  <span>{paymentEvent.associatedEntity.id}</span>
                  <LinkExternal stroke={RADOM_COLORS.BLACK} width={13} />
                </ExternalLinkText>
              }
              {
                paymentEvent.associatedEntity &&
                paymentEvent.associatedEntity.entityType === AssociatedEntityType.PaymentLink &&
                <>
                  {
                    paymentLink
                      ? <ExternalLinkText
                        onClick={e => e.stopPropagation()}
                        href={`/${paymentLink.isPresale ? 'presales' : 'payment_links'}/${paymentLink.id}`}
                        target="_blank" rel="noreferrer">
                        <span>{paymentLink.isPresale ? 'PresaleLink' : 'PaymentLink'}</span>
                        <span>{paymentEvent.associatedEntity.id}</span>
                        <LinkExternal stroke={RADOM_COLORS.BLACK} width={13} />
                      </ExternalLinkText>
                      : '-'
                  }
                </>
              }
              {
                paymentEvent.associatedEntity &&
                paymentEvent.associatedEntity.entityType === AssociatedEntityType.PaymentSession &&
                <div style={{ display: 'flex', gap: 3 }}>
                  <span>{AssociatedEntityType[paymentEvent.associatedEntity.entityType]}</span>
                  <span>{paymentEvent.associatedEntity.id}</span>
                </div>
              }
            </div>
          </div>
          <div>
            <SubTitle>Transaction date</SubTitle>
            <div>
              <TimeText textColor={RADOM_COLORS.BLACK} date={paymentEvent.date} />
            </div>
          </div>

          {
            customer &&
            <div>
              <SubTitle>Customer</SubTitle>
              <Link to={`/customer/${customer?.id}`} style={{ fontSize: 14, color: RADOM_COLORS.BLUE_MED }}>
                <span>{customer.name || customer.email}</span>
              </Link>
            </div>
          }
        </div>
      </div>

      {
        paymentLinkOrder && paymentLinkOrder.orderData.length > 0 &&
        <div style={{
          padding: 30,
          borderRadius: 10,
          border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
          display: 'flex',
          flexDirection: 'column',
          gap: 30
        }}>
          <SubTitle style={{
            fontSize: 18,
            paddingBottom: 5
          }}>Order details</SubTitle>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>

            <div style={{ fontSize: 14 }}>
              <SubTitle style={{ fontSize: 14, paddingBottom: 10 }}>Customer input</SubTitle>
              <Table
                headers={[
                  <span key="productType">Input type</span>,
                  <span key="quantity">Value</span>
                ]}
                items={
                  paymentLinkOrder.orderData.map(p => [
                    <span key="type">{p.key}</span>,
                    <span key="quantity">{p.value}</span>
                  ])
                }
                isLoading={false}
              />
            </div>

          </div>
        </div>
      }

      {
        donationLinkOrder && donationLinkOrder.orderData.length > 0 &&
        <div style={{
          padding: 30,
          borderRadius: 10,
          border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
          display: 'flex',
          flexDirection: 'column',
          gap: 30
        }}>
          <SubTitle style={{
            fontSize: 18,
            paddingBottom: 5
          }}>Donation details</SubTitle>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
            <div style={{ fontSize: 14 }}>
              <SubTitle style={{ fontSize: 14, paddingBottom: 10 }}>Customer input</SubTitle>
              <Table
                headers={[
                  <span key="productType">Input type</span>,
                  <span key="quantity">Value</span>
                ]}
                items={
                  donationLinkOrder.orderData.map(p => {
                    const valueElement = p.value.toString()

                    return [
                      <span key={`type-${p.key}`}>{p.key}</span>,
                      <span key={`quantity-${p.key}`}>{valueElement}</span>
                    ]
                  })
                }
                isLoading={false}
              />
            </div>
          </div>
        </div>
      }

      {
        isLoadingDetails &&
        <div style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
          <LoadingBar style={{ width: '100%', height: 200, borderRadius: 15 }} />
          <LoadingBar style={{ width: '100%', height: 200, borderRadius: 15 }} />
        </div>
      }

      {
        checkoutSession &&
        <CheckoutSessionDetails checkoutSession={checkoutSession} netPayment={paymentEvent.amount} />
      }

      {
        paymentSession &&
        <PaymentSessionDetails paymentSession={paymentSession} netPayment={paymentEvent.amount} />
      }

      {
        invoice?.payment?.managed &&
        <PaymentDetailsSection payment={invoice.payment.managed} netPayment={paymentEvent.amount} />
      }

      {
        paymentLinkOrder?.payment?.managed &&
        <PaymentDetailsSection payment={paymentLinkOrder.payment.managed} netPayment={paymentEvent.amount} />
      }

      {
        donationLinkOrder?.payment?.managed &&
        <PaymentDetailsSection payment={donationLinkOrder.payment.managed} netPayment={paymentEvent.amount} />
      }

      {
        paymentLinkOrder?.payment?.managedTokenDelegatePayment &&
        <PaymentDetailsSection
          payment={paymentLinkOrder.payment.managedTokenDelegatePayment}
          netPayment={paymentEvent.amount} />
      }

      {
        donationLinkOrder && donationLinkOrder.refunds.length > 0 &&
        <RefundDetails refunds={donationLinkOrder.refunds} />
      }

      {
        isIncompleteModalOpen &&
        <Modal
          title='Complete payment'
          visible={isIncompleteModalOpen}
          onClose={() => setIsIncompleteModalOpen(false)}
          innerStyles={{ width: '400px', minHeight: 'auto' }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
            <div style={{ padding: '20px 30px' }}>
              <span style={{ fontSize: 14, color: RADOM_COLORS.GRAY_DARKEST, whiteSpace: 'pre-wrap' }}>
                Are you sure you would like to mark this payment as complete?
                This will trigger any post-payment actions and is irreversible.
              </span>
            </div>
            <div style={{ padding: 30 }}>
              <PrimaryButton
                isLoading={isCompletingPayment}
                style={{ width: '100%' }}
                onClick={async () => {
                  setIsCompletingPayment(true)
                  try {
                    await Radom.completePayment({ eventId: params.id })
                    window.location.reload()
                  } catch (err) {
                    errorToast(err.reason || err.message || err.toString())
                  }
                  setIsCompletingPayment(false)
                }}>
                Complete payment
              </PrimaryButton>
            </div>
          </div>
        </Modal>
      }
    </Container>
  }

  return <div>
    <span>Order not found</span>
  </div>
})

export default Payment
