import React, { ReactElement, useEffect, useMemo, useReducer, useState } from 'react'
import { PAY_BASE_URL, RADOM_COLORS } from '../../util/Constants'
import Radom, { IPaymentLink } from '../../state/Radom'
import { useNavigate } from 'react-router-dom'
import PaymentLinkForm from '../../components/PaymentLinkForm'
import Preview from '../../components/Preview'
import { errorToast, successToast } from '../../util/Util'
import NavbarController from '../../state/NavbarController'

export const emptyPaymentLink: IPaymentLink = {
  id: '',
  url: '',
  products: [],
  gateway: {
    managed: {
      methods: []
    }
  },
  customizations: {
    leftPanelColor: RADOM_COLORS.GRAY_MED,
    primaryButtonColor: RADOM_COLORS.BLACK,
    slantedEdge: false,
    allowDiscountCodes: false
  },
  sendEmailReceipt: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  cancelUrl: '',
  successUrl: '',
  inputFields: [],
  chargeCustomerNetworkFee: true
}

export const CreatePaymentLink = (): ReactElement => {
  const navigate = useNavigate()

  const [paymentLink, setPaymentLink] = useReducer(
    (p: IPaymentLink, n: Partial<IPaymentLink>) => {
      return { ...p, ...n }
    }, emptyPaymentLink)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const previewData = useMemo(() => {
    const sellerName = Radom.sellerProfile?.name

    return Buffer.from(JSON.stringify({
      ...paymentLink,
      sellerName,
      sellerLogoUrl: Radom.sellerProfile?.logoUrl,
      inputFields: paymentLink.inputFields.slice()
    }), 'utf8').toString('base64')
  }, [paymentLink, paymentLink.inputFields])
  const [previewWidth, setPreviewWidth] = useState('90%')

  const handleCreatePaymentLink = async (): Promise<void> => {
    try {
      setIsSubmitting(true)
      await Radom.createPaymentLink({
        products: paymentLink.products.map(p => p.id),
        gateway: paymentLink.gateway,
        customizations: paymentLink.customizations,
        cancelUrl: paymentLink.cancelUrl,
        successUrl: paymentLink.successUrl,
        inputFields: paymentLink.inputFields,
        sendEmailReceipt: paymentLink.sendEmailReceipt,
        chargeCustomerNetworkFee: paymentLink.chargeCustomerNetworkFee
      })
      setIsSubmitting(false)

      successToast('Successfully created payment link')
      navigate('/payment_links')
    } catch (err) {
      setIsSubmitting(false)
      console.error(err)
      errorToast(err.response
        ? err.response.data.error || err.response.data
        : err.message)
    }
  }

  useEffect(() => {
    NavbarController.setFullscreen(true)
    return () => NavbarController.setFullscreen(false)
  }, [])

  return (
    <div style={{ display: 'flex', height: '100vh', position: 'relative' }}>
      <PaymentLinkForm
        paymentLink={paymentLink}
        onFormSubmit={handleCreatePaymentLink}
        onFormChange={setPaymentLink}
        isSubmitting={isSubmitting}
      />
      <Preview
        iframeLink={`${PAY_BASE_URL}/preview/pay#${previewData}`}
        previewWidth={previewWidth}
        onWidthChange={setPreviewWidth}
      />
    </div>
  )
}
