import React from 'react'

export default function Calendar(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0.13 24 24" stroke="#747894" {...props}>
    <g clipPath="url(#clip0_1_4404)">
      <path d="M7.5 18.875C8.94975 18.875 10.125 17.6997 10.125 16.25C10.125 14.8003 8.94975 13.625 7.5 13.625C6.05025 13.625 4.875 14.8003 4.875 16.25C4.875 17.6997 6.05025 18.875 7.5 18.875Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.5 8.375C8.94975 8.375 10.125 7.19975 10.125 5.75C10.125 4.30025 8.94975 3.125 7.5 3.125C6.05025 3.125 4.875 4.30025 4.875 5.75C4.875 7.19975 6.05025 8.375 7.5 8.375Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.5 18.875C17.9497 18.875 19.125 17.6997 19.125 16.25C19.125 14.8003 17.9497 13.625 16.5 13.625C15.0503 13.625 13.875 14.8003 13.875 16.25C13.875 17.6997 15.0503 18.875 16.5 18.875Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.5 8.375C17.9497 8.375 19.125 7.19975 19.125 5.75C19.125 4.30025 17.9497 3.125 16.5 3.125C15.0503 3.125 13.875 4.30025 13.875 5.75C13.875 7.19975 15.0503 8.375 16.5 8.375Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 21.125C3.52395 20.4264 4.20336 19.8594 4.98442 19.4688C5.76549 19.0783 6.62675 18.875 7.5 18.875C8.37325 18.875 9.23451 19.0783 10.0156 19.4688C10.7966 19.8594 11.476 20.4264 12 21.125C12.524 20.4264 13.2034 19.8594 13.9844 19.4688C14.7655 19.0783 15.6267 18.875 16.5 18.875C17.3733 18.875 18.2345 19.0783 19.0156 19.4688C19.7966 19.8594 20.476 20.4264 21 21.125" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
      <path d="M3 10.625C3.52395 9.9264 4.20336 9.35938 4.98442 8.96885C5.76549 8.57832 6.62675 8.375 7.5 8.375C8.37325 8.375 9.23451 8.57832 10.0156 8.96885C10.7966 9.35938 11.476 9.9264 12 10.625C12.524 9.9264 13.2034 9.35938 13.9844 8.96885C14.7655 8.57832 15.6267 8.375 16.5 8.375C17.3733 8.375 18.2345 8.57832 19.0156 8.96885C19.7966 9.35938 20.476 9.9264 21 10.625" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1_4404">
        <rect width="24" height="24" fill="white" transform="translate(0 0.125)"/>
      </clipPath>
    </defs>
  </svg>
}
