import React, { ReactElement } from 'react'
import { RADOM_COLORS } from '../util/Constants'
import styled from 'styled-components'

interface IProps {
  disabled?: boolean
  checked: boolean
  onClick?: () => void
  sizeScale?: number
}

const SwitchWrapper = styled.div<{ isChecked: boolean, disabled?: boolean, sizeScale: number }>`
  background-color: ${({ isChecked }) => isChecked ? RADOM_COLORS.NICE_GREEN : RADOM_COLORS.GRAY_DARK};
  border-radius: 50px;
  width: ${({ sizeScale }) => 42 * sizeScale}px;
  height: ${({ sizeScale }) => 24 * sizeScale}px;
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.2s ease all;
  cursor: pointer;
  
  ${({ disabled }) => disabled && `
    cursor: default;
    opacity: 0.5;
  `}
  
  &:hover {
    background-color: ${({ isChecked }) => isChecked
    ? RADOM_COLORS.NICE_GREEN
    : RADOM_COLORS.GRAY_DARKER};
  }

  &:active {
    opacity: 0.75;
  }
`
const SwitchInput = styled.input<{ isChecked: boolean, disabled?: boolean, sizeScale: number }>`
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  appearance: none;
  width:  ${({ sizeScale }) => 18 * sizeScale}px;
  height: ${({ sizeScale }) => 18 * sizeScale}px;
  border-radius: 100%;
  margin: 0px;
  padding: 0px;
  position: absolute;
  left: ${({ checked, sizeScale }) => checked ? 21 * sizeScale : 3 * sizeScale}px;
  background-color: #FFF;
  transition: 0.2s ease all;
`

const Switch = ({ checked, disabled, sizeScale = 0.85, onClick }: IProps): ReactElement => {
  return <SwitchWrapper
    sizeScale={sizeScale}
    isChecked={checked}
    disabled={disabled}
    onClick={_ => { if (!disabled) onClick?.() }}>
    <SwitchInput
      sizeScale={sizeScale}
      disabled={disabled}
      onChange={() => {}}
      type="checkbox"
      checked={checked}
      isChecked={checked} />
  </SwitchWrapper>
}

export default Switch
