import React from 'react'

const Cog = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
  return <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#33324F" {...props}>
    <g clipPath="url(#clip0_299_4863)">
      <path d="M16 18.5V4.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M27 18.5V26.5H5V18.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11 9.5L16 4.5L21 9.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_299_4863">
        <rect width="32" height="32" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>
}

export default Cog
