import React, { ReactElement } from 'react'

function S(props: React.SVGProps<SVGSVGElement>): ReactElement {
  return <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="0.5" width="48" height="48" rx="6.85714" fill="url(#paint0_linear_23_310)"/>
    <g clipPath="url(#clip0_23_310)">
      <path d="M32 16.25H17C16.5858 16.25 16.25 16.5858 16.25 17V32C16.25 32.4142 16.5858 32.75 17 32.75H32C32.4142 32.75 32.75 32.4142 32.75 32V17C32.75 16.5858 32.4142 16.25 32 16.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M27.5 21.5L21.5 27.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.5 21.5L27.5 27.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_23_310" x1="48.5" y1="48.5" x2="0.5" y2="0.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF6648"/>
        <stop offset="1" stopColor="#FFAD9C"/>
      </linearGradient>
      <clipPath id="clip0_23_310">
        <rect width="24" height="24" fill="white" transform="translate(12.5 12.5)"/>
      </clipPath>
    </defs>
  </svg>
}

export default S
