import React, { ReactElement, useEffect, useState } from 'react'
import Modal from './Modal'
import { useSearchParams } from 'react-router-dom'
import Radom, { Invite } from '../state/Radom'
import { RADOM_COLORS } from '../util/Constants'
import { PrimaryButton, SecondaryButton } from './Button'
import { errorToast } from '../util/Util'

function InviteAcceptModal(): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams()

  const inviteId = searchParams.get('invite')

  const [visible, setVisible] = useState(!!inviteId)

  const [invite, setInvite] = useState<Invite>()
  const [isAcceptingInvite, setIsAcceptingInvite] = useState(false)

  useEffect(() => {
    if (!inviteId) return
    Radom.getInvite(inviteId)
      .then(invite => setInvite(invite))
      .catch(() => {

      })
  }, [inviteId])

  const acceptInvite = async (): Promise<void> => {
    if (!inviteId || !invite) return
    setIsAcceptingInvite(true)
    Radom.acceptInvite(inviteId)
      .then(async () => await Radom.updateUser({ defaultOrganizationId: invite.organizationId }))
      .then(() => {
        window.location = '/' as any
      })
      .catch(err => {
        errorToast(err.response?.data?.error || err.message)
      }).finally(() => {
        setIsAcceptingInvite(false)
      })
  }

  if (!inviteId) {
    return <></>
  }

  return <Modal
    title='Accept team invite'
    visible={visible}
    onClose={() => {
      setVisible(false)
      setSearchParams({})
    }}>

    <div style={{
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: '0 30px',
      gap: 20,
      fontSize: 14,
      maxWidth: 500
    }}>
      <span>
        You have been invited to join the {invite?.organizationId} team on Radom.
        To join the team, accept the invite below.
      </span>
      <span>
        Joining the team will give you access to the team’s dashboard.
      </span>
    </div>

    <div style={{
      width: '100%',
      padding: 30,
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 10
    }}>
      <SecondaryButton
        style={{ border: 0, color: RADOM_COLORS.BLACK }}
        disabled={isAcceptingInvite}
        onClick={() => {
          window.location = '/' as any
        }}>
          Decline
      </SecondaryButton>
      <PrimaryButton
        isLoading={isAcceptingInvite}
        onClick={async () => await acceptInvite()}>
          Join team
      </PrimaryButton>
    </div>

  </Modal>
}

export default InviteAcceptModal
