import React, { ReactElement } from 'react'
import { Refund } from '../state/Radom'
import LinkExternal from '../components/LinkExternal'
import TimeText from '../components/TimeText'
import { RADOM_COLORS } from '../util/Constants'
import { shortAddress } from '../util/Util'
import { formatExplorerUrl } from '../util/Chains'
import { StatusBadge } from '../views/CheckoutSessions'
import { ExternalLinkText } from '../views/Events'
import {
  Section, SubTitle, SectionContent, SectionItem,
  PaymentAssetIcon
} from './PaymentDetails'

interface CheckoutSessionDetailsProps {
  refunds: Refund[]
}

const RefundDetails = ({ refunds }: CheckoutSessionDetailsProps): ReactElement => {
  return <Section>
    <SubTitle style={{ fontSize: 18 }}>
          Refunds
    </SubTitle>

    {
      refunds.map((r, i) =>
        <Section key={i}>
          <SectionContent>
            <SectionItem>
              <SubTitle>Refund amount</SubTitle>
              <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <PaymentAssetIcon payment={{ network: r.network, token: r.token }} />
                <span>{r.amount}</span>
              </div>
            </SectionItem>

            <SectionItem>
              <SubTitle>Status</SubTitle>
              <StatusBadge status={r.status as any}>{r.status}</StatusBadge>
            </SectionItem>

            <SectionItem>
              <SubTitle>Refund issued at</SubTitle>
              <TimeText textColor={RADOM_COLORS.BLACK} date={new Date(r.createdAt)} />
            </SectionItem>

            <SectionItem>
              <SubTitle>Refund recipient address</SubTitle>
              <div>
                {r.recipientAddress}
              </div>
            </SectionItem>

            {
              r.transactionHash &&
                  <SectionItem>
                    <SubTitle>Transaction hash</SubTitle>
                    <ExternalLinkText
                      onClick={e => e.stopPropagation()}
                      target="_blank" rel="noreferrer"
                      href={formatExplorerUrl(
                        r.network,
                        'tx',
                        r.transactionHash ?? ''
                      )}>
                      {shortAddress(r.transactionHash ?? '', 18)}
                      <LinkExternal stroke={RADOM_COLORS.BLACK} width={13} />
                    </ExternalLinkText>
                  </SectionItem>
            }
          </SectionContent>
        </Section>
      )
    }
  </Section>
}

export default RefundDetails
