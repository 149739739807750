import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'

export const IconButtonInner = styled.div`
  display: flex;
  gap: 8px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
`

export const BaseInputStyles = `
  border: 1px solid transparent;
  background-color: ${RADOM_COLORS.INPUT_DEFAULT};
  border-radius: 10px;
  padding: 8px 14px;
  width: 100%;
  outline: none;
  box-shadow: 0 1px 1px rgb(226 228 239 / 50%);
  transition: 0.2s ease all;

  &:hover {
    background-color: ${RADOM_COLORS.GRAY9};
  }



  :focus {
    border: 1px solid ${RADOM_COLORS.BLUE5};
    background-color: transparent;
    // box-shadow: 0 0 5px ${RADOM_COLORS.GRAY_DARK};
  }

  ::placeholder {
    color: ${RADOM_COLORS.GRAY_DARKER};
  }
`

export const TextArea = styled.textarea<{ disabled?: boolean }>`
  ${BaseInputStyles}
  border-radius: 10px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  resize: vertical;
  min-height: 50px;
`

export const OptionalLabel = styled.div`
  max-width: min-content;
  background-color: ${RADOM_COLORS.GRAY_MED};
  border-radius: 10px;
  padding: 3px 8px;
  color: ${RADOM_COLORS.GRAY_DARKEST};
  font-size: 10px;
  user-select: none;
`

export const TextAreaWithLabel = (
  { label, value, onChange, placeholder, onKeyDown, required, disabled }: React.HTMLProps<HTMLTextAreaElement>
): ReactElement => {
  return <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', gap: 5 }}>
      <InputLabel>
        <span>{ label }</span>
      </InputLabel>
      {!required && <OptionalLabel>Optional</OptionalLabel>}
    </div>
    <TextArea
      style={{ maxHeight: 100 }}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      onKeyDown={onKeyDown}
      required={required}
      disabled={disabled}
    />
  </div>
}

export const TextInputWithLabel = (
  {
    label,
    value,
    onChange,
    placeholder,
    pattern,
    onKeyDown,
    required,
    disabled,
    ref,
    as,
    ...props
  }: React.HTMLProps<HTMLInputElement>
): ReactElement => {
  return <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', gap: 5 }}>
      <InputLabel>
        <span>{ label }</span>
      </InputLabel>
      {!required && <OptionalLabel>Optional</OptionalLabel>}
    </div>
    <TextInput
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      pattern={pattern}
      onKeyDown={onKeyDown}
      disabled={disabled}
      required={required}
      {...props}
    />
  </div>
}

export const InputLabel = styled.span`
  font-weight: 400;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  opacity: 0.5;
  font-size: 14px;
`

export const TextInput = styled.input<{ disabled?: boolean }>`
  ${BaseInputStyles}
`

export const InputWithCheckbox = styled.div`
  padding: 6px;
  display: flex;
  gap: 2px;
  border: 1px solid ${RADOM_COLORS.GRAY_DARK};
  box-shadow: 0 1px 1px ${RADOM_COLORS.GRAY_LIGHTEST};
  border-radius: 4px;
  
  :focus-within {
    outline: 1px solid ${RADOM_COLORS.GRAY_MED};
  }
`

export const UnstyledInput = styled.input`
  border: 0;
  width: 100%;
  
  :focus {
    outline: none;
    border: none;
  }
`
