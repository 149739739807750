import React from 'react'

const SignOut = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5" {...props}>
    <g clipPath="url(#clip0_3_1000)">
      <path d="M15 4.00098H5V18.001C5 19.1055 5.89543 20.001 7 20.001H15" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 15.001L19 12.001M19 12.001L16 9.00098M19 12.001H9" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_3_1000">
        <rect width="24" height="24" fill="white" transform="translate(0 0.000976562)"/>
      </clipPath>
    </defs>
  </svg>
}

export default SignOut
