import React, { ReactElement } from 'react'
import { observer } from 'mobx-react'
import APIRoles from './Team/APIRoles'
import { Container } from '../components/Animations'

const TeamRoles = observer((): ReactElement => {
  return (
    <Container>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20, flexGrow: 1 }}>
        <APIRoles />
      </div>
    </Container>
  )
})

export default TeamRoles
