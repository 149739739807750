import React, { ReactElement, useState } from 'react'
import { PrimaryButton, SecondaryButton } from './Button'
import Radom from '../state/Radom'
import { InputLabel } from './Input'
import { RADOM_COLORS } from '../util/Constants'
import Checkbox from './Checkbox'
import styled from 'styled-components'
import Dropdown, { DropdownItem } from './Dropdown'
import { endOfDay, endOfMonth, intlFormat, startOfDay, startOfMonth, subDays, subMonths } from 'date-fns'
import { cloneDeep } from 'lodash'
import { errorToast } from '../util/Util'
import DateSelector from './DateSelector'

const DateRange = styled(SecondaryButton)`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 10px;
  color: ${RADOM_COLORS.BLACK};
  border: 0;
`

const EXPORT_TIME_RANGES = [
  {
    label: 'Today',
    dateFn: () => {
      return {
        from: startOfDay(new Date()),
        to: new Date()
      }
    }
  },
  {
    label: 'Current month',
    dateFn: () => {
      return {
        from: startOfMonth(new Date()),
        to: endOfDay(new Date())
      }
    }
  },
  {
    label: 'Last 7 days',
    dateFn: () => {
      return {
        from: startOfDay(subDays(new Date(), 7)),
        to: endOfDay(new Date())
      }
    }
  },
  {
    label: 'Last month',
    dateFn: () => {
      return {
        from: startOfMonth(subMonths(new Date(), 1)),
        to: endOfMonth(subMonths(new Date(), 1))
      }
    }
  },
  {
    label: 'All',
    dateFn: () => {
      return {
        from: new Date(0),
        to: new Date()
      }
    }
  },
  { label: 'Custom' }
]

const COLUMN_TYPES = [
  'All columns',
  'Custom'
]

const COLUMNS: { [key: string]: { label: String, key?: any, unchecked?: boolean } } = {
  Id: { label: 'ID' },
  Date: { label: 'Date', key: 'Date' },
  Currency: { label: 'Currency', key: 'Currency' },
  Subtotal: { label: 'Subtotal', key: 'Subtotal' },
  Discount: { label: 'Discount', key: 'Discount' },
  AddedNetworkFee: { label: 'Added network fee', key: 'AddedNetworkFee' },
  Total: { label: 'Total', key: 'Total' },
  ConvertedCurrency: { label: 'Converted currency', key: 'ConvertedCurrency' },
  ConvertedTotal: { label: 'Converted total', key: 'ConvertedTotal' },
  Network: { label: 'Network', key: 'Network' },
  Token: { label: 'Token', key: 'Token' },
  GrossPayment: { label: 'Gross payment', key: 'GrossPayment' },
  NetworkFee: { label: 'Network fee', key: 'NetworkFee' },
  RadomFee: { label: 'Radom fee', key: 'RadomFee' },
  TotalFee: { label: 'Total fee', key: 'TotalFee' },
  NetAmount: { label: 'Net amount', key: 'NetAmount' },
  RefundedAmount: { label: 'Refunded amount', key: 'RefundedAmount' },
  ConvertedGrossPayment: { label: 'Converted gross payment', key: 'ConvertedGrossPayment' },
  ConvertedNetworkFee: { label: 'Converted network fee', key: 'ConvertedNetworkFee' },
  ConvertedRadomFee: { label: 'Converted radom fee', key: 'ConvertedRadomFee' },
  ConvertedTotalFee: { label: 'Converted total fee', key: 'ConvertedTotalFee' },
  ConvertedNetAmount: { label: 'Converted net amount', key: 'ConvertedNetAmount' },
  ConvertedRefundedAmount: { label: 'Converted refunded amount', key: 'ConvertedRefundedAmount' }
}

function downloadBlob(blob: Blob, filename: string): HTMLAnchorElement {
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')

  a.href = url
  a.download = filename || 'download'

  const clickHandler = (): void => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      removeEventListener('click', clickHandler)
    }, 150)
  }

  a.addEventListener('click', clickHandler, false)
  a.click()

  return a
}

const ExportPaymentsForm = (): ReactElement => {
  const [closeFn, setCloseFn] = useState(() => () => {})
  const [isLoading, setIsLoading] = useState(false)
  const [selectedTimeRange, setSelectedTimeRange] = useState(EXPORT_TIME_RANGES[0])
  const [columnTypes, setColumnTypes] = useState('All columns')
  const [columns, setColumns] = useState(cloneDeep(COLUMNS))

  const [customFromDate, setCustomFromDate] = useState<Date>()
  const [customToDate, setCustomToDate] = useState<Date>()

  const exportPayments = async (): Promise<void> => {
    let fromDate, toDate
    if (selectedTimeRange.label === 'Custom') {
      if (customFromDate && customToDate) {
        fromDate = startOfDay(customFromDate).toISOString()
        toDate = endOfDay(customToDate).toISOString()
      } else {
        errorToast('Must select from and to custom date ranges')
        return
      }
    } else if (selectedTimeRange.dateFn) {
      fromDate = selectedTimeRange.dateFn().from.toISOString()
      toDate = selectedTimeRange.dateFn().to.toISOString()
    }
    setIsLoading(true)

    Radom.export(
      fromDate,
      toDate,
      Object.entries(columns).filter(([k, v]) => !v.unchecked).map(([k]) => k)
    )
      .then(data => {
        const blob = new Blob(
          [data],
          { type: 'text/csv' }
        )

        downloadBlob(blob, 'payments.csv')
      })
      .catch(err => {
        errorToast(err.response?.data?.error || err.message)
      }).finally(() => {
        setIsLoading(false)
      })
  }

  return <>
    <div style={{
      padding: '0 30px',
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      fontSize: 14,
      width: 800
    }}>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <InputLabel>Date range</InputLabel>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
          {EXPORT_TIME_RANGES.map(t =>
            <>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>

                <DateRange onClick={() => setSelectedTimeRange(t)} disabled={isLoading}>
                  <Checkbox isRadio checked={selectedTimeRange === t} size={18} disabled={isLoading} />
                  <span>{t.label}</span>

                </DateRange>
                {
                  selectedTimeRange.label === 'Custom' && t.label === 'Custom' &&
                <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                  <DateSelector onChange={d => setCustomFromDate(d)} value={customFromDate} />
                  <span>-</span>
                  <DateSelector onChange={d => setCustomToDate(d)} value={customToDate} />
                </div>
                }
              </div>
              {
                t.dateFn && t.label !== 'All' && t.label !== 'Today' &&
                 <div style={{ display: 'flex', opacity: 0.5 }}>
                   <span>{intlFormat(t.dateFn?.().from)}</span>
                   <span style={{ margin: '0 5px' }}>-</span>
                   <span>{intlFormat(t.dateFn?.().to)}</span>
                 </div>
              }
              {
                t.label === 'Today' && <div style={{ opacity: 0.5 }}>Today</div>
              }
              {
                t.label === 'All' && <div />
              }
            </>
          )}
        </div>
      </div>

      <div style={{ width: '100%', height: 1, backgroundColor: RADOM_COLORS.GRAY_MED, margin: '10px 0' }} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <InputLabel>Columns</InputLabel>
        <div style={{ width: 'fit-content' }}>
          <Dropdown
            disabled={isLoading}
            outerStyle={{ minWidth: 150 }}
            onCloseFn={_closeFn => setCloseFn(() => _closeFn)}
            selectedContent={<span>{columnTypes}</span>}
            dropdownContent={
              <div style={{ fontSize: 14 }}>
                {
                  COLUMN_TYPES.filter(c => c !== columnTypes).map((c, i) =>
                    <DropdownItem key={i} onClick={() => {
                      if (c === 'All columns') {
                        setColumns(cloneDeep(COLUMNS))
                      }
                      setColumnTypes(c)
                      closeFn()
                    }}>{c}</DropdownItem>
                  )
                }
              </div>
            } />
        </div>
        {
          columnTypes === 'Custom' &&
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', rowGap: 10, columnGap: 10, padding: 5 }}>
          {
            Object.entries(columns).map(([columnKey, columnValue]) =>
              <div key={columnKey} style={{ fontSize: 14, display: 'flex', alignItems: 'flex-start', gap: 5 }}>
                <Checkbox size={18} checked={!columnValue.unchecked} disabled={isLoading}
                  onClick={() => {
                    columnValue.unchecked = !columnValue.unchecked
                    setColumns(cloneDeep(columns))
                  }} />
                <span>{columnValue.label}</span>
              </div>
            )
          }
        </div>
        }
        {
          columnTypes === 'All columns' &&
        <span>
          {Object.entries(columns).map(([_, l]) => l.label).join(', ')}
        </span>
        }
      </div>

    </div>
    <div style={{ padding: 30 }}>

      <PrimaryButton
        style={{ width: '100%' }}
        onClick={exportPayments}
        isLoading={isLoading}>
      Export payments
      </PrimaryButton>
    </div>
  </>
}

export default ExportPaymentsForm
