import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'

export const InfoIcon = (
  props: React.SVGProps<SVGSVGElement>
): ReactElement => (
  <svg viewBox="0 0 24 24" {...props} stroke="white" fill={RADOM_COLORS.GRAY_DARKEST}>
    <g>
      <circle cx="12" cy="11.9999" r="9" strokeWidth="0" strokeLinecap="round" strokeLinejoin="round"/>
      <rect x="12" y="8" width="0.01" height="0.01" strokeWidth="3" strokeLinejoin="round"/>
      <path d="M12 12V16" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </svg>
)

export const HoverInfoIcon = styled(InfoIcon)`
  transition: all ease-in-out 0.2s;
  cursor: pointer;
  opacity: 0.3;
  
  :hover {
    opacity: 1;
  }
`
