import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { IconButton, PrimaryButton } from '../components/Button'
import Table from '../components/Table'
import { Link, useNavigate } from 'react-router-dom'
import PageHeader from '../components/PageHeader'
import Radom, { ListInvoice } from '../state/Radom'
import Pagination from '../components/Pagination'
import styled from 'styled-components'
import { PAY_BASE_URL, RADOM_COLORS } from '../util/Constants'
import TimeText from '../components/TimeText'
import { TextInput } from '../components/Input'
import LinkExternal from '../components/LinkExternal'
import Copy from '../icons/Copy'
import Dropdown, { DropdownItem } from '../components/Dropdown'
import Dots from '../icons/Dots'
import ConfirmButtonModal from '../components/ConfirmButtonModal'
import { successToast, errorToast, infoToast, formatCurrency } from '../util/Util'
import { Container } from '../components/Animations'

export const StatusBadge = styled.div<{ status: 'pending' | 'processing' | 'paid' | 'voided' | 'overdue' | 'complete' }>`
  text-transform: capitalize;
  padding: 5px 10px;
  border-radius: 5px;
  float: right;
  color: white;
  font-size: 14px;

  ${({ status }) => {
    let bgColor = RADOM_COLORS.ERROR_LIGHT as string
    let textColor = RADOM_COLORS.ERROR as string

    switch (status) {
    case 'complete':
    case 'paid':
      bgColor = RADOM_COLORS.SUCCESS_LIGHT as string
      textColor = RADOM_COLORS.SUCCESS as string
      break
    case 'processing':
    case 'pending':
      bgColor = RADOM_COLORS.GRAY_MED
      textColor = RADOM_COLORS.GRAY_DARKEST
      break
    }
    return `
      background-color: ${bgColor};
      color: ${textColor};
    `
  }};
`

const Invoices = observer((): React.ReactElement => {
  const inputRefs = {}
  const pageSize = 100
  const [invoices, setInvoices] = useState<ListInvoice[]>([])
  const [curPage, setCurPage] = useState(0)
  const [numPages, setNumPages] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    getInvoices()
  }, [])

  const getInvoices = (): void => {
    setIsLoading(true)
    Radom.getSellerInvoices()
      .then(i => {
        setInvoices(i)
        setNumPages(Math.ceil(invoices.length / pageSize))
        setIsLoading(false)
      })
  }

  const onPageClick = (i: number): void => {
    setCurPage(i)
  }

  const onInvoiceClick = (i: number): void => {
    const invoice = invoices.at(i)
    if (invoice) {
      navigate(`/invoices/${invoice.id}`)
    }
  }

  return <Container>
    <div style={{ flexGrow: 1 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PageHeader title='Invoices' subTitle='Create customizable invoices for your customers' />
        <Link to="/invoices/create" style={{ textDecoration: 'none' }}>
          <PrimaryButton style={{ gap: 8 }}>
            <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
            <span>Create invoice</span>
          </PrimaryButton>
        </Link>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', paddingTop: 20 }}>
        <Table
          borderless
          disableOnClickState
          headers={[
            <span key="url">URL</span>,
            <span key="status">Status</span>,
            <span key="amount">Amount</span>,
            <span key="customer">Customer</span>,
            <span key="dateIssued">Date issued</span>,
            <span key="action"></span>
          ]}
          isLoading={isLoading}
          items={
            invoices
              .sort((a, b) => (new Date(b.issuedAt)).getTime() - (new Date(a.issuedAt)).getTime())
              .slice(curPage * pageSize, (curPage * pageSize) + pageSize)
              .map(invoice => [
                <div key="url" style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '5px' }}>
                  <TextInput value={invoice.invoiceUrl} ref={r => r && (inputRefs[invoice.id] = r)} style={{ maxWidth: '280px', height: 'fit-content' }} disabled />
                  <IconButton style={{ padding: 0 }}>
                    <Copy style={{ width: 20 }} onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      inputRefs[invoice.id].select()
                      inputRefs[invoice.id].setSelectionRange(0, 99999)
                      navigator.clipboard.writeText(inputRefs[invoice.id].value)
                      infoToast('Copied invoice URL!')
                    }} />
                  </IconButton>
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      window.open(`${PAY_BASE_URL}/invoice/${invoice.id}`, '_blank')
                    }}>
                    <LinkExternal stroke={RADOM_COLORS.GRAY_DARK} width={20} />
                  </IconButton>
                </div>,
                <StatusBadge key="status" status={invoice.status}>
                  {invoice.status}
                </StatusBadge>,
                <span key="amount" style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  {formatCurrency(invoice.amount, invoice.currency)}
                </span>,
                <div key="customer" style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                  {invoice.email}
                </div>,
                <span key="dateIssued">{<TimeText date={new Date(invoice.issuedAt)} />}</span>,
                <div key="dots" style={{ display: 'flex' }} onClick={e => e.stopPropagation()}>
                  {
                    !invoice.paidAt && !invoice.voidedAt &&
                      <Dropdown
                        noChevron
                        selectedContent={<Dots width={15} />}
                        dropdownContent={
                          <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                            <ConfirmButtonModal
                              title='Void Invoice'
                              description='Are you sure you want to void this invoice? This action cannot be undone.'
                              action='Void invoice'
                              onConfirm={async () => {
                                await Radom.voidInvoice(invoice.id)
                                  .then(() => {
                                    successToast('Successfully voided invoice')
                                    getInvoices()
                                  })
                                  .catch(err => {
                                    errorToast(err.response?.data?.error || err.message)
                                  })
                              }}
                              ActionComponent={props => <DropdownItem {...props}>Void invoice</DropdownItem>}
                            />
                          </div>
                        }
                      />
                  }
                </div>
              ])
          }
          emptyPlaceholderText='No invoices created yet'
          onClickRow={onInvoiceClick}
        />
        <Pagination curPage={curPage} numPages={numPages} onPageClick={onPageClick} />
      </div>
    </div>
  </Container>
})

export default Invoices
