import React, { useEffect, useReducer, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import Close from '../icons/Close'
import Radom, {
  InputField, InputFieldDataType, IPaymentLink, IProduct,
  IProductUpdate
} from '../state/Radom'
import { RADOM_COLORS } from '../util/Constants'
import { Currencies } from '../util/Currencies'
import { SecondsToPaymentPeriod, errorToast, successToast } from '../util/Util'
import { IconButton, PrimaryButton, HoverText, IconLinkButton } from './Button'
import Switch from '../components/Switch'
import ProductForm, { ProductType } from './ProductForm'
import Dropdown from './Dropdown'
import { InputLabel, InputWithCheckbox, TextInput, TextInputWithLabel, UnstyledInput } from './Input'
import Modal from './Modal'
import Tooltip from './Tooltip'
import Checkbox from './Checkbox'
import { cloneDeep, isEqual } from 'lodash'
import { ManagedPaymentMethodSelector } from './ManagedMethodMultiselector'
import { ManagedPaymentMethod, getMethod } from '../util/Managed'
import ColorPicker from 'react-best-gradient-color-picker'
import { IProductCreateV2 } from '../views/Products/CreateProduct'
import SearchMultiselect from './SearchMultiselect'

const InputPresets = [
  { label: 'Collect customer’s name', name: 'Name' },
  { label: 'Collect customer’s email', name: 'Email address' }
]

const ChainLogo = styled.img`
  filter: grayscale(1);
  width: auto;
  height: 14px;
  transition: 0.2s ease all;
  opacity: 0.5;
`

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;

  :hover {
    background-color: ${RADOM_COLORS.GRAY_LIGHTEST};

    ${ChainLogo} {
      opacity: 1;
      filter: grayscale(0);
    }
  }
`

const InputPresetList = styled.div`
  display: grid;
  gap: 12px;
  align-items: center;
`

const InputPreset = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  color: ${RADOM_COLORS.GRAY_DARKEST};
  width: fit-content;
  transition: 0.2s ease all;

  &:hover {
    color: ${RADOM_COLORS.BLACK};
  }

  &:active {
    opacity: 0.5;
  }
`

const CustomInputList = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) 20px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`

const emptyInputField = {
  dataType: InputFieldDataType.String,
  inputLabel: '',
  isRequired: true
}

interface IProps {
  edit?: boolean
  isLoading?: boolean
  isSubmitting?: boolean
  paymentLink: IPaymentLink
  onFormSubmit: () => void
  onFormChange: (form: Partial<IPaymentLink>) => void
}

const PaymentLinkForm = (props: IProps): React.ReactElement => {
  const [searchParams] = useSearchParams()

  const [enableRedirect, setEnableRedirect] = useState(false)
  const [productModalOpen, setProductModalOpen] = useState(false)
  const [inputFieldCloseFns, setInputFieldCloseFns] = useState<any>({})
  const [customInputs, setCustomInputs] = useState<InputField[]>([])
  const [products, setProducts] = useState([] as IProduct[])
  const [selectedMethods, setSelectedMethods] = useState<ManagedPaymentMethod[]>(
    props.paymentLink.gateway.managed?.methods.map(m => getMethod(m.network, m.token)) ?? []
  )

  const emptyProduct: any = {
    name: '',
    description: '',
    chargingIntervalSeconds: 0,
    currency: Currencies[0].ticker,
    price: '',
    image: undefined,
    addOns: []
  }

  useEffect(() => {
    if (!props.paymentLink.gateway.managed) {
      return
    }

    props.onFormChange({
      gateway: {
        managed: {
          methods: selectedMethods.map(m => ({
            network: m.hostChain?.name ?? m.name,
            token: m.hostChain?.tokenAddress ?? undefined
          }))
        }
      }
    })
  }, [selectedMethods])

  const [productForm, updateProductForm] = useReducer((p: IProductCreateV2, n: Partial<IProductCreateV2>) => {
    return { ...p, ...n }
  }, emptyProduct)

  const handleUpdateProductForm = (form: IProductUpdate): void => {
    updateProductForm(form)
  }

  const loadProducts = async (): Promise<void> => {
    Radom.getProducts(true)
      .then(products => {
        setProducts(products.filter(p => !p.productType?.Presale))
        return products
      })
      .then((products) => {
        if (searchParams.has('product_id')) {
          const selectedProduct = products.find(p => p.id === searchParams.get('product_id'))
          if (selectedProduct) {
            props.onFormChange({ products: [selectedProduct] })
          }
        }
      })
  }

  const onCreateProduct = async (): Promise<void> => {
    try {
      const newProduct = await Radom.createProduct({
        ...productForm,
        chargingIntervalSeconds: productForm.chargingIntervalSeconds !== undefined &&
         productForm.chargingIntervalSeconds <= 0
          ? undefined
          : productForm.chargingIntervalSeconds,
        image: undefined
      } as any)

      if (productForm.image) {
        const formData = new FormData()
        formData.append('entityId', newProduct.id)
        formData.append('imageType', 'productImage')
        formData.append('image', productForm.image)
        await Radom.uploadImage(formData)
      }

      const product = await Radom.getProduct(newProduct.id)

      setProductModalOpen(false)
      successToast('Created product!')

      loadProducts()
        .then(() => {
          props.onFormChange({ products: [...props.paymentLink.products, product] })
        })
    } catch (err) {
      errorToast(err.response?.data?.error || err.message)
    }
  }

  const onFormSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    e.stopPropagation()

    props.onFormSubmit()
  }

  useEffect(() => {
    setCustomInputs(props.paymentLink.inputFields.filter(i => !InputPresets.some(ii => ii.name === i.inputLabel)))
  }, [])

  useEffect(() => {
    if ((props.paymentLink.cancelUrl && props.paymentLink.cancelUrl.trim().length > 0) ||
      (props.paymentLink.successUrl && props.paymentLink.successUrl.trim().length > 0)) {
      setEnableRedirect(true)
    } else {
      setEnableRedirect(false)
    }
  }, [props.paymentLink.cancelUrl, props.paymentLink.successUrl])

  useEffect(() => {
    const _inputPresets = props.paymentLink.inputFields
      .filter(i => InputPresets.map(i => i.name).includes(i.inputLabel))
    const inputFields = [..._inputPresets, ...customInputs]

    if (isEqual(props.paymentLink.inputFields, inputFields)) {
      return
    }

    props.onFormChange({ inputFields: cloneDeep(inputFields) })
  }, [customInputs])

  useEffect(() => {
    loadProducts()

    Radom.getSellerProfile()
      .then(profile => {
        if (props.paymentLink.gateway.managed) {
          return
        }

        if (profile) {
          if (profile.defaultTokens?.length && !props.edit) {
            props.onFormChange({ gateway: { selfCustodial: { tokens: profile.defaultTokens } } })
          }
        }
      })
  }, [])

  return <>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: 2,
      background: 'white',
      position: 'relative'
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
        padding: '10px 30px',
        top: 0,
        position: 'absolute',
        background: 'rgba(255, 255, 255, 0.85)',
        width: '100%',
        zIndex: 1,
        backdropFilter: 'blur(5px)',
        borderBottom: `1px solid ${RADOM_COLORS.GRAY_MED}`
      }}>
        <IconLinkButton to='/payment_links' type="button" style={{ padding: 5 }}>
          <Close style={{ width: 15, pointerEvents: 'none' }} />
        </IconLinkButton>
        <h1 style={{ fontSize: 18, fontWeight: 400 }}>{props.edit ? 'Edit' : 'Create'} payment link</h1>
      </div>
      <div style={{
        fontSize: 14,
        width: 450,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'scroll',
        paddingTop: 90
      }}>
        <form onSubmit={onFormSubmit} style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          justifyContent: 'space-between'
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 20,
            padding: 40,
            paddingTop: 0
          }}>

            {
              props.paymentLink.gateway.managed &&
                <ManagedPaymentMethodSelector
                  allowTestnets
                  selectedMethods={props.paymentLink.gateway.managed.methods.map(m => getMethod(m.network, m.token))}
                  setSelectedMethods={setSelectedMethods} />
            }

            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <InputLabel>Products</InputLabel>
              </div>

              <SearchMultiselect
                itemArray={products}
                selectedItems={props.paymentLink.products}
                setSelectedItems={ products => props.onFormChange({ products })}
                keyExtractor={p => p.name + p.id}
                headers={[
                  <DropdownItem
                    key='create'
                    style={{ display: 'flex', alignItems: 'center', padding: 15, width: '100%' }}
                    onClick={() => setProductModalOpen(true) }>
                    <span style={{ color: RADOM_COLORS.BLACK }}>+ Create new product</span>
                  </DropdownItem>
                ]}
                dropdownListItemStyle={{
                  borderBottom: `1px solid ${RADOM_COLORS.GRAY_MED}`
                }}
                labelExtractor={p =>
                  <DropdownItem style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 10,
                    padding: 3,
                    maxWidth: 400
                  }}>
                    {p.imageUrl && <img src={p.imageUrl} style={{ width: 20, height: 'auto' }} /> }
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                      <span style={{ overflowWrap: 'anywhere' }}>{p.name}</span>
                      <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: 12, opacity: 0.6 }}>
                          {Currencies.find(c => c.ticker.toLowerCase() === p.currency.toLowerCase())?.icon}
                          {new Intl.NumberFormat('en-US', { maximumFractionDigits: 12 }).format(p.price)}
                          {
                            p.chargingIntervalSeconds !== undefined && p.chargingIntervalSeconds > 0 &&
                            ` per ${SecondsToPaymentPeriod(p.chargingIntervalSeconds).toLowerCase()}`
                          }
                        </div>
                      </div>
                    </div>
                  </DropdownItem>}
              />
            </div>

            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                <div style={{ display: 'flex', gap: 3 }}>
                  <InputLabel style={{ opacity: 0.5 }}>Network fee</InputLabel>
                  <Tooltip message='If this is enabled, the network fee will be added to the checkout total and paid by the customer.' />
                </div>
              </div>
              <InputPresetList>
                <InputPreset
                  onClick={() => props.onFormChange({
                    chargeCustomerNetworkFee: !props.paymentLink.chargeCustomerNetworkFee
                  })}>
                  <Checkbox
                    size={16}
                    checked={!!props.paymentLink.chargeCustomerNetworkFee} />
                  <span>Pass network fee charge to customer</span>
                </InputPreset>
              </InputPresetList>
            </div>

            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                <InputLabel style={{ opacity: 1, gap: 2 }}>
                  <span style={{ opacity: 0.5 }}>Email receipt</span>
                </InputLabel>
              </div>
              <InputPresetList>
                <InputPreset
                  onClick={() => {
                    if (!props.paymentLink.sendEmailReceipt && !props.paymentLink.inputFields.map(i => i.inputLabel).includes('Email address')) {
                      props.onFormChange({
                        inputFields: [...props.paymentLink.inputFields, { dataType: InputFieldDataType.Email, inputLabel: 'Email address', isRequired: true }]
                      })
                    }
                    props.onFormChange({ sendEmailReceipt: !props.paymentLink.sendEmailReceipt })
                  } }>
                  <Checkbox
                    size={16}
                    checked={!!props.paymentLink.sendEmailReceipt} />
                  <span>Send customer an email receipt</span>
                </InputPreset>
              </InputPresetList>
            </div>

            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                <InputLabel style={{ opacity: 1, gap: 2 }}>
                  <span style={{ opacity: 0.5 }}>Input fields</span>
                  <Tooltip message='Create inputs to collect customer data.' />
                </InputLabel>
              </div>
              <InputPresetList>
                {
                  InputPresets.map((input, index) =>
                    <InputPreset
                      key={index}
                      onClick={() => {
                        const newInputs = props.paymentLink.inputFields.slice()
                        if (props.paymentLink.inputFields.map(i => i.inputLabel).includes(input.name)) {
                          // Remove preset input from inputFields
                          props.onFormChange({
                            inputFields: newInputs.filter(i => i.inputLabel !== input.name)
                          })
                        } else {
                          // Add preset input to inputFields
                          const presetInput: InputField = {
                            ...emptyInputField,
                            inputLabel: input.name,
                            dataType: input.name === 'Email address' ? InputFieldDataType.Email : InputFieldDataType.String
                          }
                          props.onFormChange({
                            inputFields: [...newInputs, presetInput]
                          })
                        }
                      }}>
                      <Checkbox
                        size={16}
                        checked={props.paymentLink.inputFields.map(i => i.inputLabel).includes(input.name)} />
                      <span>{input.label}</span>
                    </InputPreset>
                  )
                }
                <InputPreset
                  onClick={() => {
                    const checked = customInputs.length > 0
                    if (checked) {
                      setCustomInputs([])
                    } else {
                      setCustomInputs([{ ...emptyInputField }])
                    }
                  }}>
                  <Checkbox size={16} checked={customInputs.length > 0} />
                  <span>Add custom fields</span>
                </InputPreset>
              </InputPresetList>
              {
                customInputs.length > 0 &&
                  <CustomInputList>
                    {
                      customInputs
                        .map((field, fieldIndex) => [
                          <Dropdown
                            key={`${fieldIndex}-dataType`}
                            onCloseFn={fn => {
                              const newInputFieldFns = cloneDeep(inputFieldCloseFns)
                              newInputFieldFns[fieldIndex] = fn
                              setInputFieldCloseFns(newInputFieldFns)
                            }}
                            selectedContent={
                              <div style={{ fontSize: 14, minWidth: 'max-content' }}>
                                { InputFieldDataType[field.dataType] }
                              </div>
                            }
                            dropdownContent={
                              Object.entries(InputFieldDataType)
                                .filter(c => field.dataType !== c[0])
                                .map((c, i) =>
                                  <DropdownItem
                                    key={i}
                                    style={{ fontSize: 14 }}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      const newInputs = customInputs.slice()
                                      newInputs[fieldIndex].dataType = c[0] as InputFieldDataType
                                      inputFieldCloseFns[fieldIndex]?.()
                                      setCustomInputs(newInputs)
                                    }}
                                  >
                                    {c[1]}
                                  </DropdownItem>
                                )
                            } />,
                          <InputWithCheckbox key={`${fieldIndex}-input`}>
                            <UnstyledInput
                              type="text"
                              value={field.inputLabel}
                              placeholder="Input label"
                              onChange={(e) => {
                                const val = (e.target as HTMLInputElement).value
                                const newInputs = cloneDeep(customInputs.slice())
                                newInputs[fieldIndex].inputLabel = val
                                setCustomInputs(newInputs)
                              }} />
                            <div style={{
                              display: 'flex',
                              gap: 4,
                              color: RADOM_COLORS.GRAY_DARKER,
                              alignItems: 'center',
                              borderLeft: `1px solid ${RADOM_COLORS.GRAY_MED}`,
                              paddingLeft: '8px',
                              cursor: 'pointer',
                              userSelect: 'none'
                            }}
                            onClick={() => {
                              const newInputs = customInputs.slice()
                              newInputs[fieldIndex].isRequired = !newInputs[fieldIndex].isRequired
                              setCustomInputs(newInputs)
                            }}>
                              <span style={{ fontSize: 12 }}>Optional</span>
                              <Checkbox size={15} checked={!field.isRequired} />
                            </div>
                          </InputWithCheckbox>,
                          <div style={{ justifySelf: 'end' }} key={`${fieldIndex}-close`}>
                            <IconButton type="button" style={{ padding: 5 }} onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              const newInputs = customInputs.slice()
                              newInputs.splice(fieldIndex, 1)
                              setCustomInputs(newInputs)
                            }}>
                              <Close style={{ width: 12, pointerEvents: 'none' }} />
                            </IconButton>
                          </div>
                        ])
                    }
                  </CustomInputList>
              }
              {
                customInputs.length > 0 &&
                  customInputs.length < 2 &&
                  <HoverText
                    style={{ padding: 10, width: 'fit-content' }}
                    onClick={() => {
                      const newInputs = customInputs.slice()
                      newInputs.push({ ...emptyInputField })
                      setCustomInputs(newInputs)
                    }}>
                      + Add input field
                  </HoverText>
              }
            </div>

            <div style={{ display: 'flex', gap: 15 }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <InputLabel>Panel color</InputLabel>
                <div style={{ width: 'fit-content' }}>
                  <Dropdown
                    overflow='initial'
                    innerStyle={{ maxHeight: '100vh' }}
                    selectedContent={
                      <div
                        style={{
                          width: 25,
                          height: 25,
                          borderRadius: 5,
                          background: props.paymentLink.customizations.leftPanelColor
                        }}
                      />
                    }
                    dropdownContent={
                      <div style={{ padding: 10, display: 'flex', flexDirection: 'column', gap: 10 }}>
                        <ColorPicker
                          value={props.paymentLink.customizations.leftPanelColor}
                          onChange={c => {
                            props.onFormChange({
                              customizations: { ...props.paymentLink.customizations, leftPanelColor: c }
                            })
                          }}
                        />
                        <TextInput
                          value={props.paymentLink.customizations.leftPanelColor}
                          onChange={v => props.onFormChange({
                            customizations: { ...props.paymentLink.customizations, leftPanelColor: v.target.value }
                          })}
                        />
                      </div>
                    }
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <InputLabel>Button color</InputLabel>
                <div style={{ width: 'fit-content' }}>
                  <Dropdown
                    overflow='initial'
                    innerStyle={{ maxHeight: '100vh' }}
                    selectedContent={
                      <div style={{
                        width: 25,
                        height: 25,
                        borderRadius: 5,
                        background: props.paymentLink.customizations.primaryButtonColor
                      }} />
                    }
                    dropdownContent={
                      <div style={{ padding: 10, display: 'flex', flexDirection: 'column', gap: 10 }}>
                        <ColorPicker
                          value={props.paymentLink.customizations.primaryButtonColor}
                          onChange={c => {
                            props.onFormChange({
                              customizations: { ...props.paymentLink.customizations, primaryButtonColor: c }
                            })
                          }}
                        />
                        <TextInput
                          value={props.paymentLink.customizations.primaryButtonColor}
                          onChange={v => props.onFormChange({
                            customizations: { ...props.paymentLink.customizations, primaryButtonColor: v.target.value }
                          })}
                        />
                      </div>
                    }
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <InputLabel>Slanted edge</InputLabel>
                <Switch
                  checked={props.paymentLink.customizations.slantedEdge}
                  onClick={() => {
                    props.onFormChange({
                      customizations: {
                        ...props.paymentLink.customizations,
                        slantedEdge: !props.paymentLink.customizations.slantedEdge
                      }
                    })
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <InputLabel>Allow discount codes</InputLabel>
              <Switch
                checked={props.paymentLink.customizations.allowDiscountCodes}
                onClick={() => {
                  props.onFormChange({
                    customizations: {
                      ...props.paymentLink.customizations,
                      allowDiscountCodes: !props.paymentLink.customizations.allowDiscountCodes
                    }
                  })
                }}
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <InputLabel>Enable redirect URLs</InputLabel>
              <Switch
                checked={enableRedirect}
                onClick={() => setEnableRedirect(!enableRedirect)}
              />
              {enableRedirect && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <TextInputWithLabel
                      label="Success URL"
                      placeholder="https://"
                      value={props.paymentLink.successUrl}
                      onChange={(e) => props.onFormChange({ successUrl: (e.target as HTMLInputElement).value })
                      }
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <TextInputWithLabel
                      label="Cancel URL"
                      placeholder="https://"
                      value={props.paymentLink.cancelUrl}
                      onChange={(e) => props.onFormChange({ cancelUrl: (e.target as HTMLInputElement).value })
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div style={{
            padding: '20px 40px',
            position: 'sticky',
            bottom: 0,
            background: 'linear-gradient(to top, white, white, transparent)',
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10
          }}>
            <PrimaryButton style={{ marginTop: '32px', width: '100%', gap: 8 }} isLoading={props.isSubmitting}>
              {props.edit
                ? 'Save changes'
                : <>
                  <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
                  <span>Create payment link</span>
                </>
              }
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div >
    <Modal
      visible={productModalOpen}
      onClose={() => setProductModalOpen(false)}
      title='Create product'>
      <ProductForm
        modal
        form={productForm}
        allowedProductTypes={[ProductType.OneTime, ProductType.Subscription]}
        onFormSubmit={onCreateProduct}
        onFormUpdate={handleUpdateProductForm}
        isSubmitting={props.isSubmitting ?? false}
        isLoading={props.isLoading ?? false}
      />
    </Modal>
  </>
}

export default PaymentLinkForm
