import React, { ReactElement } from 'react'

function S(props: React.SVGProps<SVGSVGElement>): ReactElement {
  return <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect y="0.5" width="48" height="48" rx="6.85714" fill="url(#paint0_linear_23_254)"/>
    <g clipPath="url(#clip0_23_254)">
      <path d="M24 27.5C26.0711 27.5 27.75 25.8211 27.75 23.75C27.75 21.6789 26.0711 20 24 20C21.9289 20 20.25 21.6789 20.25 23.75C20.25 25.8211 21.9289 27.5 24 27.5Z" stroke="#3464E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.9813 31.1909C18.5456 30.0795 19.4066 29.1459 20.469 28.4939C21.5313 27.8418 22.7535 27.4966 24 27.4966C25.2465 27.4966 26.4687 27.8418 27.5311 28.4939C28.5934 29.1459 29.4545 30.0795 30.0188 31.1909" stroke="#3464E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M30.75 24.5L33 26.75L35.25 24.5" stroke="#3464E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.75 24.5L15 22.25L17.25 24.5" stroke="#3464E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15 22.25V24.5C14.9993 26.4055 15.6034 28.262 16.7252 29.8022C17.8471 31.3424 19.4289 32.4868 21.2427 33.0706C23.0566 33.6543 25.0089 33.6474 26.8185 33.0506C28.6282 32.4539 30.2017 31.2982 31.3125 29.75" stroke="#3464E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M33 26.75V24.5C33.0007 22.5945 32.3966 20.738 31.2748 19.1978C30.1529 17.6576 28.5711 16.5132 26.7573 15.9294C24.9434 15.3456 22.9911 15.3526 21.1815 15.9494C19.3718 16.5461 17.7983 17.7018 16.6875 19.25" stroke="#3464E6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_23_254" x1="0" y1="0.5" x2="43.3973" y2="46.3064" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E7EDFF"/>
        <stop offset="1" stopColor="#C6D5FF"/>
      </linearGradient>
      <clipPath id="clip0_23_254">
        <rect width="24" height="24" fill="white" transform="translate(12 12.5)"/>
      </clipPath>
    </defs>
  </svg>
}

export default S
