import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Radom, { IPaymentLink } from '../../state/Radom'
import { PAY_BASE_URL } from '../../util/Constants'
import Preview from '../../components/Preview'
import { emptyPaymentLink } from './CreatePresaleLink'
import { errorToast, successToast } from '../../util/Util'
import NavbarController from '../../state/NavbarController'
import Spinner from '../../components/Spinner'
import PresaleLinkForm from '../../components/PresaleLinkForm'

const EditPresaleLink = (): React.ReactElement => {
  const { paymentLinkId } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [paymentLink, setPaymentLink] = useReducer(
    (p: IPaymentLink, n: Partial<IPaymentLink>) => {
      return { ...p, ...n }
    },
    { ...emptyPaymentLink, chargeCustomerNetworkFee: false }
  )

  const previewData = useMemo(() => {
    const sellerName = Radom.sellerProfile?.name

    return Buffer.from(JSON.stringify({
      ...paymentLink,
      sellerName
    }), 'utf8').toString('base64')
  }, [paymentLink])
  const [previewWidth, setPreviewWidth] = useState('90%')

  const handleEditPaymentLink = async (): Promise<void> => {
    setIsSubmitting(true)
    try {
      await Radom.updatePaymentLink(paymentLink.id, {
        products: paymentLink.products.map(p => p.id),
        gateway: paymentLink.gateway,
        customizations: paymentLink.customizations,
        cancelUrl: paymentLink.cancelUrl,
        successUrl: paymentLink.successUrl,
        inputFields: paymentLink.inputFields,
        sendEmailReceipt: paymentLink.sendEmailReceipt,
        chargeCustomerNetworkFee: paymentLink.chargeCustomerNetworkFee,
        presaleData: paymentLink.presaleData?.tokenPresaleData
      })

      successToast('Successfully edited presale')
      navigate('/presales')
    } catch (err) {
      console.error(err)
      errorToast(err.response
        ? err.response.data.error || err.response.data
        : err.message)
    }
    setIsSubmitting(false)
  }

  const onFormChange = async (form: Partial<IPaymentLink>): Promise<void> => {
    setPaymentLink(form)
  }

  useEffect(() => {
    setIsLoading(true)
    if (!paymentLinkId) throw new Error('Payment link ID not found')
    Radom.getPaymentLink(paymentLinkId)
      .then(p => {
        setPaymentLink(p)
      })
      .finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    NavbarController.setFullscreen(true)
    return () => NavbarController.setFullscreen(false)
  }, [])

  if (isLoading) {
    return <Spinner />
  }

  return <div style={{ display: 'flex', height: '100vh', position: 'relative' }}>
    <PresaleLinkForm
      edit
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      paymentLink={paymentLink}
      onFormSubmit={handleEditPaymentLink}
      onFormChange={onFormChange}
    />
    <Preview
      iframeLink={`${PAY_BASE_URL}/preview/presale#${previewData}`}
      previewWidth={previewWidth}
      onWidthChange={setPreviewWidth}
      presale
    />
  </div>
}

export default EditPresaleLink
