import React from 'react'

export default function Calendar(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0.63 24 24" stroke="#747894" {...props}>
    <g clipPath="url(#clip0_83_654)">
      <path d="M16.5 5.875H7.5C5.84315 5.875 4.5 7.21815 4.5 8.875V19.375C4.5 21.0319 5.84315 22.375 7.5 22.375H16.5C18.1569 22.375 19.5 21.0319 19.5 19.375V8.875C19.5 7.21815 18.1569 5.875 16.5 5.875Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.5 5.875V3.625C7.5 3.42609 7.57902 3.23532 7.71967 3.09467C7.86032 2.95402 8.05109 2.875 8.25 2.875H15.75C15.9489 2.875 16.1397 2.95402 16.2803 3.09467C16.421 3.23532 16.5 3.42609 16.5 3.625V5.875" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.5 5.875V2.875" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.5 5.875V2.875" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.5 17.125H12.75C13.1478 17.125 13.5294 16.967 13.8107 16.6857C14.092 16.4044 14.25 16.0228 14.25 15.625C14.25 15.2272 14.092 14.8456 13.8107 14.5643C13.5294 14.283 13.1478 14.125 12.75 14.125H11.25C10.8522 14.125 10.4706 13.967 10.1893 13.6857C9.90804 13.4044 9.75 13.0228 9.75 12.625C9.75 12.2272 9.90804 11.8456 10.1893 11.5643C10.4706 11.283 10.8522 11.125 11.25 11.125H13.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 11.125V9.625" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 18.625V17.125" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_83_654">
        <rect width="24" height="24" fill="white" transform="translate(0 0.625)"/>
      </clipPath>
    </defs>
  </svg>
}
