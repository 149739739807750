import React, { ReactElement, useEffect, useState } from 'react'
import { PrimaryButton } from './Button'
import Radom, { ICheckoutSession, PaymentSession, DonationLinkOrder } from '../state/Radom'
import { RadioButtonGroup } from './RadioButton'
import { InputLabel, TextInput } from './Input'
import NumberInput from './NumberInput'
import Switch from './Switch'
import Tooltip from './Tooltip'
import { RADOM_COLORS } from '../util/Constants'
import PaymentMethodDisplay from './PaymentMethodDisplay'
import { errorToast, successToast } from '../util/Util'

interface RefundProps {
  checkoutSession?: ICheckoutSession
  paymentSession?: PaymentSession
  donationLinkOrder?: DonationLinkOrder
  onRefund: () => void
}

const RefundForm = (props: RefundProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)
  const [isFullRefund, setIsFullRefund] = useState(true)
  const [chargeCustomerNetworkFee, setChargeCustomerNetworkFee] = useState(true)

  const payment = props.checkoutSession?.payment?.managed ||
    props.checkoutSession?.payment?.managedTokenDelegatePayment ||
    props.paymentSession?.payment?.managed ||
    props.donationLinkOrder?.payment?.managed

  if (!payment) {
    return <></>
  }

  const [refundAmount, setRefundAmount] = useState(payment.amount)
  const [recipientAddress, setRecipientAddress] = useState('')

  const submitRefund = async (): Promise<void> => {
    setIsLoading(true)
    Radom.refund({
      paymentSessionId: props.paymentSession?.id,
      checkoutSessionId: props.checkoutSession?.id,
      donationLinkOrderId: props.donationLinkOrder?.id,
      refundAmount,
      recipientAddress,
      chargeCustomerNetworkFee
    }).then(() => {
      successToast('Refund submitted')
      props.onRefund()
    }).catch(err => {
      errorToast(err.reason || err.response?.data?.error || err.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (isFullRefund) setRefundAmount(payment.amount)
  }, [isFullRefund])

  return <div style={{
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 20
  }}>

    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <InputLabel>Refund type</InputLabel>
      <RadioButtonGroup
        disabled={isLoading}
        labels={['Full refund', 'Partial refund']}
        selectedLabel={isFullRefund ? 'Full refund' : 'Partial refund'}
        onSelect={label => setIsFullRefund(label === 'Full refund')}
      />
    </div>

    {
      !isFullRefund &&
      <div style={{ position: 'relative' }}>
        <PaymentMethodDisplay
          style={{
            position: 'absolute',
            left: 5,
            top: 4,
            zIndex: 1,
            transform: 'scale(0.85)',
            pointerEvents: 'none'
          }}
          method={{ network: payment.network, token: payment.token }}
          excludeName />
        <NumberInput
          style={{ textIndent: 30 }}
          disabled={isLoading}
          autoFocus
          placeholder="Refund amount"
          min="0"
          autoComplete="off"
          value={refundAmount}
          fontSize="14"
          onUserInput={(e) => setRefundAmount(e)}
        />
      </div>
    }

    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <div style={{ display: 'flex', gap: 3 }}>
        <InputLabel>Charge customer network fee</InputLabel>
        <Tooltip message='If this is enabled, the gas fee to transfer the refund will be deducted from the refund amount.' />
      </div>
      <Switch
        checked={chargeCustomerNetworkFee}
        onClick={() => setChargeCustomerNetworkFee(!chargeCustomerNetworkFee)}
        disabled={isLoading} />
    </div>

    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <InputLabel>Recipient address</InputLabel>
      <TextInput
        disabled={isLoading}
        autoFocus
        placeholder=''
        value={recipientAddress}
        onChange={e => setRecipientAddress(e.target.value)} />
    </div>

    <div style={{ width: '100%', height: 1, backgroundColor: RADOM_COLORS.GRAY_MED, margin: '10px 0' }} />

    <div style={{
      display: 'flex',
      backgroundColor: RADOM_COLORS.ORANGE_LIGHTEST,
      border: `1px solid ${RADOM_COLORS.ORANGE_LIGHT}`,
      padding: 20,
      borderRadius: 10,
      color: RADOM_COLORS.GRAY_DARKEST,
      alignItems: 'center',
      gap: 10
    }}>
      <PaymentMethodDisplay method={{ network: payment.network, token: payment.token }} excludeName />
      <span>{refundAmount} {!chargeCustomerNetworkFee && '+ gas fee'} will be withdrawn from your balance</span>
    </div>

    <PrimaryButton
      style={{ width: '100%' }}
      onClick={submitRefund}
      isLoading={isLoading}>
      Issue refund
    </PrimaryButton>
  </div>
}

export default RefundForm
