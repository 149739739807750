import React, { ReactElement, useState } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../../util/Constants'
import { IconButton, PrimaryButton, SecondaryButton } from '../../components/Button'
import Modal from '../../components/Modal'
import Close from '../../icons/Close'
import { TextInput } from '../../components/Input'

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  align-items: center;
`

export const Tag = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  background-color: ${RADOM_COLORS.GRAY9};
  border-radius: 5px;
  padding: 5px 10px;
`

interface IProps {
  isOpen: boolean
  onClose: () => void
  tags: string[]
  onSave: (tags: string[]) => void
}

const EditTagsModal = observer((props: IProps): ReactElement => {
  const [tags, setTags] = useState([...props.tags])

  const [isAdding, setIsAdding] = useState(false)

  const [newTag, setNewTag] = useState<string>()

  return <Modal title='Edit tags' onClose={ props.onClose} visible={props.isOpen}>
    <div style={{ display: 'flex', flexDirection: 'column', padding: '0 30px 30px 30px' }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: 10,
        marginBottom: 10
      }}>
        {
          tags.map((t, i) => <Tag key={i}>
            <span>{t}</span>
            <IconButton
              style={{ padding: 5 }}
              onClick={e => {
                setTags(tags.filter(tt => t !== tt))
              }}>
              <Close style={{ width: 12, pointerEvents: 'none' }} />
            </IconButton>
          </Tag>
          )
        }

        {
          !isAdding &&
          <SecondaryButton
            onClick={() => setIsAdding(true)}
            style={{
              border: 0,
              width: 'fit-content',
              padding: '10px 0px'
            }}>
            + Add tag
          </SecondaryButton>
        }
        {isAdding && <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: 5
        }}>
          <TextInput
            value={newTag}
            onChange={e => setNewTag(e.target.value)}
            placeholder='Enter tag'
            style={{ width: 'fit-content', flexGrow: 1 }}
            autoFocus />
          <PrimaryButton
            onClick={() => {
              if (newTag) {
                setIsAdding(false)
                tags.push(newTag)
                setNewTag(undefined)
              }
            }}
            style={{ fontSize: 14 }}>
            Add
          </PrimaryButton>
          <IconButton
            style={{ padding: 5 }}
            onClick={() => {
              setIsAdding(false)
            }}>
            <Close style={{ width: 15, pointerEvents: 'none' }} />
          </IconButton>
        </div>
        }
      </div>

    </div>
    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10, padding: 15, borderTop: `1px solid ${RADOM_COLORS.GRAY9}` }}>
      <SecondaryButton onClick={props.onClose}
        style={{ fontSize: 14, paddingLeft: 15, paddingRight: 15 }}>Cancel</SecondaryButton>
      <PrimaryButton onClick={() => props.onSave(tags)} style={{ fontSize: 14 }}>Save</PrimaryButton>
    </div>
  </Modal>
})

export default EditTagsModal
