import React, { useEffect, useState } from 'react'
import Radom, { Customer } from '../state/Radom'
import Table, { SortType, Sorts } from '../components/Table'
import { useNavigate } from 'react-router-dom'
import Dots from '../icons/Dots'
import Dropdown, { DropdownItem } from '../components/Dropdown'
import Spinner from '../components//Spinner'
import User from '../state/User'
import TimeText from '../components/TimeText'
import Checkbox from '../components/Checkbox'
import Pagination from '../components/Pagination'
import { observer } from 'mobx-react'
import { Container } from '../components/Animations'
import { PrimaryButton } from '../components/Button'
import PageHeader from '../components/PageHeader'
import Modal from '../components/Modal'
import CustomerForm from '../components/CustomerForm'
import ConfirmButtonModal from '../components/ConfirmButtonModal'
import { errorToast, successToast, useTestMode } from '../util/Util'
import { prodNetworks, testNetworks } from '../util/Managed'
import { Tag, TagsContainer } from './Customers/Customer'

const Customers = observer((): React.ReactElement => {
  const navigate = useNavigate()

  const [customers, setCustomers] = useState<Customer[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [sorts, setSorts] = useState<Sorts>(JSON.parse(localStorage.getItem('customersTableSort') ?? '{}'))

  const pageSize = 20
  const [totalCustomers, setTotalCustomers] = useState(0)
  const [curPage, setCurPage] = useState(0)

  const [testMode] = useTestMode()

  const loadCustomers = async (): Promise<void> => {
    setIsLoading(true)
    let sortType = 'createdAtDescending'
    if (sorts.created === SortType.Ascending) sortType = 'createdAtAscending'
    if (sorts.totalSpend === SortType.Ascending) sortType = 'totalSpendAscending'
    if (sorts.totalSpend === SortType.Descending) sortType = 'totalSpendDescending'
    const res = await Radom.listCustomers(
      curPage * pageSize,
      pageSize,
      testMode ? testNetworks : prodNetworks,
      sortType
    )
    setTotalCustomers(res.total)
    setCustomers(res.data)
    setIsLoading(false)
  }

  useEffect(() => {
    loadCustomers()
  }, [sorts, curPage, testMode])

  const [customerModalOpen, setCustomerModalOpen] = useState(false)

  return <Container>
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PageHeader title="Customers" subTitle="Manage your customers" />

        <PrimaryButton style={{ gap: 8 }} onClick={() => setCustomerModalOpen(true)}>
          <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
          <span>Create customer</span>
        </PrimaryButton>

      </div>

      {
        false &&
        <div style={{ display: 'flex', gap: 10 }}>
          <Dropdown
            selectedContent={<div style={{ fontSize: 14 }}>{'Tags'}</div>}
            dropdownContent={<div>
              {['✅ Verified'].map(s =>
                <DropdownItem
                  key={s}
                  style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: 14 }}
                  onClick={() => {

                  }}>
                  <Checkbox size={15} checked={false} />
                  <span>{s}</span>
                </DropdownItem>
              )}
            </div>} />
        </div>
      }

      <Table
        initialSorts={sorts}
        borderless
        disableOnClickState
        onClickRow={(i) => navigate(`/customer/${customers[i].id}`)}
        onSortChange={(sorts) => {
          localStorage.setItem('customersTableSort', JSON.stringify(sorts))
          setSorts(sorts)
        }}
        headers={[
          <span key="name">Name</span>,
          <span key="email">Email</span>,
          {
            key: 'totalSpend',
            element: <span key="spend">Total spend</span>,
            sortable: true
          },
          <span key="tags">Tags</span>,
          {
            key: 'created',
            element: <span key="created">Created at</span>,
            sortable: true
          },
          <span key="menu"></span>
        ]}
        items={
          customers.map(c => [
            <div key="name">{c.name || c.email}</div>,
            <span key="email">{c.email}</span>,
            <span key="spend">
              {
                Number(c.totalSpend) <= 0
                  ? '-'
                  : User.organization
                    ? new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: User.organization?.defaultCurrency
                    }).format(Number(c.totalSpend))
                    : <Spinner />
              }</span>,
            <TagsContainer key="tags">{
              c.tags.map((t, i) => <Tag key={i}>{t}</Tag>)
            }</TagsContainer>,
            <div key="created">
              <TimeText date={new Date(c.createdAt)} />
            </div>,
            <div key='a' style={{ display: 'flex', flexGrow: 1 }} onClick={e => {
              e.preventDefault()
              e.stopPropagation()
            }}>
              <Dropdown
                key={c.id}
                noChevron
                selectedContent={<Dots width={15} />}
                dropdownContent={
                  <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                    <ConfirmButtonModal
                      title='Delete customer'
                      description='Are you sure you want to delete this customer? This action cannot be undone.'
                      action='Delete customer'
                      onConfirm={async () => {
                        await Radom.deleteCustomer(c.id)
                          .then(() => {
                            successToast('Successfully deleted customer')
                            loadCustomers()
                          })
                          .catch(err => {
                            errorToast(err.response?.data?.error || err.message)
                          })
                      }}
                      ActionComponent={props => <DropdownItem {...props}>Delete customer</DropdownItem>}
                    />
                  </div>
                }
              />
            </div>
          ])
        }
        isLoading={isLoading}
        emptyPlaceholderText='No customers yet'
      />

      {
        !isLoading && <Pagination
          curPage={curPage}
          numPages={Math.ceil(totalCustomers / pageSize)}
          onPageClick={page => setCurPage(page)}
        />
      }
    </div>

    <Modal
      visible={customerModalOpen}
      onClose={() => setCustomerModalOpen(false)}
      title='Create customer'>
      <div style={{ padding: '0px 30px 30px 30px', overflow: 'scroll' }}>
        <CustomerForm onCreateCustomer={() => {
          setCustomerModalOpen(false)
          loadCustomers()
        }}/>
      </div>
    </Modal>
  </Container>
})

export default Customers
