import React, { ReactElement } from 'react'

const Icon = (props: React.SVGProps<SVGSVGElement>): ReactElement =>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="48" height="48" rx="6.85714" fill="url(#paint0_linear_201_26)"/>
    <g clipPath="url(#clip0_201_26)">
      <path d="M18.75 21.75H15V18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.3375 30C19.5168 31.1128 20.998 31.8535 22.5958 32.1294C24.1937 32.4052 25.8374 32.2041 27.3217 31.5512C28.8059 30.8982 30.0648 29.8224 30.9411 28.458C31.8173 27.0937 32.2721 25.5014 32.2486 23.88C32.2251 22.2587 31.7244 20.6803 30.8089 19.3419C29.8934 18.0035 28.6039 16.9646 27.1014 16.355C25.5988 15.7453 23.95 15.5919 22.3608 15.914C20.7716 16.2361 19.3125 17.0194 18.1659 18.1659C17.0625 19.2834 16.1512 20.3372 15 21.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_201_26" x1="48" y1="48" x2="0" y2="0" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF6648"/>
        <stop offset="1" stopColor="#FFAD9C"/>
      </linearGradient>
      <clipPath id="clip0_201_26">
        <rect width="24" height="24" fill="white" transform="translate(12 12)"/>
      </clipPath>
    </defs>
  </svg>

export default Icon
