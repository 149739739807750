import React from 'react'

export function Calendar(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#292929" strokeWidth="2" {...props}>
    <g clipPath="url(#clip0_3_564)">
      <path d="M4 4H20V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V4Z" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4 8H20" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 3V5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 3V5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_3_564">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
}
