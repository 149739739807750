import { observer } from 'mobx-react-lite'
import React from 'react'
import { Container } from '../../components/Animations'
import Profile from './Profile'

const Overview = observer(() => {
  return <Container>
    <Profile />
  </Container>
})

export default Overview
