import React from 'react'

export function NotificationBell(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" {...props}>
    <g clipPath="url(#clip0_3_497)">
      <path d="M6 19V10C6 6.68629 8.68629 4 12 4V4C15.3137 4 18 6.68629 18 10V19M6 19H18M6 19H4M18 19H20" strokeLinejoin="round" />
      <path d="M11 22L13 22" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="12" cy="3" r="1" />
    </g>
    <defs>
      <clipPath id="clip0_3_497">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
}
