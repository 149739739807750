import React, { useEffect, useState } from 'react'
import { PrimaryButton } from '../../components/Button'
import DocsButton from '../../components/DocsButton'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import { InputLabel, TextInput } from '../../components/Input'
import LinkExternal from '../../components/LinkExternal'
import Modal from '../../components/Modal'
import Web3 from '../../state/User'
import { Chains, formatExplorerUrl, getTestChains } from '../../util/Chains'
import { RADOM_COLORS } from '../../util/Constants'
import { Token, Tokens } from '../../util/Tokens'
import { errorToast, shortAddress } from '../../util/Util'
import Radom from '../../state/Radom'
import { ExternalLinkText } from '../Events'

const TestnetFaucets = {
  43113: 'https://faucet.avax.network/',
  11155111: 'https://www.infura.io/faucet',
  80001: 'https://faucet.polygon.technology/',
  1313161555: 'https://aurora.dev/faucet',
  4002: 'https://faucet.fantom.network/'
}

const TestnetTokens = (): React.ReactElement => {
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false)

  const [address, setAddress] = useState('')

  const [closeChainFn, setCloseChainFn] = useState(() => () => { })
  const [selectedChain, setSelectedChain] = useState(Chains.Sepolia)

  const [closeTokenFn, setCloseTokenFn] = useState(() => () => { })
  const [selectedToken, setSelectedToken] = useState<Token>(Object.values(Tokens)[0])

  const [isSendingTokens, setIsSendingTokens] = useState(false)

  const setInitialAddress = async (): Promise<void> => {
    setAddress(await Web3.connectedAccount)
  }

  const [txId, setTxId] = useState('')

  useEffect(() => {
    if (!isDepositModalOpen) {
      setIsSendingTokens(false)
      setTxId('')
    }
  }, [isDepositModalOpen])

  useEffect(() => {
    setInitialAddress()
  }, [])

  return <>
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 20, marginTop: 200 }}>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 10 }}>
        <div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
          <span style={{ fontSize: 24 }}>Testnet tokens</span>
          <DocsButton docsLink='additional-resources/faucet' />
        </div>
        <span style={{ fontSize: 14, color: RADOM_COLORS.GRAY_DARKEST }}>
          Send testnet tokens to an address to test checkout flows.
        </span>
      </div>
      <PrimaryButton onClick={() => setIsDepositModalOpen(true)}>Get testnet tokens</PrimaryButton>
    </div>
    <Modal
      minHeight='initial'
      visible={isDepositModalOpen}
      innerStyles={{ display: 'flex', flexDirection: 'column' }}
      title='Get testnet tokens'
      onClose={() => setIsDepositModalOpen(false)}>

      {
        txId &&
          <div style={{
            padding: '0 30px 30px 30px',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            maxHeight: '500px',
            width: 500,
            gap: 20,
            fontSize: 14
          }}>
            <span>Testnet token transaction has been submitted and will be
              included in the blockchain shortly.</span>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 5, margin: '10px 0' }}>
              <span style={{ opacity: 0.5 }}>Transaction hash</span>
              <ExternalLinkText
                onClick={e => e.stopPropagation()}
                target="_blank" rel="noreferrer"
                href={formatExplorerUrl(
                  selectedChain.name,
                  'tx',
                  txId
                )}>
                {shortAddress(txId ?? '', 18)}
                <LinkExternal stroke={RADOM_COLORS.BLACK} width={13} />
              </ExternalLinkText>
            </div>

            <PrimaryButton onClick={() => setIsDepositModalOpen(false)}
              style={{ width: '100%' }}>
              Close
            </PrimaryButton>
          </div>
      }
      {
        !txId && <>
          <div style={{
            padding: '0 30px',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            maxHeight: '500px',
            gap: 20
          }}>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <div style={{ display: 'flex', gap: 5, justifyContent: 'space-between' }}>
                <InputLabel>Chain</InputLabel>
                {
                  TestnetFaucets[selectedChain.chainId] &&
              <a style={{
                display: 'flex',
                fontSize: 14,
                textDecoration: 'none',
                alignItems: 'center',
                color: RADOM_COLORS.GRAY_DARKEST,
                gap: 3
              }}
              href={TestnetFaucets[selectedChain.chainId]}
              target="_blank"
              rel="noreferrer">
                <span>Faucet</span>
                <LinkExternal style={{ width: 14, height: 14 }} stroke={RADOM_COLORS.GRAY_DARKEST} />
              </a>
                }
              </div>
              <Dropdown
                preferredAlignment='right'
                onCloseFn={fn => setCloseChainFn(() => fn)}
                selectedContent={
                  <div style={{
                    display: 'flex',

                    alignItems: 'center',
                    gap: 8,
                    flexWrap: 'wrap'
                  }}>
                    <div style={{
                      width: 25,
                      height: 25,
                      backgroundImage: `url(${selectedChain.logo})`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat'
                    }} />
                    <span style={{ fontSize: 14 }}>{selectedChain.name}</span>
                  </div>
                }
                dropdownContent={
                  <div>
                    {getTestChains()
                      .filter(t => t.name === 'SepoliaTestnet')
                      .map(c =>
                        <DropdownItem
                          key={c.name}
                          style={{ display: 'flex', alignItems: 'center', gap: 10 }}
                          onClick={() => {
                            // props.onFormChange({ chains: [...props.paymentLink.chains, p.chainId] })
                            setSelectedChain(c as any)
                            closeChainFn()
                          }}>
                          <div style={{
                            width: 25,
                            height: 25,
                            backgroundImage: `url(${c.logo})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                          }} />
                          <span style={{ fontSize: 14 }}>{c.name}</span>
                        </DropdownItem>
                      )}
                  </div>
                }
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <InputLabel>Token</InputLabel>
              <Dropdown
                preferredAlignment='right'
                onCloseFn={fn => setCloseTokenFn(() => fn)}
                selectedContent={
                  <div style={{
                    display: 'flex',

                    alignItems: 'center',
                    gap: 8,
                    flexWrap: 'wrap'
                  }}>
                    <div style={{
                      width: 25,
                      height: 25,
                      backgroundImage: `url(${selectedToken?.logo})`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat'
                    }} />
                    <span style={{ fontSize: 14 }}>{selectedToken?.name}</span>
                  </div>
                }
                dropdownContent={
                  <div>
                    {Object.values(Tokens)
                      .filter(t => !!t.addresses[selectedChain.chainId])
                      .map(t =>
                        <DropdownItem
                          key={t.name}
                          style={{ display: 'flex', alignItems: 'center', gap: 10 }}
                          onClick={() => {
                            setSelectedToken(t)
                            closeTokenFn()
                          }}>
                          <div style={{
                            width: 25,
                            height: 25,
                            backgroundImage: `url(${t.logo})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                          }} />
                          <span style={{ fontSize: 14 }}>{t.name}</span>
                        </DropdownItem>
                      )}
                  </div>
                }
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <InputLabel>Address</InputLabel>
              <TextInput value={address} onChange={e => setAddress(e.target.value)} />
            </div>
          </div>

          <div style={{ padding: 30 }}>
            <PrimaryButton
              isLoading={isSendingTokens}
              onClick={async () => {
                setIsSendingTokens(true)

                const tokenAddress = selectedToken.addresses[selectedChain.chainId]
                Radom.claimTestTokens({
                  network: selectedChain.name,
                  token: tokenAddress,
                  address
                }).then((res) => {
                  setTxId(res.txId)
                }).catch(err => {
                  errorToast(err.response?.data?.error || err.message)
                }).finally(() => {
                  setIsSendingTokens(false)
                })
              }}
              style={{ width: '100%' }}>
              Get tokens
            </PrimaryButton>
          </div>
        </>
      }
    </Modal>
  </>
}

export default TestnetTokens
