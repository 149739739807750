import React, { ReactElement } from 'react'

function S(props: React.SVGProps<SVGSVGElement>): ReactElement {
  return <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect y="0.5" width="48" height="48" rx="6.85714" fill="url(#paint0_linear_23_238)"/>
    <g clipPath="url(#clip0_23_238)">
      <path d="M24 27.5C26.0711 27.5 27.75 25.8211 27.75 23.75C27.75 21.6789 26.0711 20 24 20C21.9289 20 20.25 21.6789 20.25 23.75C20.25 25.8211 21.9289 27.5 24 27.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.9813 31.1909C18.5456 30.0795 19.4066 29.1459 20.469 28.4939C21.5313 27.8418 22.7535 27.4966 24 27.4966C25.2465 27.4966 26.4687 27.8418 27.5311 28.4939C28.5934 29.1459 29.4545 30.0795 30.0188 31.1909" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M28.5 17.75H33" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M32.8753 23C33.1917 24.88 32.9025 26.8119 32.0493 28.5168C31.1961 30.2217 29.8231 31.6113 28.1287 32.485C26.4342 33.3587 24.5059 33.6713 22.6222 33.3776C20.7385 33.084 18.9969 32.1992 17.6489 30.8511C16.3008 29.5031 15.4161 27.7615 15.1224 25.8778C14.8287 23.9941 15.1413 22.0658 16.015 20.3713C16.8887 18.6769 18.2783 17.3039 19.9832 16.4507C21.6881 15.5976 23.62 15.3083 25.5 15.6247" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_23_238" x1="0" y1="0.5" x2="43.3973" y2="46.3064" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFAD9C"/>
        <stop offset="1" stopColor="#FF6648"/>
      </linearGradient>
      <clipPath id="clip0_23_238">
        <rect width="24" height="24" fill="white" transform="translate(12 12.5)"/>
      </clipPath>
    </defs>
  </svg>
}

export default S
