import React, { ReactElement } from 'react'
import { RADOM_COLORS } from '../util/Constants'

interface RadomLogoProps extends React.SVGProps<SVGSVGElement> {
  textColor: string
  logoColor: string
  logoOnly?: boolean
  logoProps?: React.SVGAttributes<SVGPathElement>
}

function RadomLogo({ textColor, logoColor, logoOnly, logoProps, ...props }: RadomLogoProps): ReactElement {
  return <svg width={logoOnly ? '34' : '162'} height="34" viewBox={`0 0 ${logoOnly ? 34 : 162} 34`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.3703 3.94811H3.6825V28.636C3.6825 29.5152 4.39526 30.228 5.2745 30.228H29.9623V5.54011C29.9623 4.66087 29.2496 3.94811 28.3703 3.94811ZM0.498489 0.764099V28.636C0.498489 31.2737 2.63678 33.412 5.2745 33.412H33.1464V5.54011C33.1464 2.90239 31.0081 0.764099 28.3703 0.764099H0.498489Z"
      fill={logoColor}
      {...logoProps} />
    {
      !logoOnly &&
      <g clipPath="url(#clip0_2_14)">
        <path d="M42.1872 3.35787C42.3757 3.35787 42.5512 3.35787 42.7268 3.35787C45.9563 3.35787 49.1859 3.35572 52.4155 3.35895C54.4152 3.3611 56.3978 3.49248 58.2683 4.29584C61.359 5.62256 62.9431 8.28462 62.9065 11.9019C62.8763 14.9118 60.8658 17.184 58.2683 18.2146C57.5242 18.5096 56.7198 18.6496 55.9541 18.8962C55.7517 18.9619 55.5923 19.1601 55.4135 19.2979C55.617 19.4056 55.8109 19.5671 56.0273 19.6113C56.8307 19.775 57.6642 19.8277 58.4471 20.056C59.9192 20.4857 60.8044 21.5637 61.3095 22.9679C62.2248 25.5126 63.1229 28.0637 64.0254 30.6116C64.0663 30.7279 64.0857 30.8518 64.1266 31.0187C63.9629 31.037 63.8304 31.0629 63.698 31.0639C62.3702 31.0672 61.0424 31.0542 59.7146 31.0736C59.3495 31.079 59.1858 30.9821 59.0921 30.5998C58.6054 28.6173 58.0906 26.6422 57.5705 24.6672C56.9502 22.311 55.49 21.0349 52.9679 20.8486C50.8788 20.6946 48.7767 20.7162 46.6811 20.6462C46.2514 20.6322 46.2395 20.8723 46.2395 21.1943C46.246 24.244 46.2439 27.2948 46.2439 30.3446C46.2439 30.8256 45.9976 31.0661 45.5051 31.0661C44.4132 31.0661 43.3212 31.0661 42.1872 31.0661V3.35787V3.35787ZM46.2439 17.1958C46.4786 17.1958 46.6703 17.1958 46.8631 17.1958C48.5139 17.1958 50.1648 17.1743 51.8156 17.2023C53.0745 17.2238 54.2699 16.9675 55.4318 16.5142C58.263 15.4093 59.5563 12.478 58.4116 9.68995C57.6868 7.92386 56.2276 7.06128 54.4217 6.95466C51.8996 6.80605 49.3668 6.85559 46.8394 6.79529C46.3645 6.78452 46.2299 6.92882 46.2331 7.40372C46.2525 10.5439 46.2428 13.6841 46.2428 16.8243C46.2428 16.9266 46.2428 17.03 46.2428 17.1958H46.2439Z" fill={textColor} />
        <path d="M161.474 31.0476H157.745V30.4618C157.745 26.5678 157.751 22.6738 157.74 18.7798C157.739 18.2079 157.697 17.6307 157.616 17.0643C157.347 15.183 156.181 14.1104 154.272 13.951C152.081 13.768 150.191 15.1151 149.578 17.3927C149.357 18.2123 149.244 19.0845 149.236 19.9342C149.198 23.433 149.22 26.9318 149.22 30.4317C149.22 30.6233 149.22 30.8161 149.22 31.0369H145.465C145.465 30.7999 145.465 30.591 145.465 30.3821C145.465 26.6496 145.485 22.9172 145.449 19.1847C145.441 18.2596 145.339 17.3174 145.13 16.4171C144.577 14.0286 142.35 13.6118 140.775 14.0986C138.833 14.6995 137.807 16.1737 137.311 18.027C137.07 18.9316 136.979 19.8976 136.967 20.8377C136.926 24.0317 136.952 27.2258 136.952 30.4198C136.952 30.6126 136.952 30.8053 136.952 31.0638C135.741 31.0638 134.581 31.0702 133.421 31.0519C133.332 31.0509 133.207 30.8947 133.17 30.7859C133.125 30.6578 133.155 30.5027 133.155 30.3584C133.155 24.1136 133.155 17.8687 133.155 11.625C133.155 11.1526 133.387 10.9167 133.851 10.9174C134.855 10.9174 135.859 10.9174 136.91 10.9174C137.019 12.004 136.932 13.0454 136.699 14.077C136.644 14.3215 136.546 14.5605 136.52 14.8071C136.498 15.0118 136.556 15.225 136.578 15.4339C136.749 15.2853 136.971 15.1668 137.08 14.9816C137.371 14.4895 137.579 13.9489 137.879 13.4632C139.248 11.2524 141.945 10.1561 144.453 10.8065C146.219 11.2642 147.357 12.4326 147.994 14.1147C148.077 14.3322 148.104 14.5767 148.217 14.7748C148.303 14.9256 148.481 15.1087 148.627 15.1162C148.774 15.1248 148.987 14.9676 149.074 14.8244C149.362 14.3505 149.57 13.8272 149.871 13.3641C151.221 11.2944 153.106 10.3445 155.593 10.673C156.853 10.8388 158.017 11.177 159.045 12.0019C160.382 13.0734 161.007 14.4959 161.27 16.1059C161.423 17.0384 161.48 17.9958 161.485 18.9424C161.509 22.8181 161.496 26.6938 161.495 30.5706C161.495 30.7106 161.48 30.8506 161.469 31.0476H161.474Z" fill={textColor}/>
        <path d="M107.283 31.0652C106.115 31.0652 105.003 31.076 103.893 31.049C103.778 31.0458 103.576 30.8304 103.569 30.7066C103.51 29.5199 103.551 28.3428 103.959 27.2024C104.029 27.0064 103.944 26.7544 103.931 26.5283C103.752 26.6844 103.482 26.805 103.408 27.001C102.662 28.9545 101.311 30.3114 99.3391 30.937C96.2409 31.9192 93.4054 31.3452 90.9964 29.1322C88.9471 27.2498 88.0781 24.7837 87.8563 22.0797C87.6366 19.4036 88.1438 16.8697 89.5685 14.5544C91.27 11.7911 94.2594 10.36 97.4696 10.6464C99.3757 10.8166 101.015 11.5478 102.261 13.0069C102.717 13.5411 103.004 14.2206 103.363 14.8366C103.436 14.9593 103.457 15.1252 103.554 15.2178C103.665 15.3233 103.834 15.3685 103.977 15.4396C104.014 15.2964 104.112 15.137 104.078 15.0121C103.007 10.9975 103.766 6.91069 103.588 2.86269C103.588 2.84761 103.604 2.83254 103.635 2.77438H107.284V31.0652H107.283ZM91.5467 20.9673C91.6501 21.7286 91.6953 22.5029 91.8709 23.247C92.2069 24.675 92.8562 25.9801 94.0623 26.846C96.6501 28.7025 100.135 28.0919 101.953 25.7034C103.874 23.1781 104.084 20.3664 102.757 17.5417C101.507 14.8796 98.9277 13.428 95.7606 14.2809C94.968 14.4941 94.2723 14.8409 93.6822 15.4364C92.1584 16.9731 91.6017 18.8576 91.5457 20.9683L91.5467 20.9673Z" fill={textColor}/>
        <path d="M70.3828 17.1755H66.743C66.8625 16.0663 67.2136 15.0713 67.7714 14.1473C68.6361 12.7161 69.9219 11.7911 71.4737 11.2645C74.153 10.3556 76.8613 10.3319 79.5546 11.2203C82.1779 12.0861 83.6619 13.9427 84.0775 16.6435C84.2272 17.6149 84.3134 18.6045 84.3209 19.5866C84.3489 23.2653 84.3328 26.9439 84.3317 30.6236C84.3317 30.7475 84.3177 30.8713 84.3091 31.0296H80.5723C80.5346 29.691 80.4829 28.3719 80.8943 27.0828C80.9707 26.8448 81.0397 26.6004 81.0655 26.3538C81.0773 26.2407 80.974 26.1147 80.9234 25.9952C80.8006 26.0695 80.624 26.1147 80.5637 26.2235C80.2783 26.7393 80.0726 27.3057 79.7517 27.7957C77.9329 30.5709 75.2805 31.609 72.0714 31.3085C69.9187 31.1071 68.0847 30.1993 66.9099 28.3126C64.8412 24.9883 66.5125 20.8315 70.3344 19.6599C72.1435 19.1053 74.0151 19.1193 75.8738 19.0008C76.857 18.9384 77.8424 18.8641 78.8202 18.7434C80.9406 18.4818 80.8254 16.9634 80.1028 15.6269C79.6268 14.7461 78.7491 14.3648 77.8176 14.1613C76.2023 13.8081 74.5773 13.779 72.9706 14.2087C71.6191 14.5705 70.7953 15.4643 70.4937 16.8341C70.4711 16.9386 70.428 17.0387 70.3828 17.1744V17.1755ZM80.498 20.2694C79.854 20.9715 79.097 21.286 78.2764 21.4357C77.0789 21.6532 75.8685 21.7986 74.6742 22.029C73.5736 22.2422 72.4289 22.3898 71.4059 22.813C69.6161 23.5517 69.3113 25.9909 70.7662 27.2164C71.4608 27.8022 72.2975 27.9573 73.1752 27.9756C77.8909 28.0789 80.6843 24.5252 80.4991 20.2694H80.498Z" fill={textColor}/>
        <path d="M129.966 20.9632C129.993 23.5035 129.376 25.8565 127.783 27.8864C126.247 29.8442 124.208 30.9513 121.76 31.2722C119.733 31.5371 117.755 31.3271 115.889 30.4225C113.572 29.2993 112.03 27.4708 111.198 25.0704C110.2 22.1908 110.215 19.2875 111.38 16.4542C112.939 12.6625 116.251 10.5368 120.371 10.6046C123.516 10.6563 126.126 11.8172 128.014 14.392C129.199 16.0073 129.784 17.8542 129.96 19.8357C129.994 20.2083 129.965 20.5873 129.965 20.9632H129.966ZM126.232 20.7025C126.243 19.6494 126.005 18.3905 125.393 17.221C124.362 15.2481 122.758 14.1379 120.504 14.0646C118.228 13.9903 116.416 14.8766 115.328 16.8979C113.808 19.7172 113.835 22.5925 115.488 25.3482C117.506 28.7135 122.146 28.4798 124.157 26.4413C125.658 24.9196 126.243 23.0534 126.232 20.7025Z" fill={textColor}/>
      </g>
    }
    <defs>
      <clipPath id="clip0_2_14">
        <rect width="119.315" height="28.6268" fill={textColor} transform="translate(42.186 2.77345)"/>
      </clipPath>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stopColor={RADOM_COLORS.ORANGE} />
        <stop offset="100%" stopColor={RADOM_COLORS.PURPLE} />
      </linearGradient>
    </defs>
  </svg>
}

export default RadomLogo
