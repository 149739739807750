import React, { ReactElement } from 'react'

function Shield(props: React.SVGProps<SVGSVGElement>): ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0.03 24 24" stroke="#08053F" {...props}>
    <g clipPath="url(#clip0_1_173)">
      <path d="M3.75 10.795V5.28345C3.75 5.08453 3.82902 4.89377 3.96967 4.75312C4.11032 4.61247 4.30109 4.53345 4.5 4.53345H19.5C19.6989 4.53345 19.8897 4.61247 20.0303 4.75312C20.171 4.89377 20.25 5.08453 20.25 5.28345V10.7931C20.25 18.685 13.5647 21.2997 12.2306 21.7431C12.0812 21.7947 11.9188 21.7947 11.7694 21.7431C10.4353 21.3016 3.75 18.6897 3.75 10.795Z" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.25 12.7834L10.5 15.0334L15.75 9.78345" strokeLinecap="square" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1_173">
        <rect width="24" height="24" fill="white" transform="translate(0 0.0334473)"/>
      </clipPath>
    </defs>
  </svg>
}

export default Shield
