import { observer } from 'mobx-react'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'
import { Chevron } from '../icons/Chevron'

interface IProps {
  curPage: number
  numPages: number
  style?: React.CSSProperties
  onPageClick: (page: number) => void
}

export const PaginationList = styled.div`
  display: flex;
  color: ${RADOM_COLORS.ORANGE_MED};
  gap: 8px;
  font-size: 14px;
  user-select: none;
  justify-content: center;
`

export const PaginationItem = styled.div<{ $active?: boolean }>`
  border: 1px solid ${RADOM_COLORS.GRAY_DARK};
  padding: 10px 14px;
  border-radius: 5px;
  transition: 0.2s ease all;
  background-color: ${({ $active }) => $active ? RADOM_COLORS.GRAY_LIGHTEST : 'white'};
  box-shadow: 0 1px 1px rgb(226 228 239 / 50%);
  height: 100%;
  color: ${RADOM_COLORS.BLACK};
  cursor: pointer;

  :hover {
    border-color: ${RADOM_COLORS.GRAY_DARK};
    box-shadow: 0 1px 1px ${RADOM_COLORS.GRAY_MED};
  }

  :active {
    opacity: 0.75;
  }
`

const Ellipses = styled.div`
  font-size: 18px;
  text-align: center;
  width: 24px;
  height: 24px;
  line-height: 28px;
  color: ${RADOM_COLORS.GRAY_DARKEST};
`

// Assumes pages in props are 0-indexed
const Pagination = observer((props: IProps): ReactElement => {
  const onPageClick = (page: number): void => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    props.onPageClick(page)
  }

  if (props.numPages < 2) {
    return <></>
  }

  return <>
    <PaginationList>
      {props.curPage !== 0 && (
        <PaginationItem onClick={() => onPageClick(props.curPage - 1)}>
          <Chevron style={{ rotate: '90deg', marginBottom: 2 }} />
        </PaginationItem>
      )}
      <PaginationItem onClick={() => onPageClick(0)} $active={props.curPage === 0}>
        {1}
      </PaginationItem>
      {props.curPage > 2 && (
        <Ellipses>...</Ellipses>
      )}
      {props.curPage === props.numPages - 1 && props.numPages > 3 && (
        <PaginationItem onClick={() => onPageClick(props.curPage - 2)}>
          {props.curPage - 1}
        </PaginationItem>
      )}
      {props.curPage > 1 && (
        <PaginationItem onClick={() => onPageClick(props.curPage - 1)}>
          {props.curPage}
        </PaginationItem>
      )}
      {props.curPage !== 0 && props.curPage !== props.numPages - 1 && (
        <PaginationItem
          onClick={() => onPageClick(props.curPage)}
          $active={props.curPage !== 0 && props.curPage !== props.numPages - 1}>
          {props.curPage + 1}
        </PaginationItem>
      )}
      {props.curPage < props.numPages - 2 && (
        <PaginationItem onClick={() => onPageClick(props.curPage + 1)}>
          {props.curPage + 2}
        </PaginationItem>
      )}
      {props.curPage === 0 && props.numPages > 3 && (
        <PaginationItem onClick={() => onPageClick(props.curPage + 2)}>
          {props.curPage + 3}
        </PaginationItem>
      )}
      {props.curPage < props.numPages - 3 && (
        <Ellipses>...</Ellipses>
      )}
      <PaginationItem
        onClick={() => onPageClick(props.numPages - 1)}
        $active={props.curPage === props.numPages - 1}>
        {props.numPages}
      </PaginationItem>
      {props.curPage !== props.numPages - 1 && (
        <PaginationItem onClick={() => onPageClick(props.curPage + 1)}>
          <Chevron style={{ rotate: '-90deg' }} />
        </PaginationItem>
      )}
    </PaginationList>
  </>
})

export default Pagination
