import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { PrimaryButton } from '../components/Button'
import { RADOM_COLORS } from '../util/Constants'
import { Link } from 'react-router-dom'
import Table from '../components/Table'
import { errorToast, successToast, formatCurrency } from '../util/Util'
import Radom, { IDiscountCode } from '../state/Radom'
import Dots from '../icons/Dots'
import Dropdown, { DropdownItem } from '../components/Dropdown'
import PageHeader from '../components/PageHeader'
import ConfirmButtonModal from '../components/ConfirmButtonModal'
import TimeText from '../components/TimeText'
import { Container } from '../components/Animations'
import { StatusBadge } from './CheckoutSessions'
import { isBefore } from 'date-fns'

const ProductsView = observer(() => {
  const [discountCodes, setDiscountCodes] = useState([] as IDiscountCode[])
  const [isLoadingDiscountCodes, setIsLoadingDiscountCodes] = useState(false)

  const loadDiscountCodes = (): void => {
    setIsLoadingDiscountCodes(true)
    Radom.listDiscountCodes().then((codes) => {
      setDiscountCodes(codes)
    }).finally(() => setIsLoadingDiscountCodes(false))
  }

  useEffect(() => {
    loadDiscountCodes()
  }, [])

  return <Container>
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <div style={{
        display: 'flex',

        justifyContent: 'space-between'
      }}>
        <PageHeader title='Discount codes' subTitle='Create and manage discount codes' />
        <Link key="createDiscountCode" to="/discounts/create" style={{ textDecoration: 'none' }}>
          <PrimaryButton style={{ gap: 8 }}>
            <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
            <span>Create discount code</span>
          </PrimaryButton>
        </Link>
      </div>
      <div style={{
        paddingTop: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        rowGap: 10
      }}>
        <Table
          borderless
          headers={['Code', 'Status', 'Details', 'Products', 'Starts at', 'Expires at', ''].map(h => <span key={h}>{h}</span>)}
          items={discountCodes
            .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
            .map((d, i) => {
              let status = <StatusBadge status='active'>Active</StatusBadge>
              if (d.startsAt && isBefore(new Date(), new Date(d.startsAt))) {
                status = <StatusBadge status='pending'>Pending</StatusBadge>
              }
              if (d.expiresAt && isBefore(new Date(d.expiresAt), new Date())) {
                status = <StatusBadge status='expired'>Expired</StatusBadge>
              }

              return [
                <div key="1">
                  <span style={{
                    padding: '5px 10px',
                    borderRadius: 5,
                    backgroundColor: RADOM_COLORS.GRAY_MED,
                    color: RADOM_COLORS.BLACK
                  }}>{d.code}</span>
                </div>,
                <div key="status">
                  {
                    status
                  }
                </div>,
                <div key="2">
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>{
                      d.model.modelType === 'percentage'
                        ? new Intl.NumberFormat('en-US', {
                          style: 'percent',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }).format(d.model.amountOff / 100)
                        : formatCurrency(d.model.amountOff, 'USD')
                    }</span>
                    <span style={{
                      fontSize: 12,
                      color: RADOM_COLORS.GRAY_DARKER
                    }}>
                      {d.model.modelType === 'percentage' ? 'Percentage' : 'Fixed'}
                    </span>
                  </div>
                </div>,
                <div key="2">{d.products.length}</div>,
                <div key="starts">
                  {
                    d.startsAt
                      ? <TimeText date={new Date(d.startsAt)} />
                      : <>-</>
                  }
                </div>,
                <div key="created">
                  {
                    d.expiresAt
                      ? <TimeText date={new Date(d.expiresAt)} />
                      : <>-</>
                  }
                </div>,
                <div key={`${i}${d.code}`} style={{ display: 'flex' }}>
                  <Dropdown
                    noChevron
                    selectedContent={<Dots width={15} />}
                    onCloseFn={f => () => { }}
                    dropdownContent={
                      <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                        <ConfirmButtonModal
                          title='Delete discount code'
                          description='Are you sure you want to delete this dsicount code? This action cannot be undone.'
                          action='Delete discount code'
                          onConfirm={async () => {
                            await Radom.deleteDiscountCode(d.id)
                              .then(() => {
                                successToast('Successfully deleted discount code')
                                loadDiscountCodes()
                              })
                              .catch(err => {
                                errorToast(err.response?.data?.error || err.message)
                              })
                          }}
                          ActionComponent={props => <DropdownItem {...props}>Delete discount code</DropdownItem>}
                        />
                      </div>
                    }
                  />
                </div>
              ]
            })}
          isLoading={isLoadingDiscountCodes}
          emptyPlaceholderText="No discount codes yet"
        />
      </div>
    </div>
  </Container>
})

export default ProductsView
