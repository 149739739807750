import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import { RADOM_COLORS } from '../../util/Constants'
import { errorToast, infoToast, successToast } from '../../util/Util'
import Radom, { IWebhook, IWebhookMessage } from '../../state/Radom'
import Spinner from '../../components/Spinner'
import Table from '../../components/Table'
import Pagination from '../../components/PaginationDynamic'
import PageHeader from '../../components/PageHeader'
import TimeText from '../../components/TimeText'
import Modal from '../../components/Modal'
import ConfirmButtonModal from '../../components/ConfirmButtonModal'
import { IconButton } from '../../components/Button'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import Close from '../../icons/Close'
import Copy from '../../icons/Copy'
import Dots from '../../icons/Dots'
import { Container } from '../../components/Animations'
import JSONPretty from 'react-json-pretty'
import { StatusBadge } from '../CheckoutSessions'
import BreadCrumbs from '../../components/Breadcrumbs'
import Checkbox from '../../components/Checkbox'

enum WebhookMessageFilter {
  Failed = 'Failed'
}

interface ViewMessageForm {
  webhookMessage?: IWebhookMessage
}

const ViewWebhook = observer((): ReactElement => {
  const params = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingMessages, setIsLoadingMessages] = useState(true)

  const [webhook, setWebhook] = useState<IWebhook | undefined>(undefined)

  const [webhookMessages, setWebhookMessages] = useState<IWebhookMessage[]>([])
  const pageSize = 100
  const [curPage, setCurPage] = useState(0)
  const [viewMessageForm, updateViewMessageForm] = useReducer((p: ViewMessageForm, n: Partial<ViewMessageForm>) => {
    return { ...p, ...n }
  }, {
    webhookMessage: undefined
  })

  const loadWebhook = (id: string): void => {
    setIsLoading(true)
    Radom.getWebhook(id)
      .then(w => setWebhook(w))
      .catch(err => errorToast(err.message))
      .finally(() => setIsLoading(false))
  }

  const loadWebhookMessages = (webhookId: string, page: number): void => {
    setIsLoadingMessages(true)
    Radom.getWebhookMessages(webhookId, pageSize, page * pageSize)
      .then(wfs => {
        setWebhookMessages(wfs)
        setCurPage(page)
      })
      .catch(err => errorToast(err.message))
      .finally(() => setIsLoadingMessages(false))
  }

  useEffect(() => {
    if (params.webhookId) {
      loadWebhook(params.webhookId)
      loadWebhookMessages(params.webhookId, 0)
    }
  }, [])

  const webhookMessageRows = (): IWebhookMessage[] => {
    if (!webhookMessages) return []
    return [...webhookMessages]
      .sort((a, b) =>
        new Date(b.nextDeliveryAttemptTimestamp).getTime() - new Date(a.nextDeliveryAttemptTimestamp).getTime())
  }

  const onPageClick = (i: number): void => {
    if (webhook) {
      loadWebhookMessages(webhook.id, i)
    }
  }

  const [webhookMessageFilter, setWebhookMessageFilter] = useState<WebhookMessageFilter>()

  if (isLoading) {
    return <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 140,
      gap: 20
    }}>
      <Spinner />
    </div>
  }

  if (webhook) {
    return <Container>

      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <BreadCrumbs items={[
          { to: '/developers/webhooks', item: 'Webhooks' },
          { to: '.', item: webhook.id }
        ]} />

        <div style={{ display: 'flex', gap: 10 }}>

          <Dropdown
            selectedContent={<div style={{ fontSize: 14 }}>{webhookMessageFilter || 'Message status'}</div>}
            dropdownContent={<div>
              {[WebhookMessageFilter.Failed].map(s =>
                <DropdownItem
                  key={s}
                  style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: 14 }}
                  onClick={() => {
                    if (s === webhookMessageFilter) {
                      setWebhookMessageFilter(undefined)
                    } else {
                      setWebhookMessageFilter(s)
                    }
                  }}>
                  <Checkbox size={15} checked={webhookMessageFilter === s}/>
                  <span>{s}</span>
                </DropdownItem>
              )}
            </div>} />

        </div>

        <Table
          isLoading={isLoadingMessages}
          emptyPlaceholderText={curPage === 0 ? 'No webhook messages' : 'No more webhook messages'}
          headers={[
            <span key="id">Message ID</span>,
            <span key="type">Event type</span>,
            <span key="status">Status</span>,
            <span key="res">Response code</span>,
            <span key="retryTime">Last sent at</span>,
            <span key="retries">Retry attempts</span>,
            <span key="createdTime">Created at</span>,
            <span key="action"></span>
          ]}
          items={
            webhookMessageRows().filter(message => webhookMessageFilter === 'Failed' ? message.messageStatus === 'Failed' : true).map(message => [
              <div key="id">{message.id}</div>,
              <div key="type">{message.messagePayload.eventType}</div>,
              <div key="status" style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <StatusBadge status={message.messageStatus.toLowerCase() as any}>
                  {message.messageStatus}
                </StatusBadge>
              </div>,
              <div key="responseCode">
                {message.deliveryResponseStatusCode ? <span>{message.deliveryResponseStatusCode}</span> : '-'}
              </div>,
              <TimeText key="retryTime" date={new Date(message.nextDeliveryAttemptTimestamp)} />,
              <div key="retries">{message.numFailedDeliveryAttempts}</div>,
              <TimeText key="createdTime" date={new Date(message.createdAt)} />,
              <div key="action" onClick={e => e.stopPropagation()} style={{ display: 'flex' }}>
                {
                  <Dropdown
                    noChevron
                    selectedContent={<Dots width={15}/>}
                    dropdownContent={
                      <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                        <DropdownItem
                          onClick={() => {
                            updateViewMessageForm({ webhookMessage: message })
                          }}>View
                        </DropdownItem>
                        <ConfirmButtonModal
                          title='Resend webhook message'
                          description='Resend webhook message.'
                          action='Resend webhook message'
                          onConfirm={async () => {
                            await Radom.resendWebhookMessage(message.webhookId, message.id)
                              .then(() => {
                                successToast('Resending webhook message')
                                loadWebhookMessages(message.webhookId, curPage)
                              })
                              .catch(err => {
                                errorToast(err.response?.data?.error || err.message)
                              })
                          }}
                          ActionComponent={props => <DropdownItem {...props}>Resend message</DropdownItem>}
                        />
                      </div>
                    }
                  />
                }
              </div>
            ])
          }
        />

        <Pagination
          curPage={curPage}
          pageSize={pageSize}
          curPageSize={webhookMessages.length}
          onPageClick={onPageClick}
        />

        <Modal
          visible={viewMessageForm.webhookMessage !== undefined}
          onClose={() => updateViewMessageForm({ webhookMessage: undefined })}
          innerStyles={{ padding: 40, minHeight: 'unset' }}>
          {
            viewMessageForm.webhookMessage &&
            <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <PageHeader title="Webhook message" subTitle={`${viewMessageForm.webhookMessage.id}`} />
                <IconButton type="button" onClick={() => updateViewMessageForm({ webhookMessage: undefined })}>
                  <Close style={{ width: 15, height: 15 }} />
                </IconButton>
              </div>
              <div style={{
                display: 'flex',
                border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
                backgroundColor: RADOM_COLORS.GRAY_LIGHTEST,
                justifyContent: 'space-between',
                fontSize: 14,
                position: 'relative',
                padding: '10px 20px',
                borderRadius: 5
              }}>
                <JSONPretty
                  data={viewMessageForm.webhookMessage.messagePayload}
                  theme={{
                    main: 'color:gray;overflow:auto;',
                    key: 'color:#f92672;',
                    string: 'color:black;',
                    value: 'color:#a6e22e;',
                    boolean: 'color:#ac81fe;'
                  }}
                  style={{ maxWidth: '100%', maxHeight: '50vh', overflow: 'scroll' }} />

                <IconButton style={{ padding: 10, position: 'absolute', top: 10, right: 10 }}>
                  <Copy style={{ width: 20 }} onClick={() => {
                    if (viewMessageForm.webhookMessage) {
                      navigator.clipboard.writeText(
                        JSON.stringify(viewMessageForm.webhookMessage.messagePayload, null, 2)
                      )
                      infoToast('Message payload copied to clipboard')
                    }
                  }} />
                </IconButton>
              </div>
            </div>
          }
        </Modal>
      </div>

    </Container>
  }

  return <div style={{ fontSize: 14, marginTop: 140, maxWidth: 'max-content', margin: 'auto' }}>
    <span>Webhook not found</span>
  </div>
})

export default ViewWebhook
