import React, { ReactElement, useMemo, useReducer, useState } from 'react'
import { PrimaryButton } from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import { TextAreaWithLabel } from '../../components/Input'
import { RADOM_COLORS } from '../../util/Constants'

export interface FiatVerificiationFormData {
  description: string
  isDao: boolean
  transmitsCustomerFunds: boolean
  complianceScreeningExplanation?: string
}

interface FiatVerificationFormProps {
  onSubmit: (data: FiatVerificiationFormData) => Promise<void>
}

const FiatVerificationForm = (props: FiatVerificationFormProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)
  const [fiatForm, updateFiatForm] = useReducer((prev, next) => { return { ...prev, ...next } }, {
    description: '',
    isDao: false,
    transmitsCustomerFunds: false,
    complianceScreeningExplanation: null
  })

  const disabled = useMemo(() =>
    fiatForm.name === '' ||
    fiatForm.description === '' ||
    (fiatForm.transmitsCustomerFunds && fiatForm.complianceScreeningExplanation === null),
  [fiatForm])

  const onSubmit = async (): Promise<void> => {
    setIsLoading(true)
    await props.onSubmit(fiatForm)
    setIsLoading(false)
  }

  return (
    <div style={{ padding: '0 30px' }}>
      <div style={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'start'
      }}>
        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', rowGap: 20 }}>
          <TextAreaWithLabel
            label='Description'
            placeholder='Enter a brief business description'
            required
            value={fiatForm.description}
            disabled={isLoading}
            onChange={e => {
              const el = (e.target as HTMLInputElement)
              updateFiatForm({ description: el.value })
            }}
          />
          <div
            onClick={() => updateFiatForm({ isDao: !fiatForm.isDao })}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              cursor: 'pointer',
              userSelect: 'none',
              color: RADOM_COLORS.GRAY_DARKEST,
              fontSize: '14px'
            }}>
            <Checkbox
              checked={fiatForm.isDao}
              disabled={isLoading}
              onClick={() => updateFiatForm({ isDao: !fiatForm.isDao })}
            />
            <span>Decentralized Autonomous Organization</span>
          </div>
          <div
            onClick={() => updateFiatForm({ transmitsCustomerFunds: !fiatForm.transmitsCustomerFunds })}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              cursor: 'pointer',
              userSelect: 'none',
              color: RADOM_COLORS.GRAY_DARKEST,
              fontSize: '14px'
            }}>
            <Checkbox
              checked={fiatForm.transmitsCustomerFunds}
              disabled={isLoading}
              onClick={() => updateFiatForm({ transmitsCustomerFunds: !fiatForm.transmitsCustomerFunds })}
            />
            <span>Transmits customer funds</span>
          </div>
          {fiatForm.transmitsCustomerFunds && (
            <TextAreaWithLabel
              label="Compliance Screening Explanation"
              placeholder="An explanation of types of compliance screening (KYC, KYB, or AML) the customer performs before transmitting funds on behalf of its customers"
              required
              value={fiatForm.complianceScreeningExplanation}
              disabled={isLoading}
              onChange={e => {
                const el = (e.target as HTMLInputElement)
                updateFiatForm({ complianceScreeningExplanation: el.value })
              }}
            />
          )}
        </div>
      </div>
      <div style={{
        borderRadius: 15,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        padding: '30px 0'
      }}>
        <PrimaryButton
          disabled={disabled}
          isLoading={isLoading}
          style={{ width: '100%' }}
          onClick={onSubmit}
        >
          Submit
        </PrimaryButton>
      </div>
    </div>
  )
}

export default FiatVerificationForm
