export const STAGE = process.env.STAGE ?? 'local'
export const IS_LOCAL = STAGE === 'local'
export const IS_DEV = STAGE === 'dev'
export const IS_TEST = STAGE === 'test'
export const IS_PROD = STAGE === 'prod'

const API_BASE_URLS = {
  local: 'http://localhost:3000',
  // local: 'https://api.radom.com',
  dev: 'https://api.dev.radom.network',
  test: 'https://api.test.radom.network',
  prod: 'https://api.radom.com'
}

const PAY_BASE_URLS = {
  local: 'http://localhost:1235',
  // local: 'https://pay.radom.com',
  dev: 'https://pay.dev.radom.network',
  test: 'https://pay.test.radom.network',
  prod: 'https://pay.radom.com'
}

export const API_BASE_URL = API_BASE_URLS[STAGE]
export const PAY_BASE_URL = PAY_BASE_URLS[STAGE]

export enum RADOM_COLORS {
  BLACK = '#282828',
  BLUE = 'rgb(7, 7, 40)',
  NEW_BLUE = '#3464E6',
  BLUE_ALT = '#0E259C',
  BLUE_MED = '#0F1D4B',
  PURPLE = '#9F76EA',
  ORANGE = '#FF6648',
  ORANGE_MED = '#FF8972',
  ORANGE_LIGHT = '#FFDBD4',
  ORANGE_LIGHTEST = '#FFF2EFAA',
  SUCCESS = '#36AF67',
  SUCCESS_LIGHT = '#d8f3e3',
  WARNING = '#F79009',
  WARNING_LIGHT = '#FEF0C7',
  INFO = '#5490EC',
  INFO_LIGHT = '#d1e1fa',
  ERROR = '#FF5252',
  ERROR_LIGHT = '#ffdfdf',
  GRAY_DARKEST = '#636576',
  GRAY_DARKER = '#9B9EB1',
  GRAY_DARK = '#E2E4EF',
  GRAY_MED = '#EFF0F8',
  GRAY_LIGHTEST = '#F8F8FB',
  INPUT_PLACEHOLDER = '#9ba3af',
  NICE_GREEN = '#4bb69c',
  NICE_BLUE = '#07053f',
  NEW_GRAY_1 = '#33324F',
  DARK_BLUE = '#07053f',
  BLUE4 = '#3464e6',
  BLUE5 = '#618afa',
  BLUE7 = '#e7ecff',
  GRAY4 = '#8D91AC',
  GRAY8 = '#E3E5F0',
  GRAY9 = '#eff0f8',
  RED50 = '#ffecf0',
  RED300 = '#ffa9b9',
  RED900 = '#dc082e',
  INPUT_DEFAULT = 'rgb(239 240 248 / 60%)'
}

export enum WINDOW_SIZE {
  MOBILE_SMALL = '320px',
  MOBILE_MEDIUM = '375px',
  MOBILE_LARGE = '425px',
  TABLET = '768px',
  LAPTOP = '1024px',
  LAPTOP_LARGE = '1440px',
  DESKTOP = '2560px'
}

export const RADOM_TOKEN_LOCAL_STORAGE_KEY = 'radomToken'
export const RADOM_TOKEN_EXPIRY_LOCAL_STORAGE_KEY = 'radomTokenExpiry'

export const EXPLORER_URLS: { [key: string]: string } = {
  RadomDevNet: 'http://localhost:9545',
  GoerliTestnet: 'https://goerli.etherscan.io',
  PolygonTestnet: 'https://mumbai.polygonscan.com',
  FantomTestnet: 'https://testnet.ftmscan.com',
  SepoliaTestnet: 'https://sepolia.etherscan.io',
  BitcoinTestnet: 'https://mempool.space/testnet',
  Bitcoin: 'https://mempool.space',
  AuroraTestnet: 'https://explorer.testnet.aurora.dev',
  Aurora: 'https://explorer.aurora.dev',
  Arbitrum: 'https://arbiscan.io',
  Ethereum: 'https://etherscan.io',
  Fantom: 'https://ftmscan.com',
  AvalancheTestnet: 'https://testnet.snowtrace.io',
  Avalanche: 'https://snowtrace.io',
  ScrollTestnet: 'https://blockscout.scroll.io',
  Polygon: 'https://polygonscan.com',
  BNB: 'https://bscscan.com',
  BNBTestnet: 'https://testnet.bscscan.com',
  PolkadotTestnet: 'https://westend.subscan.io',
  Polkadot: 'https://polkadot.subscan.io',
  TronTestnet: 'https://nile.tronscan.org/#',
  Tron: 'https://tronscan.org/#',
  Base: 'https://basescan.org',
  Solana: 'https://solscan.io',
  SolanaDevnet: 'https://solscan.io',
  PolkadotAssetHub: 'https://assethub-polkadot.subscan.io',
  PolkadotAssetHubTestnet: 'https://assethub-westend.subscan.io'
}
