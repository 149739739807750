import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { Container } from '../components/Animations'
import { useParams } from 'react-router-dom'
import Spinner from '../components/Spinner'
import Radom, { ISubscription } from '../state/Radom'
import BreadCrumbs from '../components/Breadcrumbs'
import { Section, SectionContent, SectionItem, SubTitle } from '../components/PaymentDetails'
import { StatusBadge } from './CheckoutSessions'
import { formatCurrency, successToast } from '../util/Util'
import ConfirmButtonModal from '../components/ConfirmButtonModal'
import Dropdown, { DropdownItem } from '../components/Dropdown'
import Dots from '../icons/Dots'
import PaymentMethodDisplay from '../components/PaymentMethodDisplay'
import TimeText from '../components/TimeText'

const Subscription = observer((): ReactElement => {
  const params = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [subscription, setSubscription] = useState<ISubscription>()

  const fetchSubscription = async (): Promise<void> => {
    setIsLoading(true)
    if (params.subscriptionId) {
      Radom.getSubscription(params.subscriptionId)
        .then(s => {
          setSubscription(s)
          setIsLoading(false)
        })
        .catch(() => {})
    }
  }

  useEffect(() => {
    fetchSubscription()
  }, [params.subscriptionId])

  if (isLoading || !subscription) {
    return <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 140,
      gap: 20
    }}>
      <Spinner />
    </div>
  }

  return <Container style={{ flexDirection: 'column', fontSize: 14, gap: 30 }}>

    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

      <BreadCrumbs items={[
        { item: 'Subscriptions', to: '/subscriptions' },
        { item: subscription.id, to: '.' }
      ]} />
      {
        subscription.status === 'active' &&
        <Dropdown
          noChevron
          selectedContent={<Dots width={15} />}
          dropdownContent={
            <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
              <ConfirmButtonModal
                title='Cancel subscription'
                description='Are you sure you want to cancel this subscription?'
                action='Cancel subscription'
                onConfirm={async () => {
                  Radom.cancelSubscription(subscription.id)
                    .then(() => successToast('Cancelled subscription'))
                    .then(fetchSubscription)
                }}
                ActionComponent={props => <DropdownItem {...props}>Cancel subscription</DropdownItem>}
              />
            </div>
          }
        />
      }
    </div>

    <Section>
      <SubTitle style={{ fontSize: 18 }}>
        Subscription details
      </SubTitle>

      <SectionContent numColumns={3}>
        <SectionItem>
          <SubTitle>ID</SubTitle>
          <div style={{ fontSize: 14 }}>
            {subscription.id}
          </div>
        </SectionItem>

        <SectionItem>
          <SubTitle>Subscription type</SubTitle>
          <span>{subscription.subscriptionType.automatedEVMSubscription ? 'Auto renew' : 'Email invoice'}</span>
        </SectionItem>

        <SectionItem>
          <SubTitle>Amount</SubTitle>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <PaymentMethodDisplay
              method={{ network: subscription.network, token: subscription.token }}
              excludeName
              disableTooltip={false} />
            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              {formatCurrency(Number(subscription.amount), subscription.currency)}
              <span style={{
                fontWeight: 300,
                opacity: 0.75,
                display: 'inline-block'
              }}>
                {subscription.period}
              </span>
            </div>
          </div>
        </SectionItem>

        <SectionItem>
          <SubTitle>Status</SubTitle>
          <StatusBadge status={subscription.status}>{subscription.status}</StatusBadge>
        </SectionItem>

        <SectionItem>
          <SubTitle>Created</SubTitle>
          <TimeText date={new Date(subscription.createdAt)} />
        </SectionItem>

        {
          subscription.status === 'active' &&
          <SectionItem>
            <SubTitle>Next payment due</SubTitle>
            <TimeText date={new Date(subscription.nextBillingDateAt)} />
          </SectionItem>
        }

        {
          subscription.cancelledAt &&
          <SectionItem>
            <SubTitle>Cancelled on</SubTitle>
            <TimeText date={new Date(subscription.cancelledAt)} />
          </SectionItem>
        }

        {
          subscription.expiredAt &&
          <SectionItem>
            <SubTitle>Expired on</SubTitle>
            <TimeText date={new Date(subscription.expiredAt)} />
          </SectionItem>
        }

      </SectionContent>
    </Section>

    <Section>
      <SubTitle style={{ fontSize: 18 }}>
        Customer details
      </SubTitle>

      <SectionContent numColumns={3}>
        {
          subscription.subscriptionType.automatedEVMSubscription &&
          <SectionItem>
            <SubTitle>EVM address</SubTitle>
            <span>{subscription.subscriptionType.automatedEVMSubscription.buyerAddress}</span>
          </SectionItem>
        }

        {
          subscription.subscriptionType.automatedEVMSubscription?.emailAddress &&
          <SectionItem>
            <SubTitle>Email address</SubTitle>
            <span>{subscription.subscriptionType.automatedEVMSubscription.emailAddress}</span>
          </SectionItem>
        }

        {
          subscription.subscriptionType.emailInvoiceSubscription?.emailAddress &&
          <SectionItem>
            <SubTitle>Email address</SubTitle>
            <span>{subscription.subscriptionType.emailInvoiceSubscription.emailAddress}</span>
          </SectionItem>
        }

      </SectionContent>
    </Section>

    { false &&
    <Section>
      <SubTitle style={{ fontSize: 18 }}>
        Payments
      </SubTitle>

      <SectionContent numColumns={3}>

        {

        }
      </SectionContent>
    </Section>
    }

  </Container>
})

export default Subscription
