import React from 'react'
import StatsArrow from '../components/StatsArrow'
import { RADOM_COLORS } from '../util/Constants'

export interface TrendProps {
  percentage: number
  isGreen: boolean
  trendUp: boolean
}

export const Trend = ({ percentage, trendUp, isGreen }: TrendProps): React.ReactElement => <div style={{
  userSelect: 'none',
  backgroundColor: isGreen ? RADOM_COLORS.SUCCESS_LIGHT : RADOM_COLORS.ERROR_LIGHT,
  display: 'flex',
  alignItems: 'center',

  columnGap: 5,
  padding: '2px 5px',
  borderRadius: 5
}}>
  <StatsArrow stroke={ isGreen ? RADOM_COLORS.SUCCESS : RADOM_COLORS.ERROR} isDecline={!trendUp} />
  <span style={{
    fontSize: 10,
    color: isGreen ? RADOM_COLORS.SUCCESS : RADOM_COLORS.ERROR,
    fontWeight: 500
  }}>
    {Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(percentage ?? 0)}%
  </span>
</div>

export const TrendNeutral = (): React.ReactElement => <div style={{
  userSelect: 'none',
  backgroundColor: RADOM_COLORS.GRAY_MED,
  display: 'flex',
  alignItems: 'center',

  columnGap: 5,
  padding: '2px 5px',
  borderRadius: 5
}}>
  <span style={{ lineHeight: 0, color: RADOM_COLORS.GRAY_DARKER, fontWeight: 100 }}>-</span>
  <span style={{ fontSize: 10, color: RADOM_COLORS.GRAY_DARKER, fontWeight: 500 }}>0%</span>
</div>
