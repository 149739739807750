import { minBy } from 'lodash'
import React, { ReactElement } from 'react'
import styled, { keyframes } from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'

let id = 0

const GraphGradient = styled.linearGradient<{ hovering: boolean }>`
  transition: 0.2s ease all;
  
  * {
    transition: 0.2s ease all;
    stop-color: ${props => props.hovering ? RADOM_COLORS.GRAY_DARKER : RADOM_COLORS.GRAY_DARK};
  }
`

const LoadingOpacity = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.4;
  }
`

const LoadingPolygon = styled.polygon`
  animation: ${LoadingOpacity} 0.5s infinite alternate;
`

interface DataPoint {
  x: number
  y: number
}

export type GraphData = DataPoint[]

const LoadingData: GraphData = [
  { x: 1, y: 1 },
  { x: 2, y: 2 },
  { x: 3, y: 3 },
  { x: 4, y: 5 },
  { x: 5, y: 2 },
  { x: 6, y: 7 },
  { x: 7, y: 10 }
]

interface IProps {
  data: GraphData
  height?: number
  formatXAxisLabelFn?: (number: number) => string
  formatXLabelFn?: (number: number) => string
  formatYLabelFn?: (number: number) => string
  isLoading?: boolean
}

interface IState {
  hovering: boolean
  toolTipPositionX: number
  toolTipContainerPositionX: number
  toolTipPositionY: number
  maxWidth: number
  maxHeight: number
  toolTipPoint: DataPoint
}

const DEFAULT_GRAPH_HEIGHT = 100

export default class LineGraph extends React.Component<IProps, IState> {
  _id = id++
  container: SVGSVGElement | null
  tooltipDiv: HTMLDivElement
  state = {
    hovering: false,
    toolTipPositionX: 10,
    toolTipContainerPositionX: 0,
    toolTipPositionY: 0,
    maxWidth: 0,
    maxHeight: (this.props.height ?? DEFAULT_GRAPH_HEIGHT) - 15,
    toolTipPoint: { x: 0, y: 0 }
  }

  loadingData = LoadingData.map(d => ({ ...d, y: Math.random() * 100 }))

  get gradientId(): string {
    return `Gradient${this._id}`
  }

  get color(): string {
    return this.state.hovering ? RADOM_COLORS.GRAY_DARKER : RADOM_COLORS.GRAY_DARKER
  }

  dataPointToGraphPoint = ({ x, y }: DataPoint): DataPoint => {
    return {
      x: 10 + ((x - this.minDataX) / Math.max(1, this.maxDataX - this.minDataX)) * (this.state.maxWidth - 10),
      y: Math.max(3, (1 - ((y - this.minDataY) / Math.max(1, this.maxDataY - this.minDataY))) * this.state.maxHeight)
    }
  }

  onMouseMove = (e: React.MouseEvent<SVGSVGElement>): void => {
    if (this.container == null) {
      return
    }
    const rect = this.container.getBoundingClientRect()
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top
    const minPoint = minBy(this.props.data, p => Math.abs(this.dataPointToGraphPoint(p).x - x))
    if (minPoint == null) {
      return
    }

    const toolTipPositionX = this.dataPointToGraphPoint(minPoint).x ?? 0
    let toolTipContainerPositionX = toolTipPositionX
    const toolTipXAbsolute = rect.left + toolTipPositionX

    if (this.tooltipDiv) {
      const tooltipRect = this.tooltipDiv.getBoundingClientRect()
      if (toolTipXAbsolute + tooltipRect.width > window.innerWidth) {
        toolTipContainerPositionX = toolTipPositionX - tooltipRect.width
      }
    }

    this.setState({
      toolTipContainerPositionX,
      toolTipPositionX,
      toolTipPositionY: y,
      toolTipPoint: minPoint
    })
  }

  onResize = (): void => {
    this.setState({ maxWidth: (this.container?.clientWidth ?? 0) - 10 })
  }

  componentDidMount(): void {
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.onResize)
  }

  get data(): GraphData {
    return this.props.isLoading ? this.loadingData : this.props.data
  }

  get minDataX(): number {
    return this.data.length > 0 ? this.data[0].x : 0
  }

  get maxDataX(): number {
    return this.data.length > 0 ? this.data[this.data.length - 1].x : 0
  }

  get minDataY(): number {
    return this.data.reduce((minValue, data) => data.y < minValue ? data.y : minValue, 0)
  }

  get maxDataY(): number {
    return this.data.reduce((maxValue, data) => data.y > maxValue ? data.y : maxValue, 0)
  }

  get polylinePoints(): string {
    return this.props.data.map((d) => {
      const graphPoint = this.dataPointToGraphPoint(d)
      return `${graphPoint.x},${graphPoint.y}`
    }).join(' ')
  }

  get polygonPoints(): string {
    return [
      `10,${this.state.maxHeight}`,
      ...this.data.map((d) => {
        const graphPoint = this.dataPointToGraphPoint(d)
        return `${graphPoint.x},${graphPoint.y}`
      }),
      `${this.state.maxWidth},${this.state.maxHeight}`
    ].join(' ')
  }

  render(): ReactElement {
    return <div style={{ position: 'relative', width: '100%' }}>
      <svg
        ref={container => {
          if (!container || container === this.container) {
            return
          }
          new ResizeObserver(() => { this.setState({ maxWidth: container.clientWidth - 10 }) }).observe(container)
          this.container = container
          this.setState({ maxWidth: container.clientWidth - 10 })
        }}
        style={{ pointerEvents: 'none' }}
        width={'100%'}
        height={`${this.props.height ?? DEFAULT_GRAPH_HEIGHT}px`}>
        <svg
          onMouseEnter={() => this.setState({ hovering: true })}
          onMouseLeave={() => this.setState({ hovering: false })}
          onMouseMove={this.onMouseMove}
          style={{ pointerEvents: 'all' }}>
          <rect fill='transparent' x={10} width={Math.max(0, this.state.maxWidth - 10)} height='calc(100% - 10px)'></rect>
          {/* {
            !this.props.isLoading &&
            this.props.data.map((_, i) =>
              <line
                key={i}
                x1={10 + i * ((this.state.maxWidth - 10) / (this.props.data.length - 1))}
                x2={10 + i * ((this.state.maxWidth - 10) / (this.props.data.length - 1))}
                y1={3}
                y2={this.state.maxHeight}
                stroke={RADOM_COLORS.GRAY_DARK}
                style={{ opacity: 0.25 }} />
            )
          } */}
          <line
            x1={10}
            x2={this.state.maxWidth}
            y1={this.state.maxHeight}
            y2={this.state.maxHeight}
            stroke="black"
            style={{ opacity: 0.1 }} />
          {
            this.state.hovering &&
            <line
              style={{ transition: '0.1s ease' }}
              x1={this.state.toolTipPositionX}
              x2={this.state.toolTipPositionX}
              y1={3}
              y2={this.state.maxHeight}
              stroke={RADOM_COLORS.GRAY_DARKEST}
              strokeDasharray="2" />
          }
          {
            this.state.hovering &&
            <circle
              style={{ transition: '0.1s ease' }}
              cx={this.state.toolTipPositionX}
              cy={this.dataPointToGraphPoint({ x: 0, y: this.state.toolTipPoint.y }).y}
              r={3}
              fill={RADOM_COLORS.GRAY_DARKER} />
          }
          <GraphGradient id={this.gradientId} x1="0" x2="0" y1="0" y2="1" hovering={this.props.isLoading ? false : this.state.hovering}>
            <stop offset="0%" stopOpacity={1} />
            <stop offset="100%" stopOpacity={0} />
          </GraphGradient>
          {!this.props.isLoading && <polyline points={this.polylinePoints} stroke={this.color} opacity={0.5} fill="none" />}
          {!this.props.isLoading && <polygon points={this.polygonPoints} opacity={0.5} fill={`url(#${this.gradientId})`} />}
          {this.props.isLoading && <LoadingPolygon points={this.polygonPoints} opacity={0.5} fill={`url(#${this.gradientId})`} />}
        </svg>
        {
          this.props.data.length > 0 &&
          [this.props.data[0], this.props.data[this.props.data.length - 1]].map((d, i) =>
            <text
              key={d.x}
              textAnchor={i === 0 ? 'start' : 'end'}
              x={((d.x - this.minDataX) / (this.maxDataX - this.minDataX)) * this.state.maxWidth}
              y={this.props.height ?? DEFAULT_GRAPH_HEIGHT - 2}
              style={{ opacity: 0.25 }}
              fontSize={12}>
              {this.props.formatXAxisLabelFn?.(d.x) ?? d.x}
            </text>
          )
        }
      </svg>
      {
        this.state.hovering &&
        <div
          ref={d => { if (d) this.tooltipDiv = d }}
          style={{
            position: 'absolute',
            boxShadow: '0 0 3px rgba(0, 0, 0, 0.25)',
            padding: 10,
            fontSize: '14px',
            background: 'white',
            borderRadius: 5,
            left: this.state.toolTipContainerPositionX,
            top: this.state.toolTipPositionY,
            marginLeft: 10,
            userSelect: 'none',
            pointerEvents: 'none',
            zIndex: 10,
            transition: '0.2s ease all'
          }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: 12, color: RADOM_COLORS.GRAY_DARKER, whiteSpace: 'nowrap' }}>
              {this.props.formatXLabelFn?.(this.state.toolTipPoint.x) ?? this.state.toolTipPoint.x}
            </span>
            <span style={{ fontSize: 16, color: RADOM_COLORS.GRAY_DARKEST }}>
              {this.props.formatYLabelFn?.(this.state.toolTipPoint.y) ?? this.state.toolTipPoint.y}
            </span>
          </div>
        </div>
      }
      {/* {
        this.props.isLoading &&
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', zIndex: 5, alignItems: 'center', justifyContent: 'center' }}>
          <Spinner />
        </div>
      } */}
    </div>
  }
}
