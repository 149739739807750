import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { IconButton, PrimaryButton, SecondaryButton } from '../../components/Button'
import Modal, { ModalProps } from '../../components/Modal'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import { InputLabel, TextInput } from '../../components/Input'
import { ManagedPaymentMethod, getMethod } from '../../util/Managed'
import Checkbox from '../../components/Checkbox'
import { RADOM_COLORS } from '../../util/Constants'
import { errorToast, shortAddress, successToast, useTestMode } from '../../util/Util'
import { ManagedPaymentMethodSelector } from '../../components/ManagedMethodMultiselector'
import Radom, { WithdrawalAccount } from '../../state/Radom'
import Close from '../../icons/Close'
import NumberInput from '../../components/NumberInput'
import { useNavigate } from 'react-router-dom'

enum TriggerType {
  MinimumAmount,
  TimeInterval
}

interface Trigger {
  triggerType: TriggerType
  triggerName: string
  placeholder: string
  value: string
}

const TriggerTypes = [
  {
    triggerType: TriggerType.MinimumAmount,
    triggerName: 'Minimum amount',
    placeholder: 'Enter amount (USD)',
    value: ''
  },
  {
    triggerType: TriggerType.TimeInterval,
    triggerName: 'Time interval',
    placeholder: 'Select time interval',
    value: ''
  }
]

type Props = ModalProps & {
  onAutomationCreated: () => void
}

const AutomationModal = observer(({ onAutomationCreated, ...props }: Props): ReactElement => {
  const navigate = useNavigate()

  const [testMode] = useTestMode()

  const [automationName, setAutomationName] = useState('')
  const [automationType, setAutomationType] = useState('withdrawal')
  const [withdrawalAccount, setWithdrawalAccount] = useState<WithdrawalAccount>()
  const [selectedMethods, setSelectedMethods] = useState<ManagedPaymentMethod[]>([])
  const [allowedNetwork, setAllowedNetwork] = useState<string>()

  const [isCreatingAutomation, setIsCreatingAutomation] = useState(false)

  useEffect(() => {
    if (selectedMethods.length > 0) {
      setAllowedNetwork(selectedMethods[0].hostChain?.name ?? selectedMethods[0].name)
    } else {
      setAllowedNetwork(undefined)
      setWithdrawalAccount(undefined)
    }
  }, [selectedMethods])

  const [withdrawalAccounts, setWithdrawalAccounts] = useState<WithdrawalAccount[]>([])

  const filteredWithdrawalAccounts = withdrawalAccounts
    .filter(w => {
      if (!w.accountDetails.Crypto) {
        return false
      }

      const method = getMethod(w.accountDetails.Crypto.network)
      return testMode ? method.isTestnet : !method.isTestnet
    })
    .filter(w => w.accountDetails.Crypto &&
     (allowedNetwork === undefined || w.accountDetails.Crypto.network === allowedNetwork))

  const [triggers, setTriggers] = useState<Array<Trigger | null>>([
    null
  ])

  useEffect(() => {
    // setIsLoadingAccounts(true)
    Radom
      .listWithdrawalAccounts()
      .then(a => setWithdrawalAccounts(a))
      // .finally(() => setIsLoadingAccounts(false))
  }, [])

  const createAutomation = (): void => {
    setIsCreatingAutomation(true)

    const req = {
      name: automationName,
      automationDetails: {
        Withdrawal: {
          assets: selectedMethods.map(m => ({
            network: m.hostChain?.name || m.name,
            token: m.hostChain?.tokenAddress
          })),
          triggers: triggers
            .filter(t => !!t)
            .map((t: Trigger) => {
              switch (t.triggerType) {
              case TriggerType.MinimumAmount:
                return {
                  minimumAmount: {
                    amount: t.value,
                    currency: 'USD'

                  }
                }
              case TriggerType.TimeInterval:
                return {
                  timeInterval: {
                    interval: t.value.toLowerCase()
                  }
                }
              default:
                return null
              }
            }),
          withdrawalAccountId: withdrawalAccount?.id
        }
      }
    }

    Radom.createAutomation(req)
      .then(() => {
        successToast('Automation created')
        onAutomationCreated()
        props.onClose()
      })
      .catch(err => errorToast(err.response?.data?.error || err.message))
      .finally(() => setIsCreatingAutomation(false))
  }

  return <Modal title='Create automation' {...props}>
    <div style={{ padding: '0 30px', fontSize: 14 }}>

      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', rowGap: 20 }}>

        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
          <InputLabel>Automation type</InputLabel>
          <div style={{ fontSize: 14, display: 'flex', gap: 20 }}>
            <SecondaryButton
              disabled={isCreatingAutomation}
              onClick={() => setAutomationType('withdrawal')}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                border: 0,
                padding: 5,
                color: automationType === 'withdrawal' ? RADOM_COLORS.BLACK : RADOM_COLORS.GRAY_DARKEST
              }}
              type='button'>
              <Checkbox isRadio checked={automationType === 'withdrawal'} />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <span>Withdrawal</span>
                <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                 Set up an automated withdrawal
                </span>
              </div>
            </SecondaryButton>
          </div>
        </div>

        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
          <InputLabel>Name</InputLabel>
          <TextInput
            disabled={isCreatingAutomation}
            placeholder='Enter automation name'
            required
            value={automationName}
            onChange={(e) => setAutomationName((e.target as HTMLInputElement).value)} />
        </div>

        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
          <ManagedPaymentMethodSelector
            disabled={isCreatingAutomation}
            label='Asset'
            placeholderText='Select asset'
            selectedMethods={selectedMethods}
            setSelectedMethods={setSelectedMethods}
            allowedNetwork={allowedNetwork}
            testnetOnly={testMode} />
        </div>

        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
          <InputLabel>Trigger</InputLabel>

          <div style={{
            display: 'grid',
            gridTemplateColumns: '200px 1fr auto',
            backgroundColor: RADOM_COLORS.GRAY_LIGHTEST,
            padding: 10,
            borderRadius: 15,
            gap: 10,
            alignItems: 'center',
            justifyContent: 'space-between',
            minWidth: 450
          }}>
            {
              triggers.map((t, i) => {
                if (t === null) {
                  return <div key={t} style={{ display: 'contents' }}>
                    <Dropdown
                      disabled={isCreatingAutomation}
                      selectedContent={<span>Select trigger</span>}
                      dropdownContent={<>
                        {
                          TriggerTypes
                            .filter(t => !triggers.some(tt => tt?.triggerType === t.triggerType))
                            .map(t =>
                              <DropdownItem
                                key={t.triggerName}
                                onClick={() => {
                                  const newTriggers = [...triggers]
                                  newTriggers[i] = { ...t }
                                  setTriggers(newTriggers)
                                }}>
                                {t.triggerName}
                              </DropdownItem>

                            )
                        }
                      </>

                      } />

                    <div></div>
                    <div>

                      {
                        triggers.length > 1 &&
                        <IconButton
                          disabled={isCreatingAutomation}
                          style={{ padding: 0, width: 'fit-content' }}
                          onClick={() => setTriggers([...triggers.slice(0, i), ...triggers.slice(i + 1)]) }>
                          <Close style={{ width: 14, padding: 10 }} />
                        </IconButton>
                      }
                    </div>
                  </div>
                }

                return <>
                  <Dropdown
                    disabled={isCreatingAutomation}
                    selectedContent={<span>{t.triggerName}</span>}
                    dropdownContent={<>
                      {
                        TriggerTypes
                          .filter(tt => t.triggerType !== tt.triggerType)
                          .map(t =>
                            <DropdownItem
                              key={t.triggerName}
                              onClick={() => {
                                const newTriggers = [...triggers]
                                newTriggers[i] = { ...t }
                                setTriggers(newTriggers)
                              }}>
                              {t.triggerName}
                            </DropdownItem>

                          )
                      }
                    </>

                    } />

                  {
                    t.triggerType === TriggerType.MinimumAmount &&
                    <NumberInput
                      disabled={isCreatingAutomation}
                      placeholder={t.placeholder}
                      fontSize='14'
                      onUserInput={(v) => {
                        triggers[i] = { ...t, value: v }
                        setTriggers(triggers)
                      }} />
                  }

                  {
                    t.triggerType === TriggerType.TimeInterval &&
                    <Dropdown
                      key={t.value}
                      outerStyle={{ flexGrow: 1 }}
                      disabled={isCreatingAutomation}
                      selectedContent={<span>{ t.value ? t.value : t.placeholder}</span>}
                      dropdownContent={
                        <>
                          {
                            ['Daily', 'Weekly', 'Monthly'].map(value =>
                              <DropdownItem key={t.value}
                                onClick={() => {
                                  const newTriggers = [...triggers]
                                  newTriggers[i] = { ...t, value }
                                  setTriggers(newTriggers)
                                }} >
                                {value}
                              </DropdownItem>
                            )
                          }
                        </>
                      }
                    />
                  }

                  <div>
                    <IconButton
                      disabled={isCreatingAutomation}
                      style={{ padding: 0, width: 'fit-content' }}
                      onClick={() => {
                        if (triggers.length === 1) {
                          return setTriggers([null])
                        }
                        setTriggers([...triggers.slice(0, i), ...triggers.slice(i + 1)])
                      } }>
                      <Close style={{ width: 14, padding: 10 }} />
                    </IconButton>
                  </div>
                </>
              })
            }
          </div>

          <SecondaryButton
            disabled={isCreatingAutomation || triggers[triggers.length - 1] === null || triggers.length === 2}
            style={{
              border: 0,
              padding: 0,
              color: RADOM_COLORS.BLACK,
              gap: 8,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 'fit-content'
            }}
            onClick={() => setTriggers([...triggers, null])}>
            <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
            <span>Add trigger</span>
          </SecondaryButton>

          <div>

          </div>

        </div>

        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
          <InputLabel>Withdrawal account</InputLabel>
          {
            filteredWithdrawalAccounts.length === 0 &&
            <SecondaryButton
              onClick={() => {
                navigate('/accounts')
                props.onClose()
              }}>
              Create withdrawal account
            </SecondaryButton>
          }
          {
            filteredWithdrawalAccounts.length > 0 &&
            <Dropdown
              disabled={isCreatingAutomation}
              selectedContent={
                withdrawalAccount
                  ? <div
                    style={{
                      fontSize: 14,
                      alignItems: 'center',
                      display: 'flex',
                      gap: 10
                    }}>
                    {
                      withdrawalAccount.accountDetails.Crypto &&
                      <img
                        src={getMethod(withdrawalAccount.accountDetails.Crypto.network).logo}
                        style={{
                          height: 20,
                          width: 'auto',
                          maxWidth: 20
                        }} />
                    }
                    {withdrawalAccount.name}
                  </div>
                  : <span style={{ fontSize: 14 }}>Select account</span>
              }
              dropdownContent={
                filteredWithdrawalAccounts
                  .filter(t => t.id !== withdrawalAccount?.id)
                  .map(t =>
                    <DropdownItem
                      key={t.name}
                      onClick={() => setWithdrawalAccount(t)}
                      style={{
                        fontSize: 14,
                        alignItems: 'center',
                        display: 'flex',
                        gap: 10
                      }}>
                      {
                        t.accountDetails.Crypto &&
                              <img
                                src={getMethod(t.accountDetails.Crypto.network).logo}
                                style={{
                                  height: 20,
                                  width: 'auto',
                                  maxWidth: 20
                                }} />
                      }
                      <span>{t.name}</span>
                      {
                        t.accountDetails.Crypto?.address &&
                        <span style={{ opacity: 0.3 }}>{shortAddress(t.accountDetails.Crypto.address)}</span>
                      }

                    </DropdownItem>
                  )
              } />
          }
        </div>

      </div>

    </div>

    <div style={{ padding: 30 }}>
      <PrimaryButton
        isLoading={isCreatingAutomation}
        disabled={
          filteredWithdrawalAccounts.length === 0 ||
          !withdrawalAccount ||
          triggers.length === 0 ||
          triggers[0] === null
        }
        style={{ width: '100%' }}
        onClick={() => {
          createAutomation()
        }}>
        Create automation
      </PrimaryButton>
    </div>
  </Modal>
})

export default AutomationModal
