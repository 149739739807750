import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import Radom, { SearchResult } from '../state/Radom'
import { RADOM_COLORS } from '../util/Constants'
import Spinner from './Spinner'
import Close from '../icons/Close'
import { IconButton } from './Button'
import { Link } from 'react-router-dom'
import { getMethod } from '../util/Managed'

const SearchContainer = styled.div`
  width: 100%;
  position: relative;
`

const SearchInput = styled.input<{ $focused: boolean }>`
  background-color: ${RADOM_COLORS.GRAY_LIGHTEST};
  border: 1px solid transparent;
  border-radius: 15px;
  display: flex;
  width: fit-content;
  transition: 0.2s ease all;
  padding: 8px 15px;
  outline: 0;
  width: 75%;
  color: rgba(0, 0, 0, 0.3);
  padding-right: 30px;
  
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
  
  ${({ $focused }) => $focused
    ? `
    width: 100%;
    color: black;
    border: 1px solid ${RADOM_COLORS.GRAY_DARK};
    box-shadow: 0 0 5px ${RADOM_COLORS.GRAY_DARK};
    background-color: white;
    flex-grow: 1;

    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }`
    : `  
    &:hover {
      background-color: ${RADOM_COLORS.GRAY_LIGHTEST};
      border: 1px solid ${RADOM_COLORS.GRAY_MED};
    }`
}
`

const SearchResultPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const SearchResultItem = styled(Link)`
  width: 100%;
  overflow: auto;
  padding: 20px;
  border-bottom: 1px solid ${RADOM_COLORS.GRAY_DARK};
  font-size: 14px;
  cursor: pointer;
  transition: 0.2s ease all;
  user-select: none;
  display: block;
  box-sizing: border-box;
  text-decoration: none;
  color: black;

  &:hover {
    background-color: ${RADOM_COLORS.GRAY_LIGHTEST};
  }

  &:active {
    opacity: 0.5;
  }
`

const SearchBox = observer((): ReactElement => {
  const [focused, setFocused] = useState(false)
  const [searchText, setSearchText] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [searchResults, setSearchResults] = useState([] as SearchResult[])

  const searchContainer = useRef<HTMLDivElement>(null)

  useMemo(() => {
    if (!searchText) {
      setSearchResults([])
      return
    }

    setIsLoading(true)
    Radom.search(searchText.trim())
      .then(res => {
        setSearchResults(res)
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        setIsLoading(false)
      })
  }, [searchText])

  const onMouseClick = (e: MouseEvent): void => {
    if (!(e.target as any === searchContainer || searchContainer.current?.contains(e.target as any))) {
      setFocused(false)
    }
  }

  const searchResultLink = (s: SearchResult): string => {
    if (s.managedEvent && (s.managedEvent.eventType === 'payment' || s.managedEvent.eventType === 'incompletePayment' || s.managedEvent.eventType === 'recurringPayment')) {
      return `/payment/${s.managedEvent.id}`
    }

    if (s.managedEvent && s.managedEvent.eventType === 'newSubscription') {
      return `/subscription/${s.managedEvent.eventData.newSubscription.subscriptionId}`
    }

    return '#'
  }

  useEffect(() => {
    window.addEventListener('click', onMouseClick)
    return () => window.removeEventListener('click', onMouseClick)
  }, [])

  return <SearchContainer ref={searchContainer}>
    <SearchInput
      onChange={e => setSearchText(e.target.value)}
      value={searchText}
      onFocus={() => setFocused(true)}
      $focused={focused}
      placeholder='Search products, payments, etc.'
      autoCorrect='off'
      spellCheck='false' />

    {
      searchText.length > 0 && focused &&
      <IconButton
        onMouseDown={e => e.preventDefault()}
        onClick={(e) => {
          e.stopPropagation()
          setSearchText('')
        }}
        style={{
          position: 'absolute',
          right: 14,
          top: 10,
          padding: 0
        }}>
        <Close style={{ width: 12, height: 'auto' }} strokeWidth={1.5} />
      </IconButton>
    }

    <div style={{
      display: focused ? 'block' : 'none',
      position: 'absolute',
      marginTop: 10,
      minWidth: 400,
      width: '100%',
      maxHeight: '80vh',
      overflow: 'auto',
      backgroundColor: 'white',
      zIndex: 5,
      borderRadius: 5,
      boxShadow: '0 0 3px rgba(0, 0, 0, 0.25)'
    }}>
      {
        !isLoading &&
        searchResults
          // .filter(r => r.managedEvent?.eventData.payment || r.managedEvent?.eventData.newSubscription)
          .map((res, i) => {
            if (!res.managedEvent) {
              return null
            }

            const payment = res.managedEvent.eventData.payment ||
             res.managedEvent.eventData.incompletePayment ||
            res.managedEvent.eventData.recurringPayment
            if (payment) {
              const { paymentMethod } = payment
              const method = getMethod(paymentMethod.network, paymentMethod.token)

              return <SearchResultItem key={i} to={searchResultLink(res)} onClick={() => setFocused(false)}>
                {
                  payment &&
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>

                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                      <div style={{
                        width: 20,
                        height: 20,
                        backgroundImage: `url(${method.logo})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        position: 'relative'
                      }}>
                        {
                          method.hostChain && <div style={{
                            background: 'rgba(255, 255, 255, 0.95)',
                            borderRadius: '100%',
                            width: 12,
                            height: 12,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'absolute',
                            bottom: -1,
                            right: -5,
                            boxShadow: '0 0 1px'
                          }}>
                            <div style={{
                              width: 10,
                              height: 10,
                              backgroundImage: `url(${method.hostChain.logo})`,
                              backgroundSize: 'contain',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat'
                            }} />
                          </div>
                        }
                      </div>
                      <span>Payment for {Number(payment.amount)}</span>
                    </div>

                    <span style={{ opacity: 0.4 }}>{res.managedEvent?.id}</span>
                  </div>

                  <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>Payment</span>
                </div>
                }
              </SearchResultItem>
            }

            if (res.managedEvent.eventData.newSubscription) {
              return <SearchResultItem key={i} to={searchResultLink(res)} onClick={() => setFocused(false)}>
                {
                  res.managedEvent?.eventData.newSubscription &&
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>

                      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <span>Subscription</span>
                      </div>
                      <span style={{ opacity: 0.4 }}>{res.managedEvent?.eventData.newSubscription.subscriptionId}</span>

                    </div>
                    <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>Subscription</span>
                  </div>
                }

              </SearchResultItem>
            }

            return null
          })
      }
      {
        !isLoading && searchResults.length === 0 &&
        <SearchResultPlaceholder>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 14,
            height: 300
          }}>
            <span style={{ fontSize: 14 }}>No search results</span>
            <span style={{ opacity: 0.5 }}>Your search query returned no results</span>
          </div>
        </SearchResultPlaceholder>
      }
      {
        isLoading &&
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: 300
        }}>
          <Spinner />
        </div>
      }
    </div>
  </SearchContainer>
})

export default SearchBox
