import React, { useEffect, useReducer, useState } from 'react'
import ProductForm, { IProductUpdateV2 } from '../../components/ProductForm'
import { useNavigate, useParams } from 'react-router-dom'
import Radom, { IProductUpdate } from '../../state/Radom'
import { isEqual } from 'lodash'
import { RADOM_COLORS } from '../../util/Constants'
import Spinner from '../../components/Spinner'
import { errorToast, successToast } from '../../util/Util'

const EditProduct = (): React.ReactElement => {
  const { productId } = useParams()

  if (!productId) return <span>Product not found</span>

  const emptyForm: IProductUpdate = {
    id: productId
  }

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>()
  const [productSnapshot, setProductSnapshot] = useState<IProductUpdate>()
  const [productForm, updateProductForm] = useReducer((p: IProductUpdate, n: Partial<IProductUpdateV2>) => {
    return { ...p, ...n }
  }, emptyForm)

  const productHasChanges = (): boolean => {
    return !isEqual(productForm, productSnapshot)
  }

  const onCancel = (): void => {
    if (productHasChanges() && productSnapshot) {
      updateProductForm(productSnapshot)
    }
    navigate('/products')
  }

  const onEditProduct = async (): Promise<void> => {
    if (!productId) throw new Error('Something went very wrong')

    setIsSubmitting(true)
    try {
      await Radom.updateProduct(productId, { ...productForm, image: undefined } as any)
      if (productForm.image) {
        const formData = new FormData()
        formData.append('entityId', productId)
        formData.append('imageType', 'productImage')
        formData.append('image', productForm.image)
        await Radom.uploadImage(formData)
      }
      successToast('Product updated successfully')
      navigate('/products')
    } catch (err) {
      errorToast(`Product failed to update: ${err.code}`)
    }
    setIsSubmitting(false)
  }

  useEffect(() => {
    if (!productId) throw new Error('Product ID not found')
    setIsLoading(true)
    Radom.getProduct(productId)
      .then(data => {
        setProductSnapshot(data)
        updateProductForm({
          ...data
        })
        setImageUrl(data.imageUrl)
      })
      .finally(() => setIsLoading(false))
  }, [])

  return <div style={{ padding: '40px' }}>
    <div style={{ margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {isLoading && <Spinner />}
      {
        !isLoading && !productSnapshot &&
        <div style={{ fontSize: 18, lineHeight: '30px' }}>
          <div><span style={{ color: RADOM_COLORS.ERROR }}>Error:</span> Product not found.</div>
          <div>Please check product ID.</div>
        </div>
      }
      {!isLoading && productSnapshot && <div>

        <ProductForm
          update
          isLoading={isLoading}
          isSubmitting={isSubmitting}
          form={{
            ...productForm,
            name: productForm.name ?? '',
            addOns: productForm.addOns ?? [],
            chargingIntervalSeconds: productForm.chargingIntervalSeconds ?? 0,
            currency: productForm.currency ?? 'USD',
            price: productForm.price ?? 0
          }}
          imageUrl={imageUrl}
          onFormSubmit={onEditProduct}
          onFormUpdate={updateProductForm}
          onCancel={onCancel}
          disabled={!productHasChanges()}
        />
      </div>}
    </div>
  </div>
}

export default EditProduct
