import React, { ReactElement } from 'react'

function S(props: React.SVGProps<SVGSVGElement>): ReactElement {
  return <svg width="33" height="33" viewBox="0 0 33 33" fill="none" stroke="black" strokeWidth="2" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1103_20)">
      <path d="M18.5452 8.07812L23.5452 3.07812" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M29.5452 9.07812L24.5452 14.0781" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M28.5452 18.0781L14.5452 4.07812" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M27.0452 16.5781L19.7164 23.9069C18.9663 24.6568 17.949 25.0781 16.8883 25.0781C15.8276 25.0781 14.8103 24.6568 14.0602 23.9069L8.71644 18.5631C7.96648 17.813 7.54517 16.7957 7.54517 15.735C7.54517 14.6743 7.96648 13.657 8.71644 12.9069L16.0452 5.57812" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.3889 21.2344L4.54517 28.0781" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1103_20">
        <rect width="32" height="32" fill="white" transform="translate(0.545166 0.078125)"/>
      </clipPath>
    </defs>
  </svg>
}

export default S
