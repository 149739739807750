import React from 'react'

export default function Calendar(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0.38 24 24" stroke="#747894" {...props}>
    <g clipPath="url(#clip0_1_4735)">
      <path d="M18.75 21.375H5.25C5.05109 21.375 4.86032 21.296 4.71967 21.1553C4.57902 21.0147 4.5 20.8239 4.5 20.625V4.125C4.5 3.92609 4.57902 3.73532 4.71967 3.59467C4.86032 3.45402 5.05109 3.375 5.25 3.375H14.25L19.5 8.625V20.625C19.5 20.8239 19.421 21.0147 19.2803 21.1553C19.1397 21.296 18.9489 21.375 18.75 21.375Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.25 3.375V8.625H19.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.922 12.3461H15.422V13.8461H7.922V12.3461Z" fill="#747894"/>
      <path d="M15.422 16.0961H7.922V17.5961H15.422V16.0961Z" fill="#747894"/>
    </g>
    <defs>
      <clipPath id="clip0_1_4735">
        <rect width="24" height="24" fill="white" transform="translate(0 0.375)"/>
      </clipPath>
    </defs>
  </svg>
}
