import React, { ReactElement, useState } from 'react'
import { RADOM_COLORS } from '../util/Constants'
import { PrimaryButton } from './Button'
import Modal from './Modal'

function ConfirmButtonModal({ ActionComponent, ...props }): ReactElement {
  const [isVisible, setIsVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const confirm = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault()
    e.stopPropagation()

    setIsLoading(true)
    props.onConfirm()
      .then(() => setIsVisible(false))
      .finally(() => {
        setIsLoading(false)
      })
  }

  return <>
    <ActionComponent onClick={(e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      setIsVisible(true)
    }} />
    <Modal
      visible={isVisible}
      onClose={() => setIsVisible(false)}
      title={props.title}
      innerStyles={{ width: '400px', minHeight: 'auto' }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}>
        <div style={{ padding: '10px 30px' }}>
          <span style={{ fontSize: 14, color: RADOM_COLORS.GRAY_DARKEST, whiteSpace: 'pre-wrap' }}>
            {props.description}
          </span>
        </div>
        <div style={{
          padding: ' 30px',
          borderRadius: 15,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0
        }}>
          <PrimaryButton
            isLoading={isLoading}
            style={{ width: '100%' }}
            onClick={confirm}>
            {props.action}
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  </>
}

export default ConfirmButtonModal
