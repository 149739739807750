import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'
import Checkbox from './Checkbox'

export const SelectedRadio = styled.button<{ disabled: boolean }>`
  background-color: white;
  color: ${RADOM_COLORS.BLACK};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s ease all;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px;

  :active {
    opacity: 0.5;
  }

  :disabled {
    cursor: default;
    opacity: 0.5;
  }
`

export const UnselectedRadio = styled(SelectedRadio)`
  // border: 1px solid ${RADOM_COLORS.GRAY_MED};
  color: black;

  :hover:not(:disabled) {
    border-color: ${RADOM_COLORS.GRAY_MED}
  }
`

interface IProps {
  labels: string[]
  selectedLabel: string
  onSelect: (label: string) => void
  disabled?: boolean
}

export const RadioButtonGroup = ({ labels, selectedLabel, onSelect, disabled }: IProps): ReactElement => {
  return (
    <div style={{ display: 'flex', gap: 20 }}>
      {
        labels.map(label => {
          if (label === selectedLabel) {
            return <SelectedRadio
              key={label}
              disabled={!!disabled}
              type='button'
              onClick={() => !disabled && onSelect(label)}>
              <Checkbox checked isRadio />
              { label }
            </SelectedRadio>
          } else {
            return <UnselectedRadio
              key={label}
              disabled={!!disabled}
              type='button'
              onClick={() => !disabled && onSelect(label)}>
              <Checkbox checked={false} isRadio />
              { label }
            </UnselectedRadio>
          }
        })
      }
    </div>
  )
}
