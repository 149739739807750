import React, { ReactElement, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import Radom, { ICheckoutSession } from '../state/Radom'
import Spinner from '../components/Spinner'
import { Container } from '../components/Animations'
import BreadCrumbs from '../components/Breadcrumbs'
import CheckoutSessionDetails from '../components/CheckoutSessionDetails'
import Dropdown, { DropdownItem } from '../components/Dropdown'
import Dots from '../icons/Dots'
import Modal from '../components/Modal'
import RefundForm from '../components/RefundForm'

const CheckoutSession = observer((): ReactElement => {
  const params = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [checkoutSession, setCheckoutSession] = useState<ICheckoutSession>()

  const [refundModalOpen, setRefundModalOpen] = useState(false)

  useEffect(() => {
    if (!params.id) {
      return
    }

    setIsLoading(true)
    Radom.getCheckoutSession(params.id)
      .then(s => setCheckoutSession(s))
      .catch(e => console.error('Failed to look up checkout session', e))
      .finally(() => setIsLoading(false))
  }, [])

  if (isLoading) {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 140, gap: 20 }}>
      <Spinner />
    </div>
  }

  if (!checkoutSession) {
    return <></>
  }

  return <Container style={{ flexDirection: 'column', fontSize: 14, gap: 30 }}>

    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

      <BreadCrumbs items={[
        { item: 'Checkout sessions', to: '/checkout_sessions' },
        { item: checkoutSession.id, to: '.' }
      ]} />

      {
        checkoutSession.payment &&
        <Dropdown
          noChevron
          selectedContent={<Dots width={15} />}
          dropdownContent={
            <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
              <DropdownItem onClick={() => setRefundModalOpen(true)}>Refund order</DropdownItem>
            </div>
          }
        />
      }

    </div>

    <CheckoutSessionDetails
      checkoutSession={checkoutSession}
      netPayment={
        (
          (Number(checkoutSession.payment?.managed?.amount) ||
          Number(checkoutSession.payment?.managedTokenDelegatePayment?.amount) || 0) -
          Number(checkoutSession.payment?.managed?.networkFee || 0)
        ) *
        0.995
      }
    />

    {
      refundModalOpen &&
      <Modal visible={refundModalOpen} onClose={() => setRefundModalOpen(false)} title='Refund order'>
        <div style={{ width: 500, padding: '0 30px 30px 30px' }}>
          <RefundForm checkoutSession={checkoutSession} onRefund={() => setRefundModalOpen(false)} />
        </div>
      </Modal>
    }

  </Container>
})

export default CheckoutSession
