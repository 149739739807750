import React, { CSSProperties, ReactElement } from 'react'
import styled, { keyframes } from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'

const LoadingBarAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
`

const LoadingBarInner = styled.div<{ animationSpeed: number }>`
  animation: ${LoadingBarAnimation} ${({ animationSpeed }) => animationSpeed}s alternate infinite linear;
  background: linear-gradient(-45deg, ${RADOM_COLORS.GRAY_DARK}, ${RADOM_COLORS.GRAY_MED});
  background-color: ${RADOM_COLORS.GRAY_DARK};
  border-radius: 5px;
  width: 50px;
  height: 20px;
`

interface Props {
  animationSpeed?: number
  style?: CSSProperties
}

const LoadingBar = ({ animationSpeed = 0.5, style }: Props): ReactElement => {
  return <LoadingBarInner animationSpeed={animationSpeed} style={style} />
}

export default LoadingBar
