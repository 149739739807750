import React, { ReactElement } from 'react'
import { PrimaryButton } from '../components/Button'
import { RADOM_COLORS } from '../util/Constants'
import Shopify from '../images/Shopify.svg'
import WooCommerce from '../images/WooCommerce.svg'
import Ghost from '../images/Ghost.png'
import Blockem from '../images/Blockem.svg'
import HubSpot from '../images/HubSpot.svg'
import Xero from '../images/Xero.png'
import PageHeader from '../components/PageHeader'
import styled from 'styled-components'
import Plug from '../icons/Plug'
import { Container } from '../components/Animations'

const IntegrationTypes = [
  {
    name: 'Shopify',
    logo: Shopify,
    description: 'Accept cryptocurrency payments on your e-commerce store’s checkout and manage payments from your Radom dashboard.',
    category: 'E-Commerce'
  },
  {
    name: 'WooCommerce',
    logo: WooCommerce,
    description: 'Boost sales on your online store and increase checkout conversion with payments on Radom.',
    category: 'E-Commerce'
  },
  {
    name: 'Ghost',
    logo: Ghost,
    description: 'Publish articles, share content, and receive cryptocurrency payments from subscribers.',
    category: 'Content publishing'
  },
  {
    name: 'Blockem',
    logo: Blockem,
    description: 'Reduce risk, detect fraud and enhance compliance with machine learning.',
    category: 'Risk analysis'
  },
  {
    name: 'HubSpot',
    logo: HubSpot,
    description: 'Sync paying customers with HubSpot CRM to better manage your relationships.',
    category: 'CRM'
  },
  {
    name: 'Xero',
    logo: Xero,
    description: 'Send your cryptocurrency payment data to Xero and optimize your accounting.',
    category: 'Accounting'
  }
  // {
  //   name: 'Monday',
  //   logo: Monday,
  //   description: 'Send your cryptocurrency payment data to Xero and optimize your accounting.',
  //   category: 'CRM'
  // }
]

const DescriptionText = styled.span`
  font-size: 14px;
  color: ${RADOM_COLORS.GRAY_DARKEST};
  opacity: 0.8;
  transition: 0.2s ease all;
`

const IntegrationCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 10px;
  border-color: ${RADOM_COLORS.GRAY_DARK};
  box-shadow: 0 1px 1px ${RADOM_COLORS.GRAY_MED};
  padding: 20px;
  gap: 15px;
  cursor: pointer;
  transition: 0.2s ease all;
  transform: scale(1);
  user-select: none;

  :hover {
    border-color: ${RADOM_COLORS.GRAY_DARKER};
    box-shadow: 0 0 3px ${RADOM_COLORS.GRAY_DARK};
    transform: scale(1.01);
    
    ${DescriptionText} {
      opacity: 1;
    }
  }
  
  &:active {
    border-color: ${RADOM_COLORS.GRAY_DARKER};
    box-shadow: 0 0 6px ${RADOM_COLORS.GRAY_DARKER};
    transform: scale(0.99);
  }
`

const Integrations = (): ReactElement => {
  return <Container>
    <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
      <PageHeader title='Integrations' />
      <div style={{
        gridTemplateColumns: 'repeat(3, minmax(150px, 1fr))',
        display: 'grid',
        gap: 20
      }}>
        {IntegrationTypes.map(integration =>
          <IntegrationCard key={integration.name}>
            <div style={{
              display: 'flex',

              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: 20
            }}>
              <img src={integration.logo as string} style={{ maxHeight: 40, maxWidth: 130, height: 'auto', width: 'auto' }} />
              <PrimaryButton disabled>
                <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                  <Plug style={{ width: 15, height: 15 }} stroke="white" strokeWidth={3} />
                  <span>Coming soon</span>
                </div>
              </PrimaryButton>
            </div>
            <div style={{ display: 'flex', gap: 15, flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <span style={{ fontSize: 16, fontWeight: 600 }}>{integration.name}</span>
                <DescriptionText>
                  {integration.description}
                </DescriptionText>
              </div>
            </div>
          </IntegrationCard>
        )}
      </div>
    </div>
  </Container>
}

export default Integrations
