import styled, { keyframes } from 'styled-components'

export const ModalFadeIn = keyframes`
  from {
    background-color: rgba(7, 7, 40, 0);
  }
  to {
    background-color: rgba(7, 7, 40, 0.75);
  }
`

export const ScaleIn = keyframes`    
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
`

export const ExpandOut = keyframes`
  from {
    /* transform: scaleY(0); */
    height: 0;
  }
  to {
    /* transform: scaleY(1) */
    height: fit-content;
  }
`

export const CollapseIn = keyframes`
  from {
    /* transform: scaleY(1) */
  }
  to {
    /* transform: scaleY(0) */
  }
`

export const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const SlideFadeUp = keyframes`
  from {
    margin-top: 100px;
    opacity: 0;
  }
  to {
    margin-top: 0;
    opacity: 1;
  }
`

export const ScaleInSlow = keyframes`    
  from {
    transform: scale(0.99);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 50px;
  row-gap: 20px;
  padding: 40px;
  animation: ${ScaleInSlow} 0.2s ease;
  transform-origin: center 0px;
`
