import React, { ReactElement, useRef } from 'react'
import styled from 'styled-components'
import DefaultSeller from '../images/DefaultSeller.png'
import { RADOM_COLORS } from '../util/Constants'
import { errorToast } from '../util/Util'
import AppearTransition from './AppearTransition'

interface IProfilePictureProps {
  isEditing?: boolean
  imgUrl?: string
  imgFile?: File
  setImgFile?: (f: File) => void
}

const CircleImage = styled.div<{ imgUrl: string }>`
  background: url(${({ imgUrl }) => `${imgUrl}`});
  background-position: center;
  background-size: contain;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
  background-repeat: no-repeat;
  filter: invert(30%) contrast(180%) saturate(130%);
`

const AddButton = styled.div<{ isEditing?: boolean }>`
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: ${RADOM_COLORS.GRAY_DARKER};
  cursor: pointer;
  opacity: ${({ isEditing }) => `${isEditing ? '100%' : '0%'}`};
  transition: 0.2s ease all;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);

  :hover {
    opacity: 0.95;
  }

  :active {
    opacity: 0.75;
  }

  ::before {
    padding-bottom: 3px;
    font-size: 22px;
    content: "+";
    color: white;
  }
`

export const ProfilePicture = (props: IProfilePictureProps): ReactElement => {
  const fileInput = useRef<HTMLInputElement>(null)
  const onFileChange = (): void => {
    const fileList = fileInput.current?.files
    if (fileList && fileList.length > 0) {
      const file = fileList[0]
      if (file.size > 4000000) {
        errorToast('File size must be less than 4MB')
        return
      }
      props.setImgFile?.(file)
    }
  }
  return (
    <div style={{ position: 'relative' }}>
      <CircleImage imgUrl={props.imgUrl ?? DefaultSeller} />
      <AppearTransition
        in={props.isEditing ?? false}
        duration={200}
        transitionId="img-add"
      >
        <AddButton onClick={() => fileInput.current?.click()} isEditing={props.isEditing} />
      </AppearTransition>
      <input
        type="file"
        style={{ display: 'none' }}
        name="profile_picture"
        ref={fileInput}
        onChange={(e) => onFileChange()}
      />
    </div>
  )
}
