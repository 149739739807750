import { JsonRpcProvider } from '@ethersproject/providers'
import { getChainById } from './Chains'

const providers = {}

export const getJsonRpcProviderByChainId = (chainId: number): JsonRpcProvider => {
  if (providers[chainId]) {
    return providers[chainId]
  }

  const provider = new JsonRpcProvider(getChainById(chainId).rpcURL)
  providers[chainId] = provider
  return provider
}
