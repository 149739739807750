import React, { ReactElement } from 'react'
import { observer } from 'mobx-react'
import Events, { RadomEvent } from '../state/Events'
import SideNav from '../components/SideNav'
import Table from '../components/Table'
import PageHeader from '../components/PageHeader'
import Pagination from '../components/Pagination'
import TimeText from '../components/TimeText'
import Order from '../icons/Order'
import { RADOM_COLORS } from '../util/Constants'
import styled from 'styled-components'
import { Container } from '../components/Animations'
import Withdrawal from '../icons/Withdrawal'
import { StatusBadge } from './CheckoutSessions'
import NewSubscription from '../icons/NewSubscription'
import SubscriptionRevoked from '../icons/SubscriptionRevoked'
import Refund from '../icons/Refund'
import { formatCurrency } from '../util/Util'

export const ExternalLinkText = styled.a`
  color: ${RADOM_COLORS.BLACK};
  align-items: center;
  display: flex;
  gap: 3px;
  text-decoration: none;
  
  &:hover {
    color: ${RADOM_COLORS.BLACK};
    text-decoration: underline;
  }

  &:active {
    opacity: 0.5;
  }
`

interface IState {
  selectedTab: string
  curPage: number
}

function renderEventType(e: RadomEvent): ReactElement {
  let Icon = <></>
  switch (e.name) {
  case 'NewSubscription':
    Icon = <NewSubscription style={{ width: 20, height: 20 }} />
    break
  case 'SubscriptionCancelled':
  case 'IncompletePaymentEvent':
    Icon = <SubscriptionRevoked style={{ width: 20, height: 20 }} />
    break
  case 'RecurringPaymentEvent':
  case 'SubscriptionPaymentEvent':
  case 'PaymentEvent': {
    Icon = <Order style={{ width: 20, height: 20 }} />
    break
  }
  case 'Withdrawal': {
    Icon = <Withdrawal style={{ width: 20, height: 20 }} />
    break
  }
  case 'Refund': {
    Icon = <Refund style={{ width: 20, height: 20 }} />
  }
  }

  return <div key="eventType" style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
    {Icon}
    <span style={{ fontSize: 14 }}>{e.label}</span>
  </div>
}

function renderEventNetwork(e: RadomEvent): ReactElement {
  if (e.gateway === 'Managed') {
    switch (e.name) {
    case 'IncompletePaymentEvent':
    case 'RecurringPaymentEvent':
    case 'SubscriptionPaymentEvent':
    case 'PaymentEvent': {
      return <div key="network" style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
        <img src={e.paymentMethod.hostChain?.logo ?? e.paymentMethod.logo} style={{ height: 20, width: 'auto', maxWidth: 20 }}/>
        <span>{e.paymentMethod.hostChain?.name ?? e.paymentMethod.name}</span>
      </div>
    }
    case 'Refund':
    case 'Withdrawal': {
      return <div key="network" style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
        <img src={e.managedMethod.logo} style={{ height: 20, width: 'auto', maxWidth: 20 }}/>
        <span>{e.managedMethod.name}</span>
      </div>
    }
    case 'SubscriptionCancelled':
    case 'NewSubscription': {
      return <div key="network" style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
        <img src={e.managedMethod.hostChain?.logo ?? e.managedMethod.logo} style={{ height: 20, width: 'auto', maxWidth: 20 }}/>
        <span>{e.managedMethod.hostChain?.name ?? e.managedMethod.name}</span>
      </div>
    }
    }
  }

  return <></>
}

function renderEventDate(e: RadomEvent): ReactElement {
  return <div key="date">
    <TimeText date={e.date} />
  </div>
}

function renderEventContext(e: RadomEvent): ReactElement {
  switch (e.name) {
  case 'IncompletePaymentEvent':
  case 'RecurringPaymentEvent':
  case 'SubscriptionPaymentEvent':
  case 'PaymentEvent': {
    return <div key="eventContext" style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <img src={e.paymentMethod.logo} style={{ height: 20, width: 'auto', maxWidth: 20 }} />
        <span>{e.amount}</span>
      </div>
    </div>
  }
  case 'Refund':
  case 'Withdrawal': {
    return <div key="eventContext" style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
      {
        e.data.isSuccess &&
        <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <img src={e.managedMethod.logo} style={{ height: 20, width: 'auto', maxWidth: 20 }} />
          <span>{e.data.withdrawalAmount}</span>
        </div>
      }
      {
        !e.data.isSuccess &&
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <StatusBadge status='expired'>Failed</StatusBadge>
          {e.data.failureReason}
        </div>
      }
    </div>
  }
  case 'SubscriptionCancelled':
  case 'NewSubscription': {
    return <div key="eventContext" style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <span>
          {formatCurrency(Number(e.data.amount), e.data.currency)}
        </span>
        <span>{e.data.period === 'custom' ? `every ${e.data.periodCustomDuration} seconds` : e.data.period}</span>
      </div>
    </div>
  }
  }

  return <></>
}

export function renderEvent(e: RadomEvent): ReactElement[] {
  return [
    renderEventType(e),
    renderEventNetwork(e),
    renderEventContext(e),
    renderEventDate(e)
  ]
}

@observer
export default class EventsView extends React.Component<{}, IState> {
  pageSize: number = 50
  state = {
    selectedTab: 'All events',
    curPage: 0
  }

  onTabChange = (selectedTab: string): void => {
    this.setState({
      selectedTab,
      curPage: 0
    })
  }

  events(): RadomEvent[] {
    switch (this.state.selectedTab) {
    case 'Team events':
      return []
    case 'Billing events':
      return []
    case 'Funds events':
      return []
    case 'All events':
    default:
      return Events.allEvents
    }
  }

  render(): ReactElement {
    return <Container>
      <SideNav
        title='Events'
        items={[
          { title: 'All events' },
          { title: 'Funds events' },
          { title: 'Billing events' },
          { title: 'Team events' }
        ]}
        onTabChange={this.onTabChange} />
      <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', rowGap: 20 }}>
        <PageHeader title={this.state.selectedTab} subTitle="View your recent events" docsLink='intro'/>
        <Table
          headers={['Event type', 'Network', 'Event context', 'Date'].map(h => <span key={h}>{h}</span>)}
          items={
            this.events()
              .slice(this.state.curPage * this.pageSize, (this.state.curPage * this.pageSize) + this.pageSize)
              .map(e => renderEvent(e))
          }
          isLoading={Events.isLoading && Events.allEvents.length <= 0}
          emptyPlaceholderText='No events yet'
        />
        {
          !Events.isLoading && <Pagination
            style={{ justifyContent: 'center' }}
            curPage={this.state.curPage}
            numPages={Math.ceil(this.events().length / this.pageSize)}
            onPageClick={page => this.setState({ curPage: page })}
          />
        }
      </div>
    </Container>
  }
}
