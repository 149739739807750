import React from 'react'

export default function Calendar(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0.75 24 24" stroke="#747894" {...props}>
    <g clipPath="url(#clip0_27_1128)">
      <path d="M12 16.5C14.0711 16.5 15.75 14.8211 15.75 12.75C15.75 10.6789 14.0711 9 12 9C9.92893 9 8.25 10.6789 8.25 12.75C8.25 14.8211 9.92893 16.5 12 16.5Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.88413 17.446C3.46973 16.7322 3.15225 15.9664 2.94006 15.1688L4.51319 13.2C4.49538 12.899 4.49538 12.5973 4.51319 12.2963L2.941 10.3275C3.15282 9.52979 3.46965 8.76372 3.88319 8.04941L6.38725 7.76816C6.58721 7.54287 6.8004 7.32968 7.02569 7.12972L7.30694 4.6266C8.02022 4.21503 8.78504 3.90008 9.58131 3.69003L11.5501 5.26316C11.851 5.24535 12.1528 5.24535 12.4538 5.26316L14.4226 3.69097C15.2203 3.90279 15.9864 4.21962 16.7007 4.63316L16.9819 7.13722C17.2072 7.33718 17.4204 7.55037 17.6204 7.77566L20.1235 8.05691C20.5379 8.77069 20.8554 9.53648 21.0676 10.3341L19.4944 12.3028C19.5123 12.6038 19.5123 12.9056 19.4944 13.2066L21.0666 15.1753C20.8563 15.9729 20.541 16.7389 20.1291 17.4535L17.6251 17.7347C17.4251 17.96 17.2119 18.1732 16.9866 18.3732L16.7054 20.8763C15.9916 21.2907 15.2258 21.6082 14.4282 21.8203L12.4594 20.2472C12.1585 20.265 11.8567 20.265 11.5557 20.2472L9.58694 21.8194C8.78943 21.6091 8.02337 21.2938 7.30881 20.8819L7.02756 18.3778C6.80228 18.1779 6.58909 17.9647 6.38913 17.7394L3.88413 17.446Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_27_1128">
        <rect width="24" height="24" fill="white" transform="translate(0 0.75)"/>
      </clipPath>
    </defs>
  </svg>
}
