import UDSTLogo from '../images/TetherLogo.svg'
import USDCLogo from '../images/USDCLogo.svg'
import EUROCLogo from '../images/EUROCLogo.svg'
import FRAXLogo from '../images/FRAXLogo.svg'
import DaiLogo from '../images/DaiLogo.svg'
import DaiKLogo from '../images/DAIKLogo.png'
import ETHKLogo from '../images/ETHKLogo.png'
import BTCKLogo from '../images/BTCKLogo.png'
import WOKTLogo from '../images/WOKTLogo.png'
import OKBLogo from '../images/OKBLogo.png'
import WETHLogo from '../images/WETH.svg'
import GeminiLogo from '../images/GeminiLogo.png'
import BATLogo from '../images/BAT.svg'
import TUSDLogo from '../images/TUSDLogo.svg'
import USDPLogo from '../images/USDPLogo.svg'
import TetherGoldLogo from '../images/TetherGoldLogo.svg'
import PRQLogo from '../images/PRQLogo.svg'
import PAXGLogo from '../images/PAXGLogo.svg'
import MIMLogo from '../images/MIMLogo.svg'
import BUSDLogo from '../images/BUSDLogo.svg'
import FTMLogo from '../images/FantomLogo.svg'
import WrappedBitcoinLogo from '../images/WrappedBitcoinLogo.svg'
import PolygonLogo from '../images/PolygonLogo.svg'
import BNBLogo from '../images/BNBLogo.svg'
import AVAXLogo from '../images/AvalancheLogo.svg'
import { Chain, getChainById, getChains } from './Chains'

export interface Token {
  name: string
  ticker: string
  logo: string
  addresses: { [key: number]: string }
  decimals: { [key: number | string]: number }
}

export const Tokens: Record<string, Token> = {
  USDC: {
    name: 'USD Coin',
    ticker: 'USDC',
    logo: USDCLogo as string,
    addresses: {
      1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      31337: '0x6d4cc59Ca95Ab85e333a17bAF97a225CA10F292c',
      5: '0x12437ca9aCb41Cc73D1c59E29C7496A054FC8A78',
      80001: '0x8f8b1972eea072C3C228EbE8f9FEADe03927D70F',
      4002: '0x31720c00bD9EDEF0dbcBFcBa20B572c6b950C6Dc',
      11155111: '0xa4fCE8264370437e718aE207805b4e6233638b9E',
      1313161555: '0x0C6013dfdaBF883F3fc2d7D9911f30f54D10E4a6',
      43113: '0x2e9B1F01068B96D7c85913f70Cf29464FA98b567',
      534353: '0x87cba17033bAA29324708dC12503a14C179a00D7',
      137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
      56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      250: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
      43114: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
      66: '0xc946DAf81b08146B1C7A8Da2A851Ddf2B3EAaf85'
    },
    decimals: {
      default: 6,
      56: 18,
      66: 18
    }
  },
  PAXG: {
    name: 'Paxos Gold',
    ticker: 'PAXG',
    addresses: {
      1: '0x45804880de22913dafe09f4980848ece6ecbaf78'
    },
    logo: PAXGLogo as string,
    decimals: {
      default: 18
    }
  },
  DAIK: {
    name: 'DAI',
    ticker: 'DAIK',
    addresses: {
      66: '0x21cDE7E32a6CAF4742d00d44B07279e7596d26B9'
    },
    logo: DaiKLogo,
    decimals: {
      default: 18
    }
  },
  ETHK: {
    name: 'ETHK',
    ticker: 'ETHK',
    addresses: {
      66: '0xEF71CA2EE68F45B9Ad6F72fbdb33d707b872315C'
    },
    logo: ETHKLogo,
    decimals: {
      default: 18
    }
  },
  WOKT: {
    name: 'Wrapped OKT',
    ticker: 'WOKT',
    addresses: {
      66: '0x8F8526dbfd6E38E3D8307702cA8469Bae6C56C15'
    },
    logo: WOKTLogo,
    decimals: {
      default: 18
    }
  },
  XAUT: {
    name: 'Tether Gold',
    ticker: 'XAUT',
    addresses: {
      1: '0x68749665FF8D2d112Fa859AA293F07A622782F38'
    },
    logo: TetherGoldLogo as string,
    decimals: {
      default: 6
    }
  },
  USDCe: {
    name: 'USDC.e Coin',
    ticker: 'USDC.e',
    addresses: {
      1313161554: '0xb12bfca5a55806aaf64e99521918a4bf0fc40802',
      43114: '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664'
    },
    logo: USDCLogo as string,
    decimals: {
      default: 6
    }
  },
  EURT: {
    name: 'Euro Tether',
    ticker: 'EURT',
    addresses: {
      1: '0xC581b735A1688071A1746c968e0798D642EDE491'
    },
    logo: UDSTLogo as string,
    decimals: {
      default: 6
    }
  },
  BTCK: {
    name: 'BTCK',
    ticker: 'BTCK',
    addresses: {
      66: '0x54e4622DC504176b3BB432dCCAf504569699a7fF'
    },
    logo: BTCKLogo,
    decimals: {
      default: 18
    }
  },
  OKB: {
    name: 'OKB',
    ticker: 'OKB',
    addresses: {
      66: '0xdF54B6c6195EA4d948D03bfD818D365cf175cFC2'
    },
    logo: OKBLogo,
    decimals: {
      default: 18
    }
  },
  EUROC: {
    name: 'Euro Coin',
    ticker: 'EUROC',
    addresses: {
      1: '0x1abaea1f7c830bd89acc67ec4af516284b1bc33c'
    },
    logo: EUROCLogo as string,
    decimals: {
      default: 6
    }
  },
  USDT: {
    name: 'Tether USD',
    ticker: 'USDT',
    addresses: {
      1: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      31337: '0x7e05775C8CD424Fc80a0a6B31c7596fdF20B8076',
      5: '0x1AAaF0f10C26E33DBe04d06531E533345CbA755e',
      80001: '0x70BE8802e2F3C6652B7e0814B478f66Ec52d9d88',
      4002: '0xCc8401Fbca27934D4bFC762FEbb667ACdb976F88',
      11155111: '0xE50d86c6dE38F9754f6777d2925377564Bf79482',
      1313161555: '0x7696c118ea37866c38918CAE97C4A9383e8129F6',
      43113: '0x31720c00bD9EDEF0dbcBFcBa20B572c6b950C6Dc',
      534353: '0xA0CA2CdC4291826AAFb0C2DF77f303EdfeA34ef3',
      137: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
      56: '0x55d398326f99059ff775485246999027b3197955',
      43114: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
      66: '0x382bB369d343125BfB2117af9c149795C6C65C50'
    },
    logo: UDSTLogo as string,
    decimals: {
      default: 6,
      56: 18,
      66: 18
    }
  },
  USDTe: {
    name: 'Tether USD',
    ticker: 'USDT.e',
    addresses: {
      1313161554: '0x4988a896b1227218e4A686fdE5EabdcAbd91571f',
      43114: '0xc7198437980c041c805a1edcba50c1ce5db95118'
    },
    logo: UDSTLogo as string,
    decimals: {
      default: 6
    }
  },
  FUSDT: {
    name: 'Frapped USDT',
    ticker: 'fUSDT',
    addresses: {
      250: '0x049d68029688eAbF473097a2fC38ef61633A3C7A'
    },
    logo: UDSTLogo as string,
    decimals: {
      default: 6
    }
  },
  FRAX: {
    name: 'FRAX',
    ticker: 'FRAX',
    addresses: {
      1: '0x853d955acef822db058eb8505911ed77f175b99e',
      250: '0xdc301622e621166bd8e82f2ca0a26c13ad0be355',
      43114: '0xD24C2Ad096400B6FBcd2ad8B24E7acBc21A1da64'
    },
    logo: FRAXLogo as string,
    decimals: {
      default: 18
    }
  },
  WAVAX: {
    name: 'Wrapped AVAX',
    ticker: 'WAVAX',
    addresses: {
      43114: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7'
    },
    logo: AVAXLogo as string,
    decimals: {
      default: 18
    }
  },
  USDP: {
    name: 'Pax Dollar',
    ticker: 'USDP',
    addresses: {
      1: '0x8e870d67f660d95d5be530380d0ec0bd388289e1'
    },
    logo: USDPLogo as string,
    decimals: {
      default: 18
    }
  },
  TUSD: {
    name: 'TrueUSD',
    ticker: 'TUSD',
    addresses: {
      1: '0x0000000000085d4780B73119b644AE5ecd22b376'
    },
    logo: TUSDLogo as string,
    decimals: {
      default: 18
    }
  },
  MIM: {
    name: 'Magic Internet Money',
    ticker: 'MIM',
    addresses: {
      250: '0x82f0B8B456c1A451378467398982d4834b6829c1',
      43114: '0x130966628846bfd36ff31a822705796e8cb8c18d'
    },
    logo: MIMLogo as string,
    decimals: {
      default: 18
    }
  },
  WFTM: {
    name: 'Wrapped Fantom',
    ticker: 'WFTM',
    addresses: {
      250: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83'
    },
    logo: FTMLogo as string,
    decimals: {
      default: 18
    }
  },
  DAI: {
    name: 'DAI Stablecoin',
    ticker: 'DAI',
    addresses: {
      1: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
      31337: '0x3a2F7b4b41706C6A0A60cDf02835321175c919e4',
      5: '0x16246c650be7C4EE01fDE5DAEd829D4C65c09450',
      80001: '0x3C10B58B766F2E9BEE0895cd5654EF8E02Fb6b8B',
      4002: '0x2e9B1F01068B96D7c85913f70Cf29464FA98b567',
      11155111: '0xE76FbC3C71499e0C522F21ad1520825BB7Be6714',
      1313161555: '0xACdC5B8b4cD24D99737116afA55F98D05D853c55',
      43113: '0x04C15309Edf4ff9fcDa1F542CddBd63684bd50f0',
      534353: '0x56F8853aE261FFD95D65131e8525DBd317103664',
      137: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
      56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
      1313161554: '0xe3520349F477A5F6EB06107066048508498A291b',
      250: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e'
    },
    logo: DaiLogo as string,
    decimals: {
      default: 18
    }
  },
  DAIe: {
    name: 'DAI Stablecoin',
    ticker: 'DAI.e',
    addresses: {
      43114: '0xd586e7f844cea2f87f50152665bcbc2c279d8d70'
    },
    logo: DaiLogo as string,
    decimals: {
      default: 18
    }
  },
  BUSD: {
    name: 'Binance USD',
    ticker: 'BUSD',
    addresses: {
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56'
    },
    logo: BUSDLogo as string,
    decimals: {
      default: 18
    }
  },
  BUSDe: {
    name: 'Binance USD',
    ticker: 'BUSD.e',
    addresses: {
      43114: '0x19860ccb0a68fd4213ab9d8266f7bbf05a8dde98'
    },
    logo: BUSDLogo as string,
    decimals: {
      default: 18
    }
  },
  WBTC: {
    name: 'Wrapped Bitcoin',
    ticker: 'WBTC',
    addresses: {
      1: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
      250: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
      137: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
      56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
      43114: '0x50b7545627a5162f82a992c33b87adc75187b218'
    },
    logo: WrappedBitcoinLogo as string,
    decimals: {
      default: 8,
      56: 18
    }
  },
  GUSD: {
    name: 'Gemini Dollar',
    ticker: 'GUSD',
    addresses: {
      1: '0x056Fd409E1d7A124BD7017459dFEa2F387b6d5Cd',
      31337: '0xc9777dd0eCe801171dFedAE50d3FE8f9D03C80F1',
      5: '0xd72301a9aB0583d5294bb4BcAfb3781B14855001',
      80001: '0x1Df991976eFA97dCE09023B702f138F30E3fC8B4',
      4002: '0x04C15309Edf4ff9fcDa1F542CddBd63684bd50f0',
      11155111: '0xB081F0D0f1eb825D0A42134ef1121dAF5321D917',
      1313161555: '0x705210d03E8585FC8FFb41D008C6b4962001E346',
      43113: '0x692d1A26B49B8417d09b9CCD510096B933c8Bc4B',
      534353: '0xEc11bA12B74E0c6215FA66334c756780C2AB3fbb',
      137: '0xc8a94a3d3d2dabc3c1caffffdca6a7543c3e3e65'
    },
    logo: GeminiLogo,
    decimals: {
      default: 2
    }
  },
  WETH: {
    name: 'Wrapped Ether',
    ticker: 'WETH',
    addresses: {
      1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
      31337: '0xC89366f3e4C59DDBf17fb116ba9DF8bbD806338c',
      5: '0x0DAa76b8ebdAe1c170bc350c9E0eFb2C595de621',
      80001: '0x9aE88Fb19F76d07996b7d5Bc5d45E9747e6E0b31',
      4002: '0x692d1A26B49B8417d09b9CCD510096B933c8Bc4B',
      11155111: '0xa5Da2a6f19D79A88bd280d758393793d348FcD3D',
      1313161555: '0x54a2D690d06DFa62d911355F5d2f79ceA1f81C8c',
      43113: '0xa550DDe4f887Bd3B49137BAbE38BBD6bFa4e0BDA',
      534353: '0x7FDbC336F0d85BC31511fE20d582c7dCA46233e3',
      137: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
      250: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
      43114: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB'
    },
    logo: WETHLogo as string,
    decimals: {
      default: 18
    }
  },
  WMATIC: {
    name: 'Wrapped Polygon',
    ticker: 'WMATIC',
    addresses: {
      137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'
    },
    logo: PolygonLogo as string,
    decimals: {
      default: 18
    }
  },
  BAT: {
    name: 'Basic Attention Token',
    ticker: 'BAT',
    addresses: {
      1: '0x0d8775f648430679a709e98d2b0cb6250d2887ef',
      137: '0x3cef98bb43d732e2f285ee605a8158cde967d219',
      56: '0x101d82428437127bf1608f699cd651e6abf9766e',
      80001: '0xd445cAAbb9eA6685D3A512439256866563a16E93',
      4002: '0x5720755857EF377D59cb8758B5E3eDBC483b4467',
      11155111: '0x5D684d37922dAf7Aa2013E65A22880a11C475e25',
      1313161555: '0x8dEf8ef4ea453c4dd988c1596DD6FaDD6A291e53',
      43113: '0xABB6a5C05a70098585D095D5446b6845b57E2873'
    },
    logo: BATLogo as string,
    decimals: {
      default: 18
    }
  },
  PRQ: {
    name: 'Parsiq Token',
    ticker: 'PRQ',
    addresses: {
      1: '0x362bc847A3a9637d3af6624EeC853618a43ed7D2'
    },
    logo: PRQLogo as string,
    decimals: {
      default: 18
    }
  }
}

export function getToken(chainID: number, address: string): Token {
  const token = Object.values(Tokens).find(t => t.addresses[chainID]?.toLowerCase() === address.toLowerCase())

  if (!token) {
    return {
      name: 'Unknown',
      ticker: 'UNKNOWN',
      addresses: {},
      logo: '',
      decimals: { default: 18 }
    }
  }

  return token
}

export function getSupportedChains(token: Token): Chain[] {
  return Object.keys(token.addresses)
    .map(chainId => getChainById(parseInt(chainId)))
    .filter(chain => getChains().map(envChain => envChain.chainId).includes(chain.chainId))
}
