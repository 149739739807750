import { observer } from 'mobx-react'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'
import { PaginationItem, PaginationList } from './Pagination'
import { Chevron } from '../icons/Chevron'

interface IProps {
  curPage: number
  pageSize: number
  curPageSize: number
  style?: React.CSSProperties
  onPageClick: (page: number) => void
}

// Assumes pages in props are 0-indexed
const Pagination = observer((props: IProps): ReactElement => {
  const onPageClick = (page: number): void => {
    window.scrollTo(0, 0)
    props.onPageClick(page)
  }
  return <>
    <PaginationList style={props.style}>
      {props.curPage !== 0 && (
        <PaginationItem
          onClick={() => onPageClick(props.curPage - 1)}
        >
          <Chevron style={{ rotate: '90deg', marginBottom: 2 }} />
        </PaginationItem>
      )}
      {props.curPageSize === props.pageSize && (
        <PaginationItem
          onClick={() => onPageClick(props.curPage + 1)}
        >
          <Chevron style={{ rotate: '-90deg' }} />
        </PaginationItem>
      )}
    </PaginationList>
  </>
})

export default Pagination
