import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { PrimaryButton } from '../../components/Button'
import PageHeader from '../../components/PageHeader'
import Table from '../../components/Table'
import AutomationModal from './AutomationModal'
import Radom, { Automation } from '../../state/Radom'
import TimeText from '../../components/TimeText'
import ConfirmButtonModal from '../../components/ConfirmButtonModal'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import Dots from '../../icons/Dots'
import { successToast } from '../../util/Util'
import { startCase } from 'lodash'
import { Container } from '../../components/Animations'

const AutomationView = observer((): ReactElement => {
  const [isOpen, setIsOpen] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [automations, setAutomations] = useState<Automation[]>([])

  const listAutomations = (): void => {
    setIsLoading(true)
    Radom.listAutomations()
      .then(a => setAutomations(a))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    listAutomations()
  }, [])

  return <Container>
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, rowGap: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <PageHeader title='Automation' subTitle='Manage your automation workflows' />
        <PrimaryButton style={{ gap: 8 }} onClick={() => setIsOpen(true)}>
          <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
          <span>Create automation</span>
        </PrimaryButton>
      </div>

      <Table
        borderless
        headers={['Name', 'Automation type', 'Created at', ''].map(t => <span key={t}>{t}</span>)}
        items={automations.map(a => [
          <div key={a.id}>{a.name}</div>,
          <div key={a.id}>
            {startCase(a.automationType)}
          </div>,
          <div key={a.id}>
            <TimeText key="txTime" date={new Date(a.createdAt as any)} />
          </div>,
          <div key={a.id} onClick={e => e.stopPropagation()}>

            <Dropdown
              noChevron
              selectedContent={<Dots width={15} />}
              dropdownContent={
                <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                  <ConfirmButtonModal
                    title='Delete automation'
                    description='Are you sure you want to delete this automation?'
                    action='Delete automation'
                    onConfirm={async () => {
                      Radom.deleteAutomation(a.id)
                        .then(async () => listAutomations())
                        .then(() => successToast('Automation deleted'))
                    }}
                    ActionComponent={props => <DropdownItem {...props}>Delete automation</DropdownItem>}
                  />
                </div>
              }
            />
          </div>

        ]
        )}
        isLoading={isLoading}
        emptyPlaceholderText='No automations yet'
      />

      {
        isOpen &&
      <AutomationModal
        onAutomationCreated={() => listAutomations()}
        visible={isOpen}
        onClose={() => setIsOpen(false)} />
      }
    </div>
  </Container>
})

export default AutomationView
