import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'

const SideNavItem = styled(Link)<{ $isActiveTab: boolean, $isChild?: boolean }>`
  ${({ $isChild }) => $isChild && 'margin-left: 10px;'}
  color: ${({ $isActiveTab }) => $isActiveTab ? RADOM_COLORS.BLACK : RADOM_COLORS.GRAY_DARKER};
  font-weight: ${({ $isChild }) => $isChild ? 400 : 500};
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 10px;
  user-select: none;
  text-decoration: none;
  background: ${({ $isActiveTab }) => $isActiveTab ? RADOM_COLORS.GRAY_MED : 'transparent'};
  transition: 0.1s ease all;
  
  &:hover {
    ${({ $isActiveTab }) => !$isActiveTab && `background: ${RADOM_COLORS.GRAY_LIGHTEST};`}
  }
  
  &:active {
    ${({ $isActiveTab }) => !$isActiveTab && 'opacity: 0.75;'}
  }
`

interface ChildItem {
  title: string
  tab?: string
}

interface Item {
  title: string
  children?: ChildItem[]
}

interface IProps {
  title?: string
  items: Item[]
  onTabChange: (selectedTab: string) => void
}

const SideNav = (props: IProps): React.ReactElement => {
  const [params] = useSearchParams()
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [selectedChildTabIndex, setSelectedChildTabIndex] = useState(-1)

  const selectedTab = (): Item => {
    if (selectedChildTabIndex > -1) {
      const children = props.items[selectedTabIndex].children
      if (children) {
        return children[selectedChildTabIndex]
      }
    }
    return props.items[selectedTabIndex]
  }

  useEffect(() => {
    if (params.has('tab')) {
      const tabName = params.get('tab')
      for (let tabIndex = 0; tabIndex < props.items.length; tabIndex++) {
        const tab = props.items[tabIndex]
        if (tab.title === tabName) {
          setSelectedTabIndex(tabIndex)
          setSelectedChildTabIndex(-1)
          return props.onTabChange(tab.title)
        }

        if (!tab.children) {
          continue
        }

        for (let childTabIndex = 0; childTabIndex < tab.children.length; childTabIndex++) {
          const childTab = tab.children[childTabIndex]
          if (childTab.title === tabName) {
            // this.setState({ selectedTabIndex: tabIndex, selectedChildTabIndex: childTabIndex })
            setSelectedTabIndex(tabIndex)
            setSelectedChildTabIndex(childTabIndex)
            return props.onTabChange(childTab.title)
          }
        }
      }
    } else {
      setSelectedTabIndex(0)
      setSelectedChildTabIndex(-1)
      return props.onTabChange(props.items[0].title)
    }
  }, [params.get('tab')])

  const selectTab = (selectedTabIndex: number, selectedChildTabIndex: number = -1): void => {
    setSelectedTabIndex(selectedTabIndex)
    setSelectedChildTabIndex(selectedChildTabIndex)
    props.onTabChange(selectedTab().title)
  }

  return <div style={{
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    minWidth: '150px',
    position: 'sticky',
    top: '95px',
    height: 'fit-content'
  }}>
    {
      props.title &&
      <div style={{ fontSize: 18, marginBottom: 15, fontWeight: 600, textIndent: 10 }}>{props.title}</div>
    }
    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      {
        props.items.map((item, i) =>
          <div
            key={item.title}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 5,
              marginBottom: i < props.items.length - 1 && item.children && item.children.length > 0 ? 10 : 0
            }}>
            <SideNavItem
              to={`?tab=${item.title}`}
              $isActiveTab={selectedTab() === item}
              onClick={() => selectTab(i)}>
              {item.title}
            </SideNavItem>
            {
              item.children?.map((childItem, childIndex) =>
                <SideNavItem
                  key={childItem.title}
                  to={`?tab=${childItem.tab ?? childItem.title}`}
                  $isChild={true}
                  $isActiveTab={selectedTab() === childItem}
                  onClick={() => selectTab(i, childIndex)}>
                  {childItem.title}
                </SideNavItem>
              )
            }
          </div>
        )
      }
    </div>
  </div>
}

export default SideNav
