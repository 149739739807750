import React from 'react'

export function Desktop(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="3.66 5.93 26 24" stroke="black" {...props}>
    <path d="M6.66003 24.9328L26.66 24.9328C27.7646 24.9328 28.66 24.0374 28.66 22.9328L28.66 8.9328C28.66 7.82823 27.7646 6.9328 26.66 6.9328L6.66003 6.9328C5.55546 6.9328 4.66003 7.82823 4.66003 8.9328L4.66003 22.9328C4.66003 24.0374 5.55546 24.9328 6.66003 24.9328Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M20.66 28.9328H12.66" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.66003 19.9328H28.66" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.66 24.9328V28.9328" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
}
