import React from 'react'

export default function Calendar(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0.88 24 24" stroke="#747894" {...props}>
    <g clipPath="url(#clip0_27_1846)">
      <path d="M12.75 14.375C14.4069 14.375 15.75 13.0319 15.75 11.375C15.75 9.71815 14.4069 8.375 12.75 8.375C11.0931 8.375 9.75 9.71815 9.75 11.375C9.75 13.0319 11.0931 14.375 12.75 14.375Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 7.625H5.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 12.875H5.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 18.125H5.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.25 16.625C8.77395 15.9264 9.45336 15.3594 10.2344 14.9688C11.0155 14.5783 11.8767 14.375 12.75 14.375C13.6233 14.375 14.4845 14.5783 15.2656 14.9688C16.0466 15.3594 16.726 15.9264 17.25 16.625" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.25 21.125V4.625C20.25 4.21079 19.9142 3.875 19.5 3.875L6 3.875C5.58579 3.875 5.25 4.21079 5.25 4.625L5.25 21.125C5.25 21.5392 5.58579 21.875 6 21.875H19.5C19.9142 21.875 20.25 21.5392 20.25 21.125Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_27_1846">
        <rect width="24" height="24" fill="white" transform="translate(0 0.875)"/>
      </clipPath>
    </defs>
  </svg>
}
