import React, { ReactElement, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import Radom, { Customer } from '../../state/Radom'
import Spinner from '../../components/Spinner'
import { Container } from '../../components/Animations'
import BreadCrumbs from '../../components/Breadcrumbs'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import Dots from '../../icons/Dots'
import { Section, SubTitle, SectionContent, SectionItem, PaymentDetailsSection } from '../../components/PaymentDetails'
import User from '../../state/User'
import styled from 'styled-components'
import { RADOM_COLORS } from '../../util/Constants'
import { SecondaryButton } from '../../components/Button'
import Edit from '../../icons/Edit'
import EditTagsModal from './EditTags'
import { errorToast, useTestMode } from '../../util/Util'
import { prodNetworks, testNetworks } from '../../util/Managed'

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  align-items: center;
`

export const Tag = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  background-color: ${RADOM_COLORS.GRAY9};
  border-radius: 5px;
  padding: 5px 10px;
`

const CustomerView = observer((): ReactElement => {
  const params = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [customer, setCustomer] = useState<Customer>()

  const [isTagsModalOpen, setIsTagsModalOpen] = useState(false)
  const [testMode] = useTestMode()

  const loadCustomer = async (): Promise<void> => {
    if (!params.id) {
      return
    }

    setIsLoading(true)
    Radom.getCustomer(params.id, testMode ? testNetworks : prodNetworks)
      .then(s => setCustomer(s))
      .catch(e => console.error('Failed to look up checkout session', e))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    loadCustomer()
  }, [testMode])

  if (isLoading) {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 140, gap: 20 }}>
      <Spinner />
    </div>
  }

  if (!customer) {
    return <></>
  }

  return <Container style={{ flexDirection: 'column', fontSize: 14, gap: 30 }}>

    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

      <BreadCrumbs items={[
        { item: 'Customers', to: '/customers' },
        { item: customer.id, to: '.' }
      ]} />

      {
        false &&
        <Dropdown
          noChevron
          selectedContent={<Dots width={15} />}
          dropdownContent={
            <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
              <DropdownItem onClick={() => {}}>Something</DropdownItem>
            </div>
          }
        />
      }

    </div>

    <Section>
      <SubTitle style={{ fontSize: 18 }}>
        Customer details
      </SubTitle>

      <SectionContent>
        <SectionItem>
          <SubTitle>ID</SubTitle>
          <div>{customer.id}</div>
        </SectionItem>

        {
          customer.name &&
          <SectionItem>
            <SubTitle>Name</SubTitle>
            <div>{customer.name}</div>
          </SectionItem>
        }

        {
          customer.email &&
          <SectionItem>
            <SubTitle>Email address</SubTitle>
            <div>{customer.email}</div>
          </SectionItem>
        }

        {
          User.organization &&
          <SectionItem>
            <SubTitle>Total spend</SubTitle>
            <div>
              {
                Number(customer.totalSpend) <= 0
                  ? '-'
                  : new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: User.organization?.defaultCurrency
                  }).format(Number(customer.totalSpend))
              }
            </div>
          </SectionItem>
        }

        <SectionItem>
          <SubTitle>
            <span>Tags</span>
            <SecondaryButton onClick={() => setIsTagsModalOpen(true)} style={{ padding: 0, border: 0, fontSize: 0 }}>
              <Edit stroke={RADOM_COLORS.GRAY_DARKER} style={{ height: 15 }} />
            </SecondaryButton>
          </SubTitle>
          <TagsContainer>
            {customer.tags.length <= 0 && <span>-</span>}
            {customer.tags.map((t, i) => <Tag key={i}>{t}</Tag>)}
          </TagsContainer>

        </SectionItem>

        {
          customer.phone &&
          <SectionItem>
            <SubTitle>Phone</SubTitle>
            <div>{customer.phone}</div>
          </SectionItem>
        }

        {
          customer.billingAddress &&
          <SectionItem>
            <SubTitle>Address</SubTitle>
            <div>{customer.billingAddress}</div>
          </SectionItem>
        }

        {
          customer.telegram &&
          <SectionItem>
            <SubTitle>Telegram</SubTitle>
            <div>{customer.telegram}</div>
          </SectionItem>
        }

        {
          customer.discord &&
          <SectionItem>
            <SubTitle>Discord</SubTitle>
            <div>{customer.discord}</div>
          </SectionItem>
        }

      </SectionContent>

    </Section>

    {
      customer.payments.length > 0 &&

      <Section>
        <SubTitle style={{ fontSize: 18 }}>
          Payments
        </SubTitle>
        {
          customer.payments.map((p, i) => {
            if (!p.managed) {
              return <></>
            }

            return <PaymentDetailsSection
              short
              key={i}
              payment={p.managed}
              netPayment={(Number(p.managed.amount) - Number(p.managed.networkFee)) * 0.995} />
          })
        }
      </Section>
    }

    {
      isTagsModalOpen &&
      <EditTagsModal
        onClose={() => setIsTagsModalOpen(false)}
        isOpen={isTagsModalOpen}
        tags={customer.tags}
        onSave={async tags => {
          try {
            await Radom.updateCustomer(customer.id, { tags })
            setIsTagsModalOpen(false)
            loadCustomer()
          } catch (err) {
            errorToast(err.response?.data?.error || err.message)
          }
        }} />
    }
  </Container>
})

export default CustomerView
