
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'
import Dropdown from './Dropdown'
import { InputLabel } from './Input'

export enum ProductPeriod {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Quarter = 'Quarter',
  SixMonths = 'SixMonths',
  Year = 'Year'
}

const ProductPeriodDisplay: { [key in ProductPeriod]: string } = {
  [ProductPeriod.Day]: 'Day',
  [ProductPeriod.Week]: 'Week',
  [ProductPeriod.Month]: 'Month',
  [ProductPeriod.Quarter]: 'Quarter',
  [ProductPeriod.SixMonths]: 'Six Months',
  [ProductPeriod.Year]: 'Year'
}

const PeriodDropdownItem = styled.div`
  padding: 6px 10px;
  cursor: pointer;

  :hover {
    background-color: ${RADOM_COLORS.GRAY_LIGHTEST}
  }
`

interface IProps {
  disabled?: boolean
  label?: boolean
  period: ProductPeriod
  setPeriod: React.Dispatch<React.SetStateAction<ProductPeriod>>
}

export default class PaymentPeriodSelector extends React.Component<IProps> {
  closeFn?: any

  render(): ReactNode {
    return (
      <>
        <InputLabel>Payment period</InputLabel>
        <Dropdown
          onCloseFn={closeFn => { this.closeFn = closeFn }}
          selectedContent={<p style={{ margin: 0 }}>{ProductPeriodDisplay[this.props.period]}</p>}
          disabled={this.props.disabled}
          dropdownContent={
            Object.keys(ProductPeriod)
              .map(pp => {
                return <PeriodDropdownItem
                  onClick={() => {
                    this.props.setPeriod(ProductPeriod[pp as keyof typeof ProductPeriod])
                    this.closeFn?.()
                  }}
                  key={pp}>
                  {ProductPeriodDisplay[ProductPeriod[pp as keyof typeof ProductPeriod]]}
                </PeriodDropdownItem>
              })
          }
        />
      </>
    )
  }
}
