import { observer } from 'mobx-react-lite'
import React, { ReactElement, useState } from 'react'
import { PrimaryButton, SecondaryButton } from './Button'
import Modal from './Modal'
import NumberInput from './NumberInput'
import { getMethod } from '../util/Managed'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'
import PaymentMethodDisplay from './PaymentMethodDisplay'
import { LinkArrow } from '../icons/LinkArrow'
import { ConvertRequest } from '../state/Radom'

interface WithdrawableAsset {
  network: string
  token?: string
  maxAmount?: string
}

interface ConversionRowProps {
  asset: WithdrawableAsset
  label: string
  amount: string
  onAmount: (v: string) => void
  autoFocus?: boolean
}

interface IProps {
  modalOpen: boolean
  onClose: () => void
  assetToConvert: WithdrawableAsset
  onConvert: (req: ConvertRequest) => void
  isLoading?: boolean
}

const ConversionRowDiv = styled.div`
  padding: 20px;
  width: 450px;
  max-width: 100%;
`

const ConversionRow = ({
  asset, amount,
  label, onAmount, autoFocus
}: ConversionRowProps): ReactElement => <ConversionRowDiv>
  <span style={{ fontSize: 12, textTransform: 'uppercase', opacity: 0.5 }}>{label}</span>
  <div style={{ display: 'flex', gap: 10 }}>

    <NumberInput
      autoFocus={autoFocus}
      onUserInput={onAmount}
      placeholder='0'
      value={amount}
      style={{
        fontSize: 22,
        border: 0,
        padding: '10px 0',
        boxShadow: 'none',
        background: 'transparent'
      }} />

    <div style={{
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      backgroundColor: 'white',
      border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
      padding: 10,
      borderRadius: 20,
      marginBottom: 12
    }}>
      <PaymentMethodDisplay
        method={{ network: asset.network, token: asset.token }}
        excludeName />
      <span style={{ fontSize: 16 }}>
        {getMethod(asset.network, asset.token).ticker}
      </span>
    </div>
  </div>
  {
    asset.maxAmount &&
  <div>
    <div style={{ display: 'flex', fontSize: 12, gap: 3, alignItems: 'center', justifyContent: 'flex-end' }}>
      <span style={{ opacity: 0.5 }}>Balance</span>
      <span>
        {
          new Intl.NumberFormat('en-US', {
            maximumSignificantDigits: 18
          }).format(asset.maxAmount as any)
        }
      </span>
      <SecondaryButton
        onClick={() => onAmount(asset.maxAmount as any)}
        style={{
          padding: '2px 6px',
          border: 0,
          fontSize: 10,
          textTransform: 'uppercase',
          backgroundColor: RADOM_COLORS.GRAY_DARK,
          color: RADOM_COLORS.GRAY_DARKEST
        }}>Max</SecondaryButton>
    </div>
  </div>
  }
</ConversionRowDiv>

const AssetConversionModal = observer((props: IProps): ReactElement => {
  const [amount, setAmount] = useState('')

  return (
    <Modal visible={props.modalOpen}
      innerStyles={{ overflow: 'hidden' }}
      onClose={() => props.onClose()}
      title='Convert'>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0 30px 30px 30px',
        gap: 20,
        alignItems: 'center'
      }}>

        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          alignItems: 'center'
        }}>

          <ConversionRow
            autoFocus
            label='From'
            asset={props.assetToConvert}
            amount={amount}
            onAmount={amount => setAmount(amount)} />

          <div style={{
            margin: '-28px',
            width: 35,
            height: 35,
            border: '3px solid #f3f3fa',
            background: 'white',
            zIndex: 1,
            borderRadius: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <LinkArrow style={{ transform: 'rotate(90deg)', width: 18 }} stroke={RADOM_COLORS.GRAY4} />
          </div>
          <div style={{ width: '100%', height: 1, background: RADOM_COLORS.GRAY9 }}></div>

          <ConversionRow
            label='Into'
            asset={{
              network: 'Ethereum',
              token: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
            }}
            amount={amount}
            onAmount={amount => setAmount(amount)} />

        </div>

        <PrimaryButton
          isLoading={props.isLoading}
          onClick={() => props.onConvert({
            from: props.assetToConvert,
            into: {
              network: 'Ethereum',
              token: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
            },
            amount
          })}
          disabled={!amount}
          style={{ width: '100%' }}>Convert</PrimaryButton>
      </div>
    </Modal>
  )
})

export default AssetConversionModal
