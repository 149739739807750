import React, { useEffect, useState } from 'react'
import { PrimaryButton, PrimaryLinkButton, SecondaryButton } from './Button'
import { RADOM_COLORS } from '../util/Constants'
import ErrorIcon from '../images/Error.png'
import DefaultSeller from '../images/DefaultSeller.png'
import Dropdown, { DropdownItem } from './Dropdown'
import { InputLabel, OptionalLabel, TextInput } from './Input'
import Modal from './Modal'
import Spinner from './Spinner'
import LinkExternal from './LinkExternal'
import Radom, { IOrganization, VerificationStatus } from '../state/Radom'
import User from '../state/User'
import { errorToast } from '../util/Util'
import Tooltip from './Tooltip'
import SearchMultiselect from './SearchMultiselect'
import { countries } from 'countries-list'

const COUNTRIES = Object.values(countries).map(c => c.name).sort()

enum OrganizationType {
  Individual = 'Individual',
  Business = 'Business',
  Nonprofit = 'Nonprofit'
}

enum Step {
  SubmittingInfo,
  Verifying,
  ChildOrganization
}

const INDUSTRIES = [
  'Agriculture, Forestry, and Fishing',
  'Mining and Quarrying',
  'Manufacturing',
  'Energy and Utilities',
  'Construction',
  'Wholesale and Retail Trade',
  'Transportation and Storage',
  'Accommodation and Food Services',
  'Information and Communication Technology (ICT)',
  'Financial Services',
  'Insurance',
  'Real Estate Activities',
  'Professional Services',
  'Public Administration and Defense',
  'Education',
  'Human Health and Social Work Activities',
  'Arts, Entertainment, and Recreation',
  'Gambling and Betting (iGaming)',
  'Adult Entertainment',
  'Cryptocurrency and Blockchain',
  'Charities and Non-Profit Organizations',
  'Import/Export and Trade Finance',
  'Cash-Intensive Businesses (e.g., Bars, Casinos, Nightclubs)',
  'High-Value Goods Dealers (e.g., Luxury Cars, Art, Jewelry)',
  'Other'
]

function formatVerificationStatus(status: VerificationStatus): string {
  switch (status) {
  case 'Unverified':
  case 'Verified':
    return status
  case 'PendingVerificationSubmission':
    return 'Pending verification submission'
  case 'PendingApproval':
    return 'Pending approval'
  }
}

const VerificationBanner = (): React.ReactElement => {
  const [requiresVerification, setRequiresVerification] = useState(false)
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false)
  const [orgType] = useState<OrganizationType>(OrganizationType.Business)

  const [isLoading, setIsLoading] = useState(true)
  const [isVerifyLoading, setIsVerifyLoading] = useState(false)

  const [step, setStep] = useState<Step>(Step.SubmittingInfo)

  const [name, setName] = useState<string>()
  const [website, setWebsite] = useState<string>()

  const [personaLink, setPersonaLink] = useState<string>()
  const [org, setOrg] = useState<IOrganization>()

  const [parentOrg, setParentOrg] = useState<string>()
  const [orgs, setOrgs] = useState<IOrganization[]>([])

  const [country, setCountry] = useState<string>()
  const [industry, setIndustry] = useState<string>()
  const [customIndustry, setCustomIndustry] = useState<string>()

  const openVerificationModal = async (): Promise<void> => {
    try {
      setIsVerificationModalOpen(true)
      setIsLoading(true)

      const org = await Radom.getOrganization()
      setOrg(org)

      if (org.personaInquiryId) {
        setStep(Step.Verifying)
        setPersonaLink(`https://withpersona.com/verify?inquiry-id=${org.personaInquiryId}`)
      } else {
        setStep(Step.SubmittingInfo)
      }

      setIsLoading(false)
    } catch (err) {

    }
  }

  const verifyOrganization = async (): Promise<void> => {
    try {
      setIsVerifyLoading(true)

      const res = await Radom.verifyOrganization({
        organizationType: orgType,
        name,
        website,
        country,
        industry: industry === 'Other' ? customIndustry : industry
      })

      setIsVerifyLoading(false)
      setPersonaLink(res.personaVerificationLink)

      setStep(Step.Verifying)
    } catch (err) {
      setIsVerifyLoading(false)
      errorToast(err.response?.data?.error || err.message)
    }
  }

  useEffect(() => {
    if (!User.organization) {
      return
    }

    Radom.getOrganization().then((o) => {
      setRequiresVerification(o.verificationStatus !== 'Verified')
    })
  }, [User.organization])

  useEffect(() => {
    if (isVerificationModalOpen) {
      return
    }

    setParentOrg(undefined)
  }, [isVerificationModalOpen])

  const loadOrgs = async (): Promise<void> => {
    const orgIds = await Radom.listOrganizations()

    const orgs = await Promise.all(orgIds.sort()
      .map(async o => await Radom.getOrganizationById(o)))

    setOrgs(orgs)
  }

  useEffect(() => {
    if (step !== Step.ChildOrganization) {
      return
    }

    loadOrgs()
  }, [step])

  const verifyOrganizationWithParent = async (): Promise<void> => {
    if (!parentOrg) {
      return
    }

    try {
      setIsVerifyLoading(true)
      await Radom.verifyOrganizationWithParent({ parentOrganizationId: parentOrg })
      window.location.reload()
    } catch (err) {
      errorToast(err.response?.data?.error || err.message)
    }

    setIsVerifyLoading(false)
  }

  if (!requiresVerification) {
    return <></>
  }

  return <>
    <div style={{
      border: '1px solid',
      borderColor: RADOM_COLORS.RED300,
      backgroundColor: RADOM_COLORS.RED50,
      padding: '15px 20px',
      borderRadius: 10,
      fontSize: 14,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 10
    }}>
      <img src={ErrorIcon} style={{ height: 35, width: 35 }} />
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 0, color: RADOM_COLORS.RED900 }}>
        <span style={{ fontWeight: 600 }}>Organization unverified</span>
        <span>
        Your organization is unverified. To enable production payments, please verify your organization.
        </span>
      </div>
      <SecondaryButton
        onClick={openVerificationModal}
        style={{
          fontSize: 14,
          backgroundColor: 'transparent',
          borderColor: RADOM_COLORS.RED900,
          border: '1px solid',
          fontWeight: 500,
          color: RADOM_COLORS.RED900
        }}>
        Verify
      </SecondaryButton>
    </div>

    {
      isVerificationModalOpen &&
      <Modal
        visible={isVerificationModalOpen}
        innerStyles={{ fontSize: 14 }}
        title='Organization verification'
        onClose={() => setIsVerificationModalOpen(false)}>

        {
          isLoading &&
          <div style={{
            padding: 50,
            minWidth: 500,
            maxWidth: 600,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <Spinner />
          </div>
        }

        {
          !isLoading &&
          step === Step.ChildOrganization &&

          <div style={{
            padding: '0 30px 30px 30px',
            minWidth: 500,
            maxWidth: 600,
            display: 'flex',
            flexDirection: 'column',
            gap: 30
          }}>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <InputLabel>Parent organization</InputLabel>
                <Dropdown
                  disabled={isVerifyLoading}
                  selectedContent={
                    <div key={parentOrg}>
                      { orgs.filter(o => o.verificationStatus === 'Verified').length <= 0
                        ? 'No verified organizations'
                        : parentOrg || 'Select organization'}
                    </div>
                  }
                  dropdownContent={<div>
                    {orgs
                      .filter(o => o.verificationStatus === 'Verified')
                      .filter(o => o.organizationId !== parentOrg)
                      .map(t =>
                        <DropdownItem
                          onClick={() => setParentOrg(t.organizationId)}
                          key={t.organizationId}
                          style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                          <img
                            src={t.logoUrl || DefaultSeller}
                            style={{
                              width: 'auto',
                              maxHeight: 30,
                              maxWidth: 100,
                              filter: 'invert(30%) contrast(180%) saturate(130%)'
                            }}
                          />
                          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>

                            {t.name && <span>{t.name}</span> }
                            <span style={{ opacity: 0.7 }}>{t.organizationId}</span>
                          </div>
                        </DropdownItem>
                      )}
                  </div>
                  } />
              </div>

            </div>

            <PrimaryButton
              onClick={async () => await verifyOrganizationWithParent()}
              disabled={!parentOrg}
              isLoading={isVerifyLoading}>
              Set parent organization
            </PrimaryButton>

          </div>
        }

        {
          !isLoading &&
          step === Step.SubmittingInfo &&

          <div style={{
            padding: '0 30px 30px 30px',
            minWidth: 500,
            maxWidth: 600,
            display: 'flex',
            flexDirection: 'column',
            gap: 30
          }}>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <InputLabel>Organization type</InputLabel>
                <span>{orgType}</span>
                {/* <Dropdown
                  disabled={isVerifyLoading}
                  selectedContent={<div>{orgType}</div>}
                  dropdownContent={<div>
                    {[OrganizationType.Business, OrganizationType.Individual]
                      .filter(t => orgType !== t)
                      .map(t =>
                        <DropdownItem onClick={() => setOrgType(t)} key={t}>{t}</DropdownItem>
                      )}
                  </div>
                  } /> */}
              </div>

              {
                orgType === OrganizationType.Business &&
                <>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <InputLabel>Business name</InputLabel>
                    <TextInput
                      disabled={isVerifyLoading}
                      placeholder='e.g. Google'
                      value={name}
                      onChange={e => {
                        setName(e.target.value)
                      }} />
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <InputLabel>Country</InputLabel>
                    <SearchMultiselect
                      placeholder='Select country'
                      disabled={isVerifyLoading}
                      isSingleSelect
                      setSelectedItems={(c) => setCountry(c[0])}
                      selectedItems={country ? [country] : []}
                      itemArray={COUNTRIES}
                      keyExtractor={c => c}
                      labelExtractor={c => c} />
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <InputLabel>
                      <span>Website</span>
                      <OptionalLabel>Optional</OptionalLabel>
                    </InputLabel>
                    <TextInput
                      disabled={isVerifyLoading}
                      placeholder='e.g. google.com'
                      value={website}
                      onChange={e => {
                        setWebsite(e.target.value)
                      }}
                    />
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <InputLabel>Industry</InputLabel>
                    <SearchMultiselect
                      disabled={isVerifyLoading}
                      isSingleSelect
                      placeholder='Select industry'
                      setSelectedItems={c => setIndustry(c[0])}
                      selectedItems={industry ? [industry] : []}
                      itemArray={INDUSTRIES}
                      keyExtractor={c => c}
                      labelExtractor={c => c} />

                    {
                      industry === 'Other' &&
                        <TextInput
                          disabled={isVerifyLoading}
                          placeholder='Enter industry'
                          value={customIndustry}
                          onChange={e => {
                            setCustomIndustry(e.target.value)
                          }}
                        />
                    }
                  </div>

                </>
              }

              {
                orgType === OrganizationType.Individual &&
                <>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <InputLabel>Full name</InputLabel>
                    <TextInput
                      disabled={isVerifyLoading}
                      placeholder='e.g. John Smith'
                      value={name}
                      onChange={e => {
                        setName(e.target.value)
                      }}/>
                  </div>
                </>
              }

              {
                orgType === OrganizationType.Nonprofit &&
                <>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <InputLabel>Name</InputLabel>
                    <TextInput disabled={isVerifyLoading} placeholder='Nonprofit name' />
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <InputLabel>
                      <span>Website</span>
                    </InputLabel>
                    <TextInput disabled={isVerifyLoading} placeholder='Nonprofit website' />
                  </div>
                </>
              }
            </div>

            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 15 }}>

              <div style={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                <Tooltip message='Only unique legal entities require verification. If this Radom organization is a child of another already verified organization, simply set it as a child organization to verify it.' />
                <span>Is this a child organization?</span>
                <SecondaryButton
                  onClick={() => setStep(Step.ChildOrganization)}
                  style={{
                    display: 'flex',
                    padding: 0,
                    border: 0,
                    color: RADOM_COLORS.BLACK,
                    flexGrow: 0,
                    width: 'fit-content',
                    gap: 5,
                    textDecoration: 'underline'
                  }}>
                  Set parent organization
                </SecondaryButton>
              </div>

              <PrimaryButton
                disabled={!name}
                isLoading={isVerifyLoading}
                onClick={async () => await verifyOrganization()}>
                Continue
              </PrimaryButton>

            </div>
          </div>
        }

        {
          !isLoading &&
          step === Step.Verifying &&
          <div style={{
            padding: '0 30px 30px 30px',
            minWidth: 500,
            maxWidth: 600,
            display: 'flex',
            flexDirection: 'column',
            gap: 30
          }}>

            <span>
              To activate your organization, please complete the Persona KYC/KYB verification using
              the link below.
            </span>

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 5,
              backgroundColor: RADOM_COLORS.GRAY9,
              padding: 20,
              borderRadius: 10
            }}>
              <InputLabel>Verification status</InputLabel>
              <span style={{ fontWeight: 500 }}>{org?.verificationStatus ? formatVerificationStatus(org?.verificationStatus) : ''}</span>
            </div>

            <PrimaryLinkButton
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 5 }}
              href={personaLink}
              target='_blank'>
              <span>Verify with Persona</span>
              <LinkExternal stroke='white' width={15} style={{ marginBottom: -1 }} />
            </PrimaryLinkButton>

          </div>
        }

      </Modal>
    }
  </>
}

export default VerificationBanner
