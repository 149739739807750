import React, { ReactElement, useState, useReducer, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import InvoiceForm from '../../components/InvoiceForm'
import Radom, { Customer, MultiCreateInvoice, Invoice } from '../../state/Radom'
import Web3 from '../../state/User'
import { PAY_BASE_URL, RADOM_COLORS } from '../../util/Constants'
import { errorToast, successToast } from '../../util/Util'

export enum FormStep {
  INVOICE,
  CONFIRM,
}

export const defaultCustomer: Customer = {
  id: '',
  name: 'Example Customer',
  email: ''
}
export const defaultInvoice: MultiCreateInvoice = {
  gateway: {
    managed: {
      methods: []
    }
  },
  customer: defaultCustomer,
  products: [],
  lineItems: [],
  customers: [],
  overdueAt: undefined,
  memo: undefined,
  inputData: [],
  url: ''
}

const CreateInvoice = (): ReactElement => {
  const navigate = useNavigate()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [formStep, setFormStep] = useState(FormStep.INVOICE)

  const iframeEl = useRef<HTMLIFrameElement>(null)

  const [invoiceForm, updateInvoiceForm] = useReducer(
    (prev: MultiCreateInvoice, next: Partial<MultiCreateInvoice>) => { return { ...prev, ...next } }, defaultInvoice)

  const sellerAddress = useMemo(() => {
    if (Web3.isConnected) {
      return Web3.accounts[0]
    }
  }, [Web3.isConnected])

  const previewData = useMemo(() => {
    return Buffer.from(JSON.stringify({
      ...invoiceForm,
      sellerAddress,
      customer: invoiceForm.customers[0] ?? defaultCustomer
    }), 'utf8').toString('base64')
  }, [invoiceForm])

  const onInvoiceCreate = (): void => {
    setIsSubmitting(true)

    Radom.createInvoice(invoiceForm)
      .then((invoices) => {
        successToast('Invoice created')
        const id = invoices[0].id
        navigate(`/invoices/${id}`)
      })
      .catch(err => {
        const msg = err.response.data.error ?? err.code
        errorToast(`Error: ${msg}`)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const onFormChange = (form: Partial<Invoice>): void => {
    updateInvoiceForm(form)
  }

  return <div style={{ display: 'flex', minHeight: 'calc(100vh)', position: 'relative' }}>
    <InvoiceForm
      header={
        <div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
          <span style={{ fontSize: 18 }}>{formStep === FormStep.INVOICE ? 'Create Invoice' : 'Review invoice details'}</span>
        </div>
      }
      invoice={invoiceForm}
      isSubmitting={isSubmitting}
      isLoading={false}
      formStep={formStep}
      onFormStep={step => setFormStep(step)}
      onFormChange={onFormChange}
      onFormSubmit={onInvoiceCreate}
    />
    <div style={{
      position: 'sticky',
      top: 0,
      zIndex: 3,
      width: '100%',
      display: 'flex',
      flexGrow: 1,
      height: '100vh',
      background: RADOM_COLORS.GRAY8
    }}>
      <iframe
        ref={iframeEl}
        style={{ border: 'none', height: '100%', width: '100%', overflow: 'scroll' }}
        src={`${PAY_BASE_URL}/preview/invoice#${previewData}`}
      />
    </div>
  </div>
}

export default CreateInvoice
