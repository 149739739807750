import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import Spinner from '../../components/Spinner'
import { Link, useParams } from 'react-router-dom'
import { PAY_BASE_URL, RADOM_COLORS } from '../../util/Constants'
import TimeText from '../../components/TimeText'
import Radom, { Invoice } from '../../state/Radom'
import { HoverText } from '../../components/Button'
import LinkExternal from '../../components/LinkExternal'
import { ItemDataThumbnail, PaymentDetailsSection, ProductThumbnail, Section, SectionContent, SectionItem, SubTitle } from '../../components/PaymentDetails'
import { Container } from '../../components/Animations'
import BreadCrumbs from '../../components/Breadcrumbs'
import { ExternalLinkText } from '../Events'
import { StatusBadge } from '../CheckoutSessions'
import { getMethod } from '../../util/Managed'
import { SelectedMethodIcon } from '../../components/PaymentMethodDisplay'
import { formatCurrency } from '../../util/Util'

const ViewInvoice = observer((props): ReactElement => {
  const params = useParams()
  const [invoice, setInvoice] = useState<Invoice | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [invoiceTotalPrice, setInvoiceTotalPrice] = useState('')

  useEffect(() => {
    setIsLoading(true)
    if (params.invoiceId) {
      Radom.getInvoice(params.invoiceId)
        .then(invoice => setInvoice(invoice))
        .finally(() => setIsLoading(false))
    }
  }, [])

  useEffect(() => {
    if (invoice) {
      const currency = invoice.lineItems.length > 0
        ? invoice.lineItems[0].currency
        : invoice.products[0].product.currency

      setInvoiceTotalPrice(formatCurrency(invoiceTotal(), currency || 'USD'))
    }
  }, [invoice])

  const invoiceItems = (): Array<{ name: string, type: string, quantity: number, price: number, currency: string }> => {
    if (!invoice) return []
    const products = invoice.products.map(p => {
      return { name: p.product.name, type: 'Product', quantity: p.quantity, price: p.product.price, currency: p.product.currency }
    })
    const lineItems = invoice.lineItems.map(l => {
      return { name: l.name, type: 'Line Item', quantity: l.quantity, price: l.price, currency: l.currency ?? 'USD' }
    })

    const items = [...products, ...lineItems]
      .sort((a, b) => (b.price * b.quantity) - (a.price * a.quantity))
    return items
  }

  const invoiceTotal = (): number => {
    return invoiceItems()
      .map(i => Number(i.price) * Number(i.quantity))
      .reduce((p: number, n: number) => p + n)
  }

  if (isLoading) {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 140, gap: 20 }}>
      <Spinner />
    </div>
  }

  if (!invoice) {
    return <div style={{ textAlign: 'center', marginTop: 100, display: 'grid', gap: 20 }}>
      <div style={{ fontSize: 18 }}>Invoice not found</div>
      <Link to="/invoices" style={{ fontSize: 14, textDecoration: 'none' }}>
        <HoverText>Back to invoice list</HoverText>
      </Link>
    </div>
  }

  return <Container style={{ flexDirection: 'column', fontSize: 14, gap: 30 }}>

    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

      <BreadCrumbs items={[
        { item: 'Invoices', to: '/invoices' },
        { item: invoice.id, to: '.' }
      ]} />

    </div>

    <Section>
      <SubTitle style={{ fontSize: 18 }}>
        Invoice details
      </SubTitle>

      <SectionContent numColumns={3}>
        <SectionItem>
          <SubTitle>ID</SubTitle>
          <ExternalLinkText href={`${PAY_BASE_URL}/invoice/${invoice.id}`} target='_blank'>
            {invoice.id}
            <LinkExternal stroke={RADOM_COLORS.BLACK} width={13} />
          </ExternalLinkText>
        </SectionItem>

        <SectionItem>
          <SubTitle>Status</SubTitle>
          <StatusBadge status={invoice.status}>{invoice.status}</StatusBadge>
        </SectionItem>

        <SectionItem>
          <SubTitle>Total</SubTitle>
          <div style={{ fontSize: 14 }}>
            {invoiceTotalPrice}
          </div>
        </SectionItem>

        <SectionItem>
          <SubTitle>Recipient</SubTitle>
          <div style={{ fontSize: 14 }}>
            {invoice.customer.email}
          </div>
        </SectionItem>

        {
          invoice.gateway.managed &&
          <SectionItem>
            <SubTitle>Payment methods</SubTitle>
            <div style={{ position: 'relative', display: 'flex', gap: 5 }}>
              {invoice.gateway.managed.methods.map((m, i) =>
                <SelectedMethodIcon
                  key={i}
                  method={getMethod(m.network, m.token)}
                />
              )
              }
            </div>
          </SectionItem>
        }

        {
          invoice.memo &&
          <SectionItem>
            <SubTitle>Memo</SubTitle>
            <div style={{ fontSize: 14 }}>
              {invoice.memo}
            </div>
          </SectionItem>
        }

        {
          invoice.overdueAt &&
          <SectionItem>
            <SubTitle>Due date</SubTitle>
            <TimeText textColor={RADOM_COLORS.BLACK} date={new Date(invoice.overdueAt)} />
          </SectionItem>
        }

        {
          invoice.lineItems && invoice.lineItems.length > 0 &&
          <SectionItem>
            <SubTitle>Line items</SubTitle>
            <div style={{ fontSize: 14 }}>
              {invoice.lineItems.map((item, i) => <ItemDataThumbnail key={i} p={item as any} />)}
            </div>
          </SectionItem>
        }

        {
          invoice.products && invoice.products.length > 0 &&
          <SectionItem>
            <SubTitle>Products</SubTitle>
            <div style={{ fontSize: 14 }}>
              {invoice.products.map((item, i) =>
                <ProductThumbnail key={i} p={item.product} />
              )}
            </div>
          </SectionItem>
        }

        <SectionItem>
          <SubTitle>Issued at</SubTitle>
          <div>
            <TimeText textColor={RADOM_COLORS.BLACK} date={new Date(invoice.issuedAt)} />
          </div>
        </SectionItem>

      </SectionContent>

    </Section>

    <div>
      {
        invoice.payment?.managed &&
        <PaymentDetailsSection
          payment={invoice.payment.managed}
          netPayment={
            ((Number(invoice.payment.managed?.amount) || 0) - Number(invoice.payment.managed?.networkFee || 0)) * 0.995
          } />
      }
    </div>

  </Container>
})

export default ViewInvoice
