import React from 'react'

export default function Calendar(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#747894" {...props}>
    <g clipPath="url(#clip0_130_661)">
      <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.9812 18.6909C6.54549 17.5795 7.40654 16.6459 8.4689 15.9939C9.53126 15.3418 10.7534 14.9966 12 14.9966C13.2465 14.9966 14.4686 15.3418 15.531 15.9939C16.5934 16.6459 17.4544 17.5795 18.0187 18.6909" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_130_661">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
}
