import React, { Component, ErrorInfo, PropsWithChildren, ReactNode } from 'react'
import RadomLogo from '../icons/Radom'
import { PrimaryButton, SecondaryButton } from './Button'
import { TransparentPatternBackground } from '../components/PatternBackground'

interface IState {
  error: undefined | Error
  redirectSecondsRemaining: number
}

export default class ErrorBoundary extends Component<PropsWithChildren> {
  state: IState = { error: undefined, redirectSecondsRemaining: 5 }

  static getDerivedStateFromError(error: Error): IState {
    return { error, redirectSecondsRemaining: 5 }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    setTimeout(this.computeRedirectTimeout, 1000)
    console.error(error, errorInfo)
  }

  computeRedirectTimeout = (): void => {
    if (this.state.redirectSecondsRemaining <= 1) {
      window.location.href = '/'
    } else {
      this.setState({ redirectSecondsRemaining: this.state.redirectSecondsRemaining - 1 })
      setTimeout(this.computeRedirectTimeout, 1000)
    }
  }

  render(): ReactNode {
    if (this.state.error) {
      return <div style={{
        width: '100%',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #07053f, #0d259c)',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1,
          width: 500,
          maxWidth: '90%'
        }}>
          <RadomLogo
            fill='white'
            style={{
              width: 70,
              position: 'fixed',
              top: 10,
              left: 15,
              opacity: 0.5
            }} />
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 5,
            alignSelf: 'flex-start'
          }}>
            <span style={{ fontSize: 36, fontWeight: 100 }}>Oops.</span>
            <span style={{ fontSize: 14, opacity: 0.75 }}>Something went wrong. Our team has been notified.</span>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 5,
            background: 'rgba(0, 0, 0, 0.25)',
            padding: 15,
            borderRadius: 5,
            width: '100%',
            maxWidth: '100%'
          }}>
            <span style={{ fontSize: 12, textTransform: 'uppercase' }}>Error details</span>
            <span style={{ fontSize: 14, opacity: 0.75 }}>
              {this.state.error.message || this.state.error.toString()}
            </span>
          </div>
          {
            this.state.redirectSecondsRemaining !== Infinity &&

          <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'flex-end', gap: 10 }}>
            <SecondaryButton onClick={() => this.setState({ redirectSecondsRemaining: Infinity })} style={{
              color: 'white',
              border: 0
            }}>
              Cancel redirect
            </SecondaryButton>
            <PrimaryButton onClick={() => window.location.href = '/'} style={{
              alignSelf: 'flex-end',
              backgroundColor: 'white',
              color: 'black'
            }}>
              Redirecting in {this.state.redirectSecondsRemaining} seconds
            </PrimaryButton>
          </div>
          }
        </div>

        <TransparentPatternBackground style={{ opacity: 0.3 }} />
      </div>
    }

    return this.props.children
  }
}
