import React, { ReactElement } from 'react'
import { RADOM_COLORS } from '../util/Constants'
import DocsButton from './DocsButton'

interface IProps {
  title: string
  subTitle?: string
  docsLink?: string
}

function PageHeader({ title, subTitle, docsLink = '' }: IProps): ReactElement {
  return <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
    <div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
      <span style={{ fontSize: 24 }}>{title}</span>
      {docsLink && <DocsButton docsLink={docsLink}/>}
    </div>
    { subTitle && <span style={{ fontSize: 14, color: RADOM_COLORS.GRAY_DARKEST }}>{subTitle}</span> }
  </div>
}

export default PageHeader
