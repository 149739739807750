import React, { useEffect, useState } from 'react'
import { PrimaryButton, SecondaryButton } from '../components/Button'
import { RADOM_COLORS, RADOM_TOKEN_EXPIRY_LOCAL_STORAGE_KEY, RADOM_TOKEN_LOCAL_STORAGE_KEY } from '../util/Constants'
import { observer } from 'mobx-react'
import { InputLabel, TextInput } from './Input'
import Radom from '../state/Radom'
import Close from '../icons/Close'
import { useSearchParams } from 'react-router-dom'
import { identifyVisitor, successToast } from '../util/Util'
import styled from 'styled-components'
import Checkmark from '../icons/AnimatedCheckmark'

enum EmailLoginStep {
  Login,
  StartSignUp,
  VerifySignUp,
  CompleteSignUp,
  ForgotPassword,
  PasswordResetEmailSent,
  PasswordResetCodeVerified,
}

const LoginButton = styled(PrimaryButton)`
  background-color: ${RADOM_COLORS.NEW_BLUE} !important;
  border-radius: 25px;
  padding: 15px !important;
  font-size: 16px;
`

const EmailLogin = observer(() => {
  const [step, setStep] = useState(EmailLoginStep.Login)

  const [err, setErr] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [code, setPin] = useState('')

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const action = searchParams.get('action')
    const urlEmail = searchParams.get('email')
    const urlPin = searchParams.get('code')
    if (urlEmail && urlPin) {
      setEmail(urlEmail)
      setPin(urlPin)
      if (action === 'reset_password') {
        setStep(EmailLoginStep.PasswordResetCodeVerified)
      } else {
        setStep(EmailLoginStep.CompleteSignUp)
      }
    }
  }, [searchParams])

  useEffect(() => {
    setErr('')
  }, [step])

  const completeSignUp = async (): Promise<void> => {
    if (password !== passwordConfirm) {
      return setErr('Passwords do not match')
    }

    setErr('')
    setIsLoading(true)

    Radom.completeEmailSignUp(email, password, code)
      .then(res => {
        Radom.token = res.jwtToken
        try {
          localStorage.setItem(RADOM_TOKEN_LOCAL_STORAGE_KEY, Radom.token)
          localStorage.setItem(RADOM_TOKEN_EXPIRY_LOCAL_STORAGE_KEY, new Date(res.expiresAt).toString())
        } catch (err) {
          localStorage.clear()
          window.location = '/' as any
        }
        setSearchParams({})

        Radom.isLoggedIn = !res.requires2fa
        Radom.requires2fa = res.requires2fa
        if (!Radom.requires2fa) {
          successToast('Successfully signed in!')
        }
      })
      .catch(err => {
        setErr(err.response?.data?.error || err.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const login = async (): Promise<void> => {
    setErr('')
    setIsLoading(true)

    try {
      const res = await Radom.emailSignIn(email, password)
      Radom.token = res.jwtToken
      try {
        localStorage.setItem(RADOM_TOKEN_LOCAL_STORAGE_KEY, Radom.token)
        localStorage.setItem(RADOM_TOKEN_EXPIRY_LOCAL_STORAGE_KEY, new Date(res.expiresAt).toString())
      } catch (err) {
        localStorage.clear()
        window.location = '/' as any
      }
      setSearchParams({})

      Radom.isLoggedIn = !res.requires2fa
      Radom.requires2fa = res.requires2fa
      if (!res.requires2fa) {
        successToast('Successfully signed in!')
      }

      try {
        const visitorTokenResponse = await Radom.generateVisitorToken()
        localStorage.setItem('visitorToken', visitorTokenResponse.token)
        await identifyVisitor()
      } catch (visitorErr) {
        console.error('Failed to generate visitor token or identify visitor:', visitorErr)
      }
    } catch (err) {
      setErr(err.response?.data?.error || err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const header = (step: EmailLoginStep): string => {
    switch (step) {
    case EmailLoginStep.Login:
    case EmailLoginStep.StartSignUp:
    case EmailLoginStep.CompleteSignUp:
      return ''
    case EmailLoginStep.VerifySignUp:
      return 'Verification email sent'
    case EmailLoginStep.ForgotPassword:
    case EmailLoginStep.PasswordResetEmailSent:
    case EmailLoginStep.PasswordResetCodeVerified:
      return 'Reset password'
    }
  }

  const subHeader = (step: EmailLoginStep): string => {
    switch (step) {
    case EmailLoginStep.Login:
    case EmailLoginStep.StartSignUp:
    case EmailLoginStep.CompleteSignUp:
      return ''
    case EmailLoginStep.VerifySignUp:
      return 'Check your email for instructions to complete your sign up'
    case EmailLoginStep.ForgotPassword:
      return 'We’ll send an email with reset instructions'
    case EmailLoginStep.PasswordResetEmailSent:
      return 'Check your email for instructions to reset your password'
    case EmailLoginStep.PasswordResetCodeVerified:
      return 'Enter your new password'
    }
  }

  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    width: '100%',
    fontSize: 14
  }}>

    {
      header(step) &&
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginBottom: 10 }}>

        {
          (step === EmailLoginStep.VerifySignUp || step === EmailLoginStep.PasswordResetEmailSent) &&
        <>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: RADOM_COLORS.SUCCESS,
              width: 35,
              height: 35,
              borderRadius: '100%'
            }}>

              <Checkmark
                animationSpeed={'1s'}
                animationDelay={'0s'}
                color='white'
              />
            </div>

          </div>

        </>
        }
        <span style={{ fontSize: 24, fontWeight: 300, letterSpacing: -1 }}>{header(step)}</span>
        <span style={{ fontSize: 14, color: RADOM_COLORS.GRAY_DARKEST }}>{subHeader(step)}</span>
      </div>
    }

    {
      err &&
      <div style={{
        backgroundColor: RADOM_COLORS.ERROR,
        borderRadius: 5,
        padding: '10px 12px',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        gap: 10
      }}>
        <div style={{
          backgroundColor: 'white',
          width: 18,
          height: 18,
          borderRadius: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Close style={{ width: 8 }} stroke={RADOM_COLORS.ERROR} strokeWidth={2} />
        </div>
        <span>{err}</span>
      </div>
    }

    {
      step === EmailLoginStep.Login &&
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
          width: '100%',
          fontSize: 14
        }}
        onSubmit={e => {
          e.preventDefault()
          login()
        }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <InputLabel style={{ color: RADOM_COLORS.NEW_GRAY_1, opacity: 1 }}>Email address</InputLabel>
          <TextInput
            placeholder='Enter email address'
            value={email}
            onChange={e => setEmail(e.target.value)}
            disabled={isLoading}
            autoFocus />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <InputLabel style={{ color: RADOM_COLORS.NEW_GRAY_1, opacity: 1 }}>
              <span>Password</span>
            </InputLabel>
            <TextInput
              type='password'
              placeholder='Enter password'
              value={password}
              disabled={isLoading}
              onChange={e => setPassword(e.target.value)} />
          </div>

          <div style={{ display: 'flex', gap: 5, fontSize: 12 }}>
            <span>Forgot your password?</span>
            <SecondaryButton
              type='button'
              style={{
                padding: 0,
                border: 0,
                display: 'inline-block',
                width: 'fit-content',
                color: RADOM_COLORS.NEW_GRAY_1,
                fontSize: 12,
                fontWeight: 500,
                textDecoration: 'underline'
              }}
              disabled={isLoading}
              onClick={() => setStep(EmailLoginStep.ForgotPassword)}>
            Reset it now
            </SecondaryButton>
          </div>
        </div>
        <LoginButton
          isLoading={isLoading}
          style={{
            backgroundColor: RADOM_COLORS.ORANGE,
            padding: 15
          }}
          onClick={async () => await login()}>
          <span>Sign in</span>
        </LoginButton>

        <div style={{ display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ fontSize: 14 }}>Don’t have an account?</span>
          <SecondaryButton
            type='button'
            disabled={isLoading}
            style={{
              padding: 0,
              border: 0,
              color: RADOM_COLORS.NEW_GRAY_1,
              textDecoration: 'underline',
              fontWeight: 500
            }}
            onClick={() => setStep(EmailLoginStep.StartSignUp)}>
              Sign up
          </SecondaryButton>
        </div>
      </form>
    }

    {
      step === EmailLoginStep.StartSignUp &&
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
          width: '100%',
          fontSize: 14
        }}
        onSubmit={e => e.preventDefault()}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <InputLabel style={{ color: RADOM_COLORS.GRAY_DARKEST, opacity: 1 }}>Email address</InputLabel>
          <TextInput
            placeholder='Enter email address'
            value={email}
            onChange={e => setEmail(e.target.value)}
            autoFocus />
        </div>

        <LoginButton
          isLoading={isLoading}
          style={{
            backgroundColor: RADOM_COLORS.ORANGE
          }}
          onClick={() => {
            setErr('')
            setIsLoading(true)
            Radom.startEmailSignUp(email)
              .then(() => {
                setStep(EmailLoginStep.VerifySignUp)
              })
              .catch(err => setErr(err.response?.data?.error || err.message))
              .finally(() => setIsLoading(false))
          }}>
          <span>Continue</span>
        </LoginButton>

        <div style={{ display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ fontSize: 14 }}>Already have an account?</span>
          <SecondaryButton
            type='button'
            style={{ padding: 0, border: 0 }}
            disabled={isLoading}
            onClick={() => setStep(EmailLoginStep.Login)}>
            Sign in
          </SecondaryButton>
        </div>
      </form>
    }

    {
      step === EmailLoginStep.PasswordResetCodeVerified &&
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
          width: '100%',
          fontSize: 14
        }}
        onSubmit={e => e.preventDefault()}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <InputLabel style={{ color: RADOM_COLORS.GRAY_DARKEST, opacity: 1 }}>Password</InputLabel>
          <TextInput
            type='password'
            placeholder='Enter password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            autoFocus />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <InputLabel style={{ color: RADOM_COLORS.GRAY_DARKEST, opacity: 1 }}>Confirm password</InputLabel>
          <TextInput
            type='password'
            placeholder='Confirm password'
            value={passwordConfirm}
            onChange={e => setPasswordConfirm(e.target.value)} />
        </div>

        <LoginButton
          isLoading={isLoading}
          style={{
            backgroundColor: RADOM_COLORS.ORANGE
          }}
          onClick={() => {
            setIsLoading(true)
            setErr('')
            Radom.completePasswordReset(email, password, code)
              .then(res => {
                Radom.token = res.jwtToken
                try {
                  localStorage.setItem(RADOM_TOKEN_LOCAL_STORAGE_KEY, Radom.token)
                  localStorage.setItem(RADOM_TOKEN_EXPIRY_LOCAL_STORAGE_KEY, new Date(res.expiresAt).toString())
                } catch (err) {
                  localStorage.clear()
                  window.location = '/' as any
                }
                setSearchParams({})
                Radom.isLoggedIn = !res.requires2fa
                Radom.requires2fa = res.requires2fa
                if (!res.requires2fa) {
                  successToast('Successfully signed in!')
                }
              })
              .catch(err => {
                setErr(err.response?.data?.error || err.message)
              })
              .finally(() => {
                setIsLoading(false)
              })
          }}>
          <span>Set password</span>
        </LoginButton>

        <div style={{ display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'center' }}>
          <SecondaryButton
            type='button'
            style={{ padding: 0, border: 0 }}
            disabled={isLoading}
            onClick={() => setStep(EmailLoginStep.Login)}>
            Back to sign in
          </SecondaryButton>
        </div>
      </form>
    }

    {
      step === EmailLoginStep.VerifySignUp &&
      <SecondaryButton
        type='button'
        style={{ padding: 0, border: 0, width: 'fit-content' }}
        disabled={isLoading}
        onClick={() => setStep(EmailLoginStep.Login)}>
        Back to sign in
      </SecondaryButton>
    }

    {
      step === EmailLoginStep.ForgotPassword &&
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
          width: '100%',
          fontSize: 14
        }}
        onSubmit={e => e.preventDefault()}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <InputLabel style={{ color: RADOM_COLORS.GRAY_DARKEST, opacity: 1 }}>Email address</InputLabel>
          <TextInput
            placeholder='Enter email address'
            value={email}
            onChange={e => setEmail(e.target.value)}
            autoFocus />
        </div>

        <LoginButton
          isLoading={isLoading}
          style={{
            backgroundColor: RADOM_COLORS.ORANGE
          }}
          onClick={() => {
            setErr('')
            setIsLoading(true)
            Radom.startPasswordReset(email)
              .then(() => {
                setStep(EmailLoginStep.PasswordResetEmailSent)
              })
              .catch(err => setErr(err.response?.data?.error || err.message))
              .finally(() => setIsLoading(false))
          }}>
          <span>Reset password</span>
        </LoginButton>

        <div style={{ display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ fontSize: 14 }}>Know your password?</span>
          <SecondaryButton
            type='button'
            style={{ padding: 0, border: 0 }}
            disabled={isLoading}
            onClick={() => setStep(EmailLoginStep.Login)}>
            Sign in
          </SecondaryButton>
        </div>
      </form>
    }

    {
      step === EmailLoginStep.CompleteSignUp &&
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
          width: '100%',
          fontSize: 14
        }}
        onSubmit={e => {
          e.preventDefault()
          completeSignUp()
        }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <InputLabel style={{ color: RADOM_COLORS.GRAY_DARKEST, opacity: 1 }}>Email address</InputLabel>
          <TextInput
            placeholder='Enter email address'
            value={email}
            disabled />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <InputLabel style={{ color: RADOM_COLORS.GRAY_DARKEST, opacity: 1 }}>Password</InputLabel>
          <TextInput
            type='password'
            placeholder='Enter password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            autoFocus />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <InputLabel style={{ color: RADOM_COLORS.GRAY_DARKEST, opacity: 1 }}>Confirm password</InputLabel>
          <TextInput
            type='password'
            placeholder='Confirm password'
            value={passwordConfirm}
            onChange={e => setPasswordConfirm(e.target.value)} />
        </div>

        <LoginButton
          isLoading={isLoading}
          style={{
            backgroundColor: RADOM_COLORS.ORANGE
          }}
          onClick={async () => await completeSignUp()}>
          <span>Sign up</span>
        </LoginButton>

        <div style={{ display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ fontSize: 14 }}>Already have an account?</span>
          <SecondaryButton
            disabled={isLoading}
            style={{ padding: 0, border: 0 }}
            onClick={() => setStep(EmailLoginStep.Login)}>
              Sign in
          </SecondaryButton>
        </div>
      </form>
    }

  </div>
})

export default EmailLogin
