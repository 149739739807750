import React, { ReactElement } from 'react'

function S(props: React.SVGProps<SVGSVGElement>): ReactElement {
  return <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect y="0.5" width="48" height="48" rx="6.85714" fill="url(#paint0_linear_23_270)"/>
    <g clipPath="url(#clip0_23_270)">
      <path d="M20.25 24.5H27.75" stroke="#FF6648" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24 33.5C28.9706 33.5 33 29.4706 33 24.5C33 19.5294 28.9706 15.5 24 15.5C19.0294 15.5 15 19.5294 15 24.5C15 29.4706 19.0294 33.5 24 33.5Z" stroke="#FF6648" strokeWidth="1.5" strokeMiterlimit="10"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_23_270" x1="0" y1="0.5" x2="43.3973" y2="46.3064" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFF2EF"/>
        <stop offset="1" stopColor="#FFDBD4"/>
      </linearGradient>
      <clipPath id="clip0_23_270">
        <rect width="24" height="24" fill="white" transform="translate(12 12.5)"/>
      </clipPath>
    </defs>
  </svg>
}

export default S
