import React, { ReactElement, useEffect, useState } from 'react'
import Modal from './Modal'
import { PrimaryButton, SecondaryButton } from './Button'
import { InputLabel, TextInputWithLabel } from './Input'
import { RADOM_COLORS } from '../util/Constants'
import { observer } from 'mobx-react'
import { RoleSelector } from './RoleSelector'
import Radom, { IPermissionRole } from '../state/Radom'
import { errorToast, successToast } from '../util/Util'
import Checkbox from './Checkbox'

interface IProps {
  visible: boolean
  isEditing: boolean
  userForm: ICreateUser
  onClose: () => void
  onRoleAssignment: () => void
  onUserFormUpdate: (u: Partial<ICreateUser>) => void
}

export interface ICreateUser {
  roles: IPermissionRole[]
  emailAddress?: string
  evmAddress?: string
}

const UserInviteModal = observer((props: IProps): ReactElement => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [apiRoles, setAPIRoles] = useState([] as IPermissionRole[])

  const [userType, setUserType] = useState('email')

  const listPermissionRoles = (): void => {
    if (!Radom.isLoggedIn) return
    Radom.listPermissionRoles()
      .then(roles => setAPIRoles(roles))
  }

  useEffect(() => {
    listPermissionRoles()
  }, [Radom.isLoggedIn])

  useEffect(() => {
    props.onUserFormUpdate({
      evmAddress: '',
      emailAddress: ''
    })
  }, [userType])

  const onSubmitUser = async (): Promise<void> => {
    setIsSubmitting(true)
    try {
      await Radom.inviteMember({
        roles: props.userForm.roles.map(r => r.id),
        emailAddress: props.userForm.emailAddress && props.userForm.emailAddress.length > 0
          ? props.userForm.emailAddress.toLowerCase()
          : undefined,
        evmAddress: props.userForm.evmAddress && props.userForm.evmAddress.length > 0
          ? props.userForm.evmAddress
          : undefined
      })
      // props.onRoleAssignment()

      successToast(`Successfully ${props.isEditing ? 'updated' : 'invited'} user`)
      // props.onUserFormUpdate({
      //   roles: [],
      //   userAddr: ''
      // })
      props.onClose()
    } catch (err) {
      errorToast(err.reason || err.response?.data?.error || err.message)
      console.error('Failed to assign user to role', err)
    }
    setIsSubmitting(false)
  }

  return <Modal
    visible={props.visible}
    onClose={() => props.onClose()}
    title={props.isEditing ? 'Update user' : 'Invite team member'}>
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '300px',
        justifyContent: 'space-between'
      }}
      onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()
      }}>
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', rowGap: 20, padding: '0 30px' }}>
        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
          <InputLabel>User type</InputLabel>
          <div style={{ fontSize: 14, display: 'flex', gap: 20 }}>
            <SecondaryButton
              onClick={() => setUserType('email')}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                border: 0,
                padding: 5,
                color: userType === 'email' ? RADOM_COLORS.BLACK : RADOM_COLORS.GRAY_DARKEST
              }}
              type='button'>
              <Checkbox isRadio checked={userType === 'email'} />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <span>Email address</span>
                <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                  User signs in with their email
                </span>
              </div>
            </SecondaryButton>
            <SecondaryButton
              onClick={() => setUserType('evm')}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                border: 0,
                padding: 5,
                color: userType === 'evm' ? RADOM_COLORS.BLACK : RADOM_COLORS.GRAY_DARKEST
              }}
              type='button'>
              <Checkbox isRadio checked={userType === 'evm'} />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <span>EVM address</span>
                <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                  User signs in with their EVM wallet
                </span>
              </div>
            </SecondaryButton>
          </div>
        </div>
        <div style={{ fontSize: 14 }}>
          <TextInputWithLabel
            label={userType === 'evm' ? 'EVM address' : 'Email address'}
            placeholder={`Enter ${userType === 'evm' ? 'EVM address' : 'email address'}`}
            disabled={props.isEditing || isSubmitting}
            required
            value={userType === 'evm' ? props.userForm.evmAddress : props.userForm.emailAddress }
            maxLength={42}
            onChange={e => {
              const el = (e.target as HTMLInputElement)
              const value = el.value

              props.onUserFormUpdate({ [userType === 'evm' ? 'evmAddress' : 'emailAddress']: value })
            }}
          />
        </div>
        <div style={{ fontSize: 14 }}>
          <InputLabel style={{ marginBottom: 10 }}>Roles</InputLabel>
          <RoleSelector
            onCloseFn={props.onClose}
            disabled={isSubmitting}
            roleCreationPage='/team/roles'
            roles={apiRoles}
            formatFunction={r => r.name}
            selectedRoles={props.userForm.roles}
            onSelectRole={r => props.onUserFormUpdate({
              roles: [...props.userForm.roles, r]
            })}
            onRemoveRole={roleId => props.onUserFormUpdate({
              roles: props.userForm.roles.filter(r => r !== roleId)
            })}
          />
        </div>
      </div>
      <div style={{
        padding: 30,
        borderRadius: 15,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
      }}>
        <PrimaryButton
          style={{ width: '100%' }}
          disabled={isSubmitting}
          isLoading={isSubmitting}
          onClick={async () => await onSubmitUser()}>
          { props.isEditing ? 'Update' : 'Send invite' }
        </PrimaryButton>
      </div>
    </form>
  </Modal>
})

export default UserInviteModal
