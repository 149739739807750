import React, { ReactElement, useEffect, useReducer, useState } from 'react'
import { observer } from 'mobx-react'
import Table from '../components/Table'
import { PrimaryButton } from '../components/Button'
import Web3 from '../state/User'
import UserInviteModal, { ICreateUser } from '../components/UserInviteModal'
import PageHeader from '../components/PageHeader'
import ConfirmButtonModal from '../components/ConfirmButtonModal'
import Radom, { IPermissionRole, Member, TeamInvite } from '../state/Radom'
import Dropdown, { DropdownItem } from '../components/Dropdown'
import Dots from '../icons/Dots'
import { Container } from '../components/Animations'
import { errorToast, infoToast, successToast } from '../util/Util'
import TimeText from '../components/TimeText'
import UserEditModal from '../components/UserEditModal'

interface UserState {
  userForm: ICreateUser
  userIsEditing: boolean
  createUserModalVisible: boolean
  deleteUserModalVisible: boolean
  userToDelete: any
}

const Team = observer((): ReactElement => {
  const [invites, setInvites] = useState([] as TeamInvite[])
  const [teamUsers, setTeamUsers] = useState([] as Member[])
  const [apiRoles, setAPIRoles] = useState([] as IPermissionRole[])
  const [isLoadingAPIRoles, setIsLoadingAPIRoles] = useState(false)
  const [closeDropdownFns, updateCloseDropdownFns] = useReducer((prev, next) => { return { ...prev, ...next } }, {})

  const [userState, updateUserState] = useReducer((oldState: UserState, newState: Partial<UserState>) => {
    return { ...oldState, ...newState }
  }, {
    userForm: {
      evmAddress: undefined,
      emailAddress: undefined,
      roles: []
    },
    userIsEditing: false,
    createUserModalVisible: false,
    deleteUserModalVisible: false,
    userToDelete: undefined
  })

  const [editUserModalVisible, setEditUserModalVisible] = useState(false)
  const [editUser, setEditUser] = useState<Member>()
  const [editUserRoles, setEditUserRoles] = useState([] as string[])

  const listPermissionRoles = (): void => {
    setIsLoadingAPIRoles(true)
    Radom.listPermissionRoles()
      .then(roles => setAPIRoles(roles))
      .finally(() => {
        setIsLoadingAPIRoles(false)
      })
  }

  const listTeamMembers = (): void => {
    Radom.listMembers().then(m => setTeamUsers(m))
  }

  const listTeamInvites = (): void => {
    Radom.listInvites().then(m => setInvites(m))
  }

  useEffect(() => {
    listPermissionRoles()
    listTeamMembers()
    listTeamInvites()
  }, [Web3.isConnected, Radom.isLoggedIn])

  const onDeleteInvite = async (inviteId: string): Promise<void> => {
    Radom.deleteInvite(inviteId)
      .then(() => {
        successToast('Successfully deleted invite')
        listTeamInvites()
      }).catch(err => {
        errorToast(err.response?.data?.error || err.message)
      })
  }

  const onDeleteUser = async (userId: string): Promise<void> => {
    Radom.deleteMember(userId)
      .then(() => {
        successToast('Successfully removed user')
        listTeamMembers()
      }).catch(err => {
        errorToast(err.response?.data?.error || err.message)
      })
  }

  return (
    <Container>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20, flexGrow: 1 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PageHeader title='Members' subTitle='Invite and manage team members' />
          <PrimaryButton
            onClick={() => updateUserState({
              createUserModalVisible: true,
              userForm: {
                emailAddress: undefined,
                evmAddress: undefined,
                roles: []
              },
              userIsEditing: false
            })}
            style={{ gap: 8 }}>
            <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
            <span>New user</span>
          </PrimaryButton>
        </div>
        <div>
          <Table
            borderless
            headers={[
              <span key="user">Member</span>,
              <span key="role">Roles</span>,
              <span key="lastLogin">Last login</span>,
              <span key="menu"></span>
            ]}
            isLoading={isLoadingAPIRoles}
            items={
              teamUsers.map((user, i) => [
                <span key="userAddr">{user.emailAddress ?? user.evmAddress}</span>,
                <span key="apiRoleName">
                  {
                    apiRoles
                      .filter(r => r.users.includes(user.userId))
                      .map(r => <div key={r.name}>{r.name}</div>)
                  }
                  {apiRoles.filter(r => r.users.includes(user.userId)).length <= 0 && '-'}
                </span>,
                <span key="lastLogin">
                  {user.lastLoginAt ? <TimeText date={new Date(user.lastLoginAt)} /> : '-' }
                </span>,
                <div key="menu" style={{ display: 'flex' }}>
                  <Dropdown
                    key={user.userId}
                    noChevron
                    selectedContent={<Dots width={15} />}
                    dropdownContent={
                      <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                        <DropdownItem onClick={() => {
                          setEditUser(user)
                          setEditUserRoles(apiRoles.filter(r => r.users.includes(user.userId)).map(r => r.id))
                          setEditUserModalVisible(true)
                        }}>Edit user</DropdownItem>
                        <ConfirmButtonModal
                          title='Delete user'
                          description='Are you sure you want to delete this user?'
                          action='Delete user'
                          onConfirm={async () => {
                            await onDeleteUser(user.userId)
                          }}
                          ActionComponent={props => <DropdownItem {...props}>Delete user</DropdownItem>}
                        />
                      </div>
                    }
                  />

                </div>
              ]).concat(invites.map(i => [
                <span key="userAddr">{i.emailAddress || i.evmAddress}</span>,
                <span key="apiRoleName">
                  {
                    apiRoles
                      .filter(r => i.roles.includes(r.id))
                      .map(r => <div key={r.name}>{r.name}</div>)
                  }
                </span>,
                <div key="lastLogin" style={{ display: 'flex' }}>
                  <span>Invite sent</span>
                </div>,
                <div key="menu">
                  <Dropdown
                    key={i.id}
                    noChevron
                    selectedContent={<Dots width={15} />}
                    onCloseFn={f => updateCloseDropdownFns({ [i.id]: f })}
                    dropdownContent={
                      <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                        <DropdownItem onClick={() => {
                          navigator.clipboard.writeText(i.inviteUrl)
                          infoToast('Invite URL copied to clipboard')
                          closeDropdownFns[i.id]()
                        }}>Copy invite URL</DropdownItem>
                        <ConfirmButtonModal
                          title='Delete invite'
                          description='Are you sure you want to delete this invite?'
                          action='Delete invite'
                          onConfirm={async () => {
                            await onDeleteInvite(i.id)
                          }}
                          ActionComponent={props => <DropdownItem {...props}>Delete invite</DropdownItem>}
                        />
                      </div>
                    }
                  />
                </div>

              ]))
            }
            emptyPlaceholderText='No members added yet'
          />
        </div>
        <UserInviteModal
          visible={userState.createUserModalVisible}
          isEditing={userState.userIsEditing}
          userForm={userState.userForm}
          onClose={() => {
            updateUserState({ createUserModalVisible: false })
            listTeamInvites()
          }}
          onRoleAssignment={() => listPermissionRoles()}
          onUserFormUpdate={(u) => updateUserState({ userForm: { ...userState.userForm, ...u } })}
        />

        {
          editUser &&
          <UserEditModal
            visible={editUserModalVisible}
            currentRoles={editUserRoles}
            user={editUser}
            onClose={() => {
              setEditUserModalVisible(false)
              listPermissionRoles()
            }}
          />
        }
      </div>
    </Container>
  )
})

export default Team
