import React from 'react'

export default function Calendar(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0.88 24 24" stroke="#747894" {...props}>
    <g clipPath="url(#clip0_1_5119)">
      <path d="M6 9.125L1.5 12.875L6 16.625" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18 9.125L22.5 12.875L18 16.625" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15 4.625L9 21.125" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1_5119">
        <rect width="24" height="24" fill="white" transform="translate(0 0.875)"/>
      </clipPath>
    </defs>
  </svg>
}
