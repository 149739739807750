
import React, { ReactElement } from 'react'

function Dots(props: React.SVGProps<SVGSVGElement>): ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="3.5 10.5 17.01 3.01" {...props}>
    <rect x="12.01" y="12.0001" width="0.01" height="0.01" transform="rotate(90 12.01 12.0001)" stroke="#292929" strokeWidth="3" strokeLinejoin="round"/>
    <rect x="19.01" y="12.0001" width="0.01" height="0.01" transform="rotate(90 19.01 12.0001)" stroke="#292929" strokeWidth="3" strokeLinejoin="round"/>
    <rect x="5.01001" y="12.0001" width="0.01" height="0.01" transform="rotate(90 5.01001 12.0001)" stroke="#292929" strokeWidth="3" strokeLinejoin="round"/>
  </svg>
}

export default Dots
