import React from 'react'

export default function Calendar(props: React.SVGProps<SVGSVGElement>): React.ReactElement {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0.5 24 24" fill="#747894" {...props}>
    <g clipPath="url(#clip0_1_4493)">
      <g clipPath="url(#clip1_1_4493)">
        <path d="M12.6737 6.78787L13.2277 6.23386C13.8973 5.57633 14.7995 5.20958 15.7381 5.2134C16.6779 5.21723 17.5781 5.59226 18.2426 6.25681C18.9072 6.92135 19.2822 7.82156 19.286 8.76136C19.2899 9.69996 18.9231 10.6021 18.2656 11.2718L16.008 13.5294C15.3657 14.172 14.502 14.5449 13.5938 14.5718C12.6856 14.5987 11.8014 14.2776 11.1222 13.6741C10.7351 13.3302 10.1426 13.3652 9.79866 13.7523C9.45477 14.1393 9.48977 14.7319 9.87683 15.0758C10.9136 15.9969 12.2631 16.4871 13.6493 16.446C15.0355 16.4049 16.3537 15.8357 17.3341 14.8549L19.5943 12.5948L19.5997 12.5893C20.6057 11.5668 21.1669 10.1882 21.161 8.75373C21.1552 7.3193 20.5828 5.94529 19.5685 4.93098C18.5542 3.91668 17.1802 3.34426 15.7457 3.33842C14.3113 3.33258 12.9326 3.89377 11.9101 4.89978L11.3478 5.46205C10.9817 5.82816 10.9817 6.42176 11.3478 6.78787C11.714 7.15399 12.3075 7.15399 12.6737 6.78787Z" />
        <path d="M10.4048 10.4305C11.313 10.4029 12.1975 10.7234 12.8772 11.3264C13.2645 11.67 13.857 11.6345 14.2006 11.2472C14.5442 10.8599 14.5088 10.2674 14.1215 9.92377C13.084 9.00339 11.7341 8.51422 10.3479 8.55633C8.96197 8.59844 7.6438 9.16912 6.6642 10.1503L4.40523 12.4046L4.39919 12.4107C3.39318 13.4332 2.83197 14.8119 2.83781 16.2463C2.84365 17.6807 3.41607 19.0547 4.43037 20.069C5.44468 21.0833 6.81869 21.6557 8.25312 21.6616C9.68754 21.6674 11.0662 21.1062 12.0887 20.1002L12.651 19.538C13.0171 19.1718 13.0171 18.5783 12.651 18.2121C12.2849 17.846 11.6913 17.846 11.3252 18.2121L10.7712 18.7662C10.1015 19.4237 9.19935 19.7904 8.26075 19.7866C7.32095 19.7828 6.42074 19.4077 5.75619 18.7432C5.09165 18.0787 4.71662 17.1784 4.71279 16.2386C4.70897 15.3002 5.0756 14.3982 5.73292 13.7286L7.99001 11.4762L7.99137 11.4748C8.63322 10.8316 9.49658 10.4581 10.4048 10.4305Z" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1_4493">
        <rect y="0.5" width="24" height="24" rx="6" fill="white"/>
      </clipPath>
      <clipPath id="clip1_1_4493">
        <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>
}
