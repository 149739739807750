import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Preview from '../../components/Preview'
import NavbarController from '../../state/NavbarController'
import { PAY_BASE_URL } from '../../util/Constants'
import { errorToast, successToast } from '../../util/Util'
import Radom, { IDonationLink } from '../../state/Radom'
import DonationLinkForm from './DonationLinkForm'

export const CreateDonationLink = (): ReactElement => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const onFormSubmit = async (donationLink: IDonationLink, logoImageFile, bannerImageFile): Promise<void> => {
    setLoading(true)
    try {
      const createdLink = await Radom.createDonationLink(donationLink)

      if (logoImageFile) {
        const formData = new FormData()
        formData.append('entityId', createdLink.id)
        formData.append('imageType', 'donationLinkAvatar')
        formData.append('image', logoImageFile)
        await Radom.uploadImage(formData)
      }

      if (bannerImageFile) {
        const formData = new FormData()
        formData.append('entityId', createdLink.id)
        formData.append('imageType', 'donationLinkBackground')
        formData.append('image', bannerImageFile)
        await Radom.uploadImage(formData)
      }

      successToast('Successfully created donation link')
      navigate('/donation_links')
    } catch (err) {
      console.error(err)
      errorToast(err.response
        ? err.response.data.error || err.response.data
        : err.message)
    }
    setLoading(false)
  }

  const [previewData, setPreviewData] = useState<string>()

  const onChange = (donationLink: IDonationLink, logoImgBytes, bannerImgBytes): void => {
    const data = {
      ...donationLink,
      imageUrl: logoImgBytes,
      bannerImageUrl: bannerImgBytes
    }
    setPreviewData(Buffer.from(JSON.stringify(data), 'utf8').toString('base64'))
  }

  useEffect(() => {
    NavbarController.setFullscreen(true)
    return () => NavbarController.setFullscreen(false)
  }, [])

  const [previewWidth, setPreviewWidth] = useState('90%')

  return <div style={{ display: 'flex', height: '100vh', position: 'relative' }}>
    <DonationLinkForm loading={loading} onChange={onChange} onSubmit={onFormSubmit} />

    <Preview
      iframeTitle='donate.radom.network'
      iframeLink={`${PAY_BASE_URL}/preview/donation#${previewData}`}
      previewWidth={previewWidth}
      onWidthChange={setPreviewWidth}
    />
  </div>
}

export default CreateDonationLink
