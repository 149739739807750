import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PrimaryButton } from '../components/Button'
import Spinner from '../components/Spinner'
import Radom, { User } from '../state/Radom'
import { RADOM_COLORS } from '../util/Constants'
import { Container } from '../components/Animations'
import { SettingContainer } from './Organization/Notifications'
import ConfirmButtonModal from '../components/ConfirmButtonModal'
import { errorToast, successToast } from '../util/Util'
import Modal from '../components/Modal'
import OneTimePassForm from '../components/OneTimePassForm'

const SubHeader = styled.div`
  font-size: 16px;
  color: ${RADOM_COLORS.BLACK};
  font-weight: 500;
`

const Account = observer(() => {
  const [user, setUser] = useState<User>()
  const [isLoading, setIsLoading] = useState(true)
  const [is2faModalVisible, setIs2faModalVisible] = useState(false)

  const getUser = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const u = await Radom.getUser()
      setUser(u)
    } catch (err) {
      errorToast(err.response?.data?.error || err.message)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getUser()
  }, [])

  if (isLoading) {
    return <Container>
      <Spinner />
    </Container>
  }

  return <Container>
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        <SubHeader>Settings</SubHeader>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <SettingContainer style={{ alignItems: 'center', gap: 30 }}>
            <div style={{ opacity: 0.5 }}>Account</div>
            <div>{user?.emailAddress || user?.evmAddress}</div>
          </SettingContainer>
          <SettingContainer style={{ alignItems: 'center', gap: 30 }}>
            <div style={{ opacity: 0.5 }}>Two-factor authentication</div>

            {
              user?.otpEnabled && user?.otpVerified

                ? <ConfirmButtonModal
                  title='Disable'
                  description='Are you sure you want to disable two-factor authentication?'
                  action='Disable'
                  onConfirm={async () => {
                    try {
                      await Radom.disableOneTimePassword()
                      successToast('Two-factor authentication disabled')
                      getUser()
                    } catch (err) {
                      errorToast(err.response?.data?.error || err.message)
                    }
                  }}
                  ActionComponent={props =>
                    <PrimaryButton {...props}>
                      <span>Disable</span>
                    </PrimaryButton>
                  }
                />
                : <PrimaryButton onClick={() => setIs2faModalVisible(true)}>
                  Enable
                </PrimaryButton>
            }
          </SettingContainer>
        </div>
      </div>

      <Modal
        title='Two-factor authentication'
        visible={is2faModalVisible}
        onClose={() => setIs2faModalVisible(false)}>
        <div style={{ padding: '0 30px 30px 30px', maxWidth: 500 }}>
          <OneTimePassForm onTwoFactorSetSuccess={ () => {
            successToast('Successfully set two-factor authentication')
            setIs2faModalVisible(false)
            getUser()
          }} />
        </div>
      </Modal>

    </div>
  </Container>
})

export default Account
