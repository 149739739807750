import { makeAutoObservable } from 'mobx'
import { Tokens } from '../util/Tokens'
import Radom, { IPriceQuote, ManagedBalance } from './Radom'
import { getMethod, getPaymentMethods } from '../util/Managed'
import User from './User'

export enum BalanceState {
  LOADING,
  LOADED
}

class Treasury {
  loading: boolean = true
  managedBalances: ManagedBalance[] = []
  priceQuotes: IPriceQuote[] = []

  constructor() {
    makeAutoObservable(this)
    this.loadPriceQuotes()
  }

  loadPriceQuotes = async (forceReload = false): Promise<void> => {
    if ((this.priceQuotes.length <= 0 && User.organization) || forceReload) {
      const organization = await User.getOrganization()
      this.priceQuotes = await Radom.getPriceQuotes(
        [...new Set(Object.keys(Tokens).concat(getPaymentMethods().map(p => p.ticker)))],
        [organization.defaultCurrency]
      )
    }
  }

  loadBalances = async (): Promise<void> => {
    this.loading = true
    await User.getOrganization()
    await this.loadPriceQuotes()
    Radom.getManagedBalance()
      .then(res => {
        this.managedBalances = res
      }).catch(() => {

      }).finally(() => {
        this.loading = false
      })
  }

  totalEstimatedDollarBalance(testMode: boolean): number {
    const managedBalance = this.managedBalances.reduce((t, b) => {
      const method = getMethod(b.network, b.token)

      if ((method.isTestnet && !testMode) || (!method.isTestnet && testMode)) {
        return t
      }

      const conversion = this.priceQuotes.find(p => p.from.toLowerCase() === method.ticker.toLowerCase())
      if (!conversion) return t
      return t + Number(b.balance) * conversion.value
    }, 0)

    return managedBalance
  }

  assetSum = (ticker: string, testMode: boolean): number => {
    const managedAssets = this.managedBalances.filter(g => getMethod(g.network, g.token).ticker === ticker)

    let managedBalance = 0
    if (managedAssets.length > 0) {
      managedBalance = managedAssets.reduce((t, a) => {
        const method = getMethod(a.network, a.token)
        if ((method.isTestnet && !testMode) || (!method.isTestnet && testMode)) {
          return t
        }

        return Number(a.balance) + t
      }, 0)
    }

    return managedBalance
  }

  dollarAssetSum = (ticker: string, testMode: boolean): number => {
    const conversion = this.priceQuotes.find(p => p.from.toLowerCase() === ticker.toLowerCase())
    return this.assetSum(ticker, testMode) * (conversion?.value ?? 1)
  }
}

export default new Treasury()
