import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'
import LinkExternal from './LinkExternal'

const DocsButtonStyle = styled.a`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background-color: #eff0f8;
  border-radius: 5px;
  cursor: pointer;
  height: fit-content;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
  transition: 0.2s ease all;

  :hover {
    background-color: ${RADOM_COLORS.ORANGE_LIGHT};
    color: ${RADOM_COLORS.ORANGE};
    opacity: 0.9;

    > svg {
      stroke: ${RADOM_COLORS.ORANGE};
    }
  }

  :active {
    opacity: 0.5;
  }
`

export default function DocsButton({ docsLink = '' }): ReactElement {
  return <DocsButtonStyle href={'https://docs.radom.com/' + docsLink}
    target="_blank">
    <span style={{ fontSize: 12, marginRight: 3 }}>Docs</span>
    <LinkExternal width={15}
      height={15}
      stroke="rgba(0,0,0,0.4)" />
  </DocsButtonStyle>
}
