import React, { ReactElement, useReducer, useState } from 'react'
import Modal from './Modal'
import { IconButton, PrimaryButton } from './Button'
import { TextInputWithLabel } from './Input'
import { RADOM_COLORS } from '../util/Constants'
import Radom, { IWebhook } from '../state/Radom'
import { InputLabel, TextInput } from '../components/Input'
import Copy from '../icons/Copy'
import { errorToast, infoToast, successToast } from '../util/Util'

function WebhookModal({ ActionComponent, ...props }): ReactElement {
  const [isUpdatingWebhook, setIsUpdatingWebhook] = useState(false)
  const [newWebhook, setNewWebhook] = useState<IWebhook | undefined>(undefined)
  const [isVisible, setIsVisible] = useState(false)
  const [webhookForm, updateWebhookForm] = useReducer((oldState, newState) => {
    return { ...oldState, ...newState }
  }, {
    url: props.webhookForm.url
  })

  const onUpdateWebhook = async (): Promise<void> => {
    setIsUpdatingWebhook(true)
    try {
      if (props.isUpdating) {
        await Radom.updateWebhook(props.webhookId, webhookForm)
        successToast('Successfully updated webhook')
      } else {
        const newWebhook = await Radom.createWebhook({
          url: webhookForm.url
        })
        setNewWebhook(newWebhook)
        successToast('Successfully created webhook')
      }
      props.onClose()
    } catch (err) {
      errorToast(err.response?.data?.error || err.message)
    }
    if (!props.isUpdating) {
      updateWebhookForm({ url: '' })
    }
    setIsUpdatingWebhook(false)
  }

  return <>
    <ActionComponent onClick={(e: React.MouseEvent) => {
      e.preventDefault()
      setIsVisible(true)
      setNewWebhook(undefined)
    }} />
    <Modal
      visible={isVisible}
      title={props.isUpdating ? 'Update webhook' : newWebhook ? 'Webhook created' : 'Create webhook'}
      onClose={() => setIsVisible(false)}>
      <form
        onSubmit={e => {
          onUpdateWebhook()
          e.preventDefault()
          e.stopPropagation()
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          maxWidth: 600
        }}>
        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', rowGap: 20, padding: '0 30px' }}>
          {
            !newWebhook && <>
              <div style={{ fontSize: 14 }}>
                <TextInputWithLabel
                  label={'URL of webhook endpoint'}
                  value={ webhookForm.url }
                  placeholder="E.g. https://provider.com/radom_webhook"
                  required
                  disabled={isUpdatingWebhook}
                  onChange={e =>
                    updateWebhookForm({
                      url: (e.target as HTMLInputElement).value
                    })
                  }
                />
              </div>
            </>
          }
          {
            newWebhook && <>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <span style={{ fontSize: 14, fontWeight: 600 }}>
                  Secure your verification key
                </span>
                <span style={{ fontSize: 14, color: RADOM_COLORS.GRAY_DARKEST }}>
                  Your webhook has been successfully created, along with a verification key that will be included in
                  all webhook POST request headers. The verification key will only be shown once for security purposes.
                  Copy the verification key below and store it in a safe place.
                </span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <InputLabel style={{ fontSize: 14 }}>Verification key</InputLabel>
                <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                  <TextInput value={newWebhook.verificationKey} disabled />
                  <IconButton
                    type='button'
                    style={{ padding: 0 }}>
                    <Copy style={{ width: 20 }} onClick={() => {
                      navigator.clipboard.writeText(newWebhook.verificationKey ?? '')
                      infoToast('Verification key copied to clipboard')
                    }} />
                  </IconButton>
                </div>
              </div>
            </>
          }
        </div>
        <div style={{ padding: 30 }}>
          <PrimaryButton
            disabled={!webhookForm.url.length && !newWebhook}
            isLoading={isUpdatingWebhook}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              if (newWebhook) {
                setIsVisible(false)
                // setNewWebhook(undefined)
              } else {
                onUpdateWebhook()
              }
            }}
            style={{ width: '100%' }}>
            {
              props.isUpdating && <>Update webhook</>
            }
            {
              !props.isUpdating && !newWebhook && <>Create webhook</>
            }
            {
              !props.isUpdating && newWebhook && <>Close</>
            }
          </PrimaryButton>
        </div>
      </form>
    </Modal>
  </>
}

export default WebhookModal
