import React, { ReactElement, useState } from 'react'
import { observer } from 'mobx-react'
import PageHeader from '../components/PageHeader'
import BillingEvents from './Billing/BillingEvents'
import { Container } from '../components/Animations'
import Dropdown, { DropdownItem } from '../components/Dropdown'
import Dots from '../icons/Dots'
import Modal from '../components/Modal'
import ExportPaymentsForm from '../components/ExportPaymentsForm'

const Billing = observer((): ReactElement => {
  const [exportPaymentsModalOpen, setExportPaymentsModalOpen] = useState(false)

  return <Container>
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, rowGap: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <PageHeader title='Payments' subTitle='View your payments' />
        <Dropdown
          noChevron
          selectedContent={<Dots width={15} />}
          dropdownContent={
            <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
              <DropdownItem onClick={() => setExportPaymentsModalOpen(true)}>Export payments</DropdownItem>
            </div>
          }
        />
      </div>
      <BillingEvents />
    </div>

    {
      exportPaymentsModalOpen &&
      <Modal visible={exportPaymentsModalOpen} onClose={() => setExportPaymentsModalOpen(false)} title='Export payments'>
        <ExportPaymentsForm />
      </Modal>
    }

  </Container>
})

export default Billing
