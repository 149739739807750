import React, { ReactElement, ReactNode, useState } from 'react'
import styled from 'styled-components'
import { RADOM_COLORS } from '../util/Constants'
import Dropdown from './Dropdown'
import { useNavigate } from 'react-router-dom'
import Close from '../icons/Close'
import { IconButton } from './Button'
import { isEqual } from 'lodash'

interface SelectorProps<T> {
  onCloseFn: () => void
  selectedRoles: T[]
  roles: T[]
  onSelectRole: (p: T) => void
  onRemoveRole: (p: T) => void
  roleCreationPage: string
  formatFunction: (p: T) => string | ReactNode
  disabled?: boolean
}

const RoleListItem = styled.div`
  min-height: max-content;
  padding: 10px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  
  :hover {
    cursor: pointer;
    background-color: ${RADOM_COLORS.GRAY_LIGHTEST}
  }

  &:first-child {
    :hover {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  &:last-child {
    :hover {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`

export const RoleSelector = <T extends unknown>(props: SelectorProps<T>): ReactElement => {
  const [closeFn, setCloseFn] = useState(() => () => {})
  const navigate = useNavigate()

  return (
    <div>
      <Dropdown
        overflow='hidden'
        disabled={props.disabled}
        selectedContent={
          <div style={{ display: 'flex', gap: 5 }}>
            {
              props.selectedRoles && props.selectedRoles.length <= 0 &&
              <span>Select a role</span>
            }
            {
              props.selectedRoles?.map((role, i) =>
                <div
                  key={i}
                  style={{
                    backgroundColor: RADOM_COLORS.GRAY_LIGHTEST,
                    padding: 5,
                    borderRadius: 5,
                    display: 'flex',

                    alignItems: 'center'
                  }}>
                  <span>{props.formatFunction(role)}</span>
                  <IconButton
                    type="button"
                    style={{ padding: 5 }}
                    onClick={() => props.onRemoveRole(role)}>
                    <Close style={{ width: 10, pointerEvents: 'none' }} />
                  </IconButton>
                </div>
              )
            }
          </div>
        }
        dropdownContent={
          <>
            <RoleListItem
              style={{ color: RADOM_COLORS.BLACK }}
              onClick={() => {
                props.onCloseFn()
                navigate(props.roleCreationPage)
              }}>
              + Create role
            </RoleListItem>
            {
              props.roles
                .filter(r => !props.selectedRoles.find(sr => isEqual(r, sr)))
                .map((r, i) =>
                  <RoleListItem
                    key={i}
                    onClick={() => {
                      props.onSelectRole(r)
                      closeFn?.()
                    }}>
                    {props.formatFunction(r)}
                  </RoleListItem>
                )
            }
          </>
        }
        onCloseFn={fn => setCloseFn(() => fn)}
      />
    </div>
  )
}
