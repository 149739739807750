import { observer } from 'mobx-react'
import React, { ReactElement, useState } from 'react'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import { InputLabel, TextInputWithLabel } from '../../components/Input'
import Modal, { ModalProps } from '../../components/Modal'
import Radom, { DEFAULT_ORGANIZATION_ID } from '../../state/Radom'
import User from '../../state/User'
import { RADOM_COLORS } from '../../util/Constants'
import { ManagedPaymentMethod, getPaymentMethods } from '../../util/Managed'
import { errorToast, successToast, useTestMode } from '../../util/Util'
import { FiatVerificiationFormData } from './FiatVerificationForm'

type AccountModalProps = ModalProps & {
  onSubmit: (data: FiatVerificiationFormData) => Promise<void>
  openAccountVerificationModal: () => void
  onAccountCreated: () => void
}

const CreateAccountModal = observer(({ openAccountVerificationModal, ...props }: AccountModalProps): ReactElement => {
  const [accountName, setAccountName] = useState('')
  const [accountType, setAccountType] = useState('crypto')

  // Crypto options
  const [selectedCryptoType, setSelectedCryptoType] = useState<ManagedPaymentMethod>()
  const [cryptoAddress, setCryptoAddress] = useState<string>()

  const [isLoading, setIsLoading] = useState(false)

  const [testMode] = useTestMode()

  const createAccount = async (): Promise<void> => {
    if (!selectedCryptoType || !cryptoAddress) {
      return
    }

    setIsLoading(true)
    try {
      await Radom.createWithdrawalAccount({
        name: accountName,
        accountDetails: {
          Crypto: {
            network: selectedCryptoType?.name,
            address: cryptoAddress
          }
        }
      })
      props.onAccountCreated()
      successToast('Withdrawal account created')
    } catch (err) {
      errorToast(err.response?.data?.error || err.message)
    }
    setIsLoading(false)
  }

  const renderCryptoForm = (): ReactElement => (
    <>
      <TextInputWithLabel
        label='Name'
        placeholder='Enter a name for this account'
        disabled={isLoading}
        required
        value={accountName}
        onChange={e => {
          const el = (e.target as HTMLInputElement)
          const value = el.value
          setAccountName(value)
        }}
      />
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
        <InputLabel>Network</InputLabel>
        <Dropdown
          disabled={isLoading}
          selectedContent={
            selectedCryptoType
              ? <div style={{
                fontSize: 14,
                alignItems: 'center',
                display: 'flex',
                gap: 10
              }}>
                <img
                  src={selectedCryptoType.logo}
                  style={{
                    height: 20,
                    width: 'auto',
                    maxWidth: 20
                  }} />
                {selectedCryptoType.name}
              </div>
              : <span style={{ fontSize: 14 }}>Select network</span>
          }
          dropdownContent={
            getPaymentMethods()
              .filter(t => !t.hostChain)
              .filter(t => t.name !== selectedCryptoType?.name)
              .filter(t => !t.isDisabled)
              .filter(t => testMode ? t?.isTestnet : !t?.isTestnet)
              .filter(t => !t.isFiat)
              .map(t =>
                <DropdownItem
                  key={t.name}
                  onClick={() => {
                    setSelectedCryptoType(t)
                  }}
                  style={{
                    fontSize: 14,
                    alignItems: 'center',
                    display: 'flex',
                    gap: 10
                  }}>
                  <img
                    src={t.logo}
                    style={{
                      height: 20,
                      width: 'auto',
                      maxWidth: 20
                    }} />
                  {t.name}
                </DropdownItem>
              )
          } />
      </div>

      <TextInputWithLabel
        label={'Address'}
        placeholder={'Enter address'}
        disabled={isLoading}
        required
        value={cryptoAddress}
        onChange={e => {
          const el = (e.target as HTMLInputElement)
          const value = el.value
          setCryptoAddress(value)
        }}
      />
    </>
  )

  const renderCryptoButton = (): ReactElement => (
    <div style={{
      padding: 30,
      borderRadius: 15,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    }}>
      <PrimaryButton
        isLoading={isLoading}
        style={{ width: '100%' }}
        onClick={() => {
          createAccount()
        }}>
          Create account
      </PrimaryButton>
    </div>
  )

  const renderFiatBanner = (): ReactElement => {
    if (User.organization?.verificationStatus !== 'Verified') {
      return (
        <div style={{
          padding: 30,
          borderRadius: 15,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0
        }}>
          <div style={{
            border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
            backgroundColor: RADOM_COLORS.GRAY_LIGHTEST,
            fontSize: 14,
            padding: 20,
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            gap: 5
          }}>
            <div style={{ display: 'flex', gap: 30, alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <span style={{ fontWeight: 500, fontSize: 15 }}>Your organization is unverified</span>
                <span style={{ opacity: 0.5 }}>
                Your organization is unverified. To enable production payments, please verify your organization.
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (User.organization.bridgeCustomer === null || User.organization.bridgeCustomer?.status === 'Created') {
      return (
        <div style={{
          padding: 30,
          borderRadius: 15,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0
        }}>
          <div style={{
            border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
            backgroundColor: RADOM_COLORS.GRAY_LIGHTEST,
            fontSize: 14,
            padding: 20,
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            gap: 5
          }}>
            <div style={{ display: 'flex', gap: 30, alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <span style={{ fontWeight: 500, fontSize: 15 }}>Get started with Bridge</span>
                <span style={{ opacity: 0.5 }}>
              In order to add a bank account, your identity will need to be verified.
                </span>
              </div>
              <div>
                <PrimaryButton
                  disabled={User.organization.organizationId !== DEFAULT_ORGANIZATION_ID}
                  onClick={openAccountVerificationModal}>
                Verify
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (User.organization.bridgeCustomer?.status === 'Pending') {
      return (
        <div style={{
          padding: 30,
          borderRadius: 15,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0
        }}>
          <div style={{
            border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
            backgroundColor: RADOM_COLORS.GRAY_LIGHTEST,
            fontSize: 14,
            padding: 20,
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            gap: 5
          }}>
            <div style={{ display: 'flex', gap: 30, alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <span style={{ fontWeight: 500, fontSize: 15 }}>Your account verification is pending</span>
                <span style={{ opacity: 0.5 }}>
              Bridge is currently verifying your account.
              Once the verification is complete, Fiat account creation will be enabled.
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (User.organization.bridgeCustomer?.status === 'Accepted') {
      return (
        <div style={{
          padding: 30,
          borderRadius: 15,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0
        }}>
          <div style={{
            border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
            backgroundColor: RADOM_COLORS.GRAY_LIGHTEST,
            fontSize: 14,
            padding: 20,
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            gap: 5
          }}>
            <div style={{ display: 'flex', gap: 30, alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <span style={{ fontWeight: 500, fontSize: 15 }}>Your account verification is verified</span>
                <span style={{ opacity: 0.5 }}>
              Your account has been verified by Bridge. You can now create Fiat accounts.
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return <></>
  }

  return <Modal {...props} innerStyles={{ width: 550 }}>
    <div style={{
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'start',
      gap: 10,
      padding: '0px 30px'
    }}>
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', rowGap: 20 }}>
        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 10 }}>
          <InputLabel>Account type</InputLabel>
          <div style={{ fontSize: 14, display: 'flex', gap: 20 }}>
            <SecondaryButton
              disabled={isLoading}
              onClick={() => setAccountType('crypto')}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                border: 0,
                padding: 5,
                color: accountType === 'crypto' ? RADOM_COLORS.BLACK : RADOM_COLORS.GRAY_DARKEST
              }}
              type='button'>
              <Checkbox isRadio checked={accountType === 'crypto'} />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <span>Crypto</span>
                <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                Transfer to a crypto address
                </span>
              </div>
            </SecondaryButton>
            { User.organization && (
              <SecondaryButton
                disabled={isLoading}
                onClick={() => setAccountType('fiat')}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 10,
                  border: 0,
                  padding: 5,
                  color: accountType === 'fiat' ? RADOM_COLORS.BLACK : RADOM_COLORS.GRAY_DARKEST
                }}
                type='button'>
                <Checkbox isRadio checked={accountType === 'fiat'} />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <span>Fiat</span>
                  <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                Offramp to a bank account
                  </span>
                </div>
              </SecondaryButton>
            )}

          </div>
        </div>

        {accountType === 'crypto' && renderCryptoForm()}
      </div>
    </div>

    {accountType === 'crypto' && renderCryptoButton()}

    {accountType === 'fiat' && renderFiatBanner()}
  </Modal>
})

export default CreateAccountModal
