import React from 'react'

const Order = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => {
  return <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="0.5" width="48" height="48" rx="6.85714" fill="url(#paint0_linear_54_6)"/>
    <g clipPath="url(#clip0_54_6)">
      <path d="M16.5 16.5C16.5 16.5 19.5 13.5 24.5 13.5C31.5 13.5 35.5 20.5 35.5 20.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M32.5 32.5C32.5 32.5 29.5 35.5 24.5 35.5C17.5 35.5 13.5 28.5 13.5 28.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M29.5 20.5H35.5V14.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.5 28.5H13.5V34.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_54_6" x1="0.5" y1="0.5" x2="43.8973" y2="46.3064" gradientUnits="userSpaceOnUse">
        <stop stopColor="#618AF9"/>
        <stop offset="1" stopColor="#2B47D8"/>
      </linearGradient>
      <clipPath id="clip0_54_6">
        <rect width="32" height="32" fill="white" transform="translate(8.5 8.5)"/>
      </clipPath>
    </defs>
  </svg>
}

export default Order
