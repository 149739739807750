import React, { PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ModalFadeIn, ScaleIn } from './Animations'
import { IconButton } from './Button'
import Close from '../icons/Close'
import NavbarController from '../state/NavbarController'

const ModalContainer = styled.div`
  background-color: rgba(7, 7, 40, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${ModalFadeIn} 0.2s;
`

const ModalInner = styled.div<{ minHeight?: string }>`
  background-color: white;
  box-shadow: 0 0 20px rgba(7, 7, 40, 0.5);
  min-width: 450px;
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
  max-width: 90%;
  border-radius: 25px;
  animation: ${ScaleIn} 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  max-height: 90%;
  overflow: scroll;
`

export type ModalProps = PropsWithChildren<{
  visible: boolean
  onClose: any
  innerStyles?: React.CSSProperties
  title?: string
  minHeight?: string
  uncloseable?: boolean
}>

const Modal = (props: ModalProps): ReactElement => {
  const inner = useRef<HTMLDivElement>(null)

  const [isMouseDown, setIsMouseDown] = useState(false)

  useEffect(() => {
    NavbarController.setModalOpen(props.visible)
    return () => NavbarController.setModalOpen(false)
  }, [props.visible])

  if (!props.visible) {
    return <></>
  }

  return <ModalContainer
    onMouseDown={e => {
      if (!(e.target === inner.current || inner.current?.contains(e.target as any))) {
        setIsMouseDown(true)
      }
    }}
    onMouseUp={e => {
      if (!(e.target === inner.current || inner.current?.contains(e.target as any)) && isMouseDown) {
        if (!props.uncloseable) {
          props.onClose()
        }
      }
      setIsMouseDown(false)
    }}>
    <ModalInner
      minHeight={props.minHeight}
      ref={inner}
      style={props.innerStyles}>

      {
        props.title &&
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          padding: 30,
          alignItems: 'center',
          zIndex: 1,
          position: 'sticky',
          top: 0,
          backgroundColor: 'white'
        }}>
          <span style={{ fontSize: 18, fontWeight: 500 }}>{props.title}</span>
          {
            !props.uncloseable &&
            <IconButton type='button' onClick={() => props.onClose()} style={{ padding: 0 }}>
              <Close style={{ width: '14px' }} />
            </IconButton>
          }
        </div>
      }

      {props.children}
    </ModalInner>
  </ModalContainer>
}

export default Modal
