import React, { ReactElement, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ProductForm from '../../components/ProductForm'
import Radom, { IProduct, IProductCreate } from '../../state/Radom'
import { Currencies } from '../../util/Currencies'
import { errorToast, successToast } from '../../util/Util'

interface IProps {
  modal?: boolean
  onCreateProduct?: (product: IProduct) => void
  disableRecurring?: boolean
}

const defaultProduct = {
  sellerAddress: '',
  name: '',
  description: '',
  chargingIntervalSeconds: 0,
  currency: Currencies[0].ticker,
  price: '',
  image: undefined,
  meteredChargingInterval: 0,
  meteredBudget: 0,
  addOns: [],
  sendSubscriptionEmails: true
}

export interface IProductCreateV2 extends IProductCreate {
  image?: File
}

const CreateProduct = ({ modal, onCreateProduct, disableRecurring }: IProps): ReactElement => {
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [productForm, updateProductForm] = useReducer(
    (p: IProductCreateV2, n: Partial<IProductCreateV2>) => {
      return { ...p, ...n }
    }, defaultProduct as any)

  const handleUpdateForm = (form: Partial<IProductCreateV2>): void => {
    updateProductForm(form)
  }

  const handleCreateProduct = async (): Promise<void> => {
    setIsSubmitting(true)

    try {
      const product = await Radom.createProduct({
        name: productForm.name,
        description: productForm.description,
        chargingIntervalSeconds: productForm.chargingIntervalSeconds !== undefined &&
         productForm.chargingIntervalSeconds <= 0
          ? undefined
          : productForm.chargingIntervalSeconds,
        addOns: [],
        currency: productForm.currency,
        price: productForm.price,
        sendSubscriptionEmails: productForm.sendSubscriptionEmails,
        productType: productForm.productType
      })

      if (productForm.image) {
        const formData = new FormData()
        formData.append('entityId', product.id)
        formData.append('imageType', 'productImage')
        formData.append('image', productForm.image)
        await Radom.uploadImage(formData)
      }

      setIsSubmitting(false)
      successToast('Created product successfully')
      if (onCreateProduct) {
        onCreateProduct(product)
      } else {
        navigate('/products')
      }
    } catch (err) {
      setIsSubmitting(false)
      console.error(err)
      const msg = err.response
        ? typeof err.response.data === 'object'
          ? err.response.data.error
          : err.response.data
        : err.message
      errorToast(msg)
    }
  }
  return (
    <div style={{ maxWidth: modal ? '100%' : '400px', width: '100%', margin: 'auto', padding: modal ? '0' : '50px 0' }}>
      <div>
        {!modal && <div style={{
          display: 'flex',
          alignItems: 'center',
          columnGap: 10,
          marginBottom: 10
        }}>
          <h1 style={{ fontSize: '28px', fontWeight: 300 }}>Create a product</h1>
        </div>}
        <ProductForm
          disableRecurring={disableRecurring}
          isLoading={false}
          isSubmitting={isSubmitting}
          form={productForm}
          onFormSubmit={handleCreateProduct}
          onFormUpdate={handleUpdateForm}
        />
      </div>
    </div>
  )
}

export default CreateProduct
